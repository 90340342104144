import {takeEvery} from "redux-saga/effects";
import {SET_FILTER} from "../reducers/filter";

export const setFilter = (action: any) => {
    return(
        {
            type: SET_FILTER,
            data: action
        }
    )
}

export default function* filter() {
    yield takeEvery(SET_FILTER, setFilter);
}
