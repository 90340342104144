import React, { useState, useEffect } from "react";
import styles from "../AddApp/AddApp.module.scss";
import Button from "../../Button/Button";
import ModalAddDescription from "../../Modal/ModalAddDescription/ModalAddDescription";
import Modal from "../../Modal/Modal";
import { Field, FieldProps } from "formik";

const AddDescription = (props: IAddDescription) => {
  const { setFieldValue, label, errors, touched, name, required } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState<string>("");

  const handleData = (value: any) => {
    setData(value);
  };

  useEffect(() => {
    setFieldValue("description", data);
  }, [data, setFieldValue]);

  function validate(value: any) {
    let error;
    if (!value.length) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.list}>
          <Field
            component={AddDescriptionField}
            validate={required && ((e: any) => validate(e))}
            data={data}
            name={name}
            setModalOpen={setModalOpen}
            handleData={handleData}
          />
        </div>
        {errors[name] && touched[name] && (
          <span className={styles.error}>{errors[name]}</span>
        )}
      </div>
      <Modal
        title="Add Desciption"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalAddDescription
          setModalOpen={setModalOpen}
          data={data}
          handleData={handleData}
        />
      </Modal>
    </>
  );
};

export default AddDescription;

const AddDescriptionField = (props: IAddDescriptionField) => {
  const { data, setModalOpen, form, field, handleData } = props;

  useEffect(() => {
    handleData(form.initialValues[field.name]);
    // TODO: fix only first render workaround
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data && <div className={styles.item}>{field.value}</div>}
      <Button
        title="+ Add"
        type="outline"
        handleClick={() => setModalOpen(true)}
      />
    </>
  );
};

export interface IAddDescriptionField extends FieldProps {
  data: string;
  setModalOpen: (e: boolean) => void;
  handleData: (e: boolean) => void;
}

interface IAddDescription {
  label?: string;
  setFieldValue: (e: string, val: any) => void;
  errors?: any;
  touched?: any;
  name: string;
  required?: boolean;
}
