export const GetLocationDashboard = () => [
  {
    title: "SNMP Bandtwidth details",
    id: "location-column-line-chart-old-snmp",
    type: "column-line-chart-old",
    dashboardType: "location",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Bandwidth speed test details",
    id: "location-column-line-chart-old-speed",
    type: "column-line-chart-old",
    dashboardType: "location",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Overall EUX score of location",
    id: "location-gauge-results-old-eux",
    type: "gauge-results-old",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Heatmap of location specific metrics",
    id: "location-heatmap-old",
    type: "heatmap-old",
    dashboardType: "location",
    size: 2,
    endpoint: "metric/heatmap",
    chartType: "agents",
  },
  {
    title: "Availability score",
    id: "location-gauge-results-old-availability",
    type: "gauge-results-old",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top applications in this location",
    id: "location-ordered-list-top-apps",
    type: "ordered-list",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
    data: [
      {
        title: "ZOOM",
        pathname: "application",
        dashboardType: "app3",
      },
      {
        title: "Microsoft 365",
        pathname: "application",
        dashboardType: "app2",
      },
      {
        title: "Test App",
        pathname: "application",
        dashboardType: "app1",
      },
    ],
  },
  {
    title: "Worse applications in this location",
    id: "location-ordered-list-worse-apps",
    type: "ordered-list",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
    data: [
      {
        title: "Test App",
        pathname: "application",
        dashboardType: "app1",
      },
      {
        title: "Microsoft 365",
        pathname: "application",
        dashboardType: "app2",
      },
      {
        title: "ZOOM",
        pathname: "application",
        dashboardType: "app3",
      },
    ],
  },
  {
    title: "Top agents from this location",
    id: "location-ordered-list-top-agents",
    type: "ordered-list",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
    data: [
      {
        title: "Agent 1",
        pathname: "agent",
        dashboardType: "agent",
      },
      {
        title: "Agent 2",
        pathname: "agent",
        dashboardType: "agent",
      },
      {
        title: "Agent 3",
        pathname: "agent",
        dashboardType: "agent",
      },
    ],
  },
  {
    title: "Worse agents from this location",
    id: "location-ordered-list-worse-agents",
    type: "ordered-list",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
    data: [
      {
        title: "Agent 1",
        pathname: "agent",
        dashboardType: "agent",
      },
      {
        title: "Agent 2",
        pathname: "agent",
        dashboardType: "agent",
      },
      {
        title: "Agent 3",
        pathname: "agent",
        dashboardType: "agent",
      },
    ],
  },
  {
    title: "Wifi performance metrics",
    id: "location-column-line-chart-old-wifi",
    type: "column-line-chart-old",
    dashboardType: "location",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Availability of agents location",
    id: "location-gauge-results-old-multi-agents-availability",
    type: "gauge-results-old-multi",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Availability of apps at location",
    id: "location-gauge-results-old-multi-apps-availability",
    type: "gauge-results-old-multi",
    dashboardType: "location",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Performance od apps running at location",
    id: "location-apps-performance-extended",
    type: "apps-performance-extended",
    dashboardType: "location",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Path Analytics",
    id: "location-path-analytics",
    type: "path-analytics-old",
    dashboardType: "location",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Applications at site and test running with performance metrics",
    id: "location-apps-performance-table",
    type: "apps-performance-table",
    dashboardType: "location",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Agents running at location with performance metrics",
    id: "location-agents-table-old",
    type: "agents-table-old",
    dashboardType: "location",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Devices discovered at location",
    id: "location-devices-details-table",
    type: "devices-details-table",
    dashboardType: "location",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
];
