import React from "react";
import styles from "./FilterInput.module.scss";
import cn from "classnames";

const FilterInput = (props: IFilterInput) => {
  const { name, label, placeholder, value, isBgGray, handleChange } = props;

  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={cn(styles.input, isBgGray && styles["isPlain"])}
        type="text"
        name="name"
        id={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default FilterInput;

export interface IFilterInput {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  isBgGray?: boolean;
  handleChange?: (e: any) => void;
}
