import React from "react";
import styles from "./ModalDeleteElem.module.scss";

interface IModalDeleteElem {
  children: React.ReactChild;
}

const ModalDeleteElem = (props: IModalDeleteElem) => {
  const { children } = props;

  return <div className={styles.element}>{children}</div>;
};
export default ModalDeleteElem;
