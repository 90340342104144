
import {all, put, takeEvery} from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import {GetTableDone, GetTableFailed} from "../../actions/widgets/TableDevices.action";
import {GetTableT, GET_TABLE_DEVICES} from "../../types/widgets/TableDevices.types";


function* GetTableHandler({params}: GetTableT) {
  try {

    const {data} = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        agentId: params.agentId
      },
    })

    if (data.length) {
      const res = data.map((elem: any) => {
        return {
          id: elem.id || "",
          device: {
            name: elem.name || "Unknown",
            id: elem.id || "",
            customerId: elem.customerId || "",
            dashboardType: "device"
          },
          deviceSource: elem.deviceSource || "",
          customerId: elem.customerId || "",
          name: elem.name || "Unknown",
          agentName: elem.agentName || "",
          status: (elem.status === false && "offline") || (elem.status === true && "online") || "unknown",
          deviceType: elem.deviceType || "",
          vendor: elem.vendor || "",
          connectionType: elem.connectionType || "",
          model: elem.model || "",
          alias: elem.alias || "",
          circuitId: elem.circuitId || "",
          description: elem.description || "",
          mac: elem.mac || "",
          ip: elem.ip || "",
          osVersion: elem.osVersion || "",
          latency: elem.latency || "",
          lastTestDate: elem.lastTestDate || "",
          location: `${elem.country ? elem.country : ""} ${elem.province ? elem.province : ""
            } ${elem.city ? elem.city : ""} ${elem.zipCode ? elem.zipCode : ""
            } ${elem.street ? elem.street : ""}`,
          country: elem.country || "",
          province: elem.province || "",
          zipCode: elem.zipCode || "",
          city: elem.city || "",
          street: elem.street || "",
          locationGroup: elem.locationGroup || "",
          bandwidthDownload: elem.bandwidthDownload || "",
          bandwidthUpload: elem.bandwidthUpload || "",
          snmpVersion: elem.snmpVersion || "",
          communityString: elem.communityString || "",
          userName: elem.userName || "",
          privProtocol: elem.privProtocol || "",
          privKey: elem.privKey || "",
          authProtocol: elem.authProtocol || "",
          authKey: elem.authKey || "",
          agentId: elem.agentId || "",
          interface: elem.interface || "",
          addedDevice: elem.addedDevice
        }
      })
      yield put(GetTableDone(params.chartType, res));
    } else {
      yield put(GetTableDone(params.chartType, []));
    }

  } catch (error) {
    yield put(GetTableFailed(params.chartType));
  }
}


export default function* WatchTableDevices() {
  yield all([
    takeEvery(GET_TABLE_DEVICES, GetTableHandler)
  ]);
}
