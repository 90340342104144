import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_CUSTOM_PRESETS, SET_CUSTOM_PRESETS_DONE, SET_CUSTOM_PRESETS_FAILED, GET_PRESETS, GET_PRESETS_DONE, GET_PRESETS_FAILED, DELETE_PRESETS, DELETE_PRESETS_DONE, DELETE_PRESETS_FAILED, SET_CURRENT_DASHBOARD, SHARE_CUSTOM_PRESET, ADD_TO_CUSTOM_PRESETS, UNSHARE_DEFAULT_PRESET, SetCurrentDashboardT } from "../types/DashboardPresets.types";
import axios from "../../api/axiosConfig";
import { GetLastDashboardPreset } from "../../functions/ManageDashboard/GetLastDashboardPreset/GetLastDashboardPreset";
import { GetPresets, SetCurrentDashboardDone, SetCurrentDashboardFailed } from "../actions/DashboadPresets.action";

function* SetCurrentDashboardHandler ( { preset }: SetCurrentDashboardT ) {
	try {
		const { data } = yield axios.post( "/user/settings", [preset] )
		const currentPreset = GetLastDashboardPreset( data.processed )
		yield put( SetCurrentDashboardDone( currentPreset ) );
	} catch ( error ) {
		yield put( SetCurrentDashboardFailed() );
	}
}

function* GetPresetsHandler ( { id }: any ): any {
	try {

		const response = yield axios.get( "/user/settings", {
			params: {
				loginId: id
			},
		} )

		const customPresets = response.data.filter( ( elem: any ) => {
			return elem.settingsType === "custom"
		} ).map( ( elem: any ) => {
			return {
				id: elem.id,
				title: elem.settings.title,
				widgets: elem.settings.widgets
			}
		} )
		const sharedPresets = response.data.filter( ( elem: any ) => {
			return elem.settingsType === "shared"
		} ).map( ( elem: any ) => {
			return {
				id: elem.id,
				title: elem.settings.title,
				widgets: elem.settings.widgets
			}
		} )

		const response2 = yield axios.get( "/user/settings", {
			params: {
				settingsType: "global"
			},
		} )

		const checkIfDefault = ( preset: any ) => {
			return customPresets.find(
				( elem: any ) => elem.title === preset.title
			);
		};

		const sharedConstantPresets = response2.data.filter( ( elem: any ) => {
			return elem.settingsType === "global"
		} ).map( ( elem: any ) => {
			if ( checkIfDefault( elem.settings ) ) {
				return {
					id: elem.id,
					sharedId: checkIfDefault( elem.settings ).id,
					title: elem.settings.title,
					widgets: elem.settings.widgets
				}
			} else
				return {
					id: elem.id,
					sharedId: elem.settings.id,
					title: elem.settings.title,
					widgets: elem.settings.widgets
				}
		} )

		yield put( { type: GET_PRESETS_DONE, payload: { custom: customPresets, receivedCustom: sharedPresets, receivedConstant: sharedConstantPresets } } );
	} catch ( error ) {
		console.log( error );
		yield put( { type: GET_PRESETS_FAILED, error } );
	}
}

function* SetCustomPresetsHandler ( { data }: any ): any {
	try {

		yield axios.post( "/user/settings", data )
		yield put( { type: SET_CUSTOM_PRESETS_DONE } );

		const userId: string = yield select( ( { authorization }: any ) => authorization.id );

		yield put( GetPresets( userId ) );
	} catch ( error ) {
		console.log( error );
		yield put( { type: SET_CUSTOM_PRESETS_FAILED, error } );
	}
}

function* DeletePresetsHandler ( { userId, data }: any ) {
	try {
		yield axios.delete( `/user/settings/${userId}`, {
			data: data
		} )
		yield put( { type: DELETE_PRESETS_DONE } );

		yield put( GetPresets( userId ) );
	} catch ( error ) {
		console.log( error );
		yield put( { type: DELETE_PRESETS_FAILED, error } );
	}
}

function* ShareCustomPresetHandler ( { data }: any ): any {
	try {
		yield axios.post( "/user/settings", data )
		const userId: string = yield select( ( { authorization }: any ) => authorization.id );
		yield put( GetPresets( userId ) );
	} catch ( error ) {
		console.log( error );
	}
}

function* UnshareDefaultPresetHandler ( { data }: any ) {
	try {
		yield axios.delete( "/user/settings/remove", {
			data: data
		} )
		const userId: string = yield select( ( { authorization }: any ) => authorization.id );
		yield put( GetPresets( userId ) );

	} catch ( error ) {
		console.log( error );
	}
}

function* AddToCustomPresetsHandler ( { data }: any ): any {
	try {
		yield axios.post( "/user/settings", [data] )

		const { id }: any = yield select( ( { authorization }: any ) => authorization );

		yield put( GetPresets( id ) );

	} catch ( error ) {
		console.log( error );
	}
}

export default function* WatchDashboardPresets () {
	yield all( [
		takeLatest( SET_CURRENT_DASHBOARD, SetCurrentDashboardHandler ),
		takeLatest( SET_CUSTOM_PRESETS, SetCustomPresetsHandler ),
		takeLatest( GET_PRESETS, GetPresetsHandler ),
		takeLatest( DELETE_PRESETS, DeletePresetsHandler ),
		takeLatest( SHARE_CUSTOM_PRESET, ShareCustomPresetHandler ),
		takeLatest( UNSHARE_DEFAULT_PRESET, UnshareDefaultPresetHandler ),
		takeLatest( ADD_TO_CUSTOM_PRESETS, AddToCustomPresetsHandler )
	] );
}
