import { all, put, takeLatest } from "redux-saga/effects";
import axios from "../../api/axiosConfig";
import { EditSLATargetsDone, EditSLATargetsFailed, GetSLATargetsDone, GetSLATargetsFailed } from "../actions/SetSLATargets.action";
import { EditSLATargetT, EDIT_SLA_TARGETS, GetSLATargetsT, GET_SLA_TARGETS } from "../types/SLATargets.types";


function* GetSLATargetsHandler ( { id }: GetSLATargetsT ) {
	try {
		const { data } = yield axios.get( `/customer/${id}/sla` )

		yield put( GetSLATargetsDone( data ) );
	} catch ( error ) {
		yield put( GetSLATargetsFailed() );
	}
}

function* EditSLATargetsHandler ( { id, payload }: EditSLATargetT ) {
	try {
		yield axios.patch( `/customer/${id}/sla`, payload )
		yield put( EditSLATargetsDone( "Network SLA targets ha been successfully changed" ) );
	} catch ( error ) {
		yield put( EditSLATargetsFailed() );
	}
}

export default function* WatchSLATargets () {
	yield all( [
		takeLatest( GET_SLA_TARGETS, GetSLATargetsHandler ),
		takeLatest( EDIT_SLA_TARGETS, EditSLATargetsHandler ),
	] );
}
