import React from "react";
import styles from "../CustomPresetsList/CustomPresetsList.module.scss";
import ReceivedPresetsListElem from "./ReceivedPresetsListElem/ReceivedPresetsListElem";
import { PresetT } from "../../../../../store/reducers/DashboardPresets";

const ReceivedPresetsList = (props: IReceivedPresetsList) => {
  const { receivedPresetsList } = props;

  return (
    <div className={styles.element}>
      {receivedPresetsList.length > 0 ? (
        receivedPresetsList.map((elem: any, key: number) => {
          return (
            <li className={styles.listItem} key={key}>
              <ReceivedPresetsListElem preset={elem} index={key} {...props} />
            </li>
          );
        })
      ) : (
        <div className={styles.empty}>
          <span>You didn't received custom presets yet</span>
        </div>
      )}
    </div>
  );
};

export default ReceivedPresetsList;

interface IReceivedPresetsList {
  showCustomPresetWidgets: (data: PresetT) => void;
  handleDeleteReceivedPreset: (index: number) => void;
  handleMovePresetToCustom: (preset: PresetT, e: number) => void;
  receivedPresetsList: PresetT[];
}
