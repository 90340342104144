import React from "react";
import styles from "./Loader.module.scss";
import cn from "classnames";

const Loader = (props: ILoader) => {
    const {className, text = "Loading", container} = props;
    if (container) {
        return(
            <div className={styles.container}>
                <div className={cn(styles.element, className)}>
                    <div className={styles.dots}>
                        <div className={styles.dot}/>
                        <div className={styles.dot}/>
                        <div className={styles.dot}/>
                    </div>
                    <div className={styles.text}>{text}</div>
                </div>
            </div>
        )
    }
    return (
        <div className={cn(styles.element, className)}>
            <div className={styles.dots}>
                <div className={styles.dot}/>
                <div className={styles.dot}/>
                <div className={styles.dot}/>
            </div>
            <div className={styles.text}>{text}</div>
        </div>
    )
}

export default Loader;

interface ILoader {
    className?: string;
    text?: string;
    container?: boolean;
}
