import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle<{ theme: IGlobalStyle }>`
    :root {
        --color-background: ${({ theme }) => theme.background};
        --color-text: ${({ theme }) => theme.text};
        --color-sidebar: ${({ theme }) => theme.sidebar};
        --color-border: ${({ theme }) => theme.border};
        --color-panel-bg: ${({ theme }) => theme.panelBg};
        --color-text-light: ${({ theme }) => theme.textLight};
        --color-tooltip: ${({ theme }) => theme.tooltip};
        --color-map: ${({ theme }) => theme.map};
        --color-input-border: ${({ theme }) => theme.inputBorder};
        --color-input-border-plain: ${({ theme }) => theme.inputBorderPlain};
        --color-input-border-checked: ${({ theme }) =>
          theme.inputBorderChecked};
        --color-text-field-checked: ${({ theme }) => theme.textFieldChecked};
        --color-input-background: ${({ theme }) => theme.inputBackground};
        --color-tabs-border: ${({ theme }) => theme.tabsBorder};
        --color-checkbox-bg: ${({ theme }) => theme.checkboxBg};
        --color-box-shadow: ${({ theme }) => theme.boxShadow};
    }
`;

export default GlobalStyles;

export interface IGlobalStyle {
  background: string;
  text: string;
  sidebar: string;
  border: string;
  panelBg: string;
  textLight: string;
  tooltip: string;
  map: string;
  inputBorder: string;
  inputBorderPlain: string;
  inputBorderChecked: string;
  textFieldChecked: string;
  inputBackground: string;
  tabsBorder: string;
  checkboxBg: string;
  boxShadow: string;
}
