import React from "react";
import styles from "./NotificationsBarList.module.scss";
import cn from "classnames";
import moment from "moment";

const NotificationsBarList = (props: INotificationsBarList) => {

    const {list} = props;

    return(
        <div className={styles.list}>{
            list.map((elem, key) => {
                return <NotificationsBarListElem key={key} {...elem}/>
            })
        }</div>
    )
}
export default NotificationsBarList;


const NotificationsBarListElem = (props: INotificationsBarListElem) => {

    const {
        type,
        title,
        date
    } = props;

    return(
        <div className={cn(styles.element, type === 'alert' && styles['isAlert'])}>
            {type !== "alert" && <div className={styles.dot}/>}
            <div className={styles.content}>
                {type === "alert" && <div className={styles.label}>Important</div>}
                <div className={styles.text}>{title}</div>
                <div className={styles.date}>{moment(date * 1000).fromNow()}</div>
            </div>
        </div>
    )
}


interface INotificationsBarList {
    list: Array<INotificationsBarListElem>
}
interface INotificationsBarListElem {
    type: string;
    title: string;
    date: number;
}
