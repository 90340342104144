import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./SingleAgent.module.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "../../components/Bootstrap/Bootstrap";
import axios from "../../api/axiosConfig";
import SingleAgentForm from "./SingleAgentForm/SingleAgentForm";
import SingleAgentFormNoEdit from "./SingleAgentFormNoEdit/SingleAgentFormNoEdit";
import Loader from "../../components/Loader/Loader";
import { formatUptime } from "../../functions/FormatUptime/FormatUptime";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import { AppState } from "../../store/reducers";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GetConnectionLevel } from "../../functions/GetConnectionLevel/GetConnectionLevel";

const SingleAgentView = () => {
  let { id } = useParams<{ id: string }>();

  const [data, setData] = useState<SingleAgentT>();
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const contextId = useSelector(
    (state: AppState) => state.customerContextState.id
  );
  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : contextId;

  const isInitialMount = useRef(true);
  const history = useHistory();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (customerContextId) {
        history.push("/agents-management/known");
      }
    }
  }, [customerContextId, history]);

  const fetchData = useCallback(async () => {
    try {
      await axios(`/configuration/agent/${id}`, {
        params: {
          allData: true,
        },
      }).then((res) => {
        if (res && res.status === 200) {
          const data = res.data;
          setData({
            id: data.id ? data.id : "",
            clientId: data.clientId ? data.clientId : "",
            clientName: data.clientName ?? "",
            name: data.name ? data.name : "",
            locationGroup: data.locationGroup ? data.locationGroup : "",
            agentType: data.type ? data.type : "",
            country: data.country ? data.country : "",
            province: data.province ? data.province : "",
            zipCode: data.zipCode ? data.zipCode : "",
            city: data.city ? data.city : "",
            street: data.street ? data.street : "",
            alias: data.alias ? data.alias : "",
            description: data.description ? data.description : "",
            registrationDate: data.registrationDate
              ? moment.unix(data.registrationDate).format("DD/MM/YYYY")
              : "",
            applications: data.applications
              ? data.applications.map((elem: any) => {
                  return {
                    id: elem.id,
                    title: elem.name,
                    type: elem.type,
                    ...(elem.countOfDay && { countOfDay: elem.countOfDay }),
                    ...(elem.subtests && { subtests: elem.subtests }),
                  };
                })
              : [],
            numberOfTests: data.numberOfTests ? data.numberOfTests : null,
            frequencyOfFeatures: data.frequencyOfFeatures
              ? data.frequencyOfFeatures
              : null,
            frequencyOfTests: data.frequencyOfTests
              ? data.frequencyOfTests
              : null,
            features: data.features
              ? data.features.map((elem: any) => {
                  return {
                    id: elem.id,
                    title: elem.name,
                  };
                })
              : [],
            network: data.network?.map((elem: any) => {
              return {
                connection: elem.connection ?? "",
                deviceName: elem.deviceName ?? "",
                connectionName: elem.connectionName ?? "",
                connectionDetails:
                  elem.connection === "WiFi"
                    ? elem.wifiStrength
                      ? GetConnectionLevel(elem.wifiStrength)
                      : 0
                    : 1,
                connectionType: elem.connectionType,
                txRate: elem.txRate ?? "",
                rssi: elem.rssi ?? "",
                noise: elem.noise ?? "",
                channel: elem.channel ?? "",
                mode: elem.mode ?? "",
                bssid: elem.bssid ?? "",
                ssid: elem.ssid ?? "",
                internalIp: elem.internalIp ?? "",
                ip: elem.ip ?? "",
                cpu: elem.cpu ?? "-",
                osName: elem.osName ?? "-",
                cpuUsage: elem.cpuUsage ?? "-",
                osVersion: elem.osVersion ?? "-",
                systemName: elem.systemName ?? "-",
                agentVersion: elem.version ?? "-",
              };
            }) || [
              {
                connection: "-",
                deviceName: "-",
                connectionName: "-",
                connectionDetails: 0,
                connectionType: "-",
                txRate: "-",
                rssi: "-",
                noise: "-",
                channel: "-",
                mode: "-",
                bssid: "-",
                ssid: "-",
                internalIp: "-",
                ip: "-",
                cpu: "-",
                osName: "-",
                cpuUsage: "-",
                osVersion: "-",
                systemName: "-",
                agentVersion: "-",
              },
            ],
            uptime: data.upTime ? formatUptime(data.upTime) : "",
            version: data.version ? data.version : "",
            wifiPassword: data.wifiPassword ? data.wifiPassword : "",
            wifiSSID: data.wifiSSID ? data.wifiSSID : "",
            currency: data.currency ? data.currency : "",
            employeeCost: data.employeeCost ? data.employeeCost : null,
            bandwidthCost: data.bandwidthCost ? data.bandwidthCost : null,
            agentDowntimeCost: data.agentDowntimeCost
              ? data.agentDowntimeCost
              : null,
            locationDowntimeCost: data.locationDowntimeCost
              ? data.locationDowntimeCost
              : null,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  return (
    <Container className={styles.container}>
      {data ? (
        checkEditAccess(permissions, customer, contextId) ? (
          <SingleAgentForm data={data} handleUpdateData={fetchData} />
        ) : (
          <SingleAgentFormNoEdit data={data} />
        )
      ) : (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default SingleAgentView;

export type SingleAgentT = {
  id: string;
  name: string;
  clientId: string;
  locationGroup: string;
  agentType: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
  alias: string;
  description: string;
  registrationDate: string;
  applications: Array<{
    id: string;
    title: string;
  }>;
  numberOfTests: number;
  frequencyOfFeatures: number;
  frequencyOfTests: number;
  features: Array<{
    id: string;
    title: string;
  }>;
  network: Array<{
    connection: string;
    deviceName: string;
    connectionName: string;
    connectionDetails: 0 | 1 | 2 | 3 | 4;
    connectionType: string;
    txRate: number;
    rssi: number;
    noise: number;
    channel: number;
    mode: string;
    bssid: string;
    ssid: string;
    internalIp: string;
    ip: string;
    cpu: string;
    osName: string;
    cpuUsage: string;
    osVersion: string;
    systemName: string;
    agentVersion: string;
  }>;
  uptime: string;
  version: string;
  wifiPassword: string;
  wifiSSID: string;
  currency: string;
  employeeCost: number;
  bandwidthCost: number;
  agentDowntimeCost: number;
  locationDowntimeCost: number;
  clientName?: string;
};
