import React from "react";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import CheckboxGroup from "../../../components/formElements/CheckboxGroup/CheckboxGroup";
import DatePicker from "../../../components/formElements/DatePicker/DatePicker";

const SingleReportGeneral = ({ ...props }: any) => {
  return (
    <div>
      <CheckboxGroup
        name="general_repeatable"
        options={[
          {
            value: "repeatable",
            label: "Set as repeatable",
          },
        ]}
        {...props}
      />
      <Row>
        <Column className="col-12 col-lg-5">
          <DatePicker
            type="range"
            name="generalDate"
            label="Date"
            isBgGray
            required
            maxDate={new Date()}
            {...props}
          />
        </Column>
      </Row>
      <CheckboxGroup
        name="general_boundaries"
        options={[
          {
            value: "boundaries",
            label: "Boundaries",
          },
        ]}
        {...props}
      />
      <Row>
        <Column className="col-12 col-lg-5">
          <DatePicker
            type="range"
            name="generalDuration"
            label="Duration"
            minDate={new Date()}
            isBgGray
            required
            {...props}
          />
          <CheckboxGroup
            name="generalDuration_filter"
            options={[
              {
                value: "filer",
                label: "Filter groups",
              },
            ]}
            {...props}
          />
        </Column>
      </Row>
      <Row>
        <Column className="col-12 col-lg-5">
          <TextInput
            name="generalCost_from"
            label="Cost"
            placeholder="Select"
            isBgGray
            required
            {...props}
          />
          <CheckboxGroup
            name="generalCost_filter"
            options={[
              {
                value: "filer",
                label: "Filter groups",
              },
            ]}
            {...props}
          />
        </Column>
        <Column className="col-12 col-lg-5">
          <TextInput
            name="generalCost_to"
            label="&nbsp;"
            placeholder="Select"
            isBgGray
            required
            {...props}
          />
        </Column>
      </Row>

      <Row>
        <Column className="col-12 col-lg-10">
          <CheckboxGroup
            name="generalDirection"
            label="Direction"
            direction="grid"
            options={[
              {
                value: "out",
                label: "Out",
              },
              {
                value: "in",
                label: "In",
              },
              {
                value: "internal",
                label: "Internal",
              },
            ]}
            {...props}
          />
          <CheckboxGroup
            name="generalSetviceType"
            label="Service type"
            direction="grid"
            options={[
              {
                value: "voice",
                label: "Voice",
              },
              {
                value: "video",
                label: "Video",
              },
              {
                value: "app-sharing",
                label: "App sharing",
              },
              {
                value: "im",
                label: "IM",
              },
            ]}
            {...props}
          />
        </Column>
      </Row>
    </div>
  );
};
export default SingleReportGeneral;
