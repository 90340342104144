import { GaugeResultsActionT, GET_GAUGE_RESULTS, GET_GAUGE_RESULTS_DONE, GET_GAUGE_RESULTS_FAILED } from "../../types/widgets/GaugeResults.types";


export const initialState: any = {};

const GaugeResultsState = (
  state = initialState,
  action: GaugeResultsActionT
) => {
  switch ( action.type ) {
    case GET_GAUGE_RESULTS:
      return { ...state, [action.params.chartType]: { data: null, loading: true, error: false } };
    case GET_GAUGE_RESULTS_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_GAUGE_RESULTS_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default GaugeResultsState;
