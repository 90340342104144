import React from "react";
import styles from "./ModalSelectCustomerList.module.scss";

const ModalSelectCustomerList = (props: IModalSelectCustomerElem) => {
  const { children } = props;

  return <ul className={styles.element}>{children}</ul>;
};

export default ModalSelectCustomerList;

interface IModalSelectCustomerElem {
  children: any;
}
