export const GET_EMPLOYEES_LIST = "GET_EMPLOYEES_LIST";
export const GET_EMPLOYEES_LIST_DONE = "GET_EMPLOYEES_LIST_DONE";
export const GET_EMPLOYEES_LIST_FAILED = "GET_EMPLOYEES_LIST_FAILED";

export type EmployeeT = {
  employee: {
    name: string,
    id: string
  },
  number: string,
  accountEnabled: string,
  givenName: string,
  surname: string,
  userType: string,
  country: string,
  city: string,
  officeLocation: string,
  companyName: string,
  department: string,
  jobTitle: string,
  userPrincipalName: string,
  mail: string,
  mobilePhone: string,
  preferredLanguage: string,
  businessPhones: string[],
  imAddresses: string[],
  license: string,
  attribute1: string,
  attribute2: string,
  attribute3: string,
  attribute4: string,
  attribute5: string,
  attribute6: string,
  attribute7: string,
  attribute8: string,
  attribute9: string,
  attribute10: string,
  attribute11: string,
  attribute12: string,
  attribute13: string,
  attribute14: string,
  attribute15: string,
  customerId: string,
  agentId: string,
  agentName: string,
  lastUpdateTest: number
}

export type ActionTypeT = GetEmployeesListT | GetEmployeesListDoneT | GetEmployeesListFailedT

export type GetEmployeesListT = {
  type: typeof GET_EMPLOYEES_LIST;
  id: string;
}

export type GetEmployeesListDoneT = {
  type: typeof GET_EMPLOYEES_LIST_DONE;
  payload: EmployeeT[] | null,
}

export type GetEmployeesListFailedT = {
  type: typeof GET_EMPLOYEES_LIST_FAILED;
  error: any
}