import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import {
  GetAgentDiscoveredDeviceTableDone,
  GetAgentDiscoveredDeviceFailed,
} from "../../actions/widgets/AgentDiscoveredDevice.action";
import {
  GetAgentDiscoveredDeviceTableT,
  GET_TABLE_AGENT_DISCOVERED_DEVICE,
} from "../../types/widgets/AgentsDiscoveredDevicesTable.types";

function* GetAgentDiscoveredDeviceHandler({
  params,
}: GetAgentDiscoveredDeviceTableT) {
  try {
    const { data } = yield axios.get(
      `${params.endpoint}/${params.customerId}`,
      {
        params: {
          startHour: params.startHour,
          endHour: params.endHour,
          startDate: params.startDate,
          endDate: params.endDate,
          ...(params.agentId && { agentId: params.agentId }),
        },
      }
    );
    if (data.length) {
      yield put(GetAgentDiscoveredDeviceTableDone(data));
    } else {
      yield put(GetAgentDiscoveredDeviceTableDone([]));
    }
  } catch (error) {
    yield put(GetAgentDiscoveredDeviceFailed());
  }
}

export default function* WatchAgentDiscoveredDeviceTable() {
  yield all([
    takeEvery(
      GET_TABLE_AGENT_DISCOVERED_DEVICE,
      GetAgentDiscoveredDeviceHandler
    ),
  ]);
}
