import {AddDeviceDoneT, AddDeviceFailedT, AddDeviceT, ADD_DEVICE, ADD_DEVICE_DONE, ADD_DEVICE_FAILED, CleanDeviceDetailsT, CLEAN_DEVICE_DETAILS, DeleteDeviceDoneT, DeleteDeviceFailedT, DeleteDeviceT, DELETE_DEVICE, DELETE_DEVICE_DONE, DELETE_DEVICE_FAILED, DeviceT, EditDeviceDoneT, EditDeviceFailedT, EditDeviceT, EDIT_DEVICE, EDIT_DEVICE_DONE, EDIT_DEVICE_FAILED, GetDeviceDoneT, GetDeviceFailedT, GetDeviceT, GET_DEVICE, GET_DEVICE_DONE, GET_DEVICE_FAILED} from "../types/Device";


export const GetDevice = (id: string): GetDeviceT => ({
  type: GET_DEVICE,
  id
});

export const GetDeviceDone = (data: DeviceT | null): GetDeviceDoneT => ({
  type: GET_DEVICE_DONE,
  payload: data
});

export const GetDeviceFailed = (error: any): GetDeviceFailedT => ({
  type: GET_DEVICE_FAILED,
  error,
});

export const CleanDeviceDetails = (): CleanDeviceDetailsT => ({
  type: CLEAN_DEVICE_DETAILS
})

export const EditDevice = (id: string, data: any): EditDeviceT => ({
  type: EDIT_DEVICE,
  id,
  data
});

export const EditDeviceDone = (): EditDeviceDoneT => ({
  type: EDIT_DEVICE_DONE
});

export const EditDeviceFailed = (error: any): EditDeviceFailedT => ({
  type: EDIT_DEVICE_FAILED,
  error,
});

export const AddDevice = (data: any): AddDeviceT => ({
  type: ADD_DEVICE,
  data
});

export const AddDeviceDone = (): AddDeviceDoneT => ({
  type: ADD_DEVICE_DONE
});

export const AddDeviceFailed = (error: any): AddDeviceFailedT => ({
  type: ADD_DEVICE_FAILED,
  error,
});

export const DeleteDevice = (id: string): DeleteDeviceT => ({
  type: DELETE_DEVICE,
  id
});

export const DeleteDeviceDone = (): DeleteDeviceDoneT => ({
  type: DELETE_DEVICE_DONE
});

export const DeleteDeviceFailed = (error: any): DeleteDeviceFailedT => ({
  type: DELETE_DEVICE_FAILED,
  error,
});
