import React from "react";
import styles from "./PanelHeader.module.scss";
import cn from "classnames";

const PanelHeader = (props: IPanelHeader) => {

    const {children, className} = props;

    return(
        <div className={cn(styles.element, className)}>
            {children}
        </div>
    )
}
export default PanelHeader;


interface IPanelHeader {
    children: any;
    className?: string;
}
