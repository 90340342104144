import React, { useEffect, useState } from "react";
import styles from "./ModalShowUsers.module.scss";
import TableCellUser from "../../../components/tableElements/TableCellUser/TableCellUser";
import { IUserCard } from "../../UserCard/UserCard";
import FilterInput from "../../formElements/FilterInput/FilterInput";
import tableStyles from "../../tableElements/ReactTable/ReactTable.module.scss";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import Loader from "../../Loader/Loader";
import cn from "classnames";
import { ReactComponent as IconChevron } from "../../../assets/icons/calendar-chevron.svg";
// import FilterTabs from "../../FilterTabs/FilterTabs";

const ModalShowUsers = (props: IModalShowUsers) => {
  const { usersData } = props;
  const [data, setTableData] = useState<any[]>([]);

  const UsersColumnFilter = ({
    column: { filterValue, setFilter },
  }: {
    column: { filterValue: any; setFilter: any };
  }) => {
    return (
      <>
        <FilterInput
          name="filter"
          value={filterValue || ""}
          handleChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder="Find User"
        />
        {/* <FilterTabs controls={["All", "Full Access", "Read Only"]} isBgWhite /> */}
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "user",
        Filter: UsersColumnFilter,
        filter: "usersName",
        Cell: ({ cell: { value } }: { cell: { value: IUserCard } }) => (
          <TableCellUser {...value} />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) setTableData(usersData);
    }, 1000);

    return () => {
      isMounted = false;
    };
  }, [usersData]);

  const filterTypes = React.useMemo(
    () => ({
      usersName: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id].name;
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className={styles.element}>
      <div className={tableStyles.wrapper}>
        <div className={tableStyles.scrollable}>
          <table {...getTableProps()} className={tableStyles.element}>
            {data.length > 5 && (
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className={cn(tableStyles.th, "px-0")}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={cn(tableStyles.td, "px-0")}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <Loader
                      container
                      className="p-5 d-flex align-items-center justify-content-center"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data.length > 5 && (
          <div className={cn(tableStyles.pagination, "px-0")}>
            <div className={tableStyles.buttonsWrapper}>
              <button
                className={tableStyles.button}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {
                  <>
                    <IconChevron className={tableStyles.iconChevron} />
                    <IconChevron className={tableStyles.iconChevron} />
                  </>
                }
              </button>
              <button
                className={tableStyles.button}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {<IconChevron className={tableStyles.iconChevron} />}
              </button>
              <div className={tableStyles.inputWrapper}>
                <input
                  className={tableStyles.input}
                  type="number"
                  placeholder={String(pageIndex + 1)}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
                <span className={tableStyles.inputOf}>
                  of {pageOptions.length}
                </span>
              </div>
              <button
                className={tableStyles.button}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {
                  <IconChevron
                    className={cn(
                      tableStyles.iconChevron,
                      tableStyles["isRight"]
                    )}
                  />
                }
              </button>
              <button
                className={tableStyles.button}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {
                  <>
                    <IconChevron
                      className={cn(
                        tableStyles.iconChevron,
                        tableStyles["isRight"]
                      )}
                    />
                    <IconChevron
                      className={cn(
                        tableStyles.iconChevron,
                        tableStyles["isRight"]
                      )}
                    />
                  </>
                }
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalShowUsers;

interface IModalShowUsers {
  usersData?: any;
}
