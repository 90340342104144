import React from "react";
import styles from "./ConnectionDetails.module.scss";
import Heading from "../Heading/Heading";
import colors from "../../assets/styles/variables/colors.module.scss";

const ConnectionDetails = (props: {
  level: 0 | 1 | 2 | 3 | 4;
  type: string;
}) => {
  const { level, type } = props;

  const generateTitle = (level: number) => {
    if (type === "WiFi") {
      switch (level) {
        case 0:
          return "Not connected";
        case 1:
          return "Weak";
        case 2:
          return "Fair";
        case 3:
          return "Good";
        case 4:
          return "Excellent";
      }
    } else {
      switch (level) {
        case 0:
          return "Not connected";
        case 1:
          return "Connected";
      }
    }
  };
  const title = generateTitle(level) || "";

  const color_level_4 = (level: number) => {
    if (type === "WiFi") {
      switch (level) {
        case 4:
          return colors["widgets-green"];
        default:
          return colors["iron"];
      }
    } else {
      switch (level) {
        case 1:
          return colors["widgets-green"];
        case 0:
          return colors["widgets-torch-red"];
      }
    }
  };

  const color_level_3 = (level: number) => {
    if (type === "WiFi") {
      switch (level) {
        case 4:
          return colors["widgets-green"];
        case 3:
          return colors["widgets-yellow"];
        case 0:
          return colors["iron"];
        default:
          return colors["iron"];
      }
    } else {
      switch (level) {
        case 1:
          return colors["widgets-green"];
        case 0:
          return colors["widgets-torch-red"];
      }
    }
  };

  const color_level_2 = (level: number) => {
    if (type === "WiFi") {
      switch (level) {
        case 4:
          return colors["widgets-green"];
        case 3:
          return colors["widgets-yellow"];
        case 2:
          return colors["widgets-crusta"];
        case 0:
          return colors["iron"];
        default:
          return colors["iron"];
      }
    } else {
      switch (level) {
        case 1:
          return colors["widgets-green"];
        case 0:
          return colors["widgets-torch-red"];
      }
    }
  };
  const color_level_1 = (level: number) => {
    if (type === "WiFi") {
      switch (level) {
        case 4:
          return colors["widgets-green"];
        case 3:
          return colors["widgets-yellow"];
        case 2:
          return colors["widgets-crusta"];
        case 1:
          return colors["widgets-torch-red"];
        case 0:
          return colors["iron"];
        default:
          return colors["iron"];
      }
    } else {
      switch (level) {
        case 1:
          return colors["widgets-green"];
        case 0:
          return colors["widgets-torch-red"];
      }
    }
  };

  return (
    <div className={styles.element}>
      <Heading title={title} Tag="h4" weight="semi-bold" />
      <span className={styles.icon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="17"
          viewBox="0 0 22 17"
          fill="none"
        >
          <rect
            width="4"
            height="17"
            rx="1"
            transform="matrix(-1 0 0 1 22 0)"
            fill={color_level_4(level)}
          />
          <rect
            width="4"
            height="14.1667"
            rx="1"
            transform="matrix(-1 0 0 1 16 2.83325)"
            fill={color_level_3(level)}
          />
          <rect
            width="4"
            height="10.625"
            rx="1"
            transform="matrix(-1 0 0 1 10 6.375)"
            fill={color_level_2(level)}
          />
          <rect
            width="4"
            height="5.66667"
            rx="1"
            transform="matrix(-1 0 0 1 4 11.3333)"
            fill={color_level_1(level)}
          />
        </svg>
      </span>
    </div>
  );
};

export default ConnectionDetails;
