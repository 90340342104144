/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ITableCellApi } from "../../components/tableElements/TableCellApi/TableCellApi";
import TableCellPriority, {
  ITableCellPriority,
} from "../../components/tableElements/TableCellPriority/TableCellPriority";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import ReactTable, {
  ISortType,
} from "../../components/tableElements/ReactTable/ReactTable";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import { AppState } from "../../store/reducers";
import ActionTools from "../../components/ActionTools/ActionTools";
import { IApiValues } from "../../components/Modal/ModalAddNewApi/ModalAddNewApi";
import { TableSortByName } from "../../functions/TableSortByName/TableSortByName";
import TableCellText from "../../components/tableElements/TableCellText/TableCellText";
import moment from "moment";
import { useSelector } from "react-redux";
import { showTableEdit } from "../../functions/ShowTableEdit/ShowTableEdit";

const ConnectedAPI = (props: IConnectedAPI) => {
  const { data, openEdit, openDelete } = props;
  const { permissions, customer, subcustomers } = useSelector(
    (state: AppState) => state.authorization
  );

  const { id, parentId } = useSelector((state: AppState) => state.customerContextState);

  const [apiList, setApiList] = useState(data);

  useEffect(() => {
    setApiList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "App",
        accessor: "name",
        sortType: TableSortByName,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellApi } }) => {
          return <TableCellText>{value}</TableCellText>
        },
      },
      {
        Header: "Status",
        accessor: "active",
        sortType: (
          rowA: ISortType["row"],
          rowB: ISortType["row"],
          id: ISortType["id"]
        ) => {
          if (rowA.original[id] > rowB.original[id]) return -1;
          if (rowB.original[id] > rowA.original[id]) return 1;
          return 0;
        },
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value ? "active" : "paused"} />,
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: (
          rowA: ISortType["row"],
          rowB: ISortType["row"],
          id: ISortType["id"]
        ) => {
          if (rowA.original[id] > rowB.original[id]) return -1;
          if (rowB.original[id] > rowA.original[id]) return 1;
          return 0;
        },
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellPriority["priority"] };
        }) => <TableCellPriority priority={value} />,
      },
      {
        Header: "Last connected",
        accessor: "lastConnected",
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText>{moment.unix(value).format("DD-MM-YYYY")}</TableCellText>
        ),
      },
      {
        Header: "Last pulled data",
        accessor: "lastPulledData",
        Cell: ({ cell: { value } }: { cell: { value: number } }) => {
          return <TableCellText>{moment.unix(value).format("DD-MM-YYYY")}</TableCellText>;
        },
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        show: customer.name === "Horitium" || showTableEdit({permission: permissions.organization, customer, id, parentId, subcustomers}) ? true : false,
        Cell: (value: { row: { original: any } }) => {
          return (
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  title="Edit API"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete API"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          );
        },
      },
    ],
    [openEdit, openDelete]
  );

  return <ReactTable columns={columns} data={apiList} />;
};

export default ConnectedAPI;

export interface IConnectedAPI {
  data: Array<SingleApiT>;
  openEdit: (api: IApiValues) => void;
  openDelete: (api: IApiValues) => void;
}

export type SingleApiT = {
  logo: {
    type: "Buffer";
    data: number[];
  };
  name: string;
  version: string;
  id: string;
  lastConnected: number;
  lastPulledData: number;
  priority: string;
  status: string;
  active: boolean;
};
