export const GetDashboardTitle = (dashboardType: string) => {
  switch (dashboardType) {
    case "app1":
      return "Application Details";
    case "app2":
      return "Application Details";
    case "app3":
      return "Application Details";
    case "device":
      return "Device Details";
    case "license":
      return "License Details";
    case "licenses":
      return "Licenses";
    case "resource":
      return "Resource Details";
    case "resources":
      return "Resources";
    case "location":
      return "Location Details";
    case "agent":
      return "Agent Details";
    case "employee-call":
      return "Employee Call Details";
    case "conference-call":
      return "Conference Call Details";
    case "call-queue":
      return "Call Queue Details";
    case "ucc-app":
      return "UCC Application";
  }
};
