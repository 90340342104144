export const GET_IMPORTANT_METRICS = "GET_IMPORTANT_METRICS";
export const GET_IMPORTANT_METRICS_DONE = "GET_IMPORTANT_METRICS_DONE";
export const GET_IMPORTANT_METRICS_FAILED = "GET_IMPORTANT_METRICS_FAILED";

export type ImportantMetricsT = Array<
  {
    id: string;
    title: string,
    count: number,
    unit: string | null
  }> | null

export type ImportantMetricsDataT =
  {
    name: string,
    value: number,
    unit?: string
  }

export type ImportantMetricsParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  agentId?: string,
  userId?: string,
  isTop?: boolean,
  url?: string,
  location?: string,
  isPotentialSaving?: boolean,
  companyName?: string,
  deviceName?: string,
  callId?: string,
  isTraceroute?: boolean
}

export type GetImportantMetricsT = {
  type: typeof GET_IMPORTANT_METRICS;
  params: ImportantMetricsParamsT
}

export type GetImportantMetricsDoneT = {
  type: typeof GET_IMPORTANT_METRICS_DONE;
  chartType: string,
  data: ImportantMetricsT
}

export type GetImportantMetricsFailedT = {
  type: typeof GET_IMPORTANT_METRICS_FAILED;
  chartType: string,
}


export type ImportantMetricsStateActionT = GetImportantMetricsT | GetImportantMetricsDoneT | GetImportantMetricsFailedT