export const financeDefaultDashboard = {
  title: "Finance",
  id: "finance",
  widgets: [
    {
      title: "EUX score overall",
      id: "finance-gauge-results",
      type: "gauge-results",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/app/getEux",
      chartType: "eux",
    },
    {
      title: "Overall performance against SLA",
      id: "finance-gauge-results-old-multi",
      type: "gauge-results-old-multi",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getPerfomanceOfWorstApps",
    },
    {
      title: "Worse applications by downtime",
      id: "finance-most-active-worse-apps",
      type: "most-active",
      dashboardType: "finance",
      availableSizes: [1, 2],
      size: 2,
      endpoint: "/metric/finance/getDowntimeOverall",
      queryParams: { isWorst: true },
    },
    // {
    //   title: "Top problems and current outages",
    //   id: "finance-important-metrics-old",
    //   type: "important-metrics-old",
    //   dashboardType: "finance",
    //   availableSizes: [1, 2]
    // },
    {
      title: "Applications performance and costs of downtime per application",
      id: "finance-tests-table-app",
      type: "finance-app-performance-table",
      dashboardType: "finance",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/finance/getDowntimeInfo",
    },
    {
      title: "Location performance and downtime per location",
      id: "finance-tests-table-location",
      type: "finance-location-table",
      dashboardType: "finance",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/finance/getDowntimeInfo",
    },
    {
      title: "Downtime overall per application",
      id: "finance-most-active-downtime-app",
      type: "most-active",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getDowntimeOverall",
      queryParams: { type: "applications" }
    },
    {
      title: "Downtime overall per location",
      id: "finance-most-active-downtime-location",
      type: "most-active",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getDowntimeOverall",
      queryParams: { type: "location" },
    },
    {
      title: "Downtime overall per employee",
      id: "finance-most-active-downtime-employee",
      type: "most-active",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getDowntimeOverall",
      queryParams: { type: "employee" },
    },
    {
      title: "Most active license",
      id: "finance-most-active-license",
      type: "most-active",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getMostActiveLicense",
    },
    {
      title: "License details",
      id: "finance-licenses-table",
      type: "licenses-table",
      dashboardType: "finance",
      availableSizes: [3, 4],
      size: 4,
      endpoint: '/metric/finance/getLicencePerEmployee'
    },
    // {
    //   title: "Resources metrics and costs",
    //   id: "finance-resource-usage-table",
    //   type: "resource-usage-table",
    //   dashboardType: "finance",
    //   availableSizes: [3, 4],
    //   size: 4
    // },
    // {
    //   title: "Resources information",
    //   id: "finance-circle-chart-resources",
    //   type: "circle-chart",
    //   dashboardType: "finance",
    //   availableSizes: [1, 2],
    //   path: {
    //     pathname: "/resources",
    //     dashboardType: "resources",
    //   },
    // },
    // {
    //   title: "Resource usage",
    //   id: "finance-column-line-chart-old-resource",
    //   type: "column-line-chart-old",
    //   dashboardType: "finance",
    //   availableSizes: [2, 3, 4]
    // },
    {
      title: "Applications downtime",
      id: "finance-column-line-chart-old-app",
      type: "column-line-chart",
      dashboardType: "finance",
      availableSizes: [2, 3, 4],
      endpoint: "/metric/finance/getDowntimePeriod",
      chartType: "appDowntime",
      data: {
        column: [
          {
            title: "App",
            id: "app",
          }
        ],
        line: [
          {
            title: "Availability",
            id: "availability",
            unit: "%"
          }
        ]
      }
    },
    {
      title: "Employee downtime",
      id: "finance-column-line-chart-employee",
      type: "column-line-chart",
      dashboardType: "finance",
      availableSizes: [2, 3, 4],
      endpoint: "/metric/finance/getDowntimePeriod",
      chartType: "empDowntime",
      data: {
        column: [
          {
            title: "Employee",
            id: "employee",
          }
        ],
        line: [
          {
            title: "Availability",
            id: "availability",
            unit: "%"
          }
        ]
      }
    },
    {
      title: "License usage",
      id: "finance-column-line-chart-license",
      type: "column-line-chart",
      dashboardType: "finance",
      availableSizes: [2, 3, 4],
      size: 3,
      endpoint: "/metric/finance/getLicenseOverallUsage",
      chartType: "financeLicense",
      data: {
        column: [
          {
            title: "License",
            id: "license",
          },
          {
            title: "Usage",
            id: "usage",
          },
          {
            title: "Unusage",
            id: "unusage",
          }
        ],
        line: [
          {
            title: "Availability",
            id: "availability",
            unit: "%"
          }
        ]
      }
    },
    {
      title: "License information",
      id: "finance-circle-chart-license",
      type: "circle-chart",
      dashboardType: "finance",
      availableSizes: [1, 2],
      endpoint: "/metric/finance/getLicenseUsage",
      chartType: "finance",
    }
  ],
};
