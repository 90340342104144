import React from "react";
import cn from "classnames";
import styles from "./Bootstrap.module.scss";

interface IContainer {
    children: Array<React.ReactChild> | React.ReactChild | any;
    fluid?: boolean;
    className?: string;
}

interface IRowCol {
    children: Array<React.ReactChild> | React.ReactChild | any;
    className?: string;
    spacing?: "large"
}


export const Container = (props: IContainer) => {
	const {children, fluid = false, className} = props;

	return(
		<div className={cn("px-0", fluid ? "container-fluid" : "container", className)}>
			{children}
		</div>
	)
}

export const Row = (props: IRowCol) => {
	const {className, children, spacing} = props;
	return(
		<div className={cn("row", className, spacing && styles['isLarge'], styles.row)}>
			{children}
		</div>
	)
}

export const Column = (props: IRowCol) => {
	const {className, children, spacing} = props;
	return(
		<div className={cn(className, spacing && styles['isLarge'], styles.column)}>
			{children}
		</div>
	)
}
