import colors from "../../../assets/styles/variables/colors.module.scss";

export type ServiceUsingPercentage = {
  appshareing: number;
  im: number;
  video: number;
  voice: number;
};

export const uccDefaultDashboard = {
  title: "UCC",
  id: "ucc",
  widgets: [
    {
      title: "Volume trend",
      id: "ucc-volume-trend-of-calls",
      type: "trend",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: "/metric/ucc/volumeTrendsOfCalls"
    },
    {
      title: "Quality trend",
      id: "ucc-quality-trend-of-calls",
      type: "trend",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: "/metric/ucc/qualityTrendsOfCalls"
    },
    {
      title: "Call Quality",
      id: "ucc-gauge-results-old-multi",
      type: "gauge-results-old-multi",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: "/metric/ucc/callQualityMos"
    },
    {
      title: "Important Metrics",
      id: "ucc-important-metrics",
      type: "important-metrics",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: "/metric/important",
      chartType: "ucc"
    },
    {
      title: "Calls per region",
      id: "ucc-map",
      type: "map",
      dashboardType: "ucc",
      availableSizes: [2, 3, 4],
      size: 4,
      endpoint: "/metric/ucc/getLocationCalls"
    },
    {
      title: "Most Active regions",
      id: "ucc-most-active-regions",
      type: "most-active",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: "/metric/ucc/getMostActiveRegions"
    },
    {
      title: "Number of sessions delivered",
      id: "ucc-circle-chart",
      type: "circle-chart",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      endpoint: '/metric/ucc/getActivityOfServices'
    },
    {
      title: "Adoption of users change",
      id: "ucc-gauge-half-old",
      type: "gauge-half",
      dashboardType: "ucc",
      availableSizes: [2, 3, 4],
      size: 2,
      endpoint: "/metric/ucc/getActivityOfServices",
      payloadAccessor: (data: {
        percentageOfUseService: ServiceUsingPercentage
      }) => data.percentageOfUseService,
      sections: [
        {
          title: "Voice",
          color: colors["widgets-blue"],
          accessor: (data: ServiceUsingPercentage) => data.voice
        },
        {
          title: "Video",
          color: colors["widgets-green"],
          accessor: (data: ServiceUsingPercentage) => data.video
        },
        {
          title: "Appshare",
          color: colors["widgets-crusta"],
          accessor: (data: ServiceUsingPercentage) => data.appshareing
        },
        // {
        //   title: "IM",
        //   color: colors["widgets-torch-red"],
        //   accessor: (data: ServiceUsingPercentage) => data.im
        // }
      ]
    },
    {
      title: "Adoption of quality change",
      id: "ucc-comparison-box-quality",
      type: "comparison-box",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      chartType: "ucc-quality",
      endpoint: "/metric/changeIn"
    },
    {
      title: "Adoption of Voice change",
      id: "ucc-comparison-box-voice",
      type: "comparison-box",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      chartType: "ucc-voice",
      endpoint: "/metric/changeIn"
    },
    {
      title: "Adoption of Video change",
      id: "ucc-comparison-box-video",
      type: "comparison-box",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      chartType: "ucc-video",
      endpoint: "/metric/changeIn"
    },
    {
      title: "Total calls change",
      id: "ucc-comparison-box-total-calls",
      type: "comparison-box",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      chartType: "ucc-total-calls",
      endpoint: "/metric/changeIn"
    },
    {
      title: "Total conferance calls change",
      id: "ucc-comparison-box-conference-calls",
      type: "comparison-box",
      dashboardType: "ucc",
      availableSizes: [1, 2],
      chartType: "ucc-conference-calls",
      endpoint: "/metric/changeIn"
    },
    {
      title: "Metrics of quality over locations/employees",
      id: "ucc-heatmap",
      type: "heatmap",
      dashboardType: "ucc",
      availableSizes: [2, 3, 4],
      size: 3,
      endpoint: '/metric/ucc/getCallMetrics',
      accessDifferentTypes: false
    },
    {
      title: "UCC app types",
      id: "ucc-apps-table",
      type: "ucc-apps-table",
      dashboardType: "ucc",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "metric/ucc/getApplicationsInfo",
      path: {
        pathname: "dashboard/",
      },
    },
    {
      title: "Call details per employee",
      id: "ucc-employee-calls-table",
      type: "employee-calls-table",
      dashboardType: "ucc",
      availableSizes: [3, 4],
      size: 4,
      path: {
        pathname: "dashboard/",
      },
    },
    {
      title: "Device details per employee",
      id: "ucc-device-employee-table",
      type: "device-employee-table",
      dashboardType: "ucc",
      availableSizes: [2, 3, 4],
      size: 4,
      path: {
        pathname: "dashboard/",
      },
    },
    {
      title: "Call details per conference call and meeting room",
      id: "ucc-conference-calls-table",
      type: "conference-calls-table",
      dashboardType: "ucc",
      availableSizes: [3, 4],
      size: 4,
      path: {
        pathname: "dashboard/",
      },
    },
    {
      _comment: "TODO: now disabled, to implement remove disable: true",
      title: "Call queue",
      id: "ucc-call-queue-table",
      type: "call-queue-table",
      dashboardType: "ucc",
      availableSizes: [3, 4],
      size: 4,
      path: {
        pathname: "dashboard/",
      },
      disable: true,
    },
  ],
};
