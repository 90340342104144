import React from "react";
import { Form, Formik } from "formik";
import styles from "./ModalAddTitle.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";

interface IModalAddTitle {
  setModalOpen: (e: boolean) => void;
  setModalInside: (e: string) => void;
  handleClick: (e: string) => void;
}

const ModalAddTitle = (props: IModalAddTitle) => {
  const { setModalOpen, setModalInside, handleClick } = props;

  const initialValues = {
    name: "",
  };

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            setModalOpen(false);
            setModalInside("customPresets");
            handleClick(values.name);
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <TextInput
              touched={touched}
              errors={errors}
              name="name"
              label="Title"
              placeholder="Enter Title"
              required
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Save"
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalAddTitle;
