import React from "react";
import styles from "./SidebarMenuLogo.module.scss";
import { ReactComponent as IconLogo } from "../../../../assets/icons/logo_hortium_eux.svg";

const SidebarMenuLogo = () => {
  return (
    <div className={styles.element}>
      <div className={styles.text}>Powered by</div>
      <IconLogo className={styles.logo} />
    </div>
  );
};

export default SidebarMenuLogo;
