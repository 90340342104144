export const GET_COLUMN_LINE_CHART = "GET_COLUMN_LINE_CHART";
export const GET_COLUMN_LINE_CHART_DONE = "GET_COLUMN_LINE_CHART_DONE";
export const GET_COLUMN_LINE_CHART_FAILED = "GET_COLUMN_LINE_CHART_FAILED";

export type ColumnLineChartT = {
  time: {
    name: string,
    data: Array<Array<number>>,
    type: string
  },
  timrequestse: {
    name: string,
    data: Array<Array<number>>,
    type: string
  },

} | null

export type ColumnLineChartParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  percent?: string,
  type?: string,
  agentId?: string | null,
  requestType?: string,
  userId?: string,
  deviceName?: string,
  isLatency?: boolean,
  companyName?: string,
  callId?: string,
  isOrganisation?: boolean,
  isUser?: boolean,
  url?: string,
  location?: string,
  isTraceroute?: boolean
}

export type GetColumnLineChartT = {
  type: typeof GET_COLUMN_LINE_CHART;
  params: ColumnLineChartParamsT,
  chartType: string
}

export type GetColumnLineChartDoneT = {
  type: typeof GET_COLUMN_LINE_CHART_DONE;
  chartType: string,
  data: ColumnLineChartT
}

export type GetColumnLineChartFailedT = {
  type: typeof GET_COLUMN_LINE_CHART_FAILED;
  chartType: string,
}

export type ColumnLineChartActionT = GetColumnLineChartT | GetColumnLineChartDoneT | GetColumnLineChartFailedT