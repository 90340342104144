import { GET_PATH_ANALYTICS, GET_PATH_ANALYTICS_DONE, GET_PATH_ANALYTICS_FAILED, PathAnalyticsStateActionT } from "../../types/widgets/PathAnalytics.types";


export const initialState: any = {};

const PathAnalyticsState = (
  state = initialState,
  action: PathAnalyticsStateActionT
) => {
  switch ( action.type ) {
    case GET_PATH_ANALYTICS:
      return { ...state, [action.params.chartType]: { data: null, loading: true, error: false } };
    case GET_PATH_ANALYTICS_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_PATH_ANALYTICS_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default PathAnalyticsState;
