import {
  ClearSLATargetT,
  CLEAR_SLA_TARGETS,
  EditSLATargetDoneT,
  EditSLATargetFailedT,
  EditSLATargetT,
  EDIT_SLA_TARGETS,
  EDIT_SLA_TARGETS_DONE,
  EDIT_SLA_TARGETS_FAILED,
  GetSLATargetFailedT,
  GetSLATargetsDoneT,
  GetSLATargetsT,
  GET_SLA_TARGETS,
  GET_SLA_TARGETS_DONE,
  GET_SLA_TARGETS_FAILED,
  SLATargetsT,
} from "../types/SLATargets.types";

export const GetSLATargets = (id: string): GetSLATargetsT => ({
  type: GET_SLA_TARGETS,
  id,
});

export const GetSLATargetsDone = (
  payload: SLATargetsT
): GetSLATargetsDoneT => ({
  type: GET_SLA_TARGETS_DONE,
  payload,
});

export const GetSLATargetsFailed = (): GetSLATargetFailedT => ({
  type: GET_SLA_TARGETS_FAILED,
});

export const ClearSLATargets = (): ClearSLATargetT => ({
  type: CLEAR_SLA_TARGETS,
});

export const EditSLATargets = (
  id: string,
  payload: SLATargetsT
): EditSLATargetT => ({
  type: EDIT_SLA_TARGETS,
  id,
  payload,
});

export const EditSLATargetsDone = (payload: string): EditSLATargetDoneT => ({
  type: EDIT_SLA_TARGETS_DONE,
  payload,
});

export const EditSLATargetsFailed = (): EditSLATargetFailedT => ({
  type: EDIT_SLA_TARGETS_FAILED,
});
