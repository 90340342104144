export const SET_FILTER = "SET_FILTER";

export type filterStateT ={
  locations: Array<{id: string, title: string}>,
  agents: Array<{id: string, title: string}>,
  applications: Array<{id: string, title: string}>,
  businessUnits: Array<{id: string, title: string}>,
  dataSources: Array<{id: string, title: string}>,
  employees: Array<{id: string, title: string}>,
}

const initialState: filterStateT = {
  locations: [],
  agents: [],
  applications: [],
  businessUnits: [],
  dataSources: [],
  employees: []
}

export default function filterState(state = initialState, action: {
    type: "SET_FILTER";
    data: filterStateT
}) {
    if (action.type === SET_FILTER) {
        return action.data;
    }
    return state;
}
