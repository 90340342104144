import React, { useEffect, useState } from "react";
import styles from "./Expand.module.scss";
import cn from "classnames";
import { ReactComponent as IconChevron } from "../../assets/icons/chevron.svg";

const Expand = (props: IExpand) => {
  const {
    title,
    active = false,
    children,
    level = 0,
    type = "default",
    setModalInside,
  } = props;

  const [expanded, setExpanded] = useState(false);

  // TODO: clean after change some expand to accordion

  useEffect(() => {
    setExpanded(active);
  }, [active]);

  const handleClick = () => {
    if (type === "oneLevel" && level === 0) {
      setModalInside && setModalInside("customPresets");
    } else {
      setExpanded(!expanded);
      setModalInside && setModalInside("widgets");
    }
  };
  return (
    <div
      className={cn(
        styles.element,
        expanded && styles["isActive"],
        level && styles[`isLevel-${level}`],
        styles[`type-${type}`]
      )}
    >
      <div className={styles.heading} onClick={handleClick}>
        {type === "default" &&
          (level === 2 ? (
            <IconChevron className={styles.arrow} />
          ) : (
            <div className={styles.icon}>
              <span className={cn(styles.plank, styles["isVertical"])} />
              <span className={styles.plank} />
            </div>
          ))}

        <div className={styles.title}>{title}</div>
        {type === "oneLevel" &&
          (level === 1 ? <IconChevron className={styles.arrow} /> : null)}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Expand;

export interface IExpand {
  title: string;
  active?: boolean;
  children?: any;
  level?: 0 | 1 | 2;
  type?: "default" | "oneLevel";
  setModalInside?: (e: string) => void;
}
