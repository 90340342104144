import {
  GET_TABLE_CONFERENCE_CALLS,
  GET_TABLE_CONFERENCE_CALLS_DONE,
  GET_TABLE_CONFERENCE_CALLS_FAILED,
  TableStateActionT
} from "../../types/widgets/ConferenceCallsTable.type";

export const initialState = {
  data: [],
  loading: false,
  error: false
};

const conferenceCallsState = (state = initialState, action: TableStateActionT) => {
  switch (action.type) {
    case GET_TABLE_CONFERENCE_CALLS:
      return { ...state, data: [], loading: true, error: false };
    case GET_TABLE_CONFERENCE_CALLS_DONE:
      return { ...state, data: action.data, loading: false, error: false };
    case GET_TABLE_CONFERENCE_CALLS_FAILED:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default conferenceCallsState;
