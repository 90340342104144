import React, { useState, useEffect, Suspense, useRef } from "react";
import styles from "../Dashboard/Dashboard.module.scss";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconFile } from "../../assets/icons/file.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import Grid, { GridElem } from "../../components/Grid/Grid";
import DateRangePicker from "../../components/RangePicker/RangePicker";
import Loader from "../../components/Loader/Loader";
import Panel from "../../components/Panel/Panel";
import TimeRangePicker from "../../components/TimeRangePicker/TimeRangePicker";
import { ManageDashboardWidgets } from "../../functions/ManageDashboard/ManageDashboardWidgets";
import { GetDashboardDrillDowns } from "../../functions/ManageDashboard/GetDashboardDrillDowns/GetDashboardDrillDowns";
import { GetDashboardTitle } from "../../functions/ManageDashboard/GetDashboardDrillDowns/GetDashboardTitle/GetDashboardTitle";
import { useHistory, useLocation } from "react-router-dom";
import LayoutSubheader from "../../components/Layout/LayoutSubheader/LayoutSubheader";
import { WidgetT } from "../../store/reducers/DashboardPresets";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

const DashboardDrillDown = () => {
  const { state }: { state: LocationStateT } = useLocation();
  const customerContextState = useSelector(
    (state: AppState) => state.customerContextState
  );

  const [widgets, setWidgets] = useState<WidgetT[]>([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // @ts-ignore
      state && setWidgets(GetDashboardDrillDowns(state?.type));

    }
    return () => {
      isMounted = false;
    };
  }, [state]);

  const prevCustomerRef = useRef(customerContextState.id);
  const history = useHistory();

  useEffect(() => {
    if (prevCustomerRef.current) {
      if (prevCustomerRef.current !== customerContextState.id) {
        history.goBack();
      }
    }
    prevCustomerRef.current = customerContextState.id;
  }, [customerContextState.id, history]);

  return (
    <Container fluid>
      <LayoutHeader goBackBtn title={GetDashboardTitle(state?.type)}>
        <DateRangePicker />
        <TimeRangePicker />
        <Button
          className={styles.button}
          title={"Report"}
          path="reports/id/:id"
          icon={<IconFile style={{ fill: "white" }} />}
          icon_position="right"
        />
      </LayoutHeader>
      {state?.name && <LayoutSubheader title={state?.name} />}
      {widgets.length > 0 && (
        <Grid>
          {widgets.map((elem, key) => {
            const resultProps = state?.id ? { ...elem, userId: state?.id } : elem

            return (
              <GridElem
                size={elem.size ? elem.size : Math.min(...elem.availableSizes)}
                key={key}
                display={elem.display}
              >
                <Suspense
                  fallback={
                    <Panel className="pt-5">
                      <Loader container />
                    </Panel>
                  }
                >
                  {ManageDashboardWidgets(
                    resultProps
                  )}
                </Suspense>
              </GridElem>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export type LocationStateT = {
  type: string;
  name: string;
  id?: string;
};

export default DashboardDrillDown;
