import React, { useEffect, useState } from "react";
import styles from "./RangePicker.module.scss";
import { ReactComponent as IconCalendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as IconChevron } from "../../assets/icons/calendar-chevron.svg";

//@ts-ignore
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setDateRange } from "../../store/sagas/dateRange";

const RangePicker = (props: IRangePicker) => {
  const { handleChange } = props;

  const today = new Date();
  const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const monthAgo = new Date(today.getTime() - 31 * 24 * 60 * 60 * 1000);

  const [value, changeValue] = useState(
    // @ts-ignore
    JSON.parse(window.localStorage.getItem("dateRange"))
      ? [
          new Date(
            // @ts-ignore
            JSON.parse(localStorage.getItem("dateRange")).start * 1000
          ),
          new Date(
            // @ts-ignore
            JSON.parse(localStorage.getItem("dateRange")).end * 1000
          ),
        ]
      : [weekAgo, today]
  );

  const dispatch = useDispatch();

  const convertToUTC = (date: any) => {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  };

  useEffect(() => {
    const data = {
      start: moment(new Date(convertToUTC(value[0]))).unix(),
      end: moment(new Date(convertToUTC(value[1]))).unix(),
    };
    dispatch(setDateRange(data));
    localStorage.setItem("dateRange", JSON.stringify(data));
  }, [value, dispatch]);

  const thisHandleChange = (e: React.SetStateAction<Date[]>) => {
    changeValue(e);
    if (handleChange) {
      handleChange(e);
    }
  };

  return (
    <DateRangePicker
      className={styles.element}
      onChange={
        handleChange
          ? (e: React.SetStateAction<Date[]>) => thisHandleChange(e)
          : changeValue
      }
      value={value}
      clearIcon={null}
      calendarIcon={<IconCalendar />}
      format="dd/MM/y"
      prev2Label={null}
      next2Label={null}
      prevLabel={<IconChevron />}
      nextLabel={<IconChevron />}
      showNeighboringMonth={false}
      maxDate={today}
      minDate={
        window.location.href.includes("reports")
          ? new Date("01/01/2020")
          : monthAgo
      }
    />
  );
};

export default RangePicker;

interface IRangePicker {
  handleChange?: (e: any) => void;
}
