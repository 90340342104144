import {takeEvery} from "redux-saga/effects";
import {SET_DATE_RANGE} from "../reducers/dateRange";

export const setDateRange = (action:any) => {
    return(
        {
            type: SET_DATE_RANGE,
            data: action
        }
    )
}

export default function* dateRange() {
    yield takeEvery(SET_DATE_RANGE, setDateRange);
}
