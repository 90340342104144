import { GetTableDoneT, GetTableFailedT, GetTableT, GET_TABLE, GET_TABLE_DONE, GET_TABLE_FAILED, TableDataT, TableParamsT } from "../../types/widgets/Table.types";

export const GetTable = (
  params: TableParamsT
): GetTableT => ( {
  type: GET_TABLE,
  params
} );

export const GetTableDone = (
  chartType: string,
  data: TableDataT
): GetTableDoneT => ( {
  type: GET_TABLE_DONE,
  chartType,
  data,
} );

export const GetTableFailed = ( chartType: string ): GetTableFailedT => ( {
  type: GET_TABLE_FAILED,
  chartType,
} );