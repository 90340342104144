import React, { useState, useEffect } from "react";
import styles from "./ModalManageDashboard.module.scss";
import { Column, Row } from "../../Bootstrap/Bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store/reducers";
import { PresetT, WidgetT } from "../../../store/reducers/DashboardPresets";
import { GetSelectedPresetDetails } from "../../../functions/ManageDashboard/GetSelectedPresetDetails/GetSelectedPresetDetails";
import { GetDefaultCurrentPreset } from "../../../functions/ManageDashboard/GetDefaultCurrentPreset/GetDefaultCurrentPreset";
import { ChangeWidgetsFormat } from "../../../functions/ManageDashboard/ChangeWidgetsFormat/ChangeWidgetsFormat";
import {
  SetCustomPresets,
  DeletePresets,
} from "../../../store/actions/DashboadPresets.action";
import cn from "classnames";
import ModalManageDashboardMenu from "./ModalManageDashboardMenu/ModalManageDashboardMenu";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import Modal from "../Modal";
import ModalAddTitle from "../ModalAddTitle/ModalAddTitle";
import ModalManageDashboardMain from "./ModalManageDashboardMain/ModalManageDashboardMain";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import Heading from "../../Heading/Heading";

const ModalManageDashboard = (props: IModalManageDashboard) => {
  const { setModalOpen, setModalWide } = props;

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["md"]}px)`,
  });

  const { id } = useSelector((state: AppState) => state.authorization);
  const customPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.customPresets
  );
  const constantPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantPresets
  );
  const constantReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantReceivedPresets
  );
  const currentDashboard = useSelector(
    (state: AppState) => state.dashboardPresetsState.currentDashboard
  );
  const customReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.customReceivedPresets
  );
  const settings = useSelector((state: AppState) => state.SettingsState);
  const [modalInside, setModalInside] = useState<string>("widgets");
  const [currentWidgets, setCurrentWidgets] = useState<WidgetT[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<
    { id: string; title: string } | any
  >(
    GetDefaultCurrentPreset(
      currentDashboard,
      settings,
      constantPresets,
      customPresets,
      constantReceivedPresets
    )
  );

  const [modalTitleOpen, setModalTitleOpen] = useState<boolean>(false);
  const [customPresetsList, setCustomPresetsList] =
    useState<PresetT[]>(customPresets);
  const [receivedPresetsList, setReceivedPresetsList] = useState<PresetT[]>(
    customReceivedPresets
  );
  const [deletedCustomPresets, setDeletedCustomPresets] = useState<PresetT[]>(
    []
  );
  const [deletedReceivedPresets, setDeletedReceivedPresets] = useState<
    PresetT[]
  >([]);
  const [sent, setSent] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleMovePresetToCustom = (preset: PresetT, index: number) => {
    setCustomPresetsList([...customPresetsList, preset]);
    const presets = Array.from(receivedPresetsList);
    presets.splice(index, 1);
    setReceivedPresetsList(presets);
  };

  const handleEmptyWidgetsArea = () => {
    setModalInside("widgets");
    setSelectedPreset(null);
    setCurrentWidgets([]);
  };

  const handleSave = (d: WidgetT[]) => {
    if (
      (selectedPreset &&
        constantPresets.find(
          (elem: PresetT) => elem.id === selectedPreset.id
        )) ||
      (!selectedPreset && modalInside === "widgets")
    ) {
      setModalTitleOpen(true);
    } else if (
      modalInside === "customPresets" ||
      modalInside === "receivedPresets"
    ) {
      if (customPresetsList.length) {
        const data = customPresetsList.map((elem: PresetT) => {
          if (elem.id) {
            return {
              userId: id,
              settingId: elem.id,
              settings: {
                id: elem.id,
                title: elem.title,
                widgets: ChangeWidgetsFormat(elem.widgets),
              },
              settingsType: "custom",
            };
          } else {
            return {
              userId: id,
              settings: {
                id: elem.id,
                title: elem.title,
                widgets: ChangeWidgetsFormat(elem.widgets),
              },
              settingsType: "custom",
            };
          }
        });
        dispatch(SetCustomPresets(data));
        setSent(true);
        setModalWide(false);
      } else setModalOpen(false);
      if (
        id &&
        (deletedCustomPresets.length || deletedReceivedPresets.length)
      ) {
        const data = deletedCustomPresets
          .concat(deletedReceivedPresets)
          .map((elem: any) => {
            return elem.id;
          });
        dispatch(DeletePresets(id, data));
        setSent(true);
        setModalWide(false);
      }
    } else {
      const data = customPresetsList.map((obj: PresetT) => {
        if (obj.id === selectedPreset.id) {
          obj.widgets = d;
          return obj;
        } else {
          return obj;
        }
      });
      setCustomPresetsList(data);
      setModalInside("customPresets");
    }
  };

  const handleSaveAsNewPreset = (title: string) => {
    const preset = {
      id: "",
      title: title,
      widgets: currentWidgets,
    };
    setCustomPresetsList([...customPresetsList, preset]);
    setSelectedPreset(null);
  };

  const showCustomPresetWidgets = (data: PresetT) => {
    setModalInside("widgets");
    setCurrentWidgets(data.widgets);
    setSelectedPreset({ title: data.title, id: data.id });
  };

  const handleDeleteCustomPreset = (index: number) => {
    const presets = Array.from(customPresetsList);
    presets.splice(index, 1);
    setCustomPresetsList(presets);
    setDeletedCustomPresets([
      ...deletedCustomPresets,
      customPresetsList[index],
    ]);
  };

  const handleDeleteReceivedPreset = (index: number) => {
    const presets = Array.from(receivedPresetsList);
    presets.splice(index, 1);
    setReceivedPresetsList(presets);
    setDeletedReceivedPresets([
      ...deletedReceivedPresets,
      receivedPresetsList[index],
    ]);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedPreset) {
        const selectedPresetDetails = GetSelectedPresetDetails(
          constantPresets,
          customPresets,
          constantReceivedPresets,
          selectedPreset
        );
        selectedPresetDetails &&
          setCurrentWidgets(selectedPresetDetails.widgets);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [customPresets, constantPresets, constantReceivedPresets, selectedPreset]);

  useEffect(() => {
    if (sent) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
    setSent(false);
    setModalWide(true);
  }, [sent, setModalOpen, setModalWide]);

  if (sent) {
    return (
      <ModalSuccess text="Dashboard presets has been successfully updated" />
    );
  }

  return (
    <>
      <Row className={cn("mx-0", styles.element)}>
        <Column className={cn("col-12 col-md-4 px-0", styles.scroll)}>
          <div className={styles.left}>
            {!isResponsiveView && (
              <Heading
                title="Manage Dashboard"
                weight="medium"
                className={styles.heading}
              />
            )}
            <ModalManageDashboardMenu
              modalInside={modalInside}
              setModalInside={setModalInside}
              currentWidgets={currentWidgets}
              setCurrentWidgets={setCurrentWidgets}
              setSelectedPreset={setSelectedPreset}
              customPresetsList={customPresetsList}
              receivedPresetsList={receivedPresetsList}
            />
          </div>
        </Column>
        <Column className="col-12 col-md-8 px-0">
          <ModalManageDashboardMain
            title={selectedPreset && selectedPreset.title}
            currentWidgets={currentWidgets}
            setCurrentWidgets={setCurrentWidgets}
            handleSave={handleSave}
            modalInside={modalInside}
            setModalInside={setModalInside}
            showCustomPresetWidgets={showCustomPresetWidgets}
            handleSaveAsNewPreset={handleSaveAsNewPreset}
            handleEmptyWidgetsArea={handleEmptyWidgetsArea}
            handleDeleteCustomPreset={handleDeleteCustomPreset}
            handleDeleteReceivedPreset={handleDeleteReceivedPreset}
            setModalOpen={setModalOpen}
            customPresetsList={customPresetsList}
            handleMovePresetToCustom={handleMovePresetToCustom}
            receivedPresetsList={receivedPresetsList}
          />
        </Column>
      </Row>
      <Modal
        title="Save as new Dashboard Preset"
        modalOpen={modalTitleOpen}
        setModalOpen={setModalTitleOpen}
      >
        <ModalAddTitle
          setModalOpen={setModalTitleOpen}
          setModalInside={setModalInside}
          handleClick={handleSaveAsNewPreset}
        />
      </Modal>
    </>
  );
};

export default ModalManageDashboard;
interface IModalManageDashboard {
  setModalOpen: (e: boolean) => void;
  setModalWide: (e: boolean) => void;
}

// export type PresetT = {
//   title: string;
//   id: string;
//   widgets: WidgetExtendedT[];
// };

// export type WidgetExtendedT = {
//   title: string;
//   id: string;
//   type: string;
//   dashboardType: string;
//   availableSizes: number[];
//   size?: 2 | 3 | 4 | number;
//   data?: any[] | any;
//   path?: any;
//   endpoint?: string;
//   chartType?: string;
//   disableIfHortium?: boolean;
// };
