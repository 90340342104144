import {
  CLEAR_SLA_TARGETS,
  EDIT_SLA_TARGETS_DONE,
  EDIT_SLA_TARGETS_FAILED,
  GET_SLA_TARGETS_DONE,
  GET_SLA_TARGETS_FAILED,
  SLATargetsStateActionT,
  SLATargetsStateT,
} from "../types/SLATargets.types";

export const initialState: SLATargetsStateT = {
  data: null,
  message: null,
  error: false,
};

const SLATargetsState = (
  state = initialState,
  action: SLATargetsStateActionT
): SLATargetsStateT => {
  switch (action.type) {
    case GET_SLA_TARGETS_DONE:
      return { ...state, data: action.payload };
    case GET_SLA_TARGETS_FAILED:
      return { ...state, error: true };
    case CLEAR_SLA_TARGETS:
      return initialState;
    case EDIT_SLA_TARGETS_DONE:
      return { ...state, message: action.payload };
    case EDIT_SLA_TARGETS_FAILED:
      return { ...state, error: true };
    default:
      return state;
  }
};

export default SLATargetsState;
