import React from "react";
import Panel from "../Panel/Panel";
import PanelHeader from "../Panel/PanelHeader/PanelHeader";
import Heading from "../Heading/Heading";
import styles from "./ReportTemplateValues.module.scss";

const ReportTemplateValues = (props: any) => {
  const { values } = props;

  return (
    <Panel className={styles.element}>
      <PanelHeader>
        <Heading title="Display in Report" className={styles.title} />
      </PanelHeader>
      <ul>
        <li> Template Name: {values.generalDescription}</li>
        <li>
          Summary Format:{" "}
          {values.generalDescription_SummaryFormat.length ? "Yes" : "No"}
        </li>
        {values.generalSharing && (
          <li>
            Sharing:
            <ul>
              {values.generalSharing.map((el: string, key: number) => (
                <li key={key}>{el}</li>
              ))}
            </ul>
          </li>
        )}
        {values.generalOrientation && (
          <li>
            Orientation:{" "}
            <ul>
              {values.generalOrientation.map((el: string, key: number) => (
                <li key={key}>{el}</li>
              ))}
            </ul>
          </li>
        )}
        {values.callsDescription && (
          <li> Calls Description: {values.callsDescription}</li>
        )}
        {values.callsWidth && <li> Calls Width: {values.callsWidth}</li>}
        {values.callsSummaryFormat && (
          <li>
            Summary Format: {values.callsSummaryFormat.length ? "Yes" : "No"}
          </li>
        )}
        {values.callsJustification && (
          <li>
            Justification:
            <ul>
              {values.callsJustification.map((el: string, key: number) => (
                <li key={key}>{el}</li>
              ))}
            </ul>
          </li>
        )}
        {values.callsAggregateMethod && (
          <li> Aggreagate Method: {values.callsAggregateMethod}</li>
        )}
      </ul>
    </Panel>
  );
};

export default ReportTemplateValues;
