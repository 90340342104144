import React from "react";
import LayoutUserAuth from "../../../components/LayoutUserAuth/LayoutUserAuth";
import UserFormTemplate from "../../../components/UserFormTemplate/UserFormTemplate";
import SignUpForm from "../../../components/UserFormTemplate/SignUpForm";

const SignUpView = () => {



    return (
      <LayoutUserAuth title="Sign up">
          <UserFormTemplate>
              <SignUpForm/>
          </UserFormTemplate>
      </LayoutUserAuth>
    );

};

export default SignUpView;
