import React from "react";
import styles from "./ActionToolsItem.module.scss";

const PanelToolsOptionsItem = (props: IPanelToolsOptionsItem) => {
  const { children } = props;

  return <div className={styles.element}>{children}</div>;
};

export default PanelToolsOptionsItem;

interface IPanelToolsOptionsItem {
  children: any;
}
