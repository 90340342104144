import TableCellText from "../../../components/tableElements/TableCellText/TableCellText";

export const bandwidthTestingDefaultDashboard = {
  title: "Bandwidth",
  id: "bandwidth-testing",
  widgets: [
    {
      title: "Global Map of bandwidth speed",
      id: "bandwidth-map",
      type: "map",
      dashboardType: "bandwidth-testing",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/bandwidth/getLocations",
    },
    {
      title: "Heatmap of locations/agents performance",
      id: "bandwidth-heatmap",
      type: "heatmap",
      dashboardType: "bandwidth-testing",
      availableSizes: [2, 3, 4],
      endpoint: "metric/heatmap",
      chartType: "agents",
      size: 4,
    },
    {
      title:
        "Locations/providers and speed test metrics with overall application test metrics",
      id: "bandwidth-status-table",
      type: "simple-table",
      dashboardType: "bandwidth-testing",
      availableSizes: [3, 4],
      size: 4,
      columns: [
        {
          Header: "provider",
          accessor: "provider",
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText>{value}</TableCellText>
          ),
        },
        {
          Header: "location",
          accessor: "location",
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText>{value}</TableCellText>
          ),
        },
        {
          Header: "transactions Time",
          accessor: "transactionsTime",
          disableSortBy: true,
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText noWrap>{value}</TableCellText>
          ),
        },
        {
          Header: "download Speed",
          accessor: "downloadSpeed",
          disableSortBy: true,
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText noWrap>{value}</TableCellText>
          ),
        },
        {
          Header: "upload Speed",
          accessor: "uploadSpeed",
          disableSortBy: true,
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText noWrap>{value}</TableCellText>
          ),
        },
        {
          Header: "connection",
          accessor: "connection",
          disableSortBy: true,
          Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <TableCellText noWrap>{value}</TableCellText>
          ),
        },
      ],
      endpoint: "/metric/bandwidth/getListOfSpeed",
    },
    {
      title: "Top providers",
      id: "bandwidth-most-active-top-providers",
      type: "most-active",
      dashboardType: "bandwidth-testing",
      availableSizes: [1, 2],
      endpoint: "/metric/bandwidth/getSpeedActive",
      queryParams: { type: "operators" },
    },
    {
      title: "Worse providers",
      id: "bandwidth-most-active-worse-providers",
      type: "most-active",
      dashboardType: "bandwidth-testing",
      availableSizes: [1, 2],
      endpoint: "/metric/bandwidth/getSpeedActive",
      queryParams: { type: "operators", isWorst: true },
    },
    {
      title: "Top locations",
      id: "bandwidth-most-active-top-locations",
      type: "most-active",
      dashboardType: "bandwidth-testing",
      availableSizes: [1, 2],
      endpoint: "/metric/bandwidth/getSpeedActive",
      queryParams: { type: "location" },
    },
    {
      title: "Worse locations",
      id: "bandwidth-most-active-worse-locations",
      type: "most-active",
      dashboardType: "bandwidth-testing",
      availableSizes: [1, 2],
      endpoint: "/metric/bandwidth/getSpeedActive",
      queryParams: { type: "location", isWorst: true },
    },
    // {
    //   title: "Latency by tech type",
    //   id: "bandwidth-column-line-chart-latency-type",
    //   type: "column-line-chart-old",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [2, 3, 4],
    // },
    // {
    //   title: "Bandwidth speed by tech type",
    //   id: "bandwidth-column-line-chart-speed-type",
    //   type: "column-line-chart-old",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [2, 3, 4],
    // },
    // {
    //   title: "Latency by provider",
    //   id: "bandwidth-column-line-chart-latency-provider",
    //   type: "column-line-chart",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [2, 3, 4],
    //   endpoint: "/metric/bandwidth/getSpeedActive",
    //   queryParams: { isLatency: true, type: "operators" },
    // },
    // {
    //   title: "Bandwidth speed by provider",
    //   id: "bandwidth-column-line-chart-speed-provider",
    //   type: "column-line-chart-",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [2, 3, 4],
    //   endpoint: "/metric/bandwidth/getSpeedActive",
    //   queryParams: { type: "operators" },
    // },
    // {
    //   title: "Availability score by region",
    //   id: "bandwidth-circle-chart-region",
    //   type: "circle-chart",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [1, 2],
    // },
    // {
    //   title: "Availability score by tech type",
    //   id: "bandwidth-circle-chart-type",
    //   type: "circle-chart",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [1, 2],
    // },
    // {
    //   title: "Availability score by provider",
    //   id: "bandwidth-circle-chart-provider",
    //   type: "circle-chart",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [1, 2],
    // },
    // {
    //   title: "Availability score by location",
    //   id: "bandwidth-circle-chart-location",
    //   type: "circle-chart",
    //   dashboardType: "bandwidth-testing",
    //   availableSizes: [1, 2],
    // },
  ],
};
