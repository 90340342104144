import {
  takeEvery,
  put,
  call,
  select,
  spawn,
  take,
  all,
} from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import {
  ConfirmT,
  LoginT,
  ForgetT,
  RecoveryT,
  AssignRoleT,
  ChangePasswordT,
  CONFIRM,
  CONFIRM_SUCCESS,
  CONFIRM_FAILURE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGET,
  FORGET_SUCCESS,
  FORGET_FAILURE,
  RECOVERY,
  RECOVERY_SUCCESS,
  RECOVERY_FAILURE,
  CHECK,
  CHECK_SUCCESS,
  CHECK_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_PHOTO_SUCCESS,
  REMOVE_PHOTO_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_FAILURE,
  UPLOAD_LOGO_SUCCESS,
  REMOVE_LOGO_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  ASSIGN_ROLE,
  ASSIGN_ROLE_FAILURE,
  ASSIGN_ROLE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  CHECK_USER_AVATAR,
  CHECK_USER_AVATAR_SUCCESS,
  CHECK_CUSTOMER_LOGO,
  CHECK_CUSTOMER_LOGO_SUCCESS,
} from "../reducers/authorization";
import {
  confirmRegistration,
  loginUser,
  forgetPassword,
  recoveryPassword,
  checkUser,
  updateUser,
  updateCustomer,
  uploadPhoto,
  removePhoto,
  uploadLogo,
  removeLogo,
  changePassword,
  assignRole,
  checkAvatar,
  checkLogo,
} from "../../api/authorization";
import {
  saveTokens,
  setToken,
  removeUser,
  getTokens,
  saveApiUrl,
} from "../../helpers/authorization";
import axios from "../../api/axiosConfig";
import { GetPresets } from "../actions/DashboadPresets.action";
import { SetSettings } from "../actions/SetSettings.action";
import {
  GET_CUSTOMERS_TREE,
  GET_CUSTOMER_LOGO,
} from "../actions/ManageCustomers.action";

function* confirmSaga(action: ConfirmT) {
  try {
    yield call(confirmRegistration, {
      ...action.data,
    });

    yield put({
      type: CONFIRM_SUCCESS,
      payload: {
        message: "Your account was successfully created. You may now Log in.",
      },
    });
  } catch (error) {
    yield put({ type: CONFIRM_FAILURE, error });
  }
}

function* loginSaga(action: LoginT) {
  try {
    const { data } = yield call(loginUser, action.data);
    saveTokens(data);
    saveApiUrl(data);
    yield put({ type: LOGIN_SUCCESS });
    yield put({ type: CHECK });

    yield take(CHECK_SUCCESS);
    yield put(push("/"));
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, error });
  }
}

function* assignRoleSaga(action: AssignRoleT) {
  try {
    yield call(assignRole, action.data);
    yield put({ type: ASSIGN_ROLE_SUCCESS });
    yield put({ type: CHECK });

    yield take(CHECK_SUCCESS);
    yield put(push("/"));
  } catch (error) {
    console.error(error);
    yield put({ type: ASSIGN_ROLE_FAILURE, error });
  }
}

function* forgetPasswordSaga(action: ForgetT) {
  try {
    yield call(forgetPassword, action.data);
    yield put({
      type: FORGET_SUCCESS,
      payload: {
        message:
          "Password reset link has been sent to your email, please follow instructions.",
      },
    });
  } catch (error) {
    yield put({ type: FORGET_FAILURE, error });
  }
}

function* recoveryPasswordSaga(action: RecoveryT) {
  try {
    const {
      router: {
        location: { search },
      },
    } = yield select();
    const recoverId = search.replace("?", "");

    yield call(recoveryPassword, {
      password: action.data.password,
      recoverId,
    });

    yield put({
      type: RECOVERY_SUCCESS,
      payload: {
        message: "Your password has been successfully changed. Please Log In.",
      },
    });
  } catch (error) {
    yield put({ type: RECOVERY_FAILURE, error });
  }
}

function* checkUserAvatarSaga() {
  try {
    const { data } = yield call(checkAvatar);
    const avatar = {
      photoBlob: data,
      photoLink: URL.createObjectURL(data),
      photoType: data.type,
    };
    yield put({ type: CHECK_USER_AVATAR_SUCCESS, payload: avatar });
  } catch (error) {
    yield put({
      type: CHECK_USER_AVATAR_SUCCESS,
      payload: {
        photoLink: "",
        photoType: null,
        photoBlob: null,
      },
    });
  }
}

function* checkCustomerLogoSaga(e: any) {
  try {
    const { data } = yield call(checkLogo, e.data);
    const logo = {
      photoBlobCustomer: data,
      photoLinkCustomer: URL.createObjectURL(data),
      photoTypeCustomer: data.type,
    };
    yield put({
      type: CHECK_CUSTOMER_LOGO_SUCCESS,
      ...(!e.fromUpdate && { payload: logo }),
    });
  } catch (error) {
    yield put({
      type: CHECK_CUSTOMER_LOGO_SUCCESS,
      payload: {
        photoLinkCustomer: "",
        photoTypeCustomer: null,
        photoBlobCustomer: null,
      },
    });
  }
}

function* checkUserSaga(params: any) {
  try {
    const token = getTokens().token || "";

    setToken(token);

    // @ts-ignore
    const companyInfo = JSON.parse(localStorage.getItem("customerContext"));
    const { data } = yield call(checkUser);
    yield put(GetPresets(data.id));
    yield put(SetSettings(data.settings));

    yield put({
      type: CHECK_USER_AVATAR,
    });

    yield take(CHECK_USER_AVATAR_SUCCESS);

    yield put({
      type: CHECK_CUSTOMER_LOGO,
      data: params.fromUpdate ? companyInfo.id : data.customer.id,
      fromUpdate: params.fromUpdate,
    });

    yield take(CHECK_CUSTOMER_LOGO_SUCCESS);

    yield put({
      type: CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // yield put( { type: LOGOUT } );
    yield put({ type: CHECK_FAILURE, error });
  }
}

function* logoutSaga() {
  try {
    removeUser();
    yield put({ type: LOGOUT_SUCCESS, userId: null });

    yield put(push("/auth/sign-in"));
  } catch (error: any) {
    console.error(error);
    yield put({ type: LOGOUT_FAILURE, error: error.message });
  }
}

function* uploadPhotoSaga(dataFile: any) {
  try {
    const checkType = (blob: any) => {
      switch (blob.type) {
        case "image/png":
          return "avatar.png";
        default:
          return "avatar.jpg";
      }
    };
    if (dataFile.photoLinkUser) {
      let file = dataFile.photoLinkUser;
      if (typeof dataFile.photoLinkUser !== "object") {
        file = new File(
          [dataFile.photoLinkUser],
          checkType(dataFile.photoLinkUser)
        );
      }
      yield call(uploadPhoto, file);
      yield put({ type: UPLOAD_PHOTO_SUCCESS });
    } else {
      yield call(removePhoto);
      yield put({ type: REMOVE_PHOTO_SUCCESS });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: UPLOAD_PHOTO_FAILURE, error: error });
  }
}

function* uploadLogoSaga(dataFile: any) {
  // @ts-ignore
  const companyInfo = JSON.parse(localStorage.getItem("customerContext"));
  try {
    const checkType = (blob: any) => {
      switch (blob.type) {
        case "image/png":
          return "logo.png";
        case "image/svg+xml":
          return "logo.svg";
        default:
          return "logo.jpg";
      }
    };
    if (dataFile.photoLinkCustomer) {
      let file = dataFile.photoLinkCustomer;
      if (typeof dataFile.photoLinkCustomer !== "object") {
        file = new File(
          [dataFile.photoLinkCustomer],
          checkType(dataFile.photoLinkCustomer)
        );
      }
      yield call(uploadLogo, file, companyInfo.id);
      yield put({ type: UPLOAD_LOGO_SUCCESS });
    } else {
      yield call(removeLogo, companyInfo.id);
      yield put({ type: REMOVE_LOGO_SUCCESS });
    }
    yield put({ type: GET_CUSTOMERS_TREE });
    yield put({ type: GET_CUSTOMER_LOGO, id: companyInfo.id});
  } catch (error) {
    console.error(error);
    yield put({ type: UPLOAD_LOGO_FAILURE, error: error });
  }
}

function* updateProfileSaga(action: any) {
  const userData = {
    name: action.data.name,
    email: action.data.email,
    contactNumber: action.data.contactNumber,
    jobTitle: action.data.jobTitle,
    description: action.data.description,
    management: action.data.management,
    configuration: action.data.configuration,
    dashboard: action.data.dashboard,
  };
  const { data } = yield call(checkUser);
  try {
    yield all([
      call(uploadPhotoSaga, action.data),
      call(updateUser, userData, action.data.userId || data.id),
    ]);
    yield put({ type: UPDATE_PROFILE_SUCCESS });

    yield put({ type: CHECK });
  } catch (error) {
    yield put({ type: UPDATE_PROFILE_FAILURE, error: error });
  }
}

function* updateCustomerSaga(action: any) {
  const customerData = {
    name: action.data.name,
    email: action.data.email,
    contactNumber: action.data.contactNumber,
    description: action.data.description,
    slaTargets: {
      networkSla: {
        bandwidthSpeedTarget: action.data.bandwidthSpeedTarget,
        delayTarget: action.data.delayTarget,
        transactionTimeTarget: action.data.transactionTimeTarget,
        numberOfHopsTarget: action.data.hopsTarget,
        jitterTarget: action.data.jitterTarget,
        availabilityTarget: action.data.availabilityTarget,
        lossTarget: action.data.lossTarget,
        bandwidthScoreTarget: action.data.bandwidthScoreTarget,
        dnsNumberTarget: action.data.dnsNumberTarget,
      },
      agentFinancialSla: {
        bandwidthCostMinuteTarget: action.data.bandwidthCostMinuteTarget,
        agentCostTarget: action.data.agentCostOfDowntimeTarget,
        locationCostTarget: action.data.locationCostTarget,
        employeeCostTarget: action.data.employeeCostOfDowntimeTarget,
        bandwidthCostMonthTarget: action.data.bandwidthCostMonthTarget,
      },
      appSla: {
        dnsTimeTarget: action.data.dnsTimeTarget,
        slaScoreTarget: action.data.slaScoreTarget,
        transactionTimeTarget: action.data.transactionTimeAppTarget,
        mosTarget: action.data.mosTarget,
        availabilityTarget: action.data.availabilityAppTarget,
        lossTarget: action.data.lossAppTarget,
        latencyTarget: action.data.latencyTarget,
        loadTimeTarget: action.data.loadTimeTarget,
      },
    },
    costDowntime: action.data.costDowntime,
    costDowntimeCurrency: action.data.costDowntimeCurrency,
  };
  try {
    yield all([
      call(uploadLogoSaga, action.data),
      call(updateCustomer, customerData, action.data.clientId),
    ]);
    yield put({ type: UPDATE_CUSTOMER_SUCCESS });

    yield put({ type: CHECK, fromUpdate: true });
  } catch (error) {
    yield put({ type: UPDATE_CUSTOMER_FAILURE, error: error });
  }
}

function* changePasswordSaga(action: ChangePasswordT) {
  try {
    yield call(changePassword, action.data);
    yield put({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({ type: CHANGE_PASSWORD_FAILURE, error });
  }
}

function* checkToken() {
  axios.defaults.headers.common["X-Requested-With"] = window.location.origin;
  // @ts-ignore
  const pathname = yield select((store) => store.router?.location?.pathname);

  const token = getTokens().token;

  if (token) {
    yield put({ type: CHECK });
    if (pathname.split("/").includes("auth")) {
      yield put(replace("/"));
    }
  } else {
    if (!pathname.split("/").includes("auth")) {
      yield put(replace("/auth/sign-in"));
    }
  }
}

export default function* authorizationSaga() {
  yield spawn(checkToken);
  yield takeEvery(CONFIRM, confirmSaga);
  yield takeEvery(LOGIN, loginSaga);
  yield takeEvery(ASSIGN_ROLE, assignRoleSaga);
  yield takeEvery(FORGET, forgetPasswordSaga);
  yield takeEvery(RECOVERY, recoveryPasswordSaga);
  yield takeEvery(CHECK, checkUserSaga);
  yield takeEvery(LOGOUT, logoutSaga);
  yield takeEvery(UPLOAD_PHOTO, uploadPhotoSaga);
  // yield takeEvery(REMOVE_PHOTO, removePhotoSaga);
  yield takeEvery(UPLOAD_LOGO, uploadLogoSaga);
  // yield takeEvery(REMOVE_LOGO, removeLogoSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(UPDATE_PROFILE, updateProfileSaga);
  yield takeEvery(UPDATE_CUSTOMER, updateCustomerSaga);
  yield takeEvery(CHECK_USER_AVATAR, checkUserAvatarSaga);
  yield takeEvery(CHECK_CUSTOMER_LOGO, checkCustomerLogoSaga);
}
