
import {all, put, takeEvery} from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import {GetAppPerformanceExtendedTableDone, GetAppPerformanceExtendedTableFailed} from "../../actions/widgets/AppsPerformanceExtendedTable.action";
import {GetAppPerformanceExtendedTableT, GET_APP_PERFORMANCE_EXTENDED_TABLE} from "../../types/widgets/AppsPerformanceExtendedTable.types";


function* GetAppsPerformanceExtendedTableHandler({params}: GetAppPerformanceExtendedTableT) {
  try {

    const {status, data} = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
        startHour: params.startHour,
        endHour: params.endHour,
        ...(params.agentId && {agentId: params.agentId}),
        ...(params.userId && {userId: params.userId}),
        ...(params.url && {url: params.url}),
        ...(params.location && {location: params.location}),
        ...(params.isByLocation !== null && params.isByLocation !== undefined && {isByLocation: params.isByLocation}),
        ...(params.isTraceroute !== null && params.isTraceroute !== undefined && {isTraceroute: params.isTraceroute})
      },
    })

    if (status === 204) {
      yield put(GetAppPerformanceExtendedTableDone(params.chartType, null));
    } else if (status === 200) {
      yield put(GetAppPerformanceExtendedTableDone(params.chartType, data));
    }
  } catch (error) {
    yield put(GetAppPerformanceExtendedTableFailed(params.chartType));
  }
}


export default function* WatchPathAnalytics() {
  yield all([
    takeEvery(GET_APP_PERFORMANCE_EXTENDED_TABLE, GetAppsPerformanceExtendedTableHandler)
  ]);
}
