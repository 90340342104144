import { GET_IMPORTANT_METRICS, GET_IMPORTANT_METRICS_DONE, GET_IMPORTANT_METRICS_FAILED, ImportantMetricsStateActionT } from "../../types/widgets/ImportantMetrics.types";


export const initialState: any = {};

const ImportantMetricsState = (
  state = initialState,
  action: ImportantMetricsStateActionT
) => {
  switch ( action.type ) {
    case GET_IMPORTANT_METRICS:
      return { ...state, [action.params.chartType]: { data: null, loading: true, error: false } };
    case GET_IMPORTANT_METRICS_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_IMPORTANT_METRICS_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default ImportantMetricsState;
