import { GetLastDashboardPreset } from "../GetLastDashboardPreset/GetLastDashboardPreset";
import { GetSelectedPresetDetails } from "../GetSelectedPresetDetails/GetSelectedPresetDetails";

export const GetDefaultCurrentPreset = (
  currentDashboard: any,
  settings: any,
  constantPresets: any,
  customPresets: any,
  constantReceivedPresets: any
) => {
  if (!currentDashboard) {
    if (settings.length) {
      const lastDashboardPreset = GetLastDashboardPreset(settings);

      const selectedPreset = GetSelectedPresetDetails(
        constantPresets,
        customPresets,
        constantReceivedPresets,
        settings[0].settings
      );
      if (lastDashboardPreset && selectedPreset) {
        return GetLastDashboardPreset(settings).settings;
      } else {
        return {
          title: constantPresets[0].title,
          id: constantPresets[0].id,
        };
      }
    } else {
      return {
        title: constantPresets[0].title,
        id: constantPresets[0].id,
      };
    }
  } else {
    const selectedPreset = GetSelectedPresetDetails(
      constantPresets,
      customPresets,
      constantReceivedPresets,
      currentDashboard
    );
    if (selectedPreset) {
      return currentDashboard;
    } else {
      return {
        title: constantPresets[0].title,
        id: constantPresets[0].id,
      };
    }
  }
};
