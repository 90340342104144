import React from "react";

const MessagesView = () => {
  return (
    <>
      <div>Messages</div>
    </>
  );
};

export default MessagesView;
