import React, { useState } from "react";
import styles from "./TableCellUsersBelong.module.scss";
import { ReactComponent as IconUsers } from "../../../assets/icons/users.svg";
import Modal from "../../../components/Modal/Modal";
import ModalShowUsers from "../../../components/Modal/ModalShowUsers/ModalShowUsers";
import {
  SingleCustomerUsersT,
  SingleCustomerCustomersT,
} from "../../../widgets/Customers/Customers";

const TableCellUsersBelong = (props: ITableCellUsersBelong) => {
  const { children, title, type = "User" } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleUsers = (
    users: ITableCellUsersBelong["children"],
    type: string | undefined
  ) => {
    switch (users.length) {
      case 0:
        return <span>{`No ${type}s`}</span>;
      case 1:
        return <span>{`1 ${type}`}</span>;
      default:
        return <span>{`${users.length} ${type}s`}</span>;
    }
  };

  return (
    <>
      {/* TODO: clean unnecessary after adjusting all users tables */}
      {Array.isArray(children) ? (
        <>
          <button
            type="button"
            className={styles.element}
            onClick={() => setModalOpen(true)}
          >
            {children.length > 0 && <IconUsers className={styles.icon} />}
            {children && handleUsers(children, type)}
          </button>
          <Modal
            title={`${title} Belong`}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          >
            <ModalShowUsers usersData={children} />
          </Modal>
        </>
      ) : (
        <span className={styles.element}>{children}</span>
      )}
    </>
  );
};

export default TableCellUsersBelong;

export interface ITableCellUsersBelong {
  children: SingleCustomerUsersT[] | SingleCustomerCustomersT[] | string;
  title: string;
  type?: "User" | "Customer";
}
