import React, { useState, useEffect, useRef } from "react";
import styles from "./SidebarMenuContext.module.scss";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";
import { ToggleMenu } from "../../../../store/actions/ToggleMenu.action";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../store/reducers";
import ModalSelectCustomer from "../../../Modal/ModalSelectCustomer/ModalSelectCustomer";
import { setCustomerContext } from "../../../../store/sagas/customerContext";
import Modal from "../../../Modal/Modal";

const SidebarMenuContext = () => {
  const { customer, permissions } = useSelector(
    (state: AppState) => state.authorization
  );
  const { customersTree } = useSelector(
    (state: AppState) => state.customersState
  );
  const companyNoAccess =
    !permissions.organization.fullAccess && !permissions.organization.data;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [customContext, setCustomContext] = useState<boolean>(true);
  const customerData = {
    id: customer.id,
    name: customer.name,
    email: customer.email,
  };
  const [context, setContext] = useState<ContextT>(
    // @ts-ignore
    JSON.parse(window.localStorage.getItem("customerContext"))
      ? // @ts-ignore
        JSON.parse(window.localStorage.getItem("customerContext"))
      : customerData
  );

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!window.location.href.includes("/profile-edit")) {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        if (customer) {
          window.localStorage.setItem(
            "customerContext",
            JSON.stringify(customer)
          );
          setContext(customer);
        }
      }
    }
  }, [customer]);

  useEffect(() => {
    if (customersTree) {
      if (customersTree.length && companyNoAccess && customContext) {
        setContext({
          id: customersTree[0].customer.id,
          name: customersTree[0].customer.name,
          email: customersTree[0].customer.email,
        });
        setCustomContext(false);
      }
    }
  }, [customersTree, companyNoAccess, customContext]);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();

  const handleClick = () => {
    isResponsiveView && dispatch(ToggleMenu());
    setModalOpen(true);
  };

  const handleContext = (customer: ContextT) => {
    setContext(customer);
    window.localStorage.setItem("customerContext", JSON.stringify(customer));
    dispatch(setCustomerContext(customer));
    setModalOpen(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.localStorage.setItem("customerContext", JSON.stringify(context));
      dispatch(setCustomerContext(context));
    }
    return () => {
      isMounted = false;
    };
  }, [context, dispatch, customer]);

  return (
    <>
      <button onClick={() => handleClick()} className={styles.element}>
        <span className={styles.title}>{context.name}</span>
      </button>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} noButton>
        <ModalSelectCustomer
          setModalOpen={setModalOpen}
          handleContext={handleContext}
        />
      </Modal>
    </>
  );
};

export default SidebarMenuContext;

export type ContextT = {
  id: string | null;
  name: string | null;
  email: string | null;
  photoLinkCustomer?: string | null;
};
