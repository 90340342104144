import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetEmployeeCallsTableDone, GetEmployeeCallsTableFailed } from "../../actions/widgets/EmployeeCallsTable";
import { GetEmployeeCallsTableT, GET_TABLE_EMPLOYEE_CALLS } from "../../types/widgets/EmployeeCallsTable.types";

function* GetEmployeeCallsTableHandler({ params }: GetEmployeeCallsTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate
      }
    });

    if (data.length) {
      yield put(GetEmployeeCallsTableDone(data));
    } else {
      yield put(GetEmployeeCallsTableDone([]));
    }
  } catch (error) {
    yield put(GetEmployeeCallsTableFailed());
  }
}

export default function* WatchEmployeeCallsTable() {
  yield all([takeEvery(GET_TABLE_EMPLOYEE_CALLS, GetEmployeeCallsTableHandler)]);
}
