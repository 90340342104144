export const GET_HEATMAP = "GET_HEATMAP";
export const GET_HEATMAP_DONE = "GET_HEATMAP_DONE";
export const GET_HEATMAP_FAILED = "GET_HEATMAP_FAILED";

export type HeatmapDataT = Array<{
  name: string,
  data: Array<{
    header: string,
    value: number
  }>
}> | null

export type HeatmapParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType?: string,
  label?: string,
  agentId?: string | null
  id?: string,
  isByLocation?: boolean,
  isTraceroute?: boolean,
  location?: string,
  url?: string
}

export type GetHeatmapT = {
  type: typeof GET_HEATMAP;
  params: HeatmapParamsT
}

export type GetHeatmapDoneT = {
  type: typeof GET_HEATMAP_DONE;
  chartType: string,
  data: HeatmapDataT
}

export type GetHeatmapFailedT = {
  type: typeof GET_HEATMAP_FAILED;
  chartType: string,
}

export type HeatmapStateActionT = GetHeatmapT | GetHeatmapDoneT | GetHeatmapFailedT