import React from "react";
import styles from "./SidebarMenu.module.scss";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconChevron } from "../../../assets/icons/chevron.svg";
import { ToggleMenu } from "../../../store/actions/ToggleMenu.action";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";

const SidebarMenu = (props: ISidebarMenu) => {
  const { label, children, className, labelLink, withLogo } = props;

  const dispatch = useDispatch();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  return (
    <div
      className={cn(styles.element, className, withLogo && styles["withLogo"])}
    >
      {label && labelLink ? (
        <NavLink
          exact
          to={labelLink}
          onClick={() => isResponsiveView && dispatch(ToggleMenu())}
          activeClassName={styles["isActive"]}
          className={cn(styles.label, styles["isLink"])}
        >
          {label}
          <IconChevron className={styles.icon} />
        </NavLink>
      ) : (
        label && <div className={styles.label}>{label}</div>
      )}
      {children}
    </div>
  );
};
export default SidebarMenu;

export interface ISidebarMenu {
  label?: string;
  children: React.ReactElement;
  className?: string;
  labelLink?: string;
  withLogo?: boolean;
}
