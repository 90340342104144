import React, { useState } from "react";
import styles from "../Reports/Reports.module.scss";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/file.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import ReportTemplatesTable from "../../widgets/ReportTemplatesTable/ReportTemplatesTable";
import Modal from "../../components/Modal/Modal";
import ModalEmailReport from "../../components/Modal/ModalEmailReport/ModalEmailReport";
import ModalExportReport from "../../components/Modal/ModalExportReport/ModalExportReport";
import DateRangePicker from "../../components/RangePicker/RangePicker";
import TimeRangePicker from "../../components/TimeRangePicker/TimeRangePicker";
import Select from "react-select";
// import { dateRangeSelector, timeRangeSelector } from "../../selectors";
// import { useSelector } from "react-redux";

const TemplateReportsView = () => {
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [fileFormat, setFileFormat] = useState({ value: "PDF", label: "PDF" });

  // const date = useSelector(dateRangeSelector);
  // const time = useSelector(timeRangeSelector);

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Report Templates">
          <div className={styles.dateWrapper}>
            <DateRangePicker />
            <TimeRangePicker />
          </div>
          <Select
          styles={customTableSelectStyles}
            options={[
              { value: "CSV", label: "CSV" },
              { value: "PDF", label: "PDF" },
            ]}
            value={fileFormat}
            onChange={(value) => value && setFileFormat(value)}
            menuPlacement="auto"
            isSearchable={false}
          />
          <Button
            title="Create template"
            icon={
              <IconButton
                className={styles.icon}
                width="1.2rem"
                height="1.5rem"
              />
            }
            icon_position="right"
            path="/create-template"
          />
        </LayoutHeader>
        <ReportTemplatesTable
          setModalEmailOpen={setModalEmailOpen}
          setModalExportOpen={setModalExportOpen}
        />
      </Container>
      <Modal
        title="Email Report"
        modalOpen={modalEmailOpen}
        setModalOpen={setModalEmailOpen}
      >
        <ModalEmailReport setModalOpen={setModalEmailOpen} />
      </Modal>
      <Modal
        title="Export Report"
        modalOpen={modalExportOpen}
        setModalOpen={setModalExportOpen}
      >
        <ModalExportReport setModalOpen={setModalExportOpen} />
      </Modal>
    </>
  );
};

export default TemplateReportsView;

export const customTableSelectStyles = {
  control: (base: any) => ({
    ...base,
    marginRight: '16px',
    width: "85px",
  })
};
