import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetEmployeeCallerMetricsTableDone, GetEmployeeCallerMetricsTableFailed } from "../../actions/widgets/EmployeeCallerMetricsTable";
import { GetEmployeeCallerMetricsTableT, GET_TABLE_EMPLOYEE_CALLER_METRICS } from "../../types/widgets/EmployeeCallerMetricsTable.types";

function* GetEmployeeCallerMetricsTableHandler({ params }: GetEmployeeCallerMetricsTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate,
        ...(params.userId && {userId: params.userId}),
      }
    });

    if (data.length) {
      yield put(GetEmployeeCallerMetricsTableDone(data));
    } else {
      yield put(GetEmployeeCallerMetricsTableDone([]));
    }
  } catch (error) {
    yield put(GetEmployeeCallerMetricsTableFailed());
  }
}

export default function* WatchEmployeeCallerMetricsTable() {
  yield all([takeEvery(GET_TABLE_EMPLOYEE_CALLER_METRICS, GetEmployeeCallerMetricsTableHandler)]);
}
