/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import axiosConfig from "../../../api/axiosConfig";
import styles from "../ModalAddNewApi/ModalAddNewApi.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import UploadJson from "../../formElements/UploadJson/UploadJson";
import Label from "../../Label/Label";
import Group from "../../CustomFields/Group/Group";
import StatusField from "../../StatusField/StatusField";
import { useSelector } from "react-redux";
import { customerContextSelector } from "../../../selectors";
import ModalError from "../ModalError/ModalError";

interface IModalAddNewApp {
  setModalOpen: (e: boolean) => void;
  handleAdd: () => void;
  setSelectedApp: (e: any) => void;
  app?: IAppValues | null;
}

const ModalAddNewApp = (props: IModalAddNewApp) => {
  const { setModalOpen, handleAdd, app, setSelectedApp } = props;

  const [sent, setSent] = useState(false);
  const [update, setUpdate] = useState(false);
  const [notSent, setNotSent] = useState(false);

  const customerContextState = useSelector(customerContextSelector);

  const initialValues = {
    name: "",
    json: "",
    description: "",
    slaTransactionTime: "",
    slaEUX: "",
    slaAvailability: "",
    slaCostPerMinDowntime: "",
    customerId: customerContextState.id,
  };

  const setConnection = async (values: any) => {
    try {
      if (app) {
        await axiosConfig.put("/configuration/app", values).then((res) => {
          if (res && res.status === 200) {
            setUpdate(true);
          }
        });
      } else {
        await axiosConfig.post("/configuration/app", values).then((res) => {
          if (res && res.status === 200) {
            setSent(true);
          }
        });
      }
    } catch (error: any) {
      setNotSent(true);
    }
  };

  useEffect(() => { 
    return () => {
      setSelectedApp(null);
    }
  }, []);

  useEffect(() => {
    if (sent || update) {
      handleAdd();
      setSelectedApp(null);
      setTimeout(() => {
        setSent(false);
        setNotSent(false);
        setUpdate(false);
      }, 4000);
    }
  }, [sent, notSent, update]);

  if (sent) {
    return <ModalSuccess text={`API has been added!`} />;
  }

  if (update) {
    return <ModalSuccess text={`API was updated!`} />;
  }

  if (notSent) {
    return <ModalError text={`Something is wrong, please try again later.`} />;
  }

  return (
    <div className={styles.content}>
      <Formik
        initialValues={app ? app : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setConnection(values);
        }}
        validate={(values) => {
          const errors = {};
          if (!values.json) {
            // @ts-ignore
            errors.json = "This field is required";
          }
          return errors;
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className={styles.element}>
            <TextInput
              touched={touched}
              errors={errors}
              name="name"
              label="App journey name"
              placeholder="Enter App journey name"
              required
            />
            <UploadJson
              touched={touched}
              errors={errors}
              name="json"
              label="App journey json"
              setFieldValue={setFieldValue}
            />
            <TextareaInput
              touched={touched}
              errors={errors}
              name="description"
              label="Description"
              placeholder="Enter Description"
            />
            {app && (
              <>
                <div className="hLine" />
                <Group className={styles.group}>
                  <Label title="Connection status" />
                  <StatusField title="not connected" />
                </Group>
                <TextareaInput
                  touched={touched}
                  errors={errors}
                  name="connection_errors"
                  label="Connection errors"
                  placeholder="No errors found"
                  disabled
                />
              </>
            )}
            <div className="hLine" />
            <Label title="Application performance SLA targets" type="heading" />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaTransactionTime"
              label="Transaction time SLA target (ms)"
              placeholder="Add transaction time SLA target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaEUX"
              label="EUX SLA target (%)"
              placeholder="Add EUX SLA target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaAvailability"
              label="Application availability target (%)"
              placeholder="Add Application availability target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaCostPerMinDowntime"
              label="Application cost of downtime per min target"
              placeholder="Add Application cost of downtime target"
              required
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title={app ? "Update" : "Connect"}
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalAddNewApp;

export interface IAppValues {
  id: string | number;
  app: {
    image: React.ReactElement;
    name: string;
    version: string;
  };
  priority: {
    label: string;
  };
  description: string;
  status: string;
  json: string;
}
