import colors from "../../../../assets/styles/variables/colors.module.scss";

export const GetUCCAppDashboard = () => [
  {
    title: "Volume trend",
    id: "ucc-most-active-volume",
    type: "most-active",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: "/metric/ucc/volumeTrendsOfCalls",
    chartType: "ucc"
    // availableSizes: [1, 2],
  },
  {
    title: "Quality trend",
    id: "ucc-most-active-quality",
    type: "most-active",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: "/metric/ucc/qualityTrendsOfCalls",
    chartType: "ucc"
    // availableSizes: [1, 2],
  },
  {
    title: "Call Quality",
    id: "ucc-gauge-results-old-multi",
    type: "gauge-results-old-multi",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: "/metric/ucc/callQualityMos",
    chartType: "ucc"
    // availableSizes: [1, 2],
  },
  {
    title: "Important Metrics",
    id: "ucc-important-metrics",
    type: "important-metrics",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: "/metric/important",
    chartType: "ucc"
    // availableSizes: [1, 2],
  },
  {
    title: "Calls per region",
    id: "ucc-map",
    type: "map",
    dashboardType: "ucc-app",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Most Active regions",
    id: "ucc-most-active-regions",
    type: "most-active",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: "/metric/ucc/getMostActiveRegions"
    // availableSizes: [1, 2],
  },
  {
    title: "Overall experience delivered",
    id: "ucc-circle-chart",
    type: "circle-chart",
    dashboardType: "ucc-app",
    size: 1,
    endpoint: '/metric/ucc/getActivityOfServices',
    chartType: "ucc"
    // availableSizes: [1, 2],
  },
  {
    title: "Adoption of users change",
    id: "ucc-gauge-half",
    type: "gauge-half",
    dashboardType: "ucc-app",
    size: 2,
    endpoint: '/metric/ucc/getActivityOfServices',
    chartType: "ucc",
    sections: [
      {
        title: "Voice",
        color: colors["widgets-blue"],
        accessor: (data: {value: number, title: string}) => data?.value
      },
      {
        title: "Video",
        color: colors["widgets-green"],
        accessor: (data: {value: number, title: string}) => data?.value
      },
      {
        title: "Appshare",
        color: colors["widgets-crusta"],
        accessor: (data: {value: number, title: string}) => data?.value
      },
    ]
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Adoption of quality change",
    id: "ucc-comparison-box-quality",
    type: "comparison-box",
    dashboardType: "ucc-app",
    size: 1,
    chartType: "ucc-quality",
    endpoint: "/metric/changeIn"
    // availableSizes: [1, 2],
  },
  {
    title: "Adoption of Voice change",
    id: "ucc-comparison-box-voice",
    type: "comparison-box",
    dashboardType: "ucc-app",
    size: 1,
    chartType: "ucc-voice",
    endpoint: "/metric/changeIn"
    // availableSizes: [1, 2],
  },
  {
    title: "Adoption of Video change",
    id: "ucc-comparison-box-video",
    type: "comparison-box",
    dashboardType: "ucc-app",
    size: 1,
    chartType: "ucc-video",
    endpoint: "/metric/changeIn"
    // availableSizes: [1, 2],
  },
  {
    title: "Total calls change",
    id: "ucc-comparison-box-calls",
    type: "comparison-box",
    dashboardType: "ucc-app",
    size: 1,
    chartType: "ucc-total-calls",
    endpoint: "/metric/changeIn"
    // availableSizes: [1, 2],
  },
  {
    title: "Total conferance calls change",
    id: "ucc-comparison-box-conference",
    type: "comparison-box",
    dashboardType: "ucc-app",
    size: 1,
    chartType: "ucc-conference-calls",
    endpoint: "/metric/changeIn"
    // availableSizes: [1, 2],
  },
  {
    title: "Metrics of quality over locations/employees",
    id: "ucc-heatmap",
    type: "heatmap",
    dashboardType: "ucc-app",
    size: 3,
    endpoint: "/metric/ucc/getCallMetrics"
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Call details per employee",
    id: "ucc-employee-calls-table",
    type: "employee-calls-table",
    dashboardType: "ucc-app",
    size: 4,
    path: {
      pathname: "dashboard/",
    },
  },
  {
    title: "Device details per employee",
    id: "ucc-device-employee-table",
    type: "device-employee-table",
    dashboardType: "ucc-app",
    size: 4,
    path: {
      pathname: "dashboard/",
    },
  },
  {
    title: "Call details per conference call and meeting room",
    id: "ucc-conference-calls-table",
    type: "conference-calls-table",
    dashboardType: "ucc-app",
    size: 4,
    path: {
      pathname: "dashboard/",
    },
  },
];
