import React from "react";
import styles from "./UserCard.module.scss";
import { ReactComponent as IconUser } from "../../assets/icons/user-placeholder-sm.svg";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { toBase64 } from "../../functions/ToBase64/ToBase64";

const UserCard = (props: IUserCard) => {
  const { isAccount, link } = props;

  if (link) {
    return (
      <NavLink
        to={link ? link : "/"}
        className={cn(
          styles.element,
          styles.link,
          isAccount && styles["isAccount"]
        )}
      >
        <InnerCard {...props} />
      </NavLink>
    );
  }
  return (
    <div className={cn(styles.element, isAccount && styles["isAccount"])}>
      <InnerCard {...props} />
    </div>
  );
};

export default UserCard;

const InnerCard = (props: IInnerCard) => {
  const { name, email, photoLink } = props;

  return (
    <>
      <div className={styles.imageWrapper}>
        {photoLink ? (
          <img
            src={`data:${photoLink.type};base64,${toBase64(photoLink.data)}`}
            alt={`${name} logo`}
          />
        ) : (
          <IconUser />
        )}
      </div>
      <div>
        <span className={styles.name}>{name}</span>
        {email && <span className={styles.email}>{email}</span>}
      </div>
    </>
  );
};

export interface IUserCard {
  name: string;
  email: string;
  photoLink?: {
    type: string;
    data: number[];
  };
  image?: string;
  isAccount?: boolean;
  link?: string;
}
export interface IInnerCard {
  name: string;
  email: string;
  photoLink?: {
    type: string;
    data: number[];
  };
  image?: string;
}
