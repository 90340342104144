export const GET_GAUGE_RESULTS = "GET_GAUGE_RESULTS";
export const GET_GAUGE_RESULTS_DONE = "GET_GAUGE_RESULTS_DONE";
export const GET_GAUGE_RESULTS_FAILED = "GET_GAUGE_RESULTS_FAILED";

export type GaugeResultsT = number | null

export type GaugeResultsParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  url?: string,
  location?: string,
  agentId?: string | null,
  userId?: string | null,
  deviceName?: string,
  callId?: string,
  isConference?: boolean,
  isTraceroute?: boolean
}

export type GetGaugeResultsT = {
  type: typeof GET_GAUGE_RESULTS;
  params: GaugeResultsParamsT
}

export type GetGaugeResultsDoneT = {
  type: typeof GET_GAUGE_RESULTS_DONE;
  chartType: string,
  data: GaugeResultsT
}

export type GetGaugeResultsFailedT = {
  type: typeof GET_GAUGE_RESULTS_FAILED;
  chartType: string,
}

export type GaugeResultsActionT = GetGaugeResultsT | GetGaugeResultsDoneT | GetGaugeResultsFailedT