import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetCallerCalleeDevicesTableDone, GetCallerCalleeDevicesTableFailed } from "../../actions/widgets/CallerCalleeDevicesTable";
import {GetCallerCalleeDevicesTableT, GET_CALLER_CALLEE_DEVICES } from "../../types/widgets/CallerCalleeDevicesTable.types";

function* GetCallerCalleeTableHandler({ params }: GetCallerCalleeDevicesTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate,
        ...(params.userId && {userId: params.userId})
      }
    });

    if (data.length) {
      yield put(GetCallerCalleeDevicesTableDone(data));
    } else {
      yield put(GetCallerCalleeDevicesTableDone([]));
    }
  } catch (error) {
    yield put(GetCallerCalleeDevicesTableFailed());
  }
}

export default function* WatchCallerCalleeTable() {
  yield all([takeEvery(GET_CALLER_CALLEE_DEVICES, GetCallerCalleeTableHandler)]);
}
