import colors from "../../../../assets/styles/variables/colors.module.scss";
import TableCellQuality from "../../../../components/tableElements/TableCellQuality/TableCellQuality";
import TableCellText from "../../../../components/tableElements/TableCellText/TableCellText";

export const GetLicenseDashboard = () => [
  {
    title: "License usage",
    id: "license-circle-chart-usage",
    type: "circle-chart",
    dashboardType: "license",
    size: 1,
    endpoint: "/metric/finance/getLicenseUsage",
    // availableSizes: [1, 2],
  },
  {
    title: "Monthly cost change against previous month",
    id: "license-most-active-cost",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    endpoint: "/metric/finance/getMostActiveLicense",
    queryParams: { isCost: true },
    // availableSizes: [1, 2],
  },
  {
    title: "Usage license per location",
    id: "license-most-active-location",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    endpoint: "/metric/finance/getMostActiveLicense",
    queryParams: { isByLocation: true },
    // availableSizes: [1, 2],
  },
  {
    title: "Potential savings across licenses",
    id: "license-important-metrics-savings",
    type: "most-active",
    endpoint: "/metric/finance/getMostActiveLicense",
    dashboardType: "license",
    size: 1,
    chartType: "licenseSavings",
    queryParams: { isPotentialSaving: true },
    // availableSizes: [1, 2],
  },
  {
    title: "Breakdowns by licence type",
    id: "license-gauge-half-breakdown",
    type: "gauge-half",
    dashboardType: "license",
    size: 2,
    endpoint: "/metric/finance/getBreakdownsLicenses",
    sections: [
      {
        title: "Voice",
        color: colors["widgets-blue"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
      {
        title: "Video",
        color: colors["widgets-green"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
      {
        title: "Appshare",
        color: colors["widgets-crusta"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
    ],
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost of license usage",
    id: "license-column-line-chart-cost",
    type: "column-line-chart",
    dashboardType: "license",
    size: 2,
    endpoint: "/metric/finance/getLicenseOverallUsage",
    chartType: "license",
    data: {
      column: [
        {
          title: "License",
          id: "license",
        },
        {
          title: "Usage",
          id: "usage",
        },
        {
          title: "Unused",
          id: "unusage",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
    // availableSizes: [2, 3, 4],
  },
  // {
  //   title: "License usage",
  //   id: "license-simple-table-users",
  //   type: "simple-table",
  //   dashboardType: "license",
  //   size: 4,
  //   // availableSizes: [2, 3, 4],
  // },
  // {
  //   title: "Mailbox usage over time",
  //   id: "license-column-line-chart-old-mailbox",
  //   type: "column-line-chart-old",
  //   dashboardType: "license",
  //   size: 2,
  //   // availableSizes: [2, 3, 4],
  // },
  // {
  //   title: "Top mailbox users per employee",
  //   id: "license-most-active-mailbox-users",
  //   type: "most-active",
  //   dashboardType: "license",
  //   size: 1,
  //   // availableSizes: [1, 2],
  // },
  // {
  //   title: "Top mailbox sizes",
  //   id: "license-most-active-mailbox-sizes",
  //   type: "most-active",
  //   dashboardType: "license",
  //   size: 1,
  //   // availableSizes: [1, 2],
  // },

  {
    title: "Savings usage",
    id: "license-column-line-chart-old-savings",
    type: "column-line-chart",
    dashboardType: "license",
    size: 2,
    endpoint: "metric/finance/getLicenseOverallUsage",
    chartType: "savingsUsage",
    data: {
      column: [
        {
          title: "Usage",
          id: "usage",
        },
        {
          title: "Savings",
          id: "unusage",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Active users",
    id: "license-column-line-chart-users",
    type: "column-line-chart",
    dashboardType: "license",
    size: 2,
    endpoint: "metric/finance/getLicenseOverallUsage",
    chartType: "activeUsers",
    queryParams: { isUser: true },
    data: {
      column: [
        {
          title: "License",
          id: "license",
        },
        {
          title: "Usage",
          id: "usage",
        },
        {
          title: "Unused",
          id: "unusage",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
    // availableSizes: [2, 3, 4],
  },
  // {
  //   title: "Employees/Users",
  //   id: "license-important-metrics-old-users",
  //   type: "important-metrics-old",
  //   dashboardType: "license",
  //   size: 1,
  //   // availableSizes: [1, 2],
  // },
  // {
  //   title: "Mailbox usage",
  //   id: "license-simple-table-mailbox",
  //   type: "simple-table",
  //   dashboardType: "license",
  //   size: 3,
  //   // availableSizes: [2, 3, 4],
  // },
  {
    title: "Employees license information",
    id: "app1-tests-table-issues",
    type: "simple-table",
    dashboardType: "finance",
    size: 4,
    availableSizes: [3, 4],
    endpoint: "/metric/finance/getEmployeeLicenseInfo",
    columns: [
      {
        Header: "employee Name",
        accessor: "employeeName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Product Name",
        accessor: "productName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "sku Id",
        accessor: "skuId",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "provisioning Status",
        accessor: "provisioningStatus",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
    ],
  },
  {
    title: "MS 365/teams license ADD table",
    id: "app1-tests-table-issues",
    type: "simple-table",
    dashboardType: "finance",
    size: 4,
    availableSizes: [3, 4],
    endpoint: "/metric/finance/getAssignedLicenses",
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "assigned",
        accessor: "assigned",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "count",
        accessor: "count",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText noWrap>{`£${value}`}</TableCellText>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => (
          <TableCellQuality quality={value} />
        ),
      },
    ],
  },
];
