import { WidgetT } from "../../../store/reducers/DashboardPresets";

export const ChangeWidgetsFormat = (d: WidgetT[]) => {
  return d.map((elem: WidgetT) => {
    if (elem.size) {
      return (({ id, size }) => ({ id, size }))(elem);
    } else {
      return (({ id }) => ({
        id,
      }))(elem);
    }
  });
};
