export const GET_DEVICES_LIST = "GET_DEVICES_LIST";
export const GET_DEVICES_LIST_DONE = "GET_DEVICES_LIST_DONE";
export const GET_DEVICES_LIST_FAILED = "GET_DEVICES_LIST_FAILED";

export type DeviceT = {
  id: string,
  device: {
    name: string,
    id: string
  }
  customerId: string,
  name: string,
  agentName: string,
  status: string,
  deviceType: string,
  vendor: string,
  connectionType: string,
  model: string,
  alias: string,
  circuitId: string,
  description: string,
  mac: string,
  ip: string,
  osVersion: string,
  latency: number,
  lastTestDate: number,
  location: string,
  country: string,
  province: string,
  zipCode: string,
  city: string,
  street: string,
  locationGroup: string,
  bandwidthDownload: string,
  bandwidthUpload: string,
  snmpVersion: string,
  communityString: string,
  userName: string,
  privProtocol: string,
  privKey: string,
  authProtocol: string,
  authKey: string,
  agentId: string,
  interface: string,
  addedDevice: boolean
}

export type ActionTypeT = GetDevicesListT | GetDevicesListDoneT | GetDevicesListFailedT

export type GetDevicesListT = {
  type: typeof GET_DEVICES_LIST;
  id: string;
  isAdded: boolean
}

export type GetDevicesListDoneT = {
  type: typeof GET_DEVICES_LIST_DONE;
  payload: DeviceT[] | null,
  isAdded: boolean
}

export type GetDevicesListFailedT = {
  type: typeof GET_DEVICES_LIST_FAILED;
  error: any
}