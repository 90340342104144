import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { confirmUser } from "../../store/reducers/authorization";
import styles from "./UserFormTemplate.module.scss";
import TextInput from "../formElements/TextInput/TextInput";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ReactComponent as IconLogin } from "../../assets/icons/logout.svg";
import axios from "../../api/axiosConfig";

const SignUpForm = () => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    customerName: "",
    name: "",
    jobTitle: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
  });

  const registrationId = window.location.search.substr(1);

  const dispatch = useDispatch();

  const [hasError, setError] = useState(null);
  const [hasMessage, setMessage] = useState(null);
  const { error, message } = useSelector((state: any) => state.authorization);

  const history = useHistory();

  useEffect(() => {
    if (message) {
      setMessage(message);
      setTimeout(() => {
        history.push("/auth/sign-in");
      }, 3000);
    }
  }, [message, history]);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    setError(null);
  }, []);

  async function submitRegister(values: any) {
    await dispatch(
      confirmUser({
        registrationId: registrationId,
        password: values.password,
        name: values.name,
        contactNumber: values.contactNumber,
        jobTitle: values.jobTitle,
      })
    );
  }

  useEffect(() => {
    axios("/auth/registration", {
      params: {
        registrationId: registrationId,
      },
    }).then((res) => {
      setInitialValues({
        ...res.data,
        password: "",
        confirmPassword: "",
        jobTitle: "",
      });
    });
  }, [registrationId]);

  if (!registrationId) {
    return <Redirect to={"/auth/sign-in"} />;
  }

  if (hasMessage) {
    return <div className={styles.confirmMessage}>{hasMessage}</div>;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitRegister(values);
      }}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <Form className={styles.element}>
          <TextInput
            touched={touched}
            errors={errors}
            name="email"
            label="Email address*"
            placeholder="Email address"
            textInputType="email"
            disabled
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="customerName"
            label="Company*"
            placeholder="Company"
            disabled
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="name"
            label="Full name*"
            placeholder="Full name"
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="jobTitle"
            label="Job title*"
            placeholder="Job title"
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="password"
            label="Create password*"
            placeholder="Password"
            textInputType="password"
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="confirmPassword"
            label="Confirm password*"
            placeholder="Confirm password"
            textInputType="password"
            confirmPassword
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="contactNumber"
            label="Contact number"
            placeholder="Phone number"
            textInputType="tel"
          />
          <div className={styles.footer}>
            {hasError && <div className={styles.errorMessage}>{hasError}</div>}
            <Button
              typeAttr="submit"
              title="Sign up"
              icon={<IconLogin />}
              icon_position="right"
            />
            <span className={styles.text}>
              Already have an account?{" "}
              <Link to={"/auth/sign-in"} className={styles.link}>
                Sign in
              </Link>
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default SignUpForm;
