import {
  GetEmployeeCallsTableDoneT,
  GetEmployeeCallsTableFailedT,
  GetEmployeeCallsTableT,
  GET_TABLE_EMPLOYEE_CALLS,
  GET_TABLE_EMPLOYEE_CALLS_DONE,
  GET_TABLE_EMPLOYEE_CALLS_FAILED,
  EmployeeCallsTableDataT,
  EmployeeCallsTableParamsT
} from "../../types/widgets/EmployeeCallsTable.types";

export const GetEmployeeCallsTable = (params: EmployeeCallsTableParamsT): GetEmployeeCallsTableT => ({
  type: GET_TABLE_EMPLOYEE_CALLS,
  params
});

export const GetEmployeeCallsTableDone = (data: EmployeeCallsTableDataT): GetEmployeeCallsTableDoneT => ({
  type: GET_TABLE_EMPLOYEE_CALLS_DONE,
  data
});

export const GetEmployeeCallsTableFailed = (): GetEmployeeCallsTableFailedT => ({
  type: GET_TABLE_EMPLOYEE_CALLS_FAILED
});
