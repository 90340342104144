export const checkEditAccess = (permissions: any, customer: any, id: any) => {
  const editAccess =
    // organization
    ((customer.id === id || !id) &&
      permissions.organization.fullAccess &&
      permissions.organization.data) ||
    // structure
    (customer.id !== id &&
      permissions.structure.fullAccess &&
      permissions.structure.data);
  return editAccess;
};
