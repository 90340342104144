export const applicationsDefaultDashboard = {
  title: "Applications",
  id: "applications",
  widgets: [
    // {
    //   title: "Applications Analysis",
    //   id: "applications-network-analysis",
    //   type: "network-analysis",
    //   dashboardType: "applications",
    //   availableSizes: [2, 3, 4],
    //   size: 4
    // },
    {
      title: "Heatmap",
      id: "applications-heatmap-S1",
      type: "heatmap",
      dashboardType: "applications",
      availableSizes: [2, 3, 4],
      size: 2,
      endpoint: "metric/app/getHeatmap",
      chartType: "agents",
      isByLocation: true
    },
    {
      title: "Overall Performance EUX score",
      id: "applications-circle-chart",
      type: "circle-chart",
      dashboardType: "applications",
      availableSizes: [1, 2],
      size: 1,
      endpoint: "metric/app/getOverallPerfomance"
    },
    {
      title: "Frustration Rate",
      id: "applications-gauge-results-frustration",
      type: "gauge-results-icon",
      dashboardType: "applications",
      availableSizes: [1, 2],
      size: 1,
      endpoint: "/metric/totalResults",
      chartType: "frustration"
    },
    {
      title: "Top 3 applications",
      id: "applications-ordered-list-top-apps",
      type: "important-metrics",
      dashboardType: "applications",
      availableSizes: [1, 2],
      endpoint: "/metric/app/getTopWorstApps",
      isTop: true,
      chartType: "top3App"
    },
    {
      title: "Worse 3 applications",
      id: "applications-ordered-list-worse-apps",
      type: "important-metrics",
      dashboardType: "applications",
      availableSizes: [1, 2],
      endpoint: "/metric/app/getTopWorstApps",
      isTop: false,
      chartType: "worse3App"
    },
    // {
    //   title: "Top locations",
    //   id: "applications-ordered-list-top-locations",
    //   type: "ordered-list",
    //   dashboardType: "applications",
    //   availableSizes: [1, 2],
    //   data: [
    //     {
    //       title: "Australia, Victoria, Hunterview, 6692, 2300 Edwards Pass",
    //       pathname: "dashboard/location",
    //       dashboardType: "location"
    //     },
    //     {
    //       title: "USA, Indiana, Flatleyburgh, 5121, 426 Cronin Plains",
    //       pathname: "dashboard/location",
    //       dashboardType: "location"
    //     },
    //     {
    //       title: "United Kindom, London, Hammersmith, W6 7AP, 12 Hammersmith Grove",
    //       pathname: "dashboard/location",
    //       dashboardType: "location"
    //     }
    //   ]
    // },
    {
      title: "Worse locations",
      id: "applications-ordered-list-worse-locations",
      type: "important-metrics",
      dashboardType: "applications",
      availableSizes: [1, 2],
      endpoint: "/metric/app/getWorstLocation",
      chartType: "agents"
    },
    {
      title: "Overall Availability of Applications",
      id: "applications-gauge-results-old",
      type: "gauge-results",
      dashboardType: "applications",
      availableSizes: [1, 2],
      endpoint: "/metric/app/getAvailibility",
    },
    {
      title: "Application/test and their performance",
      id: "applications-apps-performance-extended",
      type: "apps-performance-extended",
      dashboardType: "applications",
      availableSizes: [3, 4],
      size: 4,
      endpoint: '/metric/app/getApplicationsMetric'
    },
    {
      title: "Global Map of Applications performance",
      id: "applications-map",
      type: "map",
      dashboardType: "applications",
      availableSizes: [3, 4],
      size: 4,
      endpoint: '/metric/app/getLocation'
    },
    {
      title: "Applications cost of downtime",
      id: "applications-status-table",
      type: "apps-downtime-cost-table",
      dashboardType: "applications",
      availableSizes: [3, 4],
      size: 4
    }
  ]
}