export const GetApplicationUCCDashboard = () => [
  {
    title: "Updates on UCC usage",
    id: "app3-important-metrics-old",
    type: "important-metrics-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "EUX score overall",
    id: "app3-gauge-results-eux",
    type: "gauge-results",
    dashboardType: "app3",
    size: 1,
    endpoint: "/metric/totalResults",
    chartType: "eux",
  },
  {
    title: "Availability",
    id: "app3-gauge-results-old-availability",
    type: "gauge-results-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Adoption rate",
    id: "app3-circle-chart-adoption",
    type: "circle-chart",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "USS services",
    id: "app3-apps-performance-extended-ucc",
    type: "apps-performance-extended",
    dashboardType: "app3",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Heatmap of UCC apps",
    id: "app3-heatmap-ucc-old",
    type: "heatmap-old",
    dashboardType: "app3",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Voice performance metrics",
    id: "app3-column-line-chart-old-voice",
    type: "column-line-chart-old",
    dashboardType: "app3",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Video performance",
    id: "app3-column-line-chart-old-video",
    type: "column-line-chart-old",
    dashboardType: "app3",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "AppShare performance metrics",
    id: "app3-column-line-chart-old-appshare",
    type: "column-line-chart-old",
    dashboardType: "app3",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Login performance metrics",
    id: "app3-column-line-chart-old-login",
    type: "column-line-chart-old",
    dashboardType: "app3",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Change in latency",
    id: "app3-comparison-box-old-latency",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in packed loss",
    id: "app3-comparison-box-old-packet",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in healed ratio",
    id: "app3-comparison-box-old-healed",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in bandwidth",
    id: "app3-comparison-box-old-bandwidth",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in transaction time",
    id: "app3-comparison-box-old-transaction",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in property time",
    id: "app3-comparison-box-old-property",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in connection time",
    id: "app3-comparison-box-old-connection",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Change in adoption",
    id: "app3-comparison-box-old-adoption",
    type: "comparison-box-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Teams path performance",
    id: "app3-path-analytics-teams",
    type: "path-analytics-old",
    dashboardType: "app3",
    size: 3,
    // availableSizes: [3, 4],
  },
  {
    title: "Important Metrics",
    id: "app3-important-metrics-old-2",
    type: "important-metrics-old",
    dashboardType: "app3",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Devices and endpoints with metrics",
    id: "app3-simple-table-devices",
    type: "simple-table",
    dashboardType: "app3",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "UCC emploee call metrics per employee",
    id: "app3-simple-table-employee",
    type: "simple-table",
    dashboardType: "app3",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
];
