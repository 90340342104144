import React from "react";
import styles from "./NotificationsItem.module.scss";
import {ReactComponent as IconClose} from "../../../assets/icons/close.svg";
import cn from "classnames";
import moment from "moment";

export interface INotificationsItem {
    type: "alert" | "notification";
    title: string;
    date: number;
}

const NotificationsItem = (props: INotificationsItem) => {
    const {type, title, date} = props;

    return (
        <tr>
            <td className={cn(styles.cell, type === 'alert' ? styles['isAlert'] : styles["isNotification"])}>
                <span className={styles.status}/>
                {type === 'alert' && <span className={styles.alert}>Important</span>}
                <span className={styles.text}>{title}</span>
                <span className={styles.time}>{moment(date * 1000).fromNow()}</span>
                <button className={styles.button}>
                    <IconClose width="1.2rem" height="1.2rem"/>
                </button>
            </td>
        </tr>
    );
};

export default NotificationsItem;
