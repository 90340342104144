import React from "react";
//@ts-ignore
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import styles from "./RangePickerField.module.scss";
import { FieldProps, useFormikContext } from "formik";
import { ReactComponent as IconCalendar } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as IconChevron } from "../../../../assets/icons/calendar-chevron.svg";

const RangePickerField = (props: IRangePickerField) => {
  const { field, minDate, maxDate } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <DateRangePicker
      className={styles.element}
      value={field.value}
      name={field.name}
      clearIcon={null}
      calendarIcon={<IconCalendar />}
      format="dd/MM/y"
      prev2Label={null}
      next2Label={null}
      prevLabel={<IconChevron />}
      nextLabel={<IconChevron />}
      showNeighboringMonth={false}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(val: any) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default RangePickerField;

interface IRangePickerField extends FieldProps {
  minDate?: Date;
  maxDate?: Date;
}
