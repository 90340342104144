import {ActionTypeT, DeviceT, GET_DEVICES_LIST, GET_DEVICES_LIST_DONE, GET_DEVICES_LIST_FAILED} from "../types/Devices";

export type StateT = {
  isLoading: boolean;
  message: string | null;
  devicesAdded: DeviceT[] | null;
  devicesDiscovered: DeviceT[] | null;
  error: boolean;
};

const initialState: StateT = {
  isLoading: false,
  message: null,
  devicesAdded: null,
  devicesDiscovered: null,
  error: false,
};

const reducerDevices = (state = initialState, action: ActionTypeT): StateT => {
  switch (action.type) {
    case GET_DEVICES_LIST:
      return {...state, devicesAdded: null, devicesDiscovered: null, isLoading: true, error: false};
    case GET_DEVICES_LIST_DONE:
      return {...state, isLoading: false, devicesAdded: action.isAdded ? action.payload : null, devicesDiscovered: !action.isAdded ? action.payload : null};
    case GET_DEVICES_LIST_FAILED:
      return {...state, devicesAdded: null, devicesDiscovered: null, isLoading: false, error: true};
    default:
      return state;
  }
};

export default reducerDevices;
