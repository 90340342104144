import React, { useState } from "react";
import styles from "./SingleCustomer.module.scss";
import buttonStyles from "../SingleAccount/SingleAccount.module.scss";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/check.svg";
import { ReactComponent as IconDownload } from "../../assets/icons/download.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import { useParams } from "react-router-dom";
import Heading from "../../components/Heading/Heading";
import ModalShareWidgets from "../../components/Modal/ModalShareWidgets/ModalShareWidgets";
import SharedWidgetsTable from "../../components/SharedWidgetsTable/SharedWidgetsTable";

const SingleCustomerView = () => {
  let { id } = useParams<{ id: string }>();
  const [modalOpen, setModalOpen] = useState(false);

  const [dataWidgets] = useState([
    {
      title: "List of Sites/locations",
    },
    {
      title: "Total Potential Saving",
    },
    {
      title: "Volume of Devices",
    },
    {
      title: "BGP and Pathping ",
    },
  ]);

  return (
    <>
      <Container fluid>
        <LayoutHeader pathBack="/user-management" title={`${id}`}>
          <Button
            type="plain"
            icon={<IconDownload />}
            icon_position="right"
            title="Download Account Info"
            className={buttonStyles.button}
          />
          <Button title="Save" icon={<IconButton />} icon_position="right" />
        </LayoutHeader>
        <div className="mb-4">
          <Heading
            title="Partner"
            Tag="h3"
            weight="medium"
            className={styles.subheading}
          />
        </div>
        {/* <Users data={data} /> */}
        <div className="d-flex align-items-center mt-5 mb-4">
          <Heading
            title="Shared Widget Presets"
            Tag="h3"
            weight="medium"
            className={styles.subheading}
          />
          <Button
            title="+Add"
            type="outline"
            handleClick={() => setModalOpen(true)}
          />
        </div>
        <SharedWidgetsTable data={dataWidgets} />
      </Container>
      <ModalShareWidgets modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default SingleCustomerView;
