import React from "react";
import styles from "./CurrencyText.module.scss";
import NumberFormat from "react-number-format";

const CurrencyText = (props: ICurrencyText) => {
  const { title, selectedCurrency } = props;

  const handlePrefix = (val: string) => {
    switch (val) {
      case "GBP":
        return "£";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "AED":
        return "د.إ";
      case "AUD":
        return "$";
      case "JPY":
        return "¥";
      case "CHF":
        return "CHF";
      case "CAD":
        return "$";
      case "CNH":
        return "¥";
      case "INR":
        return "₹";
      default:
        return "";
    }
  };

  return (
    <h4>
      <NumberFormat
        className={styles.element}
        displayType={"text"}
        thousandSeparator={true}
        decimalSeparator="."
        prefix={handlePrefix(selectedCurrency)}
        decimalScale={2}
        value={title}
      />
    </h4>
  );
};

export default CurrencyText;

export interface ICurrencyText {
  title: string | number;
  selectedCurrency: string;
}
