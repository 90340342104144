import React, { useState, useEffect, useCallback } from "react";
import styles from "./ModalConnectNewAgent.module.scss";
import { Form, Formik } from "formik";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import Features from "../../../components/formElements/Features/Features";
import AddApp from "../../formElements/AddApp/AddApp";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import axios from "../../../api/axiosConfig";
import { NumSelectOptions } from "../../../functions/NumSelectOptions/NumSelectOptions";
import { SingleAgentT } from "../../../views/AgentsUnknownManagement/AgentsUnknownManagement.view";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import CustomersSelect from "../../formElements/CustomersSelect/CustomersSelect";
import { Column, Row } from "../../Bootstrap/Bootstrap";
import Label from "../../Label/Label";
import CurrencyInput from "../../../components/formElements/CurrencyInput/CurrencyInput";

export interface IModalConnectNewAgent {
  setModalOpen: (e: boolean) => void;
  agent: SingleAgentT;
  activeContext: boolean;
}

const ModalConnectNewAgent = (props: IModalConnectNewAgent) => {
  const { setModalOpen, agent, activeContext } = props;
  const [sent, setSent] = useState<boolean>(false);

  const initialValues: initialValuesT = {
    name: agent.name,
    clientId: agent.clientId,
    clientName: agent.clientName,
    agentType: agent.agentType,
    alias: agent.alias,
    description: agent.description,
    country: agent.country,
    province: agent.province,
    zipCode: agent.zipCode,
    city: agent.city,
    street: agent.street,
    numberOfTests: agent.numberOfTests,
    applications: agent.applications,
    frequencyOfTests: agent.frequencyOfTests,
    features: agent.features,
    currency: agent.currency,
    employeeCost: agent.employeeCost,
    bandwidthCost: agent.bandwidthCost,
    agentDowntimeCost: agent.agentDowntimeCost,
    locationDowntimeCost: agent.locationDowntimeCost,
  };

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        name: values.name,
        clientId: values.clientId,
        agentType: values.agentType,
        alias: values.alias,
        description: values.description,
        country: values.country,
        province: values.province,
        zipCode: values.zipCode,
        city: values.city,
        street: values.street,
        applications: values.applications,
        frequencyOfTests: Number(values.frequencyOfTests),
        features: values.features,
        currency: values.currency,
        employeeCost: Number(values.employeeCost.substring(1)),
        bandwidthCost: Number(values.bandwidthCost.substring(1)),
        agentDowntimeCost: Number(values.agentDowntimeCost.substring(1)),
        locationDowntimeCost: Number(values.locationDowntimeCost.substring(1)),
      };
      axios
        .patch(`/configuration/agent/${agent.id}/connect`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 204 || res.status === 200) {
            setSent(true);
          }
        });
    },
    [agent]
  );

  useEffect(() => {
    if (sent) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
    setSent(false);
  }, [sent, setModalOpen]);

  if (sent) {
    return <ModalSuccess text={`Agent has been successfully connected`} />;
  }

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 500);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className={styles.element}>
            <TextInput
              touched={touched}
              errors={errors}
              name="name"
              label="Agent Name"
              placeholder="Enter agent name"
              required
            />
            {activeContext ? (
              <TextInput
                touched={touched}
                errors={errors}
                name="clientName"
                label="Client Name"
                disabled
                required
              />
            ) : (
              <CustomersSelect
                touched={touched}
                errors={errors}
                name="clientId"
                label="Client Name"
                required
              />
            )}
            <SelectInput
              placeholder="Select type"
              touched={touched}
              errors={errors}
              name="agentType"
              label="Type of agent"
              options={[
                { value: "user agent", label: "user agent" },
                {
                  value: "enterprise agent",
                  label: "enterprise agent",
                },
                { value: "cloud agent", label: "cloud agent" },
              ]}
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="alias"
              label="Alias"
              placeholder="Enter Alias"
              required
            />
            <TextareaInput
              touched={touched}
              errors={errors}
              name="description"
              label="Description"
              placeholder="Enter Description"
              required
            />
            <div className="hLine" />
            <Label title="Agent Location" />
            <Row>
              <Column className="col-12 col-sm-6 pr-sm-2">
                <TextInput
                  touched={touched}
                  errors={errors}
                  name="country"
                  label="Country"
                  placeholder="Enter Country"
                  required
                />
              </Column>
              <Column className="col-12 col-sm-6 pl-sm-2">
                <TextInput
                  touched={touched}
                  errors={errors}
                  name="province"
                  label="State/Province/Region"
                  placeholder="Enter Region"
                  required
                />
              </Column>
              <Column className="col-12 col-sm-6 pr-sm-2">
                <TextInput
                  touched={touched}
                  errors={errors}
                  name="zipCode"
                  label="Postal Code"
                  placeholder="Enter Postal Code"
                  required
                />
              </Column>
              <Column className="col-12 col-sm-6 pl-sm-2">
                <TextInput
                  touched={touched}
                  errors={errors}
                  name="city"
                  label="City"
                  placeholder="Enter City"
                  required
                />
              </Column>
              <Column className="col-12">
                <TextInput
                  touched={touched}
                  errors={errors}
                  name="street"
                  label="Street"
                  placeholder="Enter Street, suite, apt."
                  required
                />
              </Column>
            </Row>
            <div className="hLine" />
            <AddApp
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              label="Apps to test"
              name="applications"
              title="Select apps to test"
            />
            <SelectInput
              touched={touched}
              errors={errors}
              name="frequencyOfTests"
              options={NumSelectOptions([1, 5, 10, 15, 30, 45, 60])}
              label="Frequency of tests"
              placeholder="How many tests needed?"
              isSearchable={false}
            />
            <Features
              name="features"
              touched={touched}
              errors={errors}
              label="Features"
            />
            <div className="hLine" />
            <SelectInput
              options={[
                { value: "GBP", label: "GBP" },
                { value: "USD", label: "USD" },
                { value: "EUR", label: "EUR" },
                { value: "AED", label: "AED" },
                { value: "AUD", label: "AUD" },
                { value: "JPY", label: "JPY" },
                { value: "CHF", label: "CHF" },
                { value: "CAD", label: "CAD" },
                { value: "CNH", label: "CNH" },
                { value: "INR", label: "INR" },
              ]}
              placeholder="Choose currency"
              label="Currency"
              touched={touched}
              errors={errors}
              name="currency"
              className={styles.select}
            />
            <CurrencyInput
              touched={touched}
              errors={errors}
              name="employeeCost"
              label="Employee cost of downtime / min"
              placeholder="Enter cost"
              setFieldValue={setFieldValue}
            />
            <CurrencyInput
              touched={touched}
              errors={errors}
              name="bandwidthCost"
              label="Bandwidth cost / mb"
              placeholder="Enter cost"
              setFieldValue={setFieldValue}
            />
            <CurrencyInput
              touched={touched}
              errors={errors}
              name="agentDowntimeCost"
              label="Agent cost of downtime / min"
              placeholder="Enter cost"
              setFieldValue={setFieldValue}
            />
            <CurrencyInput
              touched={touched}
              errors={errors}
              name="locationDowntimeCost"
              label="Location cost of downtime / min"
              placeholder="Enter cost"
              setFieldValue={setFieldValue}
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Save"
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ModalConnectNewAgent;

export type initialValuesT = {
  name: string;
  clientId: string;
  clientName: string;
  agentType: string;
  alias: string;
  description: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
  numberOfTests: number;
  applications: Array<{
    details: {
      id: string;
      title: string;
    };
  }>;
  frequencyOfTests: number;
  features: Array<string>;
  currency: string;
  employeeCost: number;
  bandwidthCost: number;
  agentDowntimeCost: number;
  locationDowntimeCost: number;
};
