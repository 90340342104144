import React from "react";
import styles from "./TableCellRole.module.scss";

const TableCellRole = (props: ITableCellRole) => {
  const { organization, customers } = props;

  const checkGeneralRole = (organization: string, customers: string) => {
    return organization === customers;
  };

  const checkAccessName = (val: string) => {
    switch (val) {
      case "none":
        return "No Access";
      case "fullAccess":
        return "Admin";
      case "data":
        return "Read Only";
    }
  };

  return (
    <div className={styles.element}>
      {checkGeneralRole(organization, customers) ? (
        checkAccessName(organization)
      ) : (
        <>
          <span className={styles.text}>{checkAccessName(organization)}</span>
          <span className={styles.text}>
            {`${checkAccessName(customers)} (Customers)`}
          </span>
        </>
      )}
    </div>
  );
};
export default TableCellRole;

export interface ITableCellRole {
  organization: string;
  customers: string;
}
