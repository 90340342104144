import React from "react";
import { Form, Formik } from "formik";
import styles from "./ModalEmailReport.module.scss";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import DatePicker from "../../../components/formElements/DatePicker/DatePicker";
import MultiSelect from "../../formElements/MultiSelect/MultiSelect";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/paper-plane.svg";

interface IModalEmailReport {
  setModalOpen: (e: boolean) => void;
}

const ModalEmailReport = (props: IModalEmailReport) => {
  const { setModalOpen } = props;

  const initialValues = {
    users: "",
    format: "",
    subject: "",
    date: "",
  };

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
            setModalOpen(false);
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <MultiSelect
              touched={touched}
              errors={errors}
              name="users"
              label="Add people"
              placeholder="Add people and press enter"
              required
            />
            <SelectInput
              touched={touched}
              errors={errors}
              name={"format"}
              label={"format"}
              options={[
                { value: "csv", label: "CSV" },
                { value: "pdf", label: "PDF" },
                { value: "xml", label: "XML" },
              ]}
              placeholder={"Select format"}
              isSearchable={false}
              // isBgGray={false}
              required
            />
            <TextareaInput
              touched={touched}
              errors={errors}
              name="subject"
              label="Subject"
              placeholder="Type anything"
              required
            />
            <DatePicker
              touched={touched}
              errors={errors}
              label="Schedule report"
              name="date"
              type="date"
              minDate={new Date()}
              required
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Send"
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalEmailReport;
