import React, { useEffect } from "react";
import TableCellText from "../../components/tableElements/TableCellText/TableCellText";
import ActionTools from "../../components/ActionTools/ActionTools";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import TableCellQuality from "../../components/tableElements/TableCellQuality/TableCellQuality";
import Button from "../../components/Button/Button";
import moment from "moment";

const Alerts = (props: PropsT) => {
  const { openEdit, openDelete, fetchAlerts, tableData } = props;

  const DATE_FORMAT = "DD/MM/YYYY";

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  const columns = React.useMemo(
    () => [
      {
        Header: "name",
        accessor: "name",
        Cell: ({
          cell: { value },
          row,
        }: {
          cell: { value: string };
          row: any;
        }) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => openEdit(row.original)}
          >
            <TableCellText type="bold">{value}</TableCellText>
          </p>
        ),
      },
      {
        Header: "time frame Start",
        accessor: "timeFrameStart",
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText>
            {value && moment(value * 1000).format(DATE_FORMAT)}
          </TableCellText>
        ),
      },
      {
        Header: "time frame End",
        accessor: "timeFrameEnd",
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText>
            {value && moment(value * 1000).format(DATE_FORMAT)}
          </TableCellText>
        ),
      },
      {
        Header: "app",
        accessor: "isApp",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "active",
        accessor: "isActive",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "email",
        accessor: "isEmail",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => {
          return (
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  title="Edit Alert"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete Alert"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          );
        },
      },
    ],
    [openDelete, openEdit]
  );

  return <ReactTable columns={columns} data={tableData} />;
};

export default Alerts;

export interface PropsT {
  openEdit: (api: string) => void;
  openDelete: (api: string) => void;
  fetchAlerts: () => void;
  tableData: any[];
}
