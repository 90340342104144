import React from "react";
import styles from "./TableCellDelete.module.scss";
import { ReactComponent as IconTrash } from "../../../assets/icons/trash.svg";

const TableCellDelete = () => {
  return (
    <button className={styles.button}>
      <IconTrash />
    </button>
  );
};

export default TableCellDelete;
