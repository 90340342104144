export const TOGGLE_NOTIFICATIONS_BAR = "TOGGLE_NOTIFICATIONS_BAR"

const notificationsBarState = (state = false, action: {
    type: 'TOGGLE_NOTIFICATIONS_BAR';
}) => {
    if (action.type === TOGGLE_NOTIFICATIONS_BAR) {
        return !state;
    }
    return state;
};

export default notificationsBarState;
