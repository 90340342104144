import React, { useState } from "react";
import LayoutUserAuth from "../../../components/LayoutUserAuth/LayoutUserAuth";
import UserFormTemplate from "../../../components/UserFormTemplate/UserFormTemplate";
import SignInForm from "../../../components/UserFormTemplate/SignInForm";

const SignInView = () => {
  const [noAccess, setNoAccess] = useState<boolean>(false);

  return (
    <LayoutUserAuth title={!noAccess ? "Sign In" : null}>
      {!noAccess ? (
        <UserFormTemplate>
          <SignInForm setNoAccess={setNoAccess} />
        </UserFormTemplate>
      ) : (
        <span>
          You do not have access to the data.
          <br />
          Please contact the administrator
        </span>
      )}
    </LayoutUserAuth>
  );
};

export default SignInView;
