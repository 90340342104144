import React from "react";
import styles from "./ModalConfirmLogout.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconLogout } from "../../../assets/icons/logout.svg";
import { useDispatch } from "react-redux";
import { logOut } from "../../../store/reducers/authorization";

interface IModalConfirmLogout {
  setModalOpen: (e: boolean) => void;
}

const ModalConfirmLogout = (props: IModalConfirmLogout) => {
  const { setModalOpen } = props;

  const dispatch = useDispatch();

  async function submitLogout() {
    await dispatch(logOut());
  }

  return (
    <div className={styles.content}>
      <span className={styles.text}>Are you sure you want to log out?</span>
      <div className={styles.footer}>
        <Button
          title="No"
          type="plain"
          typeAttr="button"
          handleClick={() => setModalOpen(false)}
        />
        <Button
          title="Yes"
          handleClick={submitLogout}
          icon={<IconLogout />}
          icon_position="right"
        />
      </div>
    </div>
  );
};
export default ModalConfirmLogout;
