import {all, put, takeLatest} from "redux-saga/effects";
import {GET_USERS, GET_USERS_DONE, GET_USERS_FAILED} from "../actions/ManageUsers.action";
import axios from "../../api/axiosConfig";

function* GetUsersHandler({id}:any):any {
	try {
		const response = yield axios
			.get(`/customer/${id}`, {
				params: {
          withUsers: "orgUsers",
          onlyOrg: true,
          includeUserPhoto: true,
        },
			})
      
      const data = response.data.users.map((elem: any)=> {
        return {
          user: {
            name: elem.name,
            email: elem.email,
            photoLink: elem.photoData ? {
              type: elem.photoData.mime,
              data: elem.photoData.content.data,
            } : null,
          },
          id: elem.id,
          orgPermission: elem.orgPermission,
          structurePermission: elem.structurePermission,
        };
      })

    yield put({type: GET_USERS_DONE, payload: data});
	} catch (error) {
		console.log(error);
    yield put({type: GET_USERS_FAILED, error});
	}
}

export default function* WatchManageUsers() {
	yield all([
		takeLatest(GET_USERS, GetUsersHandler),
	]);
}
