import React from "react";
import styles from "./LayoutSubheader.module.scss";

const LayoutSubheader = (props: ILayoutSubheader) => {
  const { title } = props;

  return <h2 className={styles.subheading}>{title}</h2>;
};

export default LayoutSubheader;

interface ILayoutSubheader {
  title: string;
}
