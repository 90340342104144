import { all } from "redux-saga/effects";
import authorization from "./authorization";
import WatchToggleMenu from "./ToggleMenu.saga";
import WatchToggleTheme from "./ToggleTheme.saga";
import WatchManageCustomers from "./ManageCustomers.saga";
import WatchManageUsers from "./ManageUsers.saga";
import notifications from "./notifications";
import dateRange from "./dateRange";
import WatchTimeRange from "./SetTimeRange.saga";
import customerContext from "./customerContext";
import filter from "./filter";
import WatchDashboardPresets from "./DashboardPresets.saga";
import WatchSLATargets from "./SLATargets.saga";
import WatchImportantMetrics from "./widgets/ImportantMetrics.saga";
import WatchGaugeResults from "./widgets/GaugeResults.saga";
import WatchComparisonBox from "./widgets/ComparisonBox.saga";
import WatchTable from "./widgets/Table.saga";
import WatchTableDevices from "./widgets/TableDevices.saga";
import WatchColumnLineChart from "./widgets/ColumnLineChart.saga";
import WatchHeatmap from "./widgets/Heatmap.saga";
import WatchPathAnalytics from "./widgets/PathAnalytics.saga";
import WatchDevices from "./Devices";
import WatchDevice from "./Device";
import WatchEmployees from "./Employees";
import WatchEmployeeCallsTable from "./widgets/EmployeeCallsTable";
import WatchConferenceCallsTable from "./widgets/ConferenceCallsTable";
import WatchEmployeeDevicesTable from "./widgets/EmployeeDevicesTable";
import WatchEmployeeCallerMetricsTable from './widgets/EmployeeCallerMetricsTable';
import WatchEmployeeCalleeOriginatorMetricsTable from './widgets/EmployeeCalleeOriginatorMetricsTable'
import WatchAgentDiscoveredDeviceTable from './widgets/AgentDiscoveredDevice.saga'
import AppsPerformanceExtendedTable from "./widgets/AppsPerformanceExtendedTable.saga";
import CallerCalleeDevicesTable from "./widgets/CallerCalleeDevicesTable";

export default function* rootSaga() {
  yield all([
    authorization(),
    WatchToggleMenu(),
    WatchToggleTheme(),
    WatchManageCustomers(),
    WatchManageUsers(),
    notifications(),
    dateRange(),
    WatchTimeRange(),
    customerContext(),
    filter(),
    WatchDashboardPresets(),
    WatchSLATargets(),
    WatchImportantMetrics(),
    WatchGaugeResults(),
    WatchComparisonBox(),
    WatchTable(),
    WatchTableDevices(),
    WatchColumnLineChart(),
    WatchHeatmap(),
    WatchPathAnalytics(),
    WatchDevices(),
    WatchDevice(),
    WatchEmployees(),
    WatchEmployeeCallsTable(),
    WatchConferenceCallsTable(),
    WatchEmployeeDevicesTable(),
    WatchEmployeeCallerMetricsTable(),
    WatchEmployeeCalleeOriginatorMetricsTable(),
    WatchAgentDiscoveredDeviceTable(),
    AppsPerformanceExtendedTable(),
    CallerCalleeDevicesTable()
  ]);
}
