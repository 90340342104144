import React, { useEffect, useState } from "react";
import styles from "./ArrowControls.module.scss";
import { ReactComponent as IconChevron } from "../../assets/icons/arrow.svg";
import cn from "classnames";

const ArrowControls = (props: IArrowControls) => {
  const { handleNext, handleBack, currentItemNumber, itemsLength } = props;
  const [nextDisabled, setNextDisabled] = useState<boolean>(
    currentItemNumber === itemsLength - 1
  );
  const [backDisabled, setBackDisabled] = useState<boolean>(
    currentItemNumber === 0
  );

  useEffect(() => {
    if (currentItemNumber === itemsLength - 1) setNextDisabled(true);
    else setNextDisabled(false);
    if (currentItemNumber === 0) setBackDisabled(true);
    else setBackDisabled(false);
  }, [currentItemNumber, itemsLength]);

  return (
    <div className={styles.wrapper}>
      <button
        className={cn(styles.button, backDisabled && styles["isDisabled"])}
        onClick={handleBack}
        type="button"
      >
        <IconChevron />
      </button>
      <button
        className={cn(styles.button, nextDisabled && styles["isDisabled"])}
        onClick={handleNext}
        type="button"
      >
        <IconChevron />
      </button>
    </div>
  );
};

export default ArrowControls;

interface IArrowControls {
  currentItemNumber: number;
  itemsLength: number;
  handleNext: () => void;
  handleBack: () => void;
}
