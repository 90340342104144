import React, { useEffect } from "react";
import styles from "../ModalConnectNewAgent/ModalConnectNewAgent.module.scss";
import { Form, Formik } from "formik";
import TextInput from "../../formElements/TextInput/TextInput";
import TextareaInput from "../../formElements/TextareaInput/TextareaInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import { Column, Row } from "../../Bootstrap/Bootstrap";
import Label from "../../Label/Label";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import { useDispatch, useSelector } from "react-redux";
import { CleanDeviceDetails, EditDevice } from "../../../store/actions/Device";
import { AppState } from "../../../store/reducers";
import Heading from "../../Heading/Heading";
import { DeviceT } from "../../../store/types/Device";
import ModalError from "../ModalError/ModalError";

export interface IModalEditDevice {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  device?: DeviceT;
}

const ModalEditDevice = (props: IModalEditDevice) => {
  const { setModalOpen, device, handleUpdateData } = props;
  const { message, error } = useSelector(
    (state: AppState) => state.DeviceState
  );

  const dispatch = useDispatch();

  const initialValues: initialValuesT = {
    name: device?.name || "",
    alias: device?.alias || "",
    description: device?.description || "",
    deviceType: device?.deviceType || "",
    connectionType: device?.connectionType || "",
    vendor: device?.vendor || "",
    model: device?.model || "",
    country: device?.country || "",
    province: device?.province || "",
    zipCode: device?.zipCode || "",
    city: device?.city || "",
    street: device?.street || "",
  };

  const restDeviceData = {
    customerId: device?.customerId,
    circuitId: device?.circuitId,
    mac: device?.mac,
    ip: device?.ip,
    osVersion: device?.osVersion,
    latency: device?.latency,
    lastTestDate: device?.lastTestDate,
    locationGroup: device?.locationGroup || "",
    bandwidthDownload: device?.bandwidthDownload || "",
    bandwidthUpload: device?.bandwidthUpload || "",
    snmpVersion: device?.snmpVersion || "",
    communityString: device?.communityString || "",
    userName: device?.userName || "",
    privProtocol: device?.privProtocol || "",
    privKey: device?.privKey || "",
    authProtocol: device?.authProtocol || "",
    authKey: device?.authKey || "",
    agentId: device?.agentId || "",
    interface: device?.interface || "",
    agentName: device?.agentName,
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setModalOpen(false);
        dispatch(CleanDeviceDetails());
        handleUpdateData();
      }, 3000);
    }
  }, [message, setModalOpen, handleUpdateData, dispatch]);

  if (message) {
    return <ModalSuccess text={"Device has been successfully updated"} />;
  }

  if (error) {
    return <ModalError />;
  }

  return (
    <>
      <Heading title="Edit Device" weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              device?.id &&
                dispatch(
                  EditDevice(device.id, { ...values, ...restDeviceData })
                );
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Device Name"
                placeholder="Enter device name"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="alias"
                label="Alias"
                placeholder="Enter Alias"
              />
              <TextareaInput
                touched={touched}
                errors={errors}
                name="description"
                label="Description"
                placeholder="Enter Description"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="deviceType"
                label="Device type"
                placeholder="Enter Device Type"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="connectionType"
                label="Connection Type"
                placeholder="Enter Connection Type"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="vendor"
                label="Vendor"
                placeholder="Enter Vendor"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="model"
                label="Model"
                placeholder="Enter Model"
              />
              <div className="hLine" />
              <Label title="Device Location" />
              <Row>
                <Column className="col-12 col-sm-6 pr-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="country"
                    label="Country"
                    placeholder="Enter Country"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pl-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="province"
                    label="State/Province/Region"
                    placeholder="Enter Region"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pr-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="zipCode"
                    label="Postal Code"
                    placeholder="Enter Postal Code"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pl-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="city"
                    label="City"
                    placeholder="Enter City"
                  />
                </Column>
                <Column className="col-12">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="street"
                    label="Street"
                    placeholder="Enter Street, suite, apt."
                  />
                </Column>
              </Row>
              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Save"
                  typeAttr="submit"
                  icon={<IconButton />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ModalEditDevice;

export type initialValuesT = {
  name: string;
  alias: string;
  description: string;
  deviceType: string;
  connectionType: string;
  vendor: string;
  model: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
};
