import React, { useState } from "react";
import { Container } from "../../components/Bootstrap/Bootstrap";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
import ApiManagement from "./ApiManagement/ApiManagement";
import AppManagement from "./AppManagement/AppManagement";

const ApiAndAppManagementView = () => {
  const [active, setActive] = useState<string>("api's");

  const handleClick = (e: string) => {
    switch (e) {
      case "api's":
        setActive("api's");
        break;
      case "applications":
        setActive("applications");
        break;
    }
  };

  return (
    <>
      <Container fluid>
        <FilterTabs
          controls={["API's", "Applications"]}
          handleClick={handleClick}
        />
        {active === "api's" && <ApiManagement />}
        {active === "applications" && <AppManagement />}
      </Container>
    </>
  );
};

export default ApiAndAppManagementView;
