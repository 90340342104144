import { all, put, select, delay, takeLatest, call } from "redux-saga/effects";
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_DONE,
  ADD_CUSTOMER_FAILED,
  GET_CUSTOMERS,
  GET_CUSTOMERS_DONE,
  GET_CUSTOMERS_FAILED,
  GetCustomers,
  GET_CUSTOMERS_TREE,
  GET_CUSTOMERS_TREE_DONE,
  GET_CUSTOMERS_TREE_FAILED,
  GET_CUSTOMER_LOGO,
  GET_CUSTOMER_LOGO_SUCCESS,
  GET_CUSTOMER_LOGO_FAILURE,
} from "../actions/ManageCustomers.action";
import axios from "../../api/axiosConfig";
import { checkLogo } from "../../api/authorization";

function* GetCustomersHandler({ id }: any): any {
  try {
    const response = yield axios.get(`/customer/${id}`, {
      params: {
        withUsers: "allUsers",
        includeLogo: true,
        includeUserPhoto: true,
      },
    });
    const data = response.data.subCustomers.map((elem: any) => {
      return {
        id: elem.customer.id,
        customerDetails: {
          name: elem.customer.name,
          email: elem.customer.email,
          photoLink: elem.customer.logo
            ? {
                type: elem.customer.logo.mime,
                data: elem.customer.logo.content.data,
              }
            : null,
          isAccount: true,
        },
        users: elem.users.map((elem: any) => {
          return {
            user: {
              name: elem.name,
              email: elem.email,
              photoLink: null,
            },
            id: elem.id,
            orgPermission: elem.orgPermission,
            structurePermission: elem.structurePermission,
          };
        }),
        customers: elem.subCustomers.map((elem: any) => {
          return {
            user: {
              name: elem.customer.name,
              email: elem.customer.email,
              photoLink: null,
              isAccount: true,
            },
            id: elem.customer.id,
          };
        }),
      };
    });

    yield put({ type: GET_CUSTOMERS_DONE, payload: data });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CUSTOMERS_FAILED, error });
  }
}

function* GetCustomerLogoHandler({ id }: any) {
  try {
    const { data } = yield call(checkLogo, id);
    yield put({
      type: GET_CUSTOMER_LOGO_SUCCESS,
      payload: data ? URL.createObjectURL(data) : "",
    });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CUSTOMER_LOGO_FAILURE, error });
  }
}

function* GetCustomersTreeHandler(): any {
  try {
    const response = yield axios.get("/customer", {
      params: {
        includeLogo: true,
        includeUserPhoto: false,
        sortCustomers: true,
      },
    });

    yield put({
      type: GET_CUSTOMERS_TREE_DONE,
      payload: response.data.subCustomers,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CUSTOMERS_TREE_FAILED, error });
  }
}

function* AddCustomerHandler({ data }: any): any {
  try {
    yield axios.post("/customer", data);

    const { id, name }: any = yield select(
      ({ customerContextState }: any) => customerContextState
    );

    yield put({
      type: ADD_CUSTOMER_DONE,
      message: `Customer has been added to ${name}`,
    });
    yield delay(3000);
    yield put(GetCustomers(id));
  } catch (error) {
    console.log(error);
    yield put({ type: ADD_CUSTOMER_FAILED, error });
  }
}

export default function* WatchManageCustomers() {
  yield all([
    takeLatest(GET_CUSTOMERS, GetCustomersHandler),
    takeLatest(GET_CUSTOMERS_TREE, GetCustomersTreeHandler),
    takeLatest(ADD_CUSTOMER, AddCustomerHandler),
    takeLatest(GET_CUSTOMER_LOGO, GetCustomerLogoHandler),
  ]);
}
