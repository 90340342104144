import React, { useState } from "react";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import Panel from "../../../components/Panel/Panel";
import Heading from "../../../components/Heading/Heading";
import Label from "../../../components/Label/Label";
import styles from "../SingleAgentForm/SingleAgentForm.module.scss";
import ConnectionDetails from "../../../components/ConnectionDetails/ConnectionDetails";
import { SingleAgentT } from "../SingleAgent.view";
import CurrencyText from "../../../components/CurrencyText/CurrencyText";
import { ReplaceToAsterisk } from "../../../functions/ReplaceToAsterisk/ReplaceToAsterisk";
import ArrowControls from "../../../components/ArrowControls/ArrowControls";

interface ISingleAgentFormNoEdit {
  data: SingleAgentT;
}

const SingleAgentFormNoEdit = (props: ISingleAgentFormNoEdit) => {
  const { data } = props;
  const [networkDataNumber, setNetworkDataNumber] = useState<number>(0);

  const handleNext = () => {
    setNetworkDataNumber(networkDataNumber + 1);
  };
  const handleBack = () => {
    setNetworkDataNumber(networkDataNumber - 1);
  };

  const showApplications = (
    data: Array<{
      id: string;
      title: string;
    }>
  ) => {
    if (data.length) {
      return data.map((elem, key) => elem.title).join(", ");
    } else return "-";
  };

  const showFeatures = (
    data: Array<{
      title: string;
      id: string;
    }>
  ) => {
    if (data.length) {
      return data.map((elem, key) => elem.title).join(", ");
    } else return "-";
  };

  return (
    <div>
      <LayoutHeader pathBack="/agents-management/known" title={data.name} />
      <Panel className={styles.panel}>
        <Heading
          title="General"
          className={styles.sectionTitle}
          weight="medium"
        />
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Group>
              <Label title="Name" />
              <Heading
                title={data.name ? data.name : "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Location group" />
              <Heading
                title={data.locationGroup ? data.locationGroup : "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Location" />
              <Heading
                title={
                  data.street &&
                  data.zipCode &&
                  data.city &&
                  data.province &&
                  data.country
                    ? `${data.street}, ${data.zipCode} ${data.city}, ${data.province}, ${data.country}`
                    : "-"
                }
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Alias" />
              <Heading
                title={data.alias ? data.alias : ""}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Description" />
              <Heading
                title={data.description ? data.description : "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-6">
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Registration Date" />
                  <Heading
                    title={data.registrationDate ? data.registrationDate : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Type of agent" />
                  <Heading
                    title={data.agentType ? data.agentType : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Number of snmp devices" />
                  <Heading title="15" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
            </Row>
            <Group>
              <Label title="Apps to test" />
              <Heading
                title={
                  data.applications ? showApplications(data.applications) : "-"
                }
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Number of application tests" />
                  <Heading
                    title={data.numberOfTests ? data.numberOfTests : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Frequency of tests" />
                  <Heading
                    title={data.frequencyOfTests ? data.frequencyOfTests : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>

            <Group>
              <Label title="Features" />
              <Heading
                title={data.features ? showFeatures(data.features) : "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
          </Column>
        </Row>
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <div className="d-flex justify-content-between">
              <Heading
                title={
                  data.network.length > 1
                    ? `Network (${data.network.length})`
                    : "Network"
                }
                className={styles.sectionTitle}
                weight="medium"
              />
              {data.network.length > 1 && (
                <ArrowControls
                  currentItemNumber={networkDataNumber}
                  itemsLength={data.network.length}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
            </div>
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Connection" />
                  <Heading
                    title={data.network[networkDataNumber]?.connection}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Device name" />
                  <Heading
                    title={data.network[networkDataNumber]?.deviceName}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Connection name" />
                  <Heading
                    title={data.network[networkDataNumber]?.connectionName}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Type" />
                  <Heading
                    title={data.network[networkDataNumber]?.connectionType}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Connection status" />
                  <ConnectionDetails
                    level={data.network[networkDataNumber]?.connectionDetails}
                    type={data.network[networkDataNumber]?.connection}
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Transmit rate (Mbps)" />
                  <Heading
                    title={data.network[networkDataNumber]?.txRate}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>

              {data.network[networkDataNumber]?.connection === "WiFi" && (
                <>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="RSSI" />
                      <Heading
                        title={data.network[networkDataNumber]?.rssi}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="Noise" />
                      <Heading
                        title={data.network[networkDataNumber]?.noise}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="Channel" />
                      <Heading
                        title={data.network[networkDataNumber]?.channel}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="Mode" />
                      <Heading
                        title={data.network[networkDataNumber]?.mode}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="BSSID" />
                      <Heading
                        title={data.network[networkDataNumber]?.bssid}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                  <Column className="col-12 col-lg-6">
                    <Group>
                      <Label title="SSID name" />
                      <Heading
                        title={data.network[networkDataNumber]?.ssid}
                        Tag="h4"
                        weight="semi-bold"
                      />
                    </Group>
                  </Column>
                </>
              )}
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Internal IP" />
                  <Heading
                    title={data.network[networkDataNumber]?.internalIp}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="External IP" />
                  <Heading
                    title={data.network[networkDataNumber]?.ip}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Uptime" />
                  <Heading title={data.uptime} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
            </Row>
          </Column>
          <Column spacing="large" className="col-12 col-lg-6">
            <Heading
              title="System Info"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="CPU" />
                  <Heading
                    title="Intel Core i7 7700K"
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Memory" />
                  <Heading title="6Gb" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="OS Type" />
                  <Heading title="Windows 10" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="OS Version" />
                  <Heading title="12446.221" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Internal system name" />
                  <Heading title="Work Station" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Agent Version" />
                  <Heading
                    title={data.version ? data.version : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Heading
              title="Advanced Settings"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Wi-fi password" />
                  <Heading
                    title={
                      data.wifiPassword
                        ? ReplaceToAsterisk(data.wifiPassword)
                        : "-"
                    }
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Wi-fi SSID" />
                  <Heading
                    title={data.wifiSSID ? data.wifiSSID : "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12">
            <Heading
              title="Financial"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Employee cost of downtime / min" />
                  <CurrencyText
                    title={data.employeeCost ? data.employeeCost : "-"}
                    selectedCurrency={data.currency ? data.currency : ""}
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Bandwidth cost / mb" />
                  <CurrencyText
                    title={data.bandwidthCost ? data.bandwidthCost : "-"}
                    selectedCurrency={data.currency ? data.currency : ""}
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Agent cost od downtime / min" />
                  <CurrencyText
                    title={
                      data.agentDowntimeCost ? data.agentDowntimeCost : "-"
                    }
                    selectedCurrency={data.currency ? data.currency : ""}
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Location cost od downtime / min" />
                  <CurrencyText
                    title={
                      data.locationDowntimeCost
                        ? data.locationDowntimeCost
                        : "-"
                    }
                    selectedCurrency={data.currency ? data.currency : ""}
                  />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
      </Panel>
    </div>
  );
};

export default SingleAgentFormNoEdit;

const Group = (props: { children: Array<React.ReactChild> }) => (
  <div className={styles.group}>{props.children}</div>
);
