import React, { useState, useEffect, Suspense } from "react";
import styles from "./Dashboard.module.scss";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconFile } from "../../assets/icons/file.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import Grid, { GridElem } from "../../components/Grid/Grid";
import DateRangePicker from "../../components/RangePicker/RangePicker";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Panel from "../../components/Panel/Panel";
import { AppState } from "../../store/reducers";
import TimeRangePicker from "../../components/TimeRangePicker/TimeRangePicker";
import { ManageDashboardWidgets } from "../../functions/ManageDashboard/ManageDashboardWidgets";
import { GetLastDashboardPreset } from "../../functions/ManageDashboard/GetLastDashboardPreset/GetLastDashboardPreset";
import { GetSelectedPresetDetails } from "../../functions/ManageDashboard/GetSelectedPresetDetails/GetSelectedPresetDetails";
import { WidgetT } from "../../store/reducers/DashboardPresets";
import { checkIfHortium } from "../../functions/CheckIfHortium/CheckIfHortium";

const Dashboard = () => {
  const customPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.customPresets
  );
  const constantPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantPresets
  );
  const constantReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantReceivedPresets
  );
  const currentDashboard = useSelector(
    (state: AppState) => state.dashboardPresetsState.currentDashboard
  );

  const settings = useSelector((state: AppState) => state.SettingsState);
  const { customer } = useSelector((state: AppState) => state.authorization);
  const { id } = useSelector((state: AppState) => state.customerContextState);

  const [widgets, setWidgets] = useState<WidgetT[]>([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (currentDashboard) {
        const selectedPreset = GetSelectedPresetDetails(
          constantPresets,
          customPresets,
          constantReceivedPresets,
          currentDashboard
        );
        if (selectedPreset) setWidgets(selectedPreset.widgets);
        else {
          const selectedPreset = constantPresets[0];
          setWidgets(selectedPreset.widgets);
        }
      } else {
        if (settings.length) {
          const lastDashboardPreset = GetLastDashboardPreset(settings);
          const selectedPreset = GetSelectedPresetDetails(
            constantPresets,
            customPresets,
            constantReceivedPresets,
            lastDashboardPreset.settings
          );

          if (lastDashboardPreset && selectedPreset) {
            setWidgets(selectedPreset.widgets);
          } else {
            const selectedPreset = constantPresets[0];
            setWidgets(selectedPreset.widgets);
          }
        } else {
          const selectedPreset = constantPresets[0];
          setWidgets(selectedPreset.widgets);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    currentDashboard,
    customPresets,
    constantPresets,
    constantReceivedPresets,
    settings,
  ]);
  return (
    <Container fluid>
      <LayoutHeader description="Here is what happened since your last visit">
        <DateRangePicker />
        <TimeRangePicker />
        <Button
          className={styles.button}
          title={"Report"}
          path="/reports/standard"
          icon={<IconFile style={{ fill: "white" }} />}
          icon_position="right"
        />
      </LayoutHeader>
      {widgets.length > 0 && (
        <Grid>
          {widgets.map((elem, key) => {
            return (
              !(elem.disableIfHortium && checkIfHortium(customer, id)) &&
              !elem.disable && (
                <GridElem
                  size={
                    elem.size ? elem.size : Math.min(...elem.availableSizes)
                  }
                  key={key}
                >
                  <Suspense
                    fallback={
                      <Panel className="pt-5">
                        <Loader container />
                      </Panel>
                    }
                  >
                    {ManageDashboardWidgets(
                      elem
                    )}
                  </Suspense>
                </GridElem>
              )
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default Dashboard;
