import React from "react";
import Expand from "../../../components/Expand/Expand";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import CheckboxGroup from "../../../components/formElements/CheckboxGroup/CheckboxGroup";

const ReportTemplateSummary = ({ ...props }: any) => {
  return (
    <>
      <Expand title="Callee NMOS degradation" level={2}>
        123
      </Expand>
      <Expand title="Callee NMOS degradation" level={2}>
        123
      </Expand>
      <Expand title="Calls" level={2}>
        <TextInput
          name="callsDescription"
          label="Description"
          placeholder="Calls"
          isBgGray
          {...props}
        />
        <TextInput
          name="callsWidth"
          label="Width"
          placeholder="5"
          isBgGray
          {...props}
        />
        <CheckboxGroup
          name="callsSummaryFormat"
          options={[
            {
              value: "summary_format",
              label: "Summary format",
            },
          ]}
          {...props}
        />
        <CheckboxGroup
          name="callsJustification"
          label="Justification"
          direction="grid"
          options={[
            {
              value: "left",
              label: "Left",
            },
            {
              value: "right",
              label: "Right",
            },
            {
              value: "center",
              label: "Center",
            },
          ]}
          {...props}
        />
        <TextInput
          name="callsAggregateMethod"
          label="Aggregate method"
          placeholder="Count"
          isBgGray
          {...props}
        />
      </Expand>
    </>
  );
};

export default ReportTemplateSummary;
