import React from "react";
import styles from "./LayoutHeader.module.scss";
import Heading from "../../Heading/Heading";
import { useSelector } from "react-redux";
import _ from "lodash";
import { AppState } from "../../../store/reducers";
import cn from "classnames";

const LayoutHeader = (props: ILayoutHeader) => {
  const { title, description, children, pathBack, goBackBtn } = props;

  const auth = useSelector((state: AppState) => state.authorization);
  const customer = useSelector((state: AppState) => state.customerContextState);
  const customerContext = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext"))
    : customer;

  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className={cn(styles.col, styles.left)}>
        {auth.customer.id !== customerContext.id ? (
          <div className="d-flex align-items-center">
            {document.location.pathname === "/" &&
              customerContext.photoLinkCustomer && (
                <div className={styles.imageWrapper}>
                  <img
                    src={customerContext.photoLinkCustomer}
                    alt={`${customerContext.name} logo`}
                  />
                </div>
              )}
            <Heading
              pathBack={pathBack}
              goBackBtn={goBackBtn}
              title={title ? title : customerContext.name}
              Tag="h1"
            />
          </div>
        ) : (
          <>
            <Heading
              pathBack={pathBack}
              goBackBtn={goBackBtn}
              Tag="h1"
              title={
                title
                  ? title
                  : `Welcome back${
                      auth.name ? ", " + _.first(auth.name.split(" ")) : ""
                    }`
              }
            />
            {description && <p className={styles.description}>{description}</p>}
          </>
        )}
      </div>
      <div className={cn(styles.col, styles.right)}>{children}</div>
    </div>
  );
};

export default LayoutHeader;

interface ILayoutHeader {
  title?: string;
  description?: string;
  children?: any;
  pathBack?: string;
  goBackBtn?: boolean;
}
