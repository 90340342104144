import { TOGGLE_MENU } from "../actions/ToggleMenu.action";

export default function ToggleMenuState(
  state = false,
  action: {
    type: "TOGGLE_MENU";
    init?: boolean;
  }
) {
  if (action.init) {
    return state;
  } else {
    if (action.type === TOGGLE_MENU) {
      return !state;
    }
    return state;
  }
}
