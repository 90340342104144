export const GET_APP_PERFORMANCE_EXTENDED_TABLE = "GET_APP_PERFORMANCE_EXTENDED_TABLE";
export const GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE = "GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE";
export const GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED = "GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED";

export type GetAppPerformanceExtendedDataT = {
  devices: any[],
  nodes: any[],
  paths: any[]
} | null;

export type GetAppPerformanceExtendedParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  agentId?: string,
  userId?: string,
  url?: string,
  location?: string,
  isByLocation?: boolean,
  isTraceroute?: boolean
}

export type GetAppPerformanceExtendedTableT = {
  type: typeof GET_APP_PERFORMANCE_EXTENDED_TABLE;
  params: GetAppPerformanceExtendedParamsT
}

export type GetAppPerformanceExtendedTableDoneT = {
  type: typeof GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE;
  chartType: string,
  data: GetAppPerformanceExtendedDataT
}

export type GetAppPerformanceExtendedTableFailedT = {
  type: typeof GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED;
  chartType: string,
}

export type AppPerformanceExtendedTableActionT = GetAppPerformanceExtendedTableT | GetAppPerformanceExtendedTableDoneT | GetAppPerformanceExtendedTableFailedT