import React from "react";
import styles from "./TableCellAgent.module.scss";
import { Link } from "react-router-dom";

const TableCellAgent = (props: ITableCellAgent) => {
  const { name, id, children } = props;

  return (
    <>
      {id ? (
        <Link to={`/agents-management/agent/${id}`} className={styles.element}>
          <div className={"d-flex flex-column"}>
            <span className={styles.name}>{name}</span>
            <span className={styles.id}>ID: {id}</span>
          </div>
        </Link>
      ) : (
        <div className={"d-flex align-items-center justify-content-between"}>
          <span className={styles.name}>{children}</span>
          <span className={styles.label}>New</span>
        </div>
      )}
    </>
  );
};

export default TableCellAgent;

export interface ITableCellAgent {
  name?: string;
  id?: number;
  children?: any;
}
