import React from "react";
import styles from "./TableCellStatus.module.scss";
import cn from "classnames";
import colors from "../../../assets/styles/variables/colors.module.scss";
import { Capitalize } from "../../../functions/Capitalize/Capitalize";

const TableCellStatus = (props: ITableCellStatus) => {
  const { status, statusProgress, view } = props;

  const handleColor = (statusProgress: number) => {
    if (statusProgress < 35) return colors["widgets-torch-red"];
    else if (statusProgress >= 35 && statusProgress < 50)
      return colors["widgets-crusta"];
    else if (statusProgress >= 50) return colors["widgets-green"];
  };

  return (
    <>
      {status ? (
        <div className={styles.wrapper}>
          <span
            className={cn(
              styles.status,
              styles[`is${Capitalize(status)}`],
              view === "alerts" && styles["alert"],
              view === "agents" && styles["agent"]
            )}
          />
          <span className={styles.text}>{status}</span>
        </div>
      ) : (
        <span className={styles.text}>-</span>
      )}
      {/* TODO: remove if this type is not used */}
      {statusProgress && (
        <div className={"d-flex align-items-center"}>
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{
                width: `${statusProgress}%`,
                backgroundColor: handleColor(statusProgress),
              }}
            />
          </div>
          <span className={styles.text}>{statusProgress}%</span>
        </div>
      )}
    </>
  );
};

export default TableCellStatus;

export interface ITableCellStatus {
  status?: string;
  statusProgress?: number;
  view?: string;
}
