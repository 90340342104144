import React, { useEffect, useCallback } from "react";
import { Form, Formik } from "formik";
import styles from "../ModalInviteUser/ModalInviteUser.module.scss";
import TextInput from "../../formElements/TextInput/TextInput";
import Button from "../../Button/Button";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
import { AddCustomer } from "../../../store/actions/ManageCustomers.action";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store/reducers";
import Heading from "../../Heading/Heading";

interface IModalAddCustomer {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  customer: string;
}

const ModalAddCustomer = (props: IModalAddCustomer) => {
  const { setModalOpen, customer } = props;

  const { id } = useSelector((state: AppState) => state.customerContextState);
  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : id;
  const { message, error } = useSelector(
    (state: AppState) => state.customersState
  );
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        AddCustomer({
          name: values.name,
          email: values.email,
          adminFullName: values.adminFullName,
          parentId: customerContextId,
        })
      );
    },
    [customerContextId, dispatch]
  );

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setModalOpen(false);
      }, 3000);
    }
  }, [setModalOpen, dispatch, error, message]);

  const initialValues = {
    email: "",
    name: "",
    adminFullName: "",
  };

  if (message) {
    return <ModalSuccess text={message} />;
  }

  return (
    <>
      <Heading title={`Add Customer to ${customer}`} weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmit(values);
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Company*"
                placeholder="Company Name"
                stateValidation
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="email"
                label="Admin's Email"
                placeholder="Email"
                textInputType="email"
                required
                validateWithOther
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="adminFullName"
                label="Admin's Full Name"
                placeholder="Full Name"
                required
                validateWithOther
              />

              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Add"
                  typeAttr="submit"
                  icon={<IconCheck />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ModalAddCustomer;
