export const GET_TABLE_EMPLOYEE_DEVICES = "GET_TABLE_EMPLOYEE_DEVICES";
export const GET_TABLE_EMPLOYEE_DEVICES_DONE = "GET_TABLE_EMPLOYEE_DEVICES_DONE";
export const GET_TABLE_EMPLOYEE_DEVICES_FAILED = "GET_TABLE_EMPLOYEE_DEVICES_FAILED";

export type EmployeeDevicesT = {
  id: string;
  employeeId: string;
  room: string;
  employeeName: string;
  displayName: string;
  deviceName: string;
  deviceModel: string;
  deviceType: string;
  firmware: string;
  serialNumber: string;
  uptime: number;
  osName: string;
  osVersion: string;
  heartbeat: string;
  resourceState: string;
  alias: string;
  appVersion: string;
  ipAddress: string;
  exchangeStatus: string;
  opperationType: string;
};

export type EmployeeDevicesTableDataT = Array<EmployeeDevicesT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type EmployeeDevicesTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
};

export type GetEmployeeDevicesTableT = {
  type: typeof GET_TABLE_EMPLOYEE_DEVICES;
  params: EmployeeDevicesTableParamsT;
};

export type GetEmployeeDevicesTableDoneT = {
  type: typeof GET_TABLE_EMPLOYEE_DEVICES_DONE;
  data: EmployeeDevicesTableDataT;
};

export type GetEmployeeDevicesTableFailedT = {
  type: typeof GET_TABLE_EMPLOYEE_DEVICES_FAILED;
};

export type TableStateActionT = GetEmployeeDevicesTableT | GetEmployeeDevicesTableDoneT | GetEmployeeDevicesTableFailedT;
