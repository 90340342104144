import React, { useState, useEffect } from "react";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Employees from "../../widgets/Employees/Employees";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import { GetEmployeesList } from "../../store/actions/Employees";
import { EmployeeT } from "../../store/types/Employees";

const EmployeesManagementView = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<EmployeeT[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const { id } = useSelector((state: AppState) => state.customerContextState);
  const { list, isLoading, error } = useSelector(
    (state: AppState) => state.EmployeesState
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) id && dispatch(GetEmployeesList(id));
    return () => {
      isMounted = false;
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (isLoading) {
      setTableData([]);
      setNoData(false);
    } else {
      if (list) {
        if (list.length) {
          setTableData(list);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } else {
        setNoData(true);
      }
    }
  }, [isLoading, list]);

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Employees" />
        <Employees data={tableData} noData={noData} />
      </Container>
      {noData && (
        <NoDataPlaceholder
          image
          title={
            error ? "Something goes wrong. Try again later" : "No employees yet"
          }
        />
      )}
    </>
  );
};

export default EmployeesManagementView;
