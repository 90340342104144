import React, { useCallback, useEffect, useState } from "react";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import styles from "./NewAlert.module.scss";
import cn from "classnames";
import TextInput from "../../components/formElements/TextInput/TextInput";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import CustomersSelect from "../../components/formElements/CustomersSelect/CustomersSelect";
import DatePicker from "../../components/formElements/DatePicker/DatePicker";
import SwitchElem from "../../components/formElements/SwitchElem/SwitchElem";
import axios from "../../api/axiosDashboard";
import axiosConfig from "../../api/axiosDashboard";
import SelectInput from "../../components/formElements/SelectInput/SelectInput";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

const NewAlert = ({
  selectedAlert,
  fetchAlerts,
  setModalOpen,
  setModalSuccessOpen,
}: any) => {
  const [tests, setTests] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [urls, setUrls] = useState<{ value: any; label: string }[]>([]);
  const [urlsLabel, setUrlsLabel] = useState<string>();
  const [currentTestName, setCurrentTestName] = useState<string>(
    selectedAlert?.tests.value
  );

  const { id } = useSelector((state: AppState) => state.customerContextState);

  const handleCurrentTestName = (testName: string) => {
    setCurrentTestName(testName);
  };

  const initialValues = {
    customerId: selectedAlert?.clientName ?? "",
    name: selectedAlert?.name ?? "",
    webhookUrl: selectedAlert?.webhookUrl ?? "",
    emailList: selectedAlert?.emailList ?? [""],
    urls: selectedAlert?.urls?.length
      ? selectedAlert.urls.map((option: string) => {
          return { label: option, value: option };
        })
      : [],
    tests: selectedAlert?.tests ?? null,
    isActive: selectedAlert?.isActive ?? false,
    isEmail: selectedAlert?.isEmail ?? false,
    isApp: selectedAlert?.isApp ?? false,
    timeFrameStart: selectedAlert?.timeFrameStart
      ? new Date(selectedAlert.timeFrameStart * 1000)
      : undefined,
    timeFrameEnd: selectedAlert?.timeFrameEnd
      ? new Date(selectedAlert.timeFrameEnd * 1000)
      : undefined,
  };

  const fetchTests = useCallback(async () => {
    try {
      await axiosConfig(`alertTests`).then((res) => {
        if (res && res.status === 200) {
          setTests(
            res.data.tests.map((option: string) => {
              return { label: option, value: option };
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchUrls = useCallback(async () => {
    try {
      await axiosConfig(
        `alert/testConfig/${id}?testName=${currentTestName}`
      ).then((res) => {
        if (res && res.status === 200) {
          setUrls(
            res.data.values?.map((option: string) => {
              return { label: option, value: option };
            })
          );
          setUrlsLabel(res.data.label);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, currentTestName]);

  const saveData = useCallback(
    async (data: any) => {
      try {
        if (selectedAlert?.customerId) {
          axios.put("/alert", data).then((res) => {
            if (res.status === 200) {
              setModalSuccessOpen(true);
              fetchAlerts();
              setModalOpen(false);
            }
          });
        } else {
          axios.post("/alert", data).then((res) => {
            if (res.status === 200) {
              setModalSuccessOpen(true);
              fetchAlerts();
              setModalOpen(false);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [selectedAlert?.customerId, fetchAlerts, setModalSuccessOpen, setModalOpen]
  );

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    if (currentTestName || !!selectedAlert) {
      fetchUrls();
    }
  }, [fetchUrls, currentTestName, selectedAlert]);

  return (
    <>
      <div className={styles.content}>
        <Formik
          validationSchema={Yup.object({
            emailList: Yup.array().of(
              Yup.string().required("Email required").email("Enter valid email")
            ),
          })}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const data = {
              customerId:
                values.customerId === selectedAlert?.clientName
                  ? selectedAlert.customerId
                  : values.customerId,
              name: values.name,
              emailList: values.emailList,
              isActive: values.isActive,
              isEmail: values.isEmail,
              isApp: values.isApp,
              tests: values.tests,
              webhookUrl: values.webhookUrl,
              urls: values.urls.map(
                (option: { value: string | number; label: string }) => {
                  return option.value;
                }
              ),
              timeFrameStart: values.timeFrameStart?.getTime(),
              timeFrameEnd: values.timeFrameEnd?.getTime(),
              ...(selectedAlert?.id && { id: selectedAlert.id }),
            };
            setTimeout(() => {
              setSubmitting(false);
              saveData(data);
            }, 500);
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className={cn(styles.alertForm, styles.element)}>
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Name"
                placeholder="Name of the alert"
                isBgGray
                required
              />
              <CustomersSelect
                touched={touched}
                errors={errors}
                name="customerId"
                label="Client Name"
                required
              />
              <DatePicker
                errors={errors}
                touched={touched}
                name="timeFrameStart"
                label="Time Frame Start"
                type="date"
                minDate={new Date()}
                required
              />
              <DatePicker
                errors={errors}
                touched={touched}
                name="timeFrameEnd"
                label="Time Frame End"
                type="date"
                minDate={values.timeFrameStart}
              />
              <SelectInput
                placeholder="Select tests"
                touched={touched}
                errors={errors}
                name="tests"
                label="Tests"
                options={tests}
                customValue
                required
                onChange={(e: any) => {
                  handleCurrentTestName(e.value);
                  setFieldValue("urls", []);
                }}
              />
              {urlsLabel && (
                <SelectInput
                  placeholder={`Select ${urlsLabel}`}
                  touched={touched}
                  errors={errors}
                  name="urls"
                  label={urlsLabel}
                  options={urls}
                  isMulti
                  customValue
                />
              )}
              <TextInput
                touched={touched}
                errors={errors}
                name="webhookUrl"
                label="Webhook URL"
                placeholder="Webhook"
                isBgGray
              />
              <FieldArray
                name="emailList"
                render={(arrayHelpers) => {
                  const emailList = values.emailList;
                  return (
                    <div>
                      {emailList && emailList.length > 0
                        ? // @ts-ignore
                          emailList.map((user, index) => (
                            <div key={index}>
                              <TextInput
                                touched={touched}
                                errors={errors}
                                name={`emailList.${index}`}
                                label="Email Address"
                                placeholder="Email Address"
                                textInputType="email"
                                isBgGray
                              />
                              <ErrorMessage
                                className={styles.errorMessage}
                                component="p"
                                name={`emailList.${index}`}
                              />
                              {!!index ? (
                                <p
                                  onClick={() => arrayHelpers.remove(index)}
                                  style={{ textAlign: "right" }}
                                  className={styles.removeEmail}
                                >
                                  Remove Email
                                </p>
                              ) : null}
                            </div>
                          ))
                        : null}
                      <div>
                        <p
                          onClick={() => arrayHelpers.push("")}
                          style={{ textAlign: "right" }}
                          className={styles.addEmail}
                        >
                          Add Email
                        </p>
                      </div>
                    </div>
                  );
                }}
              />
              <div
                className={cn("d-flex align-items-center ", styles.switcher)}
              >
                <p>Email</p>
                <SwitchElem
                  disabled={false}
                  values={values}
                  setFieldValue={setFieldValue}
                  defaultValue={values.isEmail}
                  name={`isEmail`}
                />
              </div>
              <div
                className={cn("d-flex align-items-center ", styles.switcher)}
              >
                <p>App</p>
                <SwitchElem
                  disabled={false}
                  values={values}
                  setFieldValue={setFieldValue}
                  defaultValue={values.isApp}
                  name={`isApp`}
                />
              </div>
              <div
                className={cn("d-flex align-items-center ", styles.switcher)}
              >
                <p>Active</p>
                <SwitchElem
                  disabled={false}
                  values={values}
                  setFieldValue={setFieldValue}
                  defaultValue={values.isActive}
                  name={`isActive`}
                />
              </div>
              <div className={styles.footer}>
                <Button title="Save" typeAttr="submit" icon_position="right" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default NewAlert;
