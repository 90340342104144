export const GET_COMPARISON_BOX = "GET_COMPARISON_BOX";
export const GET_COMPARISON_BOX_DONE = "GET_COMPARISON_BOX_DONE";
export const GET_COMPARISON_BOX_FAILED = "GET_COMPARISON_BOX_FAILED";

export type ComparisonBoxT = {
  startDateValue: number
  endDateValue: number,
  percentageChange: number
} | null

export type ComparisonBoxParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  agentId: string | null
}

export type GetComparisonBoxT = {
  type: typeof GET_COMPARISON_BOX;
  params: ComparisonBoxParamsT
}

export type GetComparisonBoxDoneT = {
  type: typeof GET_COMPARISON_BOX_DONE;
  chartType: string,
  data: ComparisonBoxT
}

export type GetComparisonBoxFailedT = {
  type: typeof GET_COMPARISON_BOX_FAILED;
  chartType: string,
}

export type ComparisonBoxActionT = GetComparisonBoxT | GetComparisonBoxDoneT | GetComparisonBoxFailedT