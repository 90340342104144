import React from "react";
import styles from "./Heading.module.scss";
import cn from "classnames";
import { ReactComponent as IconArrow } from "../../assets/icons/arrow.svg";
import { NavLink, useHistory } from "react-router-dom";

const Heading = (props: IHeading) => {
  const {
    title,
    Tag = "h3",
    weight = "normal",
    transform,
    className,
    pathBack,
    goBackBtn,
  } = props;

  const history = useHistory();

  return (
    <Tag
      className={cn(
        styles.element,
        styles[Tag],
        transform && styles[transform],
        weight && styles[weight],
        className
      )}
    >
      {pathBack && (
        <NavLink to={pathBack} className={styles.back}>
          <IconArrow />
        </NavLink>
      )}
      {goBackBtn && (
        <button className={styles.back} onClick={history.goBack}>
          <IconArrow />
        </button>
      )}
      {title}
    </Tag>
  );
};

export default Heading;

export interface IHeading {
  title: string | number;
  Tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  weight?: "normal" | "medium" | "semi-bold" | "bold";
  transform?: "uppercase";
  className?: string;
  pathBack?: string;
  goBackBtn?: boolean;
}
