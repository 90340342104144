import React, { useEffect, useState } from "react";
import styles from "./RadioGroup.module.scss";
import cn from "classnames";
import { Field, FieldProps } from "formik";

const RadioGroup = (props: IRadioGroup) => {
  const { name, options, className, label, handleToggle, disabled } = props;

  const [fields, setFields] = useState<IRadioField[]>();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    //@ts-ignore
    setFields(options);
  }, [options]);

  const handleClick = (key: number) => {
    setActiveIndex(key);
    handleToggle && handleToggle(key);
  };

  return (
    <div className={styles.wrapper}>
      {label && <span className={styles.title}>{label}</span>}
      <div className={cn(styles.group, className)}>
        {fields &&
          fields.map((field, key) => {
            return (
              <Field
                component={RadioField}
                {...field}
                active={activeIndex === key}
                index={key}
                handleClick={handleClick}
                key={key}
                name={name}
                disabled={disabled}
              />
            );
          })}
      </div>
    </div>
  );
};

export default RadioGroup;

const RadioField = (props: IRadioField) => {
  const {
    form,
    field,
    value,
    label,
    active,
    handleClick,
    index,
    disabled,
  } = props;

  useEffect(() => {
    if (form.initialValues[field.name] === value) {
      handleClick(index);
    }
    // TODO: fix only first render workaround
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.element}>
      <label
        className={cn(
          styles.label,
          active && styles["isActive"],
          disabled && styles["isDisabled"]
        )}
      >
        <input
          type="radio"
          name={field.name}
          value={value}
          disabled={disabled}
          onChange={(e: any) => {
            handleClick(index);
            field.onChange(e);
          }}
        />
        <div className={styles.box}>
          <span className={styles.icon} />
        </div>
        <span className={styles.text}>{label}</span>
      </label>
    </div>
  );
};

interface IRadioField extends FieldProps {
  value: string;
  label: string;
  active: boolean;
  index: number;
  handleClick: (key: number) => void;
  name: string;
  disabled?: boolean;
}

export interface IRadioGroup {
  label?: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  name: string;
  className?: string;
  handleToggle?: (key: number) => void;
  disabled?: boolean;
}
