import React from "react";
import styles from "./NotFound.module.scss";
import {ReactComponent as IconNotFound} from "../../assets/icons/notFound.svg";
import {Container} from "../../components/Bootstrap/Bootstrap";
import {NavLink} from "react-router-dom";

const NotFoundView = (props: {isLoggedIn?: boolean}) => {

    const { isLoggedIn } = props;

    const showLink = (isLoggedIn: boolean | undefined) => {

        let url = {
            path: "/sign-in",
            title: "login page"
        }
        if (isLoggedIn) {
            url = {
                path: "/",
                title: "dashboard"
            }
        }

        return <NavLink className={styles.link} to={url.path}>{url.title}</NavLink>
    }

    return (
        <div className={styles.element}>
            <Container className="mx-auto">
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <IconNotFound className={styles.icon}/>
                        <div className={styles.title}>404</div>
                        <div className={styles.subtitle}>Page not found</div>
                        <div className={styles.text}>
                            Page you are looking for does not exists.<br/> Please go to {showLink(isLoggedIn)}.
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default NotFoundView;
