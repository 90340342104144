import React from "react";
import styles from "../TableCellText/TableCellText.module.scss";
import cn from "classnames";
import { Link, useLocation, useParams } from "react-router-dom";
import { ITableCellLink } from "./TableCellLink.type";

export type { ITableCellLink };

const TableCellLink = (props: ITableCellLink) => {
  const { path, stateProps, type, noWrap, endpoint, name, title, id } = props;
  let { name: quaryName } = useParams<{ name: string }>();
  const { pathname } = useLocation<{ pathname: string }>();

  const checkUrl = () => {
    if (pathname.includes(`/dashboard/application/${quaryName}`)) {
      return path.toLowerCase();
    }
return path?.replace(/\s+/g, "-").toLowerCase();
  }

  return (
    <Link
      onClick={() => stateProps === 'employee-call' && id && localStorage.setItem( "userId", id )}
      to={
        stateProps
          ? {
              pathname: path
                ? `/${endpoint}/${checkUrl()}`
                : endpoint,
              state: {
                type: stateProps,
                name: name || title,
                id: id || null,
              },
            }
          : path?.replace(/\s+/g, "-")
      }
      className={cn(
        styles.element,
        styles.link,
        type === "bold" && styles["isBold"],
        type === "light" && styles["isLight"],
        noWrap && styles["noWrap"]
      )}
    >
      {id ? (
        <div className={"d-flex flex-column"}>
          <span className={styles.name}>{name}</span>
          <span className={styles.id}>ID: {id}</span>
        </div>
      ) : (
        name
      )}
    </Link>
  );
};

export default TableCellLink;
