import React, { useEffect, useState } from "react";
import styles from "./SwitchElem.module.scss";
import cn from "classnames";
import { Field } from "formik";

const SwitchElem = (props: ISwitchElem) => {
  const { values, setFieldValue, disabled, name, defaultValue } = props;

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof defaultValue === "boolean") {
      setActive(defaultValue);
    }
  }, [defaultValue])
  

  const handleClick = () => {
    setActive(!active);
    setFieldValue(name, !values[name]);
  };

  return (
    <div className={styles.wrapper}>
      <Field
        as="div"
        className={cn(
          styles.element,
          active && styles["isActive"],
          disabled && styles["isDisabled"]
        )}
        name={name}
        onClick={() => handleClick()}
      />
    </div>
  );
};

export default SwitchElem;

interface ISwitchElem {
  values: any;
  setFieldValue: (e: string, val: any) => void;
  disabled: boolean;
  name: string;
  defaultValue?: boolean
}
