import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { Redirect } from "react-router-dom";

const AgentsManagementView = () => {
  const { mainCustomer } = useSelector(
    (state: AppState) => state.authorization.customer
  );

  if (mainCustomer) {
    return <Redirect to="/agents-management/unknown" />;
  } else {
    return <Redirect to="/agents-management/known" />;
  }
};

export default AgentsManagementView;
