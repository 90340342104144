export const GET_TABLE_CONFERENCE_CALLS = "GET_TABLE_CONFERENCE_CALLS";
export const GET_TABLE_CONFERENCE_CALLS_DONE = "GET_TABLE_CONFERENCE_CALLS_DONE";
export const GET_TABLE_CONFERENCE_CALLS_FAILED = "GET_TABLE_CONFERENCE_CALLS_FAILED";

export type ConferenceCallsT = {
  id: string;
  employeeId: string;
  employeeName: string;
  conferenceName: string;
  organisorName: string;
  numberOfParticipants: number;
  meetingType: string;
  duration: number;
  noOfIssues: number;
  quality: boolean;
  startTime: number;
  sessionType: string;
};

export type ConferenceCallsTableDataT = Array<ConferenceCallsT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type ConferenceCallsTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
};

export type GetConferenceCallsTableT = {
  type: typeof GET_TABLE_CONFERENCE_CALLS;
  params: ConferenceCallsTableParamsT;
};

export type GetConferenceCallsTableDoneT = {
  type: typeof GET_TABLE_CONFERENCE_CALLS_DONE;
  data: ConferenceCallsTableDataT;
};

export type GetConferenceCallsTableFailedT = {
  type: typeof GET_TABLE_CONFERENCE_CALLS_FAILED;
};

export type TableStateActionT = GetConferenceCallsTableT | GetConferenceCallsTableDoneT | GetConferenceCallsTableFailedT;
