import React, { useEffect, useState } from "react";
import { ITableCellApi } from "../../components/tableElements/TableCellApi/TableCellApi";
import TableCellPriority, {
  ITableCellPriority,
} from "../../components/tableElements/TableCellPriority/TableCellPriority";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import ReactTable, {
  ISortType,
} from "../../components/tableElements/ReactTable/ReactTable";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import { IAppValues } from "../../components/Modal/ModalAddNewApp/ModalAddNewApp";
import { TableSortByName } from "../../functions/TableSortByName/TableSortByName";
import TableCellText from "../../components/tableElements/TableCellText/TableCellText";

const ConnectedApps = (props: IConnectedApps) => {
  const { data, openEdit, openDelete } = props;

  const [apiList, setApiList] = useState(data);

  useEffect(() => {
    setApiList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "App",
        accessor: "name",
        sortType: TableSortByName,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellApi } }) => {
          return <TableCellText>{value}</TableCellText>
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: (
          rowA: ISortType["row"],
          rowB: ISortType["row"],
          id: ISortType["id"]
        ) => {
          if (rowA.original[id] > rowB.original[id]) return -1;
          if (rowB.original[id] > rowA.original[id]) return 1;
          return 0;
        },
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value ? "active" : "paused"} />,
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: (
          rowA: ISortType["row"],
          rowB: ISortType["row"],
          id: ISortType["id"]
        ) => {
          if (rowA.original[id] > rowB.original[id]) return -1;
          if (rowB.original[id] > rowA.original[id]) return 1;
          return 0;
        },
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellPriority["priority"] };
        }) => <TableCellPriority priority={value} />,
      },
      {
        Header: "Last connected",
        accessor: "lastConnected",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Last successful test",
        accessor: "lastPulled",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => {
          return <TableCellDate>{value}</TableCellDate>;
        },
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => {
          return (
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  title="Edit App"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete App"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          );
        },
      },
    ],
    [openEdit, openDelete]
  );

  return <ReactTable columns={columns} data={apiList} />;
};

export default ConnectedApps;

export interface IConnectedApps {
  data: Array<SingleAppT>;
  openEdit: (api: IAppValues) => void;
  openDelete: (api: IAppValues) => void;
}

export type SingleAppT = {
  logo: {
    type: "Buffer";
    data: number[];
  };
  name: string;
  version: string;
  id: string;
  lastConnected: number;
  lastPulledData: number;
  priority: string;
  status: string;
  active: boolean;
};
