export const SET_CUSTOMER_CONTEXT = "SET_CUSTOMER_CONTEXT";

export type customerStateT = {
  id: string,
  name: string,
  email: string,
  photoLink: string,
  parentId?: string,
  logo?: any
}

const initialState: customerStateT = {
  id: "",
  name: "",
  email: "",
  photoLink: "",
}

export default function customerContextState(state = initialState, action: {
    type: "SET_CUSTOMER_CONTEXT";
    data: customerStateT
}) {

    if (action.type === SET_CUSTOMER_CONTEXT) {
        return action.data;
    }
    return state;
}

