export const GetAgentDashboard = () => [
  {
    title: "EUX score of agent",
    id: "agent-gauge-results",
    type: "gauge-results",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/totalResults",
    chartType: "eux",
  },
  {
    title: "Performance against SLA",
    id: "agent-gauge-results-performance",
    type: "gauge-results",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/totalResults",
    chartType: "perf",
  },
  {
    title: "Availability %",
    id: "agent-important-metrics-old-issues",
    type: "gauge-results",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/totalResults",
    chartType: "availability",
  },
  {
    title: "Frustration Rate",
    id: "agent-gauge-results-frustration",
    type: "gauge-results-icon",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/totalResults",
    chartType: "frustration",
  },
  {
    title: "Found Issues",
    id: "agents-important-metrics-issues",
    type: "important-metrics",
    dashboardType: "agents",
    availableSizes: [1, 2],
    size: 1,
    endpoint: "/metric/ucc/getTodayTransactionMetric",
    disableIfHortium: true,
  },
  {
    title: "Heatmap for tests per Agent/Locations",
    id: "agent-heatmap",
    type: "heatmap",
    dashboardType: "agent",
    size: 3,
    endpoint: "metric/heatmap",
    chartType: "agent",
  },
  {
    title: "Bandwidth tests",
    id: "agents-column-line-chart-bandwidth",
    type: "column-line-chart",
    dashboardType: "agents",
    availableSizes: [2, 3, 4],
    endpoint: "/metric/agent/getWifiData",
    chartType: "bandwidth",
    disableIfHortium: true,
    data: {
      column: [
        {
          title: "Download",
          id: "download",
        },
        {
          title: "Upload",
          id: "upload",
        },
        {
          title: "Network",
          id: "network",
        }
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%"
        }
      ]
    },
  },
  {
    title: "Device Performance",
    id: "agent-column-line-chart-old-device",
    type: "column-line-chart",
    dashboardType: "agent",
    size: 2,
    disableIfHortium: true,
    endpoint: "/metric/agent/getDevicePerfomanceMetric",
    chartType: "device",
    data: {
      column: [
        {
          title: "Memory",
          id: "memory",
        },
        {
          title: "Processes",
          id: "processes",
        },
        {
          title: "Connection",
          id: "connection",
        },
        {
          title: "Wifi",
          id: "wifi",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Agent Test Metrics",
    id: "agent-column-line-chart-old-app",
    type: "column-line-chart",
    dashboardType: "agent",
    size: 2,
    disableIfHortium: true,
    endpoint: "/metric/agent/getTracerouteMetric",
    chartType: "app",
    data: {
      column: [
        {
          title: "Transaction",
          id: "transaction",
        },
        {
          title: "Hops",
          id: "hops",
        },
        {
          title: "Eux",
          id: "eux",
        },
        {
          title: "Availbility",
          id: "availbility",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "LAN performance",
    id: "agents-column-line-chart-old-network",
    type: "column-line-chart",
    dashboardType: "agents",
    availableSizes: [2, 3, 4],
    endpoint: "/metric/agent/getConnectionPerfomance",
    chartType: "network",
    disableIfHortium: true,
    size: 2,
    data: {
      column: [
        {
          title: "Throughput",
          id: "throughput",
        },
        {
          title: "RSSI",
          id: "rssi",
        },
        {
          title: "Channel",
          id: "channel",
        },
        {
          title: "Network",
          id: "network",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Transaction Performance",
    id: "agents-column-line-chart-old-process",
    type: "column-line-chart",
    dashboardType: "agents",
    endpoint: "/metric/agent/getProcessesMetric",
    chartType: "agent",
    availableSizes: [2, 3, 4],
    size: 4,
    disableIfHortium: true,
    data: {
      column: [
        {
          title: "Transaction Speed",
          id: "throughout",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Agent details - system and performance",
    id: "agent-simple-table-details",
    type: "agents-table",
    dashboardType: "agent",
    size: 4,
    endpoint: "metric/agent/getAgentInfos",
    chartType: "agents",
    data: [
      {
        Header: "Agent",
        accessor: "agent",
        type: "name",
      },
      {
        Header: "Alias",
        accessor: "alias",
        type: "text",
      },
      {
        Header: "Hostname",
        accessor: "hostname",
        type: "text",
      },
      {
        Header: "CPU",
        accessor: "cpu",
        type: "text",
      },
      {
        Header: "Memory",
        accessor: "memory",
        type: "text",
      },
      {
        Header: "Number of Processes",
        accessor: "numberOfProcessess",
        type: "text",
      },
      {
        Header: "Top process",
        accessor: "topProcess",
        type: "text",
      },
      {
        Header: "Connection type",
        accessor: "connectionType",
        type: "text",
      },
      {
        Header: "Wifi Strength",
        accessor: "wifiStrength",
        type: "text",
      },
      {
        Header: "SSID",
        accessor: "ssid",
        type: "text",
      },
      {
        Header: "TX Rate",
        accessor: "txRate",
        type: "text",
      },
      {
        Header: "Connection speed",
        accessor: "connectionSpeed",
        type: "text",
      },
      {
        Header: "Noise",
        accessor: "noise",
        type: "text",
      },
      {
        Header: "RSSI",
        accessor: "rssi",
        type: "text",
      },
      {
        Header: "Network Latency",
        accessor: "networkLatency",
        type: "text",
      },
      {
        Header: "Packetloss",
        accessor: "packetLoss",
        type: "text",
      },
      {
        Header: "DNS Time",
        accessor: "dnsTime",
        type: "text",
      },
      {
        Header: "Transaction time",
        accessor: "transactionTime",
        type: "text",
      },
      {
        Header: "Number of Hops",
        accessor: "hops",
        type: "text",
      },
      {
        Header: "EUX score",
        accessor: "euxScore",
        type: "text",
      },
      {
        Header: "SLA score %",
        accessor: "slaScore",
        type: "text",
      },
      {
        Header: "Availability",
        accessor: "availability",
        type: "text",
      },
      {
        Header: "Bandwidth uplaod",
        accessor: "bwUpload",
        type: "text",
      },
      {
        Header: "Bandwidth download",
        accessor: "bwDownload",
        type: "text",
      },
      {
        Header: "Bandwidth location",
        accessor: "bwLocation",
        type: "text",
      },
      {
        Header: "Number of discovered devices",
        accessor: "numberOfDiscoveredDevices",
        type: "text",
      },
      {
        Header: "Number of employees",
        accessor: "numberOfEmployees",
        type: "text",
      },
      {
        Header: "Uptime",
        accessor: "uptime",
        type: "text",
      },
      {
        Header: "Number of apps test running",
        accessor: "numberOfAppsTestRunning",
        type: "text",
      },
      {
        Header: "Average performance of app",
        accessor: "avPerformanceOfApp",
        type: "text",
      },
      {
        Header: "Appscore",
        accessor: "appscore",
        type: "text",
      },
      {
        Header: "Number of transactions",
        accessor: "numberOfTransactions",
        type: "text",
      },
      {
        Header: "Average transaction time",
        accessor: "avTransactionTime",
        type: "text",
      },
      {
        Header: "Average quaility of MoS score",
        accessor: "avQuailityOrMoSScore",
        type: "text",
      },
      {
        Header: "Total downtime of agent mins",
        accessor: "totalDowntimeOfAgentMins",
        type: "text",
      },
      {
        Header: "Total downtime of apps",
        accessor: "totalDownTimeOfApps",
        type: "text",
      },
      {
        Header: "Agent IP address",
        accessor: "agentIP",
        type: "text",
      },
      {
        Header: "",
        accessor: "tools",
        type: "tools-agent",
      },
    ],
  },
  {
    title: "Path Analytics",
    id: "agent-path-analytics",
    type: "path-analytics",
    dashboardType: "agent",
    size: 3,
    availableSizes: [3, 4],
    disableIfHortium: true,
    endpoint: "/metric/pathAnalytics",
    chartType: "agents",
  },
  {
    title: "Important Metrics Today",
    id: "agent-important-metrics",
    type: "important-metrics",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/important",
    chartType: "hops",
  },
  {
    title: "Discovered devices and metrics from agent discovery",
    id: "agent-devices-details-table",
    type: "devices-table",
    dashboardType: "agent",
    size: 4,
    endpoint: "/metric/devices",
    chartType: "devices",
    disableIfHortium: true,
  },
  {
    title: "Change in discovered devices",
    id: "agent-comparison-box-devices",
    type: "comparison-box",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/changeIn",
    chartType: "device",
  },
  {
    title: "Change in EUX",
    id: "agent-comparison-box-eux",
    type: "comparison-box",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/changeIn",
    chartType: "eux",
  },
  {
    title: "Change in latency",
    id: "agent-comparison-box-latency",
    type: "comparison-box",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/changeIn",
    chartType: "latency",
  },
  {
    title: "Change in loss/failures",
    id: "agent-comparison-box-loss",
    type: "comparison-box",
    dashboardType: "agent",
    size: 1,
    endpoint: "/metric/changeIn",
    chartType: "loss",
  },
  {
    title: "Application/test running from agent",
    id: "agents-apps-performance-extended",
    type: "apps-performance-extended",
    dashboardType: "agents",
    availableSizes: [3, 4],
    size: 4,
    endpoint: "/metric/agent/getApplicationsMetric",
    chartType: "agents",
    disableIfHortium: true,
  },
];
