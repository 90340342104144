import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./TableCellWaterfall.module.scss";
import cn from "classnames";
import ModalTestDetails from "../../Modal/ModalTestDetails/ModalTestDetails";
import { colorGenerator } from "../../../helpers/colorGenerator";

const TableCellWaterfall = (props: ITableCellWaterfall) => {
  const { chart, gateway, rowData } = props;
  const dataRef: any = useRef(chart);

  const [active, setActive] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setTotal(chart.reduce((a, b) => a + b.value, 0));
  }, [chart]);

  const memoChild = useMemo(
    () => (
      <div
        style={total > 0 ? { minWidth: "200px" } : {}}
        className={styles.progress}
      >
        {chart &&
          chart.map((elem, key) => {
            dataRef.current[key].color = colorGenerator();
            return (
              <div
                className={cn(styles.bar, styles[elem.title])}
                key={key}
                style={{
                  width: `${(total / 100) * elem.value}%`,
                  backgroundColor: dataRef.current[key].color,
                }}
              />
            );
          })}
      </div>
    ),
    [chart, total]
  );

  return (
    <>
      <div
        className={styles.inner}
        onClick={() => setModalOpen(true)}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        {memoChild}
        <span className={styles.total}>{parseFloat(rowData.transactionTime.toFixed(1))}ms</span>
        {active && (
          <Details
            chart={chart}
            gateway={gateway}
            total={parseFloat(total.toFixed(1))}
          />
        )}
      </div>
      <ModalTestDetails modalOpen={modalOpen} rowData={rowData} setModalOpen={setModalOpen} />
    </>
  );
};

export default TableCellWaterfall;

export interface ITableCellWaterfallRowData {
  url: string,
    scriptId: string,
    isHttps: boolean,
    statusCode: number,
    eux: number,
    accessability: number,
    performance: number,
    speedIndex: number,
    totalBlockingTime: number,
    timeToInteractive: number,
    transactionTime: number,
    latency: number,
    networkRtt: number,
    fieldTotalBlockingTime:number,
    fieldTimeToInteractive: number,
    fieldSpeedIndex: number,
    sla: number,
    waterfall: Array<{
      title: string;
      value: number;
      color: string;
    }>,
    hops: number,
    dnsTime: number
}

export interface ITableCellWaterfall {
  chart: Array<{
    title: string;
    value: number;
  }>;
  gateway: string;
  rowData: ITableCellWaterfallRowData
}

const Details = (props: IDetails) => {
  const { chart, gateway, total } = props;

  return (
    <div className={styles.tooltip}>
      <span className={styles.title}>{gateway}</span>
      <div className={"d-flex justify-content-between"}>
        <span className={styles.startTime}>Start Time</span>
      </div>
      {chart &&
        chart.filter(item => ['speed index', 'total blocking time', 'time to interactive', 'performance', 'availibility'].includes(item.title.toLowerCase())).map((item, key) => {
          return (
            <div className={"d-flex justify-content-between"} key={key}>
              <span className={styles.title}>
                <span className={cn(styles.dot, styles[item.title])} />
                {item.title}
              </span>
              <span className={styles.value}>{`${parseFloat(item.value.toFixed(1))} ms`}</span>
            </div>
          );
        })}
      <div className={"d-flex justify-content-between"}>
        <span className={styles.summary}>Total</span>
        <span className={styles.summaryValue}>{`${total} ms`}</span>
      </div>
    </div>
  );
};

export interface IDetails {
  total: number;
  chart: Array<{
    title: string;
    value: number;
  }>;
  gateway: string;
}
