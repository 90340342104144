import {
  GET_TABLE_AGENT_DISCOVERED_DEVICE,
  GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE,
  GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED,
  TableStateActionT,
  AgentDiscoveredDeviceT
} from "../../types/widgets/AgentsDiscoveredDevicesTable.types";

export const initialState: { data: Array<AgentDiscoveredDeviceT>, loading: boolean, error: boolean } = {
  data: [],
  loading: false,
  error: false
};

const agentDiscoveredDeviceState = (state = initialState, action: TableStateActionT) => {
  switch (action.type) {
    case GET_TABLE_AGENT_DISCOVERED_DEVICE:
      return { ...state, data: [], loading: true, error: false };
    case GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE:
      return { ...state, data: action.data, loading: false, error: false };
    case GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default agentDiscoveredDeviceState;
