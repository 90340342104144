export const serviceDefaultDashboard = {
  title: "Service",
  id: "service",
  widgets: [
    {
      title: "EUX score overall",
      id: "service-gauge-results",
      type: "gauge-results",
      dashboardType: "service",
      availableSizes: [1, 2],
      endpoint: "/metric/totalResults",
      chartType: "eux"
    },
    {
      title: "EUX score overall for applications",
      id: "service-gauge-results-old-apps",
      type: "gauge-results-old",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "EUX score overall for locations",
      id: "service-gauge-results-old-locations",
      type: "gauge-results-old",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "EUX score overall for users/agents",
      id: "service-gauge-results-old-users",
      type: "gauge-results-old",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Service management per location by EUX",
      id: "service-column-line-chart-old-location",
      type: "column-line-chart-old",
      dashboardType: "service",
      availableSizes: [2, 3, 4]
    },
    {
      title: "Service management per application by EUX",
      id: "service-column-line-chart-old-app",
      type: "column-line-chart-old",
      dashboardType: "service",
      availableSizes: [2, 3, 4]
    },
    {
      title: "Top performing locations",
      id: "service-most-active-top-locations",
      type: "most-active",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Top performing applications",
      id: "service-most-active-top-apps",
      type: "most-active",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Worse performing locations",
      id: "service-most-active-worse-locations",
      type: "most-active",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Worse performing applications",
      id: "service-most-active-worse-apps",
      type: "most-active",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Heatmap performance by location",
      id: "service-heatmap-location",
      type: "heatmap",
      dashboardType: "service",
      availableSizes: [2, 3, 4],
      size: 3,
      endpoint: "metric/heatmap",
      chartType: "agents"
    },
    {
      title: "Availability location application overall",
      id: "service-circle-chart",
      type: "circle-chart",
      dashboardType: "service",
      availableSizes: [1, 2]
    },
    {
      title: "Heatmap performance by application",
      id: "service-heatmap-app",
      type: "heatmap",
      dashboardType: "service",
      availableSizes: [2, 3, 4],
      size: 4,
      endpoint: "metric/heatmap",
      chartType: "agents"
    },
    {
      title: "Applications performance by metrics",
      id: "service-tests-table-app",
      type: "tests-table",
      dashboardType: "service",
      availableSizes: [3, 4],
      size: 4
    },
    {
      title: "Location performance by metrics",
      id: "service-tests-table-location",
      type: "tests-table",
      dashboardType: "service",
      availableSizes: [3, 4],
      size: 4
    },
    {
      title: "Applications test and their performance",
      id: "service-apps-performance-extended",
      type: "apps-performance-extended",
      dashboardType: "service",
      availableSizes: [3, 4],
      size: 4
    }
  ]
}