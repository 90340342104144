import React, { useEffect, useState, useCallback } from "react";
import { Form, Formik } from "formik";
import styles from "../ModalInviteUser/ModalInviteUser.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import Button from "../../Button/Button";
import { ReactComponent as IconSave } from "../../../assets/icons/check.svg";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import axios from "../../../api/axiosConfig";
import Heading from "../../Heading/Heading";

interface IModalEditCustomer {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  selectedUser: any;
}

const ModalEditCustomer = (props: IModalEditCustomer) => {
  const { setModalOpen, handleUpdateData, selectedUser } = props;

  const [sent, setSent] = useState(false);

  // const errorHandler = (error: string) => {
  //   switch (error) {
  //     case "email already registered":
  //       return { email: "Email already registered" };
  //     default:
  //       return { email: "Unknown error. Try again later." };
  //   }
  // };

  const handleSubmit = useCallback(
    (values, setErrors) => {
      axios
        .patch(`/customer/${selectedUser.id}`, {
          name: values.name,
          email: values.email,
        })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            handleUpdateData();
            setSent(true);
          }
        })
        .catch((error) => console.log(error));
    },

    [handleUpdateData, selectedUser]
  );

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
        setModalOpen(false);
      }, 3000);
    }
  }, [sent, setModalOpen]);

  const initialValues = {
    email: selectedUser.customerDetails.email,
    name: selectedUser.customerDetails.name,
  };

  if (sent) {
    return (
      <ModalSuccess text={`Customer data has been successfully changed`} />
    );
  }

  return (
    <>
      <Heading title="Edit Customer" weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmit(values, setErrors);
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Company*"
                placeholder="Company Name"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="email"
                label="Email"
                placeholder="Email"
                textInputType="email"
                required
              />

              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Save"
                  typeAttr="submit"
                  icon={<IconSave />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ModalEditCustomer;
