import { IGlobalStyle } from "./global";

export const darkTheme: IGlobalStyle = {
  background: "#22242C",
  text: "#FFFFFF",
  sidebar: "#1A1B21",
  border: "#22242C",
  panelBg: "#292A33",
  textLight: "#8A97AD",
  map: "#3b404c",
  tooltip: "#1A1B21",
  inputBorder: "#8A97AD",
  inputBorderPlain: "#8A97AD",
  inputBorderChecked: "#FFFFFF",
  textFieldChecked: "#FFFFFF",
  inputBackground: "transparent",
  tabsBorder: "transparent",
  checkboxBg: "#292A33",
  boxShadow: "0px 12px 34px -1px rgba(0, 0, 0, 0.16)",
};
