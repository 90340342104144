import React from "react";
import styles from "./TableCellPriority.module.scss";
import cn from "classnames";
import {Capitalize} from "../../../functions/Capitalize/Capitalize";

const TableCellPriority = (props: ITableCellPriority) => {
  const { priority } = props;
  return (
    <div className={styles.element}>
      <span className={cn(styles.priority, styles[`is${Capitalize(priority)}`])} />
      <span>{priority}</span>
    </div>
  );
};

export default TableCellPriority;

export interface ITableCellPriority {
  priority: string;
}
