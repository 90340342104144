import React, { useEffect, useState } from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellDevice, {
  ITableCellDevice,
} from "../../components/tableElements/TableCellDevice/TableCellDevice";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import ActionTools from "../../components/ActionTools/ActionTools";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import { DeviceT } from "../../store/types/Devices";

interface IDevicesDiscovered {
  data: Array<DeviceT>;
  noData: boolean;
  openEdit: (e: DeviceT) => void;
  openDelete: (e: DeviceT) => void;
}

const DevicesDiscovered = (props: IDevicesDiscovered) => {
  const { data, openDelete, noData, openEdit } = props;
  const [devicesList, setDevicesList] = useState<Array<DeviceT>>(data);
  const { permissions } = useSelector((state: AppState) => state.authorization);

  useEffect(() => {
    setDevicesList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDevice } }) => (
          <TableCellDevice>{value}</TableCellDevice>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value} view="agents" />,
      },
      {
        Header: "Agent",
        accessor: "agentName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Type",
        accessor: "deviceType",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Model",
        accessor: "model",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Connection Type",
        accessor: "connectionType",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Alias",
        accessor: "alias",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Circuit Id",
        accessor: "circuitId",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "MAC address",
        accessor: "mac",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "IP address",
        accessor: "ip",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "OS Version",
        accessor: "osVersion",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Latency",
        accessor: "latency",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        show:
          permissions.structure.fullAccess && permissions.structure.data
            ? true
            : false,
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => (
          <div className={"d-flex align-items-center"}>
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  title="Edit Device"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete Device"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          </div>
        ),
      },
    ],
    [openDelete, permissions, openEdit]
  );

  return <ReactTable columns={columns} data={devicesList} noData={noData} />;
};

export default DevicesDiscovered;
