import React, { useState } from "react";
import axiosConfig from "../../../api/axiosConfig";
import stylesInput from "../TextInput/TextInput.module.scss";
import styles from "./UploadJson.module.scss";
import cn from "classnames";
import Button from "../../Button/Button";

const UploadFile = (props: IUploadFile) => {
  const [fileName, setFileName] = useState("No file selected");

  const { name, label, errors, touched, setFieldValue } = props;

  const setJson = async (file: any) => {
    const formData = new FormData();
    formData.append('json', file);
    try {
      await axiosConfig
        .post("/configuration/app/json", formData)
        .then((res) => {
          if (res && res.status === 200) {
            setFieldValue("json", res.data.Location);
          }
        });
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div
      className={cn(
        styles.wrapper,
        errors[name] && touched[name] && styles["isError"]
      )}
    >
      {label && (
        <label className={cn(styles.label, stylesInput.label)} htmlFor="json">
          {label}
          <div className={styles.input}>
            <Button
              type="outline"
              className={styles.button}
              title="Choose file"
            />
            <span className={styles.text}>{fileName}</span>
          </div>

          <input
            type="file"
            id="json"
            className={cn(stylesInput.input, styles.hidden)}
            name={name}
            onChange={(event: any) => {
              setJson(event.currentTarget.files[0]);
              setFileName(
                event.currentTarget.files[0]
                  ? event.currentTarget.files[0].name
                  : "No file selected"
              );
            }}
          />
        </label>
      )}

      {errors[name] && touched[name] && (
        <span className={stylesInput.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default UploadFile;

export interface IUploadFile {
  name: string;
  label?: string;
  errors?: any;
  touched?: any;
  setFieldValue: (e: string, val: any) => void;
}
