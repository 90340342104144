import React, { useState } from "react";
import styles from "./ModalManageDashboardMain.module.scss";
import Heading from "../../../Heading/Heading";
import Button from "../../../Button/Button";
import { ReactComponent as IconCheck } from "../../../../assets/icons/check.svg";
import Modal from "../../Modal";
import ModalAddTitle from "../../ModalAddTitle/ModalAddTitle";
import WidgetsList from "./WidgetsList/WidgetsList";
import CustomPresetsList from "./CustomPresetsList/CustomPresetsList";
import ReceivedPresetsList from "./ReceivedPresetsList/ReceivedPresetsList";
import { PresetT, WidgetT } from "../../../../store/reducers/DashboardPresets";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";

const ModalManageDashboardMain = (props: IModalManageDashboardMain) => {
  const {
    handleSave,
    setModalOpen,
    title,
    modalInside,
    handleSaveAsNewPreset,
    currentWidgets,
    handleEmptyWidgetsArea,
  } = props;

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["md"]}px)`,
  });

  const [modalTitleOpen, setModalTitleOpen] = useState<boolean>(false);

  const handleSwitchModalArea = (modalInside: string) => {
    switch (modalInside) {
      case "widgets":
        return <WidgetsList {...props} />;
      case "customPresets":
        return <CustomPresetsList {...props} />;
      case "receivedPresets":
        return <ReceivedPresetsList {...props} />;
    }
  };

  return (
    <>
      <div className={styles.right}>
        {isResponsiveView && (
          <Heading title="Manage Dashboard" weight="medium" />
        )}
        <div className={styles.meta}>
          {title && modalInside === "widgets" && (
            <div className={styles.title}>{title.replace(/\+/g, " ")}</div>
          )}
          {modalInside === "widgets" ? (
            <Button
              title="Save as new template"
              type="outline"
              handleClick={() => setModalTitleOpen(true)}
              className={styles.button}
            />
          ) : (
            <Button
              title="Create new preset"
              type="outline"
              handleClick={handleEmptyWidgetsArea}
              className={styles.button}
            />
          )}
        </div>
        {handleSwitchModalArea(modalInside)}
        <div className={styles.footer}>
          <div className="ml-auto">
            <Button
              title="Cancel"
              type="plain"
              typeAttr="button"
              handleClick={() => setModalOpen(false)}
            />
            <Button
              title="Save"
              icon={<IconCheck />}
              icon_position="right"
              handleClick={() => handleSave(currentWidgets)}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Add Template Title"
        modalOpen={modalTitleOpen}
        setModalOpen={setModalTitleOpen}
      >
        <ModalAddTitle
          {...props}
          setModalOpen={setModalTitleOpen}
          handleClick={handleSaveAsNewPreset}
        />
      </Modal>
    </>
  );
};

export default ModalManageDashboardMain;

interface IModalManageDashboardMain {
  handleSave: (d: WidgetT[]) => void;
  title: string;
  setModalOpen: (e: boolean) => void;
  setModalInside: (e: string) => void;
  handleSaveAsNewPreset: (e: string) => void;
  currentWidgets: WidgetT[];
  showCustomPresetWidgets: (data: PresetT) => void;
  handleDeleteReceivedPreset: (index: number) => void;
  handleDeleteCustomPreset: (index: number) => void;
  handleEmptyWidgetsArea: () => void;
  handleMovePresetToCustom: (preset: PresetT, e: number) => void;
  modalInside: string;
  customPresetsList: PresetT[];
  receivedPresetsList: PresetT[];
  setCurrentWidgets: (e: WidgetT[]) => void;
}
