export const agentsDefaultDashboard = {
  title: "Agents",
  id: "agents",
  widgets: [
    {
      title: "EUX score overall",
      id: "agents-gauge-results",
      type: "gauge-results",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/totalResults",
      chartType: "eux",
      disableIfHortium: true
    },
    {
      title: "Performance against SLA",
      id: "agents-gauge-results-performance",
      type: "gauge-results",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/totalResults",
      chartType: "perf",
      disableIfHortium: true
    },
    // {
    //   title: "Overall application SLA",
    //   id: "agents-gauge-results-app",
    //   type: "gauge-results",
    //   dashboardType: "agents",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/totalResults",
    //   chartType: "application",
    //   disableIfHortium: true
    // },
    {
      title: "Availability %",
      id: "agents-gauge-results-availability",
      type: "gauge-results",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/totalResults",
      chartType: "availability",
      disableIfHortium: true
    },
    {
      title: "Frustration Rate",
      id: "agents-gauge-results-frustration",
      type: "gauge-results-icon",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/totalResults",
      chartType: "frustration",
      disableIfHortium: true
    },
    // TODO: structure in backend is incorrect for now 
    // {
    //   title: "Found Issues",
    //   id: "agents-important-metrics-issues",
    //   type: "important-metrics",
    //   dashboardType: "agents",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/ucc/getTodayTransactionMetric",
    //   disableIfHortium: true
    // },
    // TODO: Done. Structure is good, but data field is empty for this request
    {
      title: "Agent heatmap",
      id: "agents-heatmap",
      type: "heatmap",
      dashboardType: "agents",
      availableSizes: [2, 3, 4],
      size: 4,
      disableIfHortium: true,
      endpoint: "metric/heatmap",
      chartType: "agents"
    },
    {
      title: "Important Metrics Today",
      id: "agents-important-metrics",
      type: "important-metrics",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/important",
      chartType: "hops",
      disableIfHortium: true
    },
    // {
    //   title: "Bandwidth tests",
    //   id: "agents-column-line-chart-bandwidth",
    //   type: "column-line-chart",
    //   dashboardType: "agents",
    //   availableSizes: [2, 3, 4],
    //   endpoint: "/metric/agent/getWifiData",
    //   chartType: "bandwidth",
    //   disableIfHortium: true,
    //   data: {
    //     column: [
    //       {
    //         title: "Download",
    //         id: "download",
    //       },
    //       {
    //         title: "Upload",
    //         id: "upload",
    //       },
    //       {
    //         title: "Network",
    //         id: "network",
    //       }
    //     ],
    //     line: [
    //       {
    //         title: "Availability",
    //         id: "availability",
    //         unit: "%"
    //       }
    //     ]
    //   }
    // },
    // {
    //   title: "Device Performance",
    //   id: "agents-column-line-chart-device",
    //   type: "column-line-chart",
    //   dashboardType: "agents",
    //   availableSizes: [2, 3, 4],
    //   disableIfHortium: true,
    //   endpoint: "/metric/agent/getDevicePerfomanceMetric",
    //   chartType: "device",
    //   data: {
    //     column: [
    //       {
    //         title: "Memory",
    //         id: "memory",
    //       },
    //       {
    //         title: "Processes",
    //         id: "processes",
    //       },
    //       {
    //         title: "Connection",
    //         id: "connection",
    //       },
    //       {
    //         title: "Wifi",
    //         id: "wifi",
    //       }
    //     ],
    //     line: [
    //       {
    //         title: "Availability",
    //         id: "availability",
    //         unit: "%"
    //       }
    //     ]
    //   }
    // },
    {
      title: "Agent Test Metrics",
      id: "agents-column-line-chart-app",
      type: "column-line-chart",
      dashboardType: "agents",
      availableSizes: [2, 3, 4],
      disableIfHortium: true,
      endpoint: "/metric/agent/getTracerouteMetric",
      chartType: "app",
      size: 3,
      data: {
        column: [
          {
            title: "Transaction",
            id: "transaction",
          },
          {
            title: "Hops",
            id: "hops",
          },
          {
            title: "Eux",
            id: "eux",
          },
          {
            title: "Availbility",
            id: "availbility",
          }
        ],
        line: [
          {
            title: "Availability",
            id: "availability",
            unit: "%"
          }
        ]
      }
    },
    {
      title: "Agent details - system and performance",
      id: "agents-agents-table",
      type: "agents-table",
      dashboardType: "agents",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/agent/getAgentInfos",
      chartType: "agents",
      data: [
        {
          Header: "Agent",
          accessor: "agent",
          type: "name-link"
        },
        {
          Header: "Alias",
          accessor: "alias",
          type: "text"
        },
        {
          Header: "Hostname",
          accessor: "hostname",
          type: "text"
        },
        {
          Header: "CPU",
          accessor: "cpu",
          type: "text"
        },
        {
          Header: "Memory",
          accessor: "memory",
          type: "text"
        },
        {
          Header: "Number of Processes",
          accessor: "numberOfProcessess",
          type: "text"
        },
        {
          Header: "Top process",
          accessor: "topProcess",
          type: "text"
        },
        {
          Header: "Connection type",
          accessor: "connectionType",
          type: "text"
        },
        {
          Header: "Wifi Strength",
          accessor: "wifiStrength",
          type: "text"
        },
        {
          Header: "SSID",
          accessor: "ssid",
          type: "text"
        },
        {
          Header: "TX Rate",
          accessor: "txRate",
          type: "text"
        },
        {
          Header: "Connection speed",
          accessor: "connectionSpeed",
          type: "text"
        },
        {
          Header: "Noise",
          accessor: "noise",
          type: "text"
        },
        {
          Header: "RSSI",
          accessor: "rssi",
          type: "text"
        },
        {
          Header: "Network Latency",
          accessor: "networkLatency",
          type: "text"
        },
        {
          Header: "Packetloss",
          accessor: "packetLoss",
          type: "text"
        },
        {
          Header: "DNS Time",
          accessor: "dnsTime",
          type: "text"
        },
        {
          Header: "Transaction time",
          accessor: "transactionTime",
          type: "text"
        },
        {
          Header: "Number of Hops",
          accessor: "hops",
          type: "text"
        },
        {
          Header: "EUX score",
          accessor: "euxScore",
          type: "text"
        },
        {
          Header: "SLA score %",
          accessor: "slaScore",
          type: "text"
        },
        {
          Header: "Availability",
          accessor: "availability",
          type: "text"
        },
        {
          Header: "Bandwidth uplaod",
          accessor: "bwUpload",
          type: "text"
        },
        {
          Header: "Bandwidth download",
          accessor: "bwDownload",
          type: "text"
        },
        {
          Header: "Bandwidth location",
          accessor: "bwLocation",
          type: "text"
        },
        {
          Header: "Number of discovered devices",
          accessor: "numberOfDiscoveredDevices",
          type: "text"
        },
        {
          Header: "Number of employees",
          accessor: "numberOfEmployees",
          type: "text"
        },
        {
          Header: "Uptime",
          accessor: "uptime",
          type: "text"
        },
        {
          Header: "Number of apps test running",
          accessor: "numberOfAppsTestRunning",
          type: "text"
        },
        {
          Header: "Average performance of app",
          accessor: "avPerformanceOfApp",
          type: "text"
        },
        {
          Header: "Appscore",
          accessor: "appscore",
          type: "text"
        },
        {
          Header: "Number of transactions",
          accessor: "numberOfTransactions",
          type: "text"
        },
        {
          Header: "Average transaction time",
          accessor: "avTransactionTime",
          type: "text"
        },
        {
          Header: "Average quaility of MoS score",
          accessor: "avQuailityOrMoSScore",
          type: "text"
        },
        {
          Header: "Total downtime of agent mins",
          accessor: "totalDowntimeOfAgentMins",
          type: "text"
        },
        {
          Header: "Total downtime of apps",
          accessor: "totalDownTimeOfApps",
          type: "text"
        },
        {
          Header: "Agent IP address",
          accessor: "agentIP",
          type: "text"
        },
        {
          Header: "",
          accessor: "tools",
          type: "tools-agents"
        }
      ]
    },
    // TODO: what data we need to display?
    {
      title: "Discovered devices and metrics from agent discovery",
      id: "agents-devices-table",
      type: "devices-table",
      dashboardType: "agents",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/devices",
      chartType: "devices",
      disableIfHortium: true,
    },
    {
      title: "Change in discovered devices",
      id: "agents-comparison-box-devices",
      type: "comparison-box",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/changeIn",
      chartType: "device",
      disableIfHortium: true
    },
    {
      title: "Change in EUX",
      id: "agents-comparison-box-eux",
      type: "comparison-box",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/changeIn",
      chartType: "eux",
      disableIfHortium: true
    },
    {
      title: "Change in latency",
      id: "agents-comparison-box-latency",
      type: "comparison-box",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/changeIn",
      chartType: "latency",
      disableIfHortium: true
    },
    {
      title: "Change in loss/failures",
      id: "agents-comparison-box-loss",
      type: "comparison-box",
      dashboardType: "agents",
      availableSizes: [1, 2],
      endpoint: "/metric/changeIn",
      chartType: "loss",
      disableIfHortium: true
    },
    // {
    //   title: "Agent Performance",
    //   id: "agents-column-line-chart-old-process",
    //   type: "column-line-chart",
    //   dashboardType: "agents",
    //   endpoint: '/metric/agent/getProcessesMetric',
    //   chartType: "agent",
    //   availableSizes: [2, 3, 4],
    //   disableIfHortium: true,
    //   data: {
    //     column: [
    //       {
    //         title: "Memory",
    //         id: "memory",
    //       },
    //       {
    //         title: "Processes",
    //         id: "processes",
    //       },
    //       {
    //         title: "Throughout",
    //         id: "throughout",
    //       }
    //     ],
    //     line: [
    //       {
    //         title: "Availability",
    //         id: "availability",
    //         unit: "%"
    //       }
    //     ]
    //   }
    // },
    // {
    //   title: "Agent Connection Performance",
    //   id: "agents-column-line-chart-old-network",
    //   type: "column-line-chart",
    //   dashboardType: "agents",
    //   availableSizes: [2, 3, 4],
    //   endpoint: "/metric/agent/getConnectionPerfomance",
    //   chartType: "network",
    //   disableIfHortium: true,
    //   data: {
    //     column: [
    //       {
    //         title: "Throughput",
    //         id: "throughput",
    //       },
    //       {
    //         title: "RSSI",
    //         id: "rssi",
    //       },
    //       {
    //         title: "Channel",
    //         id: "channel",
    //       },
    //       {
    //         title: "Network",
    //         id: "network",
    //       }
    //     ],
    //     line: [
    //       {
    //         title: "Availability",
    //         id: "availability",
    //         unit: "%"
    //       }
    //     ]
    //   }
    // },
    {
      title: "Application/test running from agent",
      id: "agents-apps-performance-extended",
      type: "apps-performance-extended",
      dashboardType: "agents",
      availableSizes: [3, 4],
      size: 4,
      endpoint: "/metric/agent/getApplicationsMetric",
      chartType: "agents",
      disableIfHortium: true
    }
  ]
}