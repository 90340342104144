import {EmployeeT, GetEmployeesListDoneT, GetEmployeesListFailedT, GetEmployeesListT, GET_EMPLOYEES_LIST, GET_EMPLOYEES_LIST_DONE, GET_EMPLOYEES_LIST_FAILED} from "../types/Employees";



export const GetEmployeesList = (id: string): GetEmployeesListT => ({
  type: GET_EMPLOYEES_LIST,
  id
});

export const GetEmployeesListDone = (data: EmployeeT[] | null): GetEmployeesListDoneT => ({
  type: GET_EMPLOYEES_LIST_DONE,
  payload: data
});

export const GetEmployeesListFailed = (error: any): GetEmployeesListFailedT => ({
  type: GET_EMPLOYEES_LIST_FAILED,
  error,
});
