import React from "react";
import Loader from "../../components/Loader/Loader";
import Panel from "../../components/Panel/Panel";
import { WidgetT } from '../../store/reducers/DashboardPresets'


export const ManageDashboardWidgets = ({
  type,
  title,
  path,
  data,
  endpoint,
  chartType,
  id,
  payloadAccessor,
  sections,
  accessDifferentTypes,
  userId,
  requestType,
  isTop,
  isByLocation,
  columns,
  queryParams
}: WidgetT) => {
  const ImportantMetricsOld = React.lazy(
    () => import("../../widgets/ImportantMetrics/ImportantMetrics.old.widget")
  );
  const ImportantMetrics = React.lazy(
    () => import("../../widgets/ImportantMetrics/ImportantMetrics.widget")
  );
  const OrderedList = React.lazy(
    () => import("../../widgets/OrderedList/OrderedList.widget")
  );
  const Map = React.lazy(() => import("../../widgets/Map/Map"));
  const HeatmapOld = React.lazy(
    () => import("../../widgets/Heatmap/Heatmap.old.widget")
  );
  const Heatmap = React.lazy(
    () => import("../../widgets/Heatmap/Heatmap.widget")
  );

  const PartnerBox = React.lazy(
    () => import("../../widgets/PartnerBox/PartnerBox")
  );
  const ComparisonBoxOld = React.lazy(
    () => import("../../widgets/ComparisonBox/ComparisonBox.old")
  );
  const ComparisonBox = React.lazy(
    () => import("../../widgets/ComparisonBox/ComparisonBox.widget")
  );
  const GaugeFullCircle = React.lazy(
    () => import("../../widgets/GaugeFullCircle/GaugeFullCircle")
  );
  const ColumnLineChartOld = React.lazy(
    () => import("../../widgets/ColumnLineChart/ColumnLineChart.old.widget")
  );
  const ColumnLineChart = React.lazy(
    () => import("../../widgets/ColumnLineChart/ColumnLineChart.widget")
  );
  const MostActive = React.lazy(
    () => import("../../widgets/MostActive/MostActive")
  );
  const MostActiveLicense = React.lazy(
    () => import("../../widgets/MostActiveLicense/MostActiveLicense")
  );
  const GaugeHalfOld = React.lazy(
    () => import("../../widgets/GaugeHalfOld/GaugeHalfOld")
  );
  const GaugeHalf = React.lazy(() => import("../../widgets/GaugeHalf/GaugeHalf"))
  const NetworkAnalysis = React.lazy(
    () => import("../../widgets/NetworkAnalysis/NetworkAnalysis")
  );
  const GaugeResultsOld = React.lazy(
    () => import("../../widgets/GaugeResults/GaugeResults.old")
  );
  const GaugeResults = React.lazy(
    () => import("../../widgets/GaugeResults/GaugeResults.widget")
  );
  const GaugeResultsMultiOld = React.lazy(
    () => import("../../widgets/GaugeResultsMulti/GaugeResultsMulti.old")
  );
  const TestsTable = React.lazy(
    () => import("../../widgets/TestsTable/TestsTable")
  );
  const SimpleTable = React.lazy(
    () => import("../../widgets/SimpleTable/SimpleTable")
  );
  const FinanceAppTable = React.lazy(
    () => import("../../widgets/Finances/AppPerformance")
  );
  const FinanceLocationTable = React.lazy(
    () => import("../../widgets/Finances/AppLocation")
  );
  const StatusTable = React.lazy(
    () => import("../../widgets/StatusTable/StatusTable")
  );

  const EmployeeCallerMetricsTable = React.lazy(() => import("../../widgets/EmployeeCallerMetricsTable/EmployeeCallerMetricsTable.widget"))

  const EmployeeCalleeOriginatorMetricsTable = React.lazy(() => import('../../widgets/EmployeeCalleeOriginatorMetricsTable/EmployeeCalleeOriginatorMetricsTable.widget'))

  const EmployeeCallsTable = React.lazy(
    () => import("../../widgets/EmployeeCallsTable/EmployeeCallsTable.widget")
  );
  const CallerCalleeDevicesTable = React.lazy(
    () => import("../../widgets/CallerCalleeDevicesTable/CallerCalleeDevicesTable.widget")
  );
  const EmployeeDevicesTable = React.lazy(
    () => import("../../widgets/EmployeeDevicesTable/EmployeeDevicesTable.widget")
  );
  const ConferenceCallsTable = React.lazy(
    () =>
      import("../../widgets/ConferenceCallsTable/ConferenceCallsTable.widget")
  );

  const AgentsDiscoveredDevicesTable = React.lazy(
    () => import("../../widgets/AgentsDiscoveredDevicesTable/AgentsDiscoveredDevicesTable.widget")
  );
  const CallQueueTable = React.lazy(
    () => import("../../widgets/CallQueueTable/CallQueueTable.widget")
  );
  const AppsDowntimeCostTable = React.lazy(
    () => import("../../widgets/AppsDowntimeCostTable/AppsDowntimeCostTable")
  );
  const AppsPerformanceTable = React.lazy(
    () =>
      import("../../widgets/AppsPerformanceTable/AppsPerformanceTable.widget")
  );
  const AppsPerformanceExtendedTable = React.lazy(
    () =>
      import(
        "../../widgets/AppsPerformanceExtendedTable/AppsPerformanceExtendedTable.widget"
      )
  );
  const DevicesDetailsTable = React.lazy(
    () => import("../../widgets/DevicesDetailsTable/DevicesDetailsTable.widget")
  );
  const AgentsPerformanceTable = React.lazy(
    () =>
      import(
        "../../widgets/AgentsPerformanceTable/AgentsPerformanceTable.widget"
      )
  );
  const Table = React.lazy(() => import("../../widgets/Table/Table.widget"));
  const ResourcesUsageTable = React.lazy(
    () => import("../../widgets/ResourcesUsageTable/ResourcesUsageTable")
  );
  const LicensesDetailsTable = React.lazy(
    () =>
      import("../../widgets/LicensesDetailsTable/LicensesDetailsTable.widget")
  );
  const UCCAppsTable = React.lazy(
    () => import("../../widgets/UCCAppsTable/UCCAppsTable.widget")
  );
  const PathAnalytics = React.lazy(
    () => import("../../widgets/PathAnalytics/PathAnalytics.widget")
  );
  const PathAnalyticsOld = React.lazy(
    () => import("../../widgets/PathAnalytics/PathAnalytics.old")
  );

  const Trend = React.lazy(() => import("../../widgets/Trend/Trend"))

  switch (type) {
    case "important-metrics-old":
      return <ImportantMetricsOld title={title} />;
    case "important-metrics":
      return (
        <ImportantMetrics
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          id={userId}
          isTop={isTop}
          {...queryParams}
        />
      );
    case "ordered-list":
      return <OrderedList title={title} data={data} />;
    case "heatmap-old":
      return <HeatmapOld title={title} />;
    case "heatmap":
      return (
        <Heatmap
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          id={userId || ''}
          accessDifferentTypes={accessDifferentTypes}
          isByLocation={isByLocation}
          customId={id}
        />
      );
    case "partner":
      return <PartnerBox />;
    case "comparison-box-old":
      return <ComparisonBoxOld title={title} />;
    case "comparison-box":
      return (
        <ComparisonBox
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          id={userId}
          customId={id}
        />
      );
    case "circle-chart":
      return <GaugeFullCircle title={title} endpoint={endpoint} {...path} />;
    case "most-active":
      return <MostActive title={title} endpoint={endpoint} id={id} {...queryParams} />;
    case "trend":
      return <Trend title={title} endpoint={endpoint || ''}/>;
    case "most-active-license":
      return <MostActiveLicense title={title} endpoint={endpoint || ''} />;
    case "network-analysis":
      return <NetworkAnalysis title={title} />;
    case "column-line-chart-old":
      return <ColumnLineChartOld title={title} />;
    case "column-line-chart":
      return (
        <ColumnLineChart
          title={title}
          endpoint={endpoint || ''}
          data={data}
          chartType={chartType || ''}
          id={userId}
          requestType={requestType}
          {...queryParams}
        />
      );
    case "map":
      return <Map title={title} endpoint={endpoint} userId={userId} {...queryParams} />;
    case "gauge-results-old-multi":
      return <GaugeResultsMultiOld title={title} endpoint={endpoint} chartType={chartType || ''} />;
    case "gauge-results-old":
      return <GaugeResultsOld title={title} />;
    case "gauge-results-old-icon":
      return <GaugeResultsOld title={title} withFaceIcon />;
    case "gauge-results":
      return (
        <GaugeResults
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          id={userId}
          {...queryParams}
        />
      );
    case "gauge-results-icon":
      return (
        <GaugeResults
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          id={userId}
          withFaceIcon
        />
      );
    case "gauge-half-old":
      return <GaugeHalfOld title={title} {...path} />;
    case "gauge-half":
      return <GaugeHalf title={title} {...path} endpoint={endpoint} payloadAccessor={payloadAccessor} sections={sections} />
    case "employee-caller-metrics-table":
      return <EmployeeCallerMetricsTable id={userId} />
    case "employee-callee-originator-metrics-table":
      return <EmployeeCalleeOriginatorMetricsTable id={userId} />
    case "simple-table":
      return (
        <SimpleTable endpoint={endpoint} {...path} {...queryParams} customColumns={columns} />
      );
    case "finance-app-performance-table":
      return <FinanceAppTable endpoint={endpoint} {...path} />;
    case "finance-location-table":
      return <FinanceLocationTable endpoint={endpoint} {...path} />;
    case "employee-calls-table":
      return <EmployeeCallsTable {...path} />;
    case "caller-callee-table":
      return <CallerCalleeDevicesTable {...path} id={userId} />;
    case "device-employee-table":
      return <EmployeeDevicesTable {...path} />;
    case "conference-calls-table":
      return <ConferenceCallsTable {...path} />;
    case "call-queue-table":
      return <CallQueueTable {...path} />;
    case "status-table":
      return <StatusTable endpoint={endpoint} />;
    case "apps-downtime-cost-table":
      return <AppsDowntimeCostTable />;
    case "apps-performance-extended":
      return (
        <AppsPerformanceExtendedTable
          endpoint={endpoint || ""}
          chartType={chartType || ""}
          id={userId}
          isByLocation={isByLocation}
        />
      );
    case "apps-performance-table":
      return <AppsPerformanceTable />;
    case "devices-table":
      return (
        <AgentsDiscoveredDevicesTable id={userId} {...path} endpoint={endpoint} />
      );
    case "devices-details-table":
      return <DevicesDetailsTable />;
    case "agents-table-old":
      return <AgentsPerformanceTable />;
    case "agents-table":
      return (
        <Table endpoint={endpoint || ''} chartType={chartType || ''} data={data} id={userId} />
      );
    case "resource-usage-table":
      return <ResourcesUsageTable />;
    case "licenses-table":
      return <LicensesDetailsTable endpoint={endpoint} />;
    case "tests-table":
      return <TestsTable id={userId} />;
    case "ucc-apps-table":
      return <UCCAppsTable endpoint={endpoint} {...path}/>;
    case "path-analytics-old":
      return <PathAnalyticsOld title={title} />;
    case "path-analytics":
      return (
        <PathAnalytics
          title={title}
          endpoint={endpoint || ''}
          chartType={chartType || ''}
          isTop={isTop}
          id={userId}
        />
      );
    default:
      return (
        <Panel className="pt-5">
          <Loader container />
        </Panel>
      );
  }
};
