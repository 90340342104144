import React, { useEffect, useState } from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellDevice, {
  ITableCellDevice,
} from "../../components/tableElements/TableCellDevice/TableCellDevice";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import { DeviceT } from "../../store/types/Devices";

interface IDevicesAdded {
  data: Array<DeviceT>;
  noData: boolean;
  openDelete: (e: any) => void;
}

const DevicesAdded = (props: IDevicesAdded) => {
  const { data, openDelete, noData } = props;
  const [devicesList, setDevicesList] = useState<Array<DeviceT>>(data);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id } = useSelector((state: AppState) => state.customerContextState);

  useEffect(() => {
    setDevicesList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "device",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDevice } }) => (
          <TableCellDevice name={value.name} id={value.id} />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value} view="agents" />,
      },
      {
        Header: "Agent",
        accessor: "agentName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Type",
        accessor: "deviceType",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Model",
        accessor: "model",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Connection Type",
        accessor: "connectionType",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Alias",
        accessor: "alias",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Circuit Id",
        accessor: "circuitId",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "MAC address",
        accessor: "mac",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "IP address",
        accessor: "ip",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "OS Version",
        accessor: "osVersion",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Latency",
        accessor: "latency",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        show:
          checkEditAccess(permissions, customer, id) ||
          (customer.mainCustomer &&
            permissions.structure.fullAccess &&
            permissions.structure.data)
            ? true
            : false,
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => (
          <div className={"d-flex align-items-center"}>
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  path={`/devices-management/device/${value.row.original.device.id}`}
                  title="Edit Device"
                  type="tooltip"
                  scale={false}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete Device"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          </div>
        ),
      },
    ],
    [openDelete, permissions, customer, id]
  );

  return <ReactTable columns={columns} data={devicesList} noData={noData} />;
};

export default DevicesAdded;
