import { createPortal } from "react-dom";
import stylesModal from "../Modal.module.scss";
import Heading from "../../Heading/Heading";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import styles from "./ModalTestDetails.module.scss";
import Label from "../../Label/Label";
import { Column, Row } from "../../Bootstrap/Bootstrap";
import { ITableCellWaterfallRowData } from "../../tableElements/TableCellWaterfall/TableCellWaterfall";
import TableCellQuality from "../../tableElements/TableCellQuality/TableCellQuality";

const ModalTestDetails = (props: {
  modalOpen: boolean;
  setModalOpen: (b: boolean) => void;
  rowData: ITableCellWaterfallRowData;
}) => {
  const { modalOpen, setModalOpen, rowData } = props;

  const fieldStatsSum = rowData.waterfall
    .filter((stat) => stat.title.toLowerCase().includes("field"))
    .reduce((a, b) => a + b.value, 0);
  const noFieldStatsSum = rowData.waterfall
    .filter((stat) => !stat.title.toLowerCase().includes("field"))
    .reduce((a, b) => a + b.value, 0);

  if (!modalOpen) return null;

  const ModalTestDetailsTemplate = (
    <div className={stylesModal.overlay} onClick={() => setModalOpen(false)}>
      <div
        className={stylesModal.element}
        role="dialog"
        aria-labelledby={"Manage Overview Widgets"}
        onClick={(e) => e.stopPropagation()}
      >
        <Heading title="Details" weight="medium" />
        <button
          className={stylesModal.button}
          onClick={() => setModalOpen(false)}
        >
          <IconClose width="1.8rem" height="1.8rem" />
        </button>
        <div className={styles.content}>
          <Group>
            <Label title="Status" />
            <Heading title="success" weight="semi-bold" Tag="h4" />
          </Group>
          <Progress
            fieldStatsSum={fieldStatsSum}
            noFieldStatsSum={noFieldStatsSum}
            stats={rowData.waterfall}
          />
          <Row>
            <Column className="col-12 col-md-6">
              <Group>
                <Label title="Hops" />
                <Heading title={rowData.hops} weight="semi-bold" Tag="h4" />
              </Group>
              <Group>
                <Label title="Network RTT" />
                <Heading
                  title={rowData.networkRtt}
                  weight="semi-bold"
                  Tag="h4"
                />
              </Group>
              <Group>
                <Label title=" HTTP status code" />
                <Heading
                  title={rowData.statusCode}
                  weight="semi-bold"
                  Tag="h4"
                />
              </Group>
            </Column>
            <Column className="col-12 col-md-6">
              <Group>
                <Label title="DNS Time" />
                <Heading title={rowData.dnsTime} weight="semi-bold" Tag="h4" />
              </Group>
              <Group>
                <Label title="Network Server Latency" />
                <Heading title={rowData.latency} weight="semi-bold" Tag="h4" />
              </Group>
              <Group>
                <Label title="HTTPS" />
                <TableCellQuality quality={rowData.isHttps} />
              </Group>
            </Column>
          </Row>
        </div>
      </div>
    </div>
  );

  return createPortal(ModalTestDetailsTemplate, document.body);
};
export default ModalTestDetails;

const Group = ({ children }: any) => (
  <div className={styles.group}>{children}</div>
);

const Progress = ({
  stats,
  fieldStatsSum,
  noFieldStatsSum,
}: {
  stats: IStat[];
  noFieldStatsSum: number;
  fieldStatsSum: number;
}) => {
  const metricType = (title: string) => {
    switch (title) {
      case "Speed index":
      case "Field Speed index":
      case "Time to Interactive":
      case "Field Time to Interactive":
      case "App":
        return "s";

      case "Total Blocking time":
      case "Field Total Blocking time":
      case "LAN":
      case "Jitter":
      case "Latency":
      case "CDN/Cloud":
      case "DNS":
        return "ms";

      case "WIFI":
        return "dBm";

      case "Pack Loss":
      case "Healed Ratio":
        return "%";

      default:
        return "";
    }
  };
  return (
    <>
      <div className={styles.progress}>
        {stats
          .filter((stat) => stat.title.toLowerCase().includes("field"))
          .map((stat, key) => (
            <div
              className={styles.bar}
              style={{
                backgroundColor: stat.color,
                width: (stat.value * 100) / fieldStatsSum + "%",
              }}
              key={key}
            />
          ))}
      </div>
      <div className={styles.progress}>
        {stats
          .filter((stat) => !stat.title.toLowerCase().includes("field"))
          .map((stat, key) => (
            <div
              className={styles.bar}
              style={{
                backgroundColor: stat.color,
                width: (stat.value * 100) / noFieldStatsSum + "%",
              }}
              key={key}
            />
          ))}
      </div>
      <div className={styles.legend}>
        {stats.map((stat, key) => {
          return (
            <div className={styles.info} key={key}>
              <div className={styles.legendItem}>
                <span style={{ backgroundColor: stat.color }} />
                {stat.title}
              </div>
              <Label
                title={
                  `${stat.title === "WIFI" ? "-" : ""}` +
                  stat.value +
                  ` ${metricType(stat.title)}`
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

interface IStat {
  title: string;
  value: number;
  color: string;
}
