export const devicesDefaultDashboard = {
  title: "Devices",
  id: "devices",
  widgets: [
    {
      title: "Devices information",
      id: "devices-important-metrics",
      type: "important-metrics",
      dashboardType: "devices",
      availableSizes: [1, 2],
      size: 2,
      endpoint: "/metric/devices/important",
      chartType: "devices",
    },
    {
      title: "Firmware status",
      id: "devices-important-metrics-old-firmware",
      type: "important-metrics",
      dashboardType: "devices",
      availableSizes: [1, 2],
      size: 2,
      endpoint: "/metric/devices/important",
      chartType: "firmware",
    },
    // {
    //   title: "Rooms device details",
    //   id: "devices-important-metrics-old-rooms",
    //   type: "important-metrics",
    //   dashboardType: "devices",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/devices/important",
    //   chartType: "room",
    // },
    // {
    //   title: "Device performance heatmap",
    //   id: "devices-heatmap-old",
    //   type: "heatmap",
    //   dashboardType: "devices",
    //   availableSizes: [2, 3, 4],
    //   size: 4,
    //   endpoint: '/metric/ucc/getDeviceMetric'
    // },
    // {
    //   title: "Rooms performance heatmap",
    //   id: "devices-heatmap-old-rooms",
    //   type: "heatmap-old",
    //   dashboardType: "devices",
    //   availableSizes: [2, 3, 4],
    //   size: 4,
    //  endpoint: '/metric/ucc/getRoomMetric'
    // },
    {
      title: "Devices and metrics",
      id: "devices-devices-table",
      type: "devices-table",
      dashboardType: "devices",
      availableSizes: [2, 3, 4],
      size: 4,
      endpoint: "metric/devices",
      chartType: "devices",
      path: {
        pathname: "dashboard/"
      },
    },
    // {
    //   title: "User adoption of calls",
    //   id: "devices-gauge-half-old-calls",
    //   type: "gauge-half-old",
    //   dashboardType: "devices",
    //   availableSizes: [2, 3, 4],
    // },
    // {
    //   title: "User adoption of devices",
    //   id: "devices-gauge-half-old-devices",
    //   type: "gauge-half-old",
    //   dashboardType: "devices",
    //   availableSizes: [2, 3, 4],
    // },
    {
      title: "Availability of devices overtime and device metrics overtime",
      id: "devices-column-line-chart",
      type: "column-line-chart",
      dashboardType: "devices",
      availableSizes: [2, 3, 4],
      size: 3,
      endpoint: "/metric/devices/getDeviceStatistic",
      chartType: "devicesAvailability",
      disableIfHortium: true,
      data: {
        column: [
          {
            title: "Device",
            id: "device",
          },
          {
            title: "Health",
            id: "health",
          },
        ],
        line: [
          {
            title: "Availability",
            id: "availability",
            unit: "%",
          },
        ],
      },
    },
    {
      title: "Overall device quality",
      id: "devices-gauge-results-old",
      type: "gauge-results",
      dashboardType: "devices",
      availableSizes: [1, 2],
      size: 1,
      endpoint: "/metric/devices/quallity"
    },
    // {
    //   title: "Availability of devices",
    //   id: "devices-gauge-results-old-multi-devices",
    //   type: "gauge-results-old-multi",
    //   dashboardType: "devices",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/devices/getAvailibility"
    // },
    // {
    //   title: "Availability of phones",
    //   id: "devices-gauge-results-old-multi-phones",
    //   type: "gauge-results-old-multi",
    //   dashboardType: "devices",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/devices/getAvailibility",
    //   chartType: "phone"
    // },
    // {
    //   title: "Availability of room devices",
    //   id: "devices-gauge-results-old-multi-devices",
    //   type: "gauge-results-old-multi",
    //   dashboardType: "devices",
    //   availableSizes: [1, 2],
    //   endpoint: "/metric/devices/getAvailibility",
    //   chartType: "room"
    // },
  ],
};
