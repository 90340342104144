import React, { useEffect } from "react";
import styles from "./SidebarHeader.module.scss";
import Logo from "../../Logo/Logo";
import { ReactComponent as IconChevron } from "../../../assets/icons/chevron.svg";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import { ToggleMenu } from "../../../store/actions/ToggleMenu.action";
import { AppState } from "../../../store/reducers";

const SidebarHeader = () => {
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();
  const menuState = useSelector((state: AppState) => state.menuState);

  useEffect(() => {
    if (isResponsiveView) {
      dispatch(ToggleMenu());
    }
  }, [isResponsiveView, dispatch]);

  return (
    <div className={cn(styles.element, menuState && styles["isMenuHidden"])}>
      {isResponsiveView && (
        <button
          className={cn(styles.burger, !menuState && styles["isActive"])}
          onClick={() => dispatch(ToggleMenu())}
        >
          <span className={styles.plank} />
          <span className={styles.plank} />
          <span className={styles.plank} />
        </button>
      )}
      <Logo />
      {!isResponsiveView && (
        <button
          className={styles.trigger}
          onClick={() => dispatch(ToggleMenu())}
        >
          <IconChevron className={styles.icon} />
        </button>
      )}
    </div>
  );
};

export default SidebarHeader;
