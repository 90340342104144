import React, { useState } from "react";
import styles from "../ProfileSettingsAvatar/ProfileSettingsAvatar.module.scss";
import Button from "../../../components/Button/Button";
import { ReactComponent as IconUser } from "../../../assets/icons/user-placeholder.svg";
import cn from "classnames";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";

const ProfileSettingsLogo = (props: IProfileSettingsLogo) => {
  const { userName, onSetValue, setLogo, logo: selectedLogo } = props;
  const [error, setError] = useState(false);
  const { customerLogo } = useSelector((state: AppState) => state.customersState);

  const handleChange = (event: React.ChangeEvent<any>) => {
    const reader = new FileReader();
    if (event.currentTarget.files[0]) {
      reader.readAsDataURL(event.currentTarget.files[0]);
      if (event.currentTarget.files[0].size > 400000) {
        setError(true);
      } else {
        reader.onloadend = () => {
          setLogo(String(reader.result));
        };
        onSetValue("photoLinkCustomer", event.currentTarget.files[0]);
        onSetValue("photoTypeCustomer", event.currentTarget.files[0].type);
        setError(false);
      }
    }
  };

  const handleDelete = () => {
    setLogo(null);
    onSetValue("photoLinkCustomer", null);
    onSetValue("photoTypeCustomer", null);
    setError(false);
  };

  return (
    <div className={styles.element}>
      <div className={cn(styles.picture, styles.isLogo)}>
        {customerLogo || selectedLogo ? (
          <img
            src={selectedLogo ? selectedLogo : customerLogo}
            alt={userName}
          />
        ) : (
          <IconUser />
        )}
      </div>
      <div className={"d-flex flex-column justify-content-center"}>
        <span className={styles.title}>Logo</span>
        <span className={styles.text}>
          SVG or at least 256x256 PNG or JPG file <br />
          max size 400kB
        </span>
        <div>
          <label className={styles.uploadBtn} htmlFor="photoLink">
            Upload
            <input
              type="file"
              id="photoLink"
              accept="image/png, image/jpeg, image/svg+xml"
              name="photoLink"
              onChange={(event) => handleChange(event)}
            />
          </label>
          <Button
            title="Remove"
            type="plain"
            handleClick={() => handleDelete()}
          />
        </div>
        {error && (
          <span className={styles.error}>
            File is too large! <br /> Maximum size is 400kB
          </span>
        )}
      </div>
    </div>
  );
};
export default ProfileSettingsLogo;

interface IProfileSettingsLogo {
  userName?: string;
  onSetValue?: any;
  setLogo: (e: any) => void;
  logo: string | undefined | null;
}
