import React from "react";
import styles from "./WidgetsList.module.scss";
import WidgetsListElem from "./WidgetsListElem/WidgetsListElem";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { WidgetT } from "../../../../../store/reducers/DashboardPresets";

const WidgetsList = (props: IWidgetsList) => {
  const { currentWidgets, setCurrentWidgets } = props;

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(currentWidgets);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCurrentWidgets(items);
  };

  const handleDeleteWidget = (index: number) => {
    const items = Array.from(currentWidgets);
    items.splice(index, 1);
    setCurrentWidgets(items);
  };

  const handleChangeSize = (id: string, size: number) => {
    const items = Array.from(currentWidgets);
    const thisItem = currentWidgets.findIndex((element: WidgetT) => {
      return element.id === id;
    });
    items[thisItem] = { ...currentWidgets[thisItem], size: size };
    setCurrentWidgets(items);
  };

  return (
    <div className={styles.element}>
      {currentWidgets && currentWidgets.length > 0 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="elements">
            {(provided) => (
              <div
                className={"elements"}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {currentWidgets.map((elem: WidgetT, key: number) => {
                  return (
                    <Draggable key={elem.id} draggableId={elem.id} index={key}>
                      {(provided) => (
                        <li
                          className={styles.listItem}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <WidgetsListElem
                            {...elem}
                            index={key}
                            handleDeleteWidget={handleDeleteWidget}
                            handleChangeSize={handleChangeSize}
                          />
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={styles.empty}>
          <span>Add your first widget from menu</span>
        </div>
      )}
    </div>
  );
};

export default WidgetsList;

interface IWidgetsList {
  currentWidgets: WidgetT[];
  setCurrentWidgets: (e: WidgetT[]) => void;
}
