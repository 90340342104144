import React from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import { authManagementRoutes, authRoutes, layoutRoutes } from "./routes";
import { history } from "./store";
import { useSelector } from "react-redux";
import NotFoundView from "./views/NotFound/NotFound.view";
import RoleSelectView from "./views/Auth/RoleSelect/RoleSelect.view";
import { AppState } from "./store/reducers";

const App = () => {
  const { email, role } = useSelector((state: AppState) => state.authorization);

  if (email && role) {
    return (
      <Router history={history}>
        <Switch>
          <Layout>
            {layoutRoutes.map((route, key) => {
              return (
                <Route exact key={key} path={route.path}>
                  {route.component}
                </Route>
              );
            })}
          </Layout>
          <Route>
            <NotFoundView isLoggedIn={!!email} />
          </Route>
        </Switch>
      </Router>
    );
  }
  if (email && !role) {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/role-select">
            <RoleSelectView />
          </Route>
          <Redirect to="/role-select" />
        </Switch>
      </Router>
    );
  }
  return (
    <Router history={history}>
      <Switch>
        {authRoutes.map((route, key) => {
          return (
            <Route key={key} path={route.path}>
              {route.component}
            </Route>
          );
        })}
        {authManagementRoutes.map((route, key) => {
          return (
            <Route exact key={key} path={route.path}>
              {route.component}
            </Route>
          );
        })}
        {/*<Redirect to="/auth/sign-in"/>*/}
      </Switch>
    </Router>
  );
};

export default App;
