import React, { useEffect, useState, useCallback } from "react";
import { Form, Formik } from "formik";
import axios from "../../../api/axiosConfig";
import styles from "./ModalInviteUser.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import DatePicker from "../../formElements/DatePicker/DatePicker";
import RadioGroup from "../../../components/formElements/RadioGroup/RadioGroup";
import Button from "../../Button/Button";
import { ReactComponent as IconSend } from "../../../assets/icons/paper-plane.svg";
import { sendUserInvite } from "../../../api/authorization";
import moment from "moment";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import Heading from "../../Heading/Heading";
import SelectInput from "../../formElements/SelectInput/SelectInput";

interface IModalInviteUser {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  customer: string;
}

const ModalInviteUser = (props: IModalInviteUser) => {
  const { setModalOpen, handleUpdateData, customer } = props;

  const [sent, setSent] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>({
    management: [],
    configuration: [],
    dashboard: [],
  });

  const { id } = useSelector((state: AppState) => state.customerContextState);
  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : id;

  const fetchData = useCallback(async () => {
    try {
      await axios(`auth/getPermissionEnums`).then((res) => {
        if (res && res.status === 200) {
          setPermissions({
            management: toInputData(Object.values(res.data.management)),
            configuration: toInputData(Object.values(res.data.configuration)),
            dashboard: toInputData(Object.values(res.data.dashboard)),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toInputData = (permissionsData: Array<string>) => {
    return permissionsData.map((value: string) => {
      return { value, label: value };
    });
  };

  const toBackendData = (permissionsData: Array<{ value: string, label: string }>) => {
    return permissionsData.map((obj: { value: string, label: string }) => {
      return obj.value;
    });
  };

  const errorHandler = (error: string) => {
    switch (error) {
      case "email already registered":
        return { email: "Email already registered" };
      default:
        return { email: "Unknown error. Try again later." };
    }
  };

  const handleSubmit = useCallback(
    (values, setErrors) => {
      sendUserInvite({
        name: values.name,
        email: values.email,
        contactNumber: values.phone,
        expireDate: values.expireDate
          ? moment(new Date(values.expireDate).setHours(23, 59, 59)).unix()
          : null,
        customerContextId: customerContextId,
        orgPermission: values.companyAuth,
        structurePermission: values.customersAuth,
        management: values.management ? toBackendData(values.management) : [],
        configuration: values.configuration ? toBackendData(values.configuration) : [],
        dashboard: values.dashboard ? toBackendData(values.dashboard) : [],
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            handleUpdateData();
            setSent(true);
          }
        })
        .catch((error) => setErrors(errorHandler(error)));
    },
    [handleUpdateData, customerContextId]
  );

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
        setModalOpen(false);
      }, 3000);
    }
  }, [sent, setModalOpen]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    expireDate: "",
    companyAuth: "data",
    customersAuth: "data",
    management: null,
    configuration: null,
    dashboard: null,
  };

  if (sent) {
    return <ModalSuccess text={`Invitation has been sent`} />;
  }

  return (
    <>
      <Heading title={`Invite User to ${customer}`} weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmit(values, setErrors);
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="email"
                label="Email"
                placeholder="Email"
                textInputType="email"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Name"
                placeholder="Full name"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="phone"
                label="Phone"
                placeholder="(000) 000-0000"
                textInputType="tel"
              />
              <SelectInput
                placeholder="Select managements"
                touched={touched}
                errors={errors}
                name="management"
                label="Managements"
                options={permissions.management}
                isMulti
                customValue
              />
              <SelectInput
                placeholder="Select configurations"
                touched={touched}
                errors={errors}
                name="configuration"
                label="Configurations"
                options={permissions.configuration}
                isMulti
                customValue
              />
              <SelectInput
                placeholder="Select dashboards"
                touched={touched}
                errors={errors}
                name="dashboard"
                label="Dashboards"
                options={permissions.dashboard}
                isMulti
                customValue
              />
              <DatePicker
                touched={touched}
                errors={errors}
                label="Expire date"
                name="expireDate"
                type="date"
                minDate={new Date()}
              />
              <RadioGroup
                name="companyAuth"
                label="Permissions Level within Company"
                options={[
                  {
                    value: "data",
                    label: "Read Only",
                  },
                  {
                    value: "fullAccess",
                    label: "Full Access",
                  },
                  {
                    value: "none",
                    label: "No Access",
                  },
                ]}
                className={styles.radioGroup}
              />
              <RadioGroup
                name="customersAuth"
                label="Permissions Level at Customers"
                options={[
                  {
                    value: "data",
                    label: "Read Only",
                  },
                  {
                    value: "fullAccess",
                    label: "Full Access",
                  },
                  {
                    value: "none",
                    label: "No Access",
                  },
                ]}
                className={styles.radioGroup}
              />
              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Send"
                  typeAttr="submit"
                  icon={<IconSend />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ModalInviteUser;
