export const GET_SLA_TARGETS = "GET_SLA_TARGETS";
export const GET_SLA_TARGETS_DONE = "GET_SLA_TARGETS_DONE";
export const GET_SLA_TARGETS_FAILED = "GET_SLA_TARGETS_FAILED";
export const CLEAR_SLA_TARGETS = "CLEAR_SLA_TARGETS";
export const EDIT_SLA_TARGETS = "EDIT_SLA_TARGETS";
export const EDIT_SLA_TARGETS_DONE = "EDIT_SLA_TARGETS_DONE";
export const EDIT_SLA_TARGETS_FAILED = "EDIT_SLA_TARGETS_FAILED";

export type SLATargetsT = {
  delayTarget: number,
  jitterTarget: number,
  lossTarget: number,
  bandwidthSpeedTarget: number,
  availabilityTarget: number
}

export type GetSLATargetsT = {
  type: typeof GET_SLA_TARGETS;
  id: string;
}

export type GetSLATargetsDoneT = {
  type: typeof GET_SLA_TARGETS_DONE;
  payload: SLATargetsT
}

export type GetSLATargetFailedT = {
  type: typeof GET_SLA_TARGETS_FAILED;
}

export type EditSLATargetT = {
  type: typeof EDIT_SLA_TARGETS;
  id: string,
  payload: SLATargetsT
}

export type EditSLATargetDoneT = {
  type: typeof EDIT_SLA_TARGETS_DONE;
  payload: string
}

export type EditSLATargetFailedT = {
  type: typeof EDIT_SLA_TARGETS_FAILED;
}

export type ClearSLATargetT = {
  type: typeof CLEAR_SLA_TARGETS;
}

export type SLATargetsStateT = {
  data: SLATargetsT | null;
  message: string | null;
  error: boolean;
};

export type SLATargetsStateActionT = GetSLATargetsDoneT | GetSLATargetFailedT | ClearSLATargetT | EditSLATargetDoneT | EditSLATargetFailedT