import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "../../../api/axiosConfig";
import { AppState } from "../../../store/reducers";
import SelectInput from "../SelectInput/SelectInput";

const CustomersSelect = (props: ICustomersSelect) => {
  const { touched, errors, label, name, required } = props;
  const [data, setData] = useState<Array<{ value: string; label: string }>>([]);
  const { id } = useSelector((state: AppState) => state.customerContextState);

  const fetchData = useCallback(async () => {
    try {
      await axios(`customer/${id}`).then((res) => {
        if (res && res.status === 200) {
          const customer = {
            value: res.data.customer.id,
            label: res.data.customer.name,
          };
          const subCustomers = res.data.subCustomers.map((elem: any) => {
            return {
              value: elem.customer.id,
              label: elem.customer.name,
            };
          });
          setData([customer, ...subCustomers]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <SelectInput
      touched={touched}
      errors={errors}
      name={name}
      options={data}
      label={label}
      placeholder="Select Customer"
      isSearchable={true}
      required={required}
      fetchData={fetchData}
    />
  );
};

export default CustomersSelect;

interface ICustomersSelect {
  touched: any;
  errors: any;
  name: string;
  label: string;
  required?: boolean;
  customID?: string;
}
