import React, { useState, useCallback } from "react";
import axios from "../../../api/axiosConfig";
import SelectInput from "../SelectInput/SelectInput";
import { useFormikContext } from "formik";

const AgentLocationGroupSelect = (props: IAgentLocationGroupSelect) => {
  const { id, touched, errors, label, name, required, className } = props;
  const [data, setData] = useState<Array<{ value: string; label: string }>>([]);

  const formValues = useFormikContext();
  // @ts-ignore
  const address = `${formValues.values.country}, ${formValues.values.city}`;

  const fetchData = useCallback(async () => {
    try {
      await axios(`/configuration/agent/${id}/location`).then((res) => {
        if (res && res.status === 200) {
          const includeAddress = res.data.find(
            (elem: string) => elem === address
          );
          const data = res.data.map((elem: string) => {
            return {
              value: elem,
              label: elem,
            };
          });
          if (includeAddress) {
            setData(data);
          } else {
            const sortedData = [
              ...data,
              { value: address, label: address },
            ].sort((a, b) => a.value.localeCompare(b.value));
            setData(sortedData);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, address]);

  return (
    <>
      <SelectInput
        touched={touched}
        errors={errors}
        name={name}
        options={data}
        label={label}
        placeholder="Select Location"
        isSearchable={true}
        required={required}
        className={className}
        fetchData={fetchData}
        hasWatchedFields={true}
      />
    </>
  );
};

export default AgentLocationGroupSelect;

interface IAgentLocationGroupSelect {
  touched: any;
  errors: any;
  name: string;
  label?: string;
  required?: boolean;
  id: string;
  className?: string;
}
