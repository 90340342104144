import React, { useEffect, useState } from "react";
import styles from "./ModalManageDashboardMenu.module.scss";
import MenuElem from "./MenuElem/MenuElem";
import { ReactComponent as IconPlus } from "../../../../assets/icons/plus-thick.svg";
import { ReactComponent as IconMinus } from "../../../../assets/icons/minus-thick.svg";
import MenuElemCustom from "./MenuElemCustom/MenuElemCustom";
import MenuElemReceived from "./MenuElemReceived/MenuElemReceived";
import { PresetT, WidgetT } from "../../../../store/reducers/DashboardPresets";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";

const ModalManageDashboardMenu = (props: IModalManageDashboardMenu) => {
  const {
    modalInside,
    currentWidgets,
    customPresetsList,
    setCurrentWidgets,
    receivedPresetsList,
  } = props;

  const constantPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantPresets
  );

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const checkIfAdded = (widget: WidgetT) => {
    return currentWidgets.find((elem: WidgetT) => elem.id === widget.id);
  };

  const handleAddWidget = (widget: WidgetT, added: any) => {
    if (added)
      setCurrentWidgets(currentWidgets.filter((item) => item.id !== widget.id));
    else setCurrentWidgets([...currentWidgets, widget]);
  };

  useEffect(() => {
    if (modalInside === "customPresets") {
      setActiveIndex(9);
    }
  }, [modalInside]);

  return (
    <div>
      {constantPresets.map((elem: PresetT, key: number) => {
        const widgetsList = elem.widgets;
        return (
          <MenuElem
            key={key}
            index={key}
            active={activeIndex}
            title={`${widgetsList[0].dashboardType.replace(/\+/g, " ")} (${
              widgetsList.length
            })`}
            setActiveIndex={setActiveIndex}
          >
            <div>
              {widgetsList.map((widget: WidgetT, key: number) => {
                return (
                  <div
                    key={key}
                    typeof="button"
                    className={styles.element}
                    onClick={() =>
                      handleAddWidget(widget, checkIfAdded(widget))
                    }
                  >
                    <div className={styles.title}>{widget.title}</div>
                    {modalInside === "widgets" && (
                      <div className={styles.add}>
                        {checkIfAdded(widget) ? (
                          <IconMinus className={styles.icon} />
                        ) : (
                          <IconPlus className={styles.icon} />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </MenuElem>
        );
      })}
      <MenuElemCustom
        title={`Custom Presets (${customPresetsList.length})`}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        {...props}
      />
      <MenuElemReceived
        title={`Received Presets (${receivedPresetsList.length})`}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        {...props}
      />
    </div>
  );
};

export default ModalManageDashboardMenu;

export interface IModalManageDashboardMenu {
  setModalInside: (e: string) => void;
  setSelectedPreset: (preset: { id: string; title: string } | any) => void;
  setCurrentWidgets: (data: WidgetT[]) => void;
  currentWidgets: WidgetT[];
  modalInside: string;
  customPresetsList: PresetT[];
  receivedPresetsList: PresetT[];
}
