import React, { useState } from "react";
import styles from "./ModalSelectCustomerElem.module.scss";
import { ReactComponent as IconChevron } from "../../../../assets/icons/chevron.svg";
import { ReactComponent as IconUser } from "../../../../assets/icons/user-placeholder-sm.svg";
import cn from "classnames";
import ModalSelectCustomerList from "../ModalSelectCustomerList/ModalSelectCustomerList";
import { toBase64 } from "../../../../functions/ToBase64/ToBase64";

const ModalSelectCustomerElem = (props: IModalSelectCustomerElem) => {
  const {
    customer,
    subCustomers,
    isOpen,
    setModalOpen,
    handleContext,
    handleClick,
    isCompany,
    logo,
    Tag = "li",
    disabled,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tag className={cn(styles.element, isOpen && styles["isOpen"])}>
      <div className={styles.userWrapper}>
        <button
          className={cn(styles.user, disabled && styles["isDisabled"])}
          onClick={() => handleContext(customer)}
        >
          <div className={styles.imageWrapper}>
            {logo ? (
              <img src={logo} alt={`${customer.name} logo`} />
            ) : customer.logo ? (
              <img
                src={`data:${customer.logo.mime};base64,${toBase64(
                  customer.logo.content.data
                )}`}
                alt={`${customer.name} logo`}
              />
            ) : (
              <IconUser />
            )}
          </div>
          <div>
            <span className={styles.name}>{customer.name}</span>
            <span className={styles.email}>{customer.email}</span>
          </div>
        </button>
        {subCustomers.length && !isCompany && subCustomers ? (
          <button
            className={styles.button}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <IconChevron
              className={cn(styles.arrow, open && styles["isExpanded"])}
            />
          </button>
        ) : null}
      </div>
      {subCustomers.length && open ? (
        <ModalSelectCustomerElemChildren
          subCustomers={subCustomers}
          isOpen={open}
          setModalOpen={setModalOpen}
          handleContext={handleContext}
          handleClick={handleClick}
        />
      ) : null}
    </Tag>
  );
};

export default ModalSelectCustomerElem;

const ModalSelectCustomerElemChildren = (
  props: IModalSelectCustomerElemChildren
) => {
  const { subCustomers, isOpen, setModalOpen, handleContext, handleClick } =
    props;

  return (
    <ModalSelectCustomerList>
      {subCustomers.map((item, key) => {
        return (
          <ModalSelectCustomerElem
            {...item}
            key={key}
            setModalOpen={() => setModalOpen(false)}
            handleContext={handleContext}
            handleClick={handleClick}
            isOpen={isOpen}
          />
        );
      })}
    </ModalSelectCustomerList>
  );
};

interface IModalSelectCustomerElemChildren {
  subCustomers: IModalSelectCustomerElem["subCustomers"];
  isOpen?: IModalSelectCustomerElem["isOpen"];
  setModalOpen: (e: boolean) => void;
  handleContext: (e: IModalSelectCustomerElem["customer"]) => void;
  handleClick: () => void;
  logo?: {
    content: any;
    id: string;
    mime: string;
  };
}

interface IModalSelectCustomerElem {
  customer: {
    id: string | null;
    name: string | null;
    email: string | null;
    logo?: {
      content: any;
      id: string;
      mime: string;
    };
  };
  subCustomers: Array<any>;
  isOpen?: boolean;
  setModalOpen: (e: boolean) => void;
  handleContext: (e: IModalSelectCustomerElem["customer"]) => void;
  handleClick: () => void;
  isCompany?: boolean;
  Tag?: "li" | "div";
  logo?: string | null;
  disabled?: boolean;
}
