import React, {useEffect, useState} from "react";
import styles from "./Toast.module.scss";
import cn from "classnames";
import {ReactComponent as IconClose} from "../../assets/icons/close.svg";
import {ReactComponent as IconInfo} from "../../assets/icons/info-button.svg";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const Toast = (props: { toastList: IToast[] }) => {

    const {
        toastList
    } = props;

    const [toasts, setToasts] = useState(toastList);

    useEffect(() => {
        setToasts([...toastList])
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastList.length && toasts.length) {
                deleteToast(toastList[0].id);
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, toasts]);

    const deleteToast = (id: number) => {
        const listItemIndex = toasts.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        toasts.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setToasts([...toasts]);
    }

    const createLabel = (type: string) => {
        switch (type) {
            case "message":
                return {
                    type: "Info",
                    icon: <IconInfo/>
                };
            case "error":
                return {
                    type: "Error",
                    icon: <IconInfo/>
                }
        }
    }


    return (
        <TransitionGroup className={styles.element} appear={true}>
            {toasts.map((toast, key) => {
                const label = createLabel(toast.type);
                return (
                    <CSSTransition key={key}
                                   timeout={100}
                                   mountOnEnter
                                   unmountOnExit
                                   in={true}
                    >
                        <div className={cn(styles.toast, toast.type && styles[`is-${toast.type}`])}>
                            {
                                label &&
								<div className={styles.icon}>
                                    {label.icon}
								</div>
                            }
                            <div className={styles.content}>
                                {
                                    label &&
									<div className={styles.type}>{label.type}</div>
                                }
                                {toast.message}
                            </div>
                            <button className={styles.close}>
                                <IconClose/>
                            </button>
                        </div>
                    </CSSTransition>
                )
            })}
        </TransitionGroup>
    )

}

export default Toast;


export interface IToast {
    id: number;
    message: string;
    type: "error" | "message";
}
