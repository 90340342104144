import {
    UserConfirmT,
    UserLoginT,
    ForgetPasswordT,
    RecoverPasswordT,
    TokensT,
    ChangingPasswordT,
    UserAssignRoleT,
} from "../../api/authorization";

export const CONFIRM = "CONFIRM";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_FAILURE = "CONFIRM_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const ASSIGN_ROLE_SUCCESS = "ASSIGN_ROLE_SUCCESS";
export const ASSIGN_ROLE_FAILURE = "ASSIGN_ROLE_FAILURE";

export const FORGET = "FORGET";
export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_FAILURE = "FORGET_FAILURE";

export const RECOVERY = "RECOVERY";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_FAILURE = "RECOVERY_FAILURE";

export const CHECK = "CHECK";
export const CHECK_SUCCESS = "CHECK_SUCCESS";
export const CHECK_FAILURE = "CHECK_FAILURE";

export const RENEW_TOKEN = "RENEW_TOKEN";
export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS";
export const RENEW_TOKEN_FAILURE = "RENEW_TOKEN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE";

export const REMOVE_PHOTO = "REMOVE_PHOTO";
export const REMOVE_PHOTO_SUCCESS = "REMOVE_PHOTO_SUCCESS";
export const REMOVE_PHOTO_FAILURE = "REMOVE_PHOTO_FAILURE";

export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_FAILURE = "UPLOAD_LOGO_FAILURE";

export const REMOVE_LOGO = "REMOVE_LOGO";
export const REMOVE_LOGO_SUCCESS = "REMOVE_LOGO_SUCCESS";
export const REMOVE_LOGO_FAILURE = "REMOVE_LOGO_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const CHECK_USER_AVATAR = "CHECK_USER_AVATAR";
export const CHECK_USER_AVATAR_SUCCESS = "CHECK_USER_AVATAR_SUCCESS";
export const CHECK_USER_AVATAR_FAILURE = "CHECK_USER_AVATAR_FAILURE";

export const CHECK_CUSTOMER_LOGO = "CHECK_CUSTOMER_LOGO";
export const CHECK_CUSTOMER_LOGO_SUCCESS = "CHECK_CUSTOMER_LOGO_SUCCESS";
export const CHECK_CUSTOMER_LOGO_FAILURE = "CHECK_CUSTOMER_LOGO_FAILURE";

export type ConfirmT = {
    type: typeof CONFIRM;
    data: UserConfirmT;
};

type ConfirmSuccessT = {
    type: typeof CONFIRM_SUCCESS;
    payload: any;
};

type ConfirmFailureT = {
    type: typeof CONFIRM_FAILURE;
    error: string;
};

export type LoginT = {
    type: typeof LOGIN;
    data: UserLoginT;
};

type LoginSuccessT = {
    type: typeof LOGIN_SUCCESS;
    payload: any;
};

type LoginFailureT = {
    type: typeof LOGIN_FAILURE;
    error: string;
};

export type AssignRoleT = {
    type: typeof ASSIGN_ROLE;
    data: UserAssignRoleT;
}

export type AssignRoleSuccessT = {
    type: typeof ASSIGN_ROLE_SUCCESS;
    payload: any;
}

export type AssignRoleFailureT = {
    type: typeof ASSIGN_ROLE_FAILURE;
    error: string;
}

export type ForgetT = {
    type: typeof FORGET;
    data: ForgetPasswordT;
};

type ForgetSuccessT = {
    type: typeof FORGET_SUCCESS;
    payload: any;
};

type ForgetFailureT = {
    type: typeof FORGET_FAILURE;
    error: string;
};

export type RecoveryT = {
    type: typeof RECOVERY;
    data: RecoverPasswordT;
};

type RecoverySuccessT = {
    type: typeof RECOVERY_SUCCESS;
    payload: any;
};

type RecoveryFailureT = {
    type: typeof RECOVERY_FAILURE;
    error: string;
};

export type CheckT = {
    type: typeof CHECK;
};

type CheckSuccessT = {
    type: typeof CHECK_SUCCESS;
    payload: any;
};

type CheckFailureT = {
    type: typeof CHECK_FAILURE;
    error: string;
};

export type RenewTokenT = {
    type: typeof RENEW_TOKEN;
};

type RenewTokenSuccessT = {
    type: typeof RENEW_TOKEN_SUCCESS;
    payload: TokensT;
};

type RenewTokenFailureT = {
    type: typeof RENEW_TOKEN_FAILURE;
    error: string;
};

export type LogoutT = {
    type: typeof LOGOUT;
};

type LogoutSuccessT = {
    type: typeof LOGOUT_SUCCESS;
    payload: { userId: null };
};

type LogoutFailureT = {
    type: typeof LOGOUT_FAILURE;
    error: string;
};

export type UploadPhotoT = {
    type: typeof UPLOAD_PHOTO;
    file: File;
};

type UploadPhotoSuccessT = {
    type: typeof UPLOAD_PHOTO_SUCCESS;
    payload: any;
};

type UploadPhotoFailureT = {
    type: typeof UPLOAD_PHOTO_FAILURE;
    error: string;
};

export type RemovePhotoT = {
    type: typeof REMOVE_PHOTO;
};

type RemovePhotoSuccessT = {
    type: typeof REMOVE_PHOTO_SUCCESS;
    editedPhoto: true;
};

type RemovePhotoFailureT = {
    type: typeof REMOVE_PHOTO_FAILURE;
    error: string;
};

export type UploadLogoT = {
    type: typeof UPLOAD_LOGO;
    file: File;
};

type UploadLogoSuccessT = {
    type: typeof UPLOAD_LOGO_SUCCESS;
    payload: any;
};

type UploadLogoFailureT = {
    type: typeof UPLOAD_LOGO_FAILURE;
    error: string;
};

export type RemoveLogoT = {
    type: typeof REMOVE_LOGO;
};

type RemoveLogoSuccessT = {
    type: typeof REMOVE_LOGO_SUCCESS;
    editedPhoto: true;
};

type RemoveLogoFailureT = {
    type: typeof REMOVE_LOGO_FAILURE;
    error: string;
};

export type ChangePasswordT = {
    type: typeof CHANGE_PASSWORD;
    data: ChangingPasswordT;
};

type ChangePasswordSuccessT = {
    type: typeof CHANGE_PASSWORD_SUCCESS;
};

type ChangePasswordFailureT = {
    type: typeof CHANGE_PASSWORD_FAILURE;
    error: string;
};

export type UpdateProfileT = {
    type: typeof UPDATE_PROFILE;
    data: ChangingPasswordT;
};

type UpdateProfileSuccessT = {
    type: typeof UPDATE_PROFILE_SUCCESS;
};

type UpdateProfileFailureT = {
    type: typeof UPDATE_PROFILE_FAILURE;
    error: string;
};

export type UpdateCustomerT = {
    type: typeof UPDATE_CUSTOMER;
    data: ChangingPasswordT;
};

type UpdateCustomerSuccessT = {
    type: typeof UPDATE_CUSTOMER_SUCCESS;
};

type UpdateCustomerFailureT = {
    type: typeof UPDATE_CUSTOMER_FAILURE;
    error: string;
};

export type CheckUserAvatarT = {
    type: typeof CHECK_USER_AVATAR;
    data: ChangingPasswordT;
};

type CheckUserAvatarSuccessT = {
    type: typeof CHECK_USER_AVATAR_SUCCESS;
    payload: any;
};

type CheckUserAvatarFailureT = {
    type: typeof CHECK_USER_AVATAR_FAILURE;
    error: string;
};

export type CheckCustomerLogoT = {
    type: typeof CHECK_CUSTOMER_LOGO;
    data: ChangingPasswordT;
};

type CheckCustomerLogoSuccessT = {
    type: typeof CHECK_CUSTOMER_LOGO_SUCCESS;
    payload: any;
};

type CheckCustomerLogoFailureT = {
    type: typeof CHECK_CUSTOMER_LOGO_FAILURE;
    error: string;
};

export type ActionTypeT =
    | ConfirmT
    | ConfirmSuccessT
    | ConfirmFailureT
    | LoginT
    | LoginSuccessT
    | LoginFailureT
    | AssignRoleT
    | AssignRoleFailureT
    | ForgetT
    | ForgetSuccessT
    | ForgetFailureT
    | RecoveryT
    | RecoverySuccessT
    | RecoveryFailureT
    | CheckT
    | CheckSuccessT
    | CheckFailureT
    | RenewTokenT
    | RenewTokenSuccessT
    | RenewTokenFailureT
    | LogoutT
    | LogoutSuccessT
    | LogoutFailureT
    | UploadPhotoT
    | UploadPhotoSuccessT
    | UploadPhotoFailureT
    | RemovePhotoT
    | RemovePhotoSuccessT
    | RemovePhotoFailureT
    | UploadLogoT
    | UploadLogoSuccessT
    | UploadLogoFailureT
    | RemoveLogoT
    | RemoveLogoSuccessT
    | RemoveLogoFailureT
    | ChangePasswordT
    | ChangePasswordSuccessT
    | ChangePasswordFailureT
    | UpdateProfileT
    | UpdateProfileSuccessT
    | UpdateProfileFailureT
    | UpdateCustomerT
    | UpdateCustomerSuccessT
    | UpdateCustomerFailureT
    | CheckUserAvatarT
    | CheckUserAvatarSuccessT
    | CheckUserAvatarFailureT
    | CheckCustomerLogoT
    | CheckCustomerLogoSuccessT
    | CheckCustomerLogoFailureT

export type StateT = {
    apiUrl: string | null;
    loading: boolean;
    loaded: boolean;
    id: string | null;
    name: string | null;
    error: string | null;
    email: string | null;
    contactNumber: string | null;
    description: string | null;
    message: string | null;
    photoLink: string | null;
    photoType: string | null;
    photoBlob: Blob | null;
    photoLinkCustomer: string | null;
    photoTypeCustomer: string | null;
    photoBlobCustomer: Blob | null;
    address: string | null;
    role: null;
    permissions: {
        organization: {
            data: boolean | null,
            fullAccess: boolean | null
        },
        structure: {
            data: boolean | null,
            fullAccess: boolean | null
        }
    };
    management: Array<string> | null;
    dashboard: Array<string> | null;
    configuration: Array<string> | null;
    customer: {
        id: string | null,
        name: string | null,
        email: string | null,
        contactNumber: string | null;
        description: string | null;
        delayTarget: number | null;
        jitterTarget: number | null;
        lossTarget: number | null;
        bandwidthSpeedTarget: number | null;
        availabilityTarget: number | null;
        mainCustomer: boolean | null
    };
    settings: any[]
    subcustomers: any
};

const initialState: StateT = {
    apiUrl: null,
    loading: false,
    loaded: false,
    id: null,
    name: null,
    message: null,
    error: null,
    email: null,
    contactNumber: null,
    description: null,
    photoLink: null,
    photoType: null,
    photoBlob: null,
    photoLinkCustomer: null,
    photoTypeCustomer: null,
    photoBlobCustomer: null,
    address: null,
    role: null,
    permissions: {
        organization: {
            data: null,
            fullAccess: null
        },
        structure: {
            data: null,
            fullAccess: null
        }
    },
    management: null,
    dashboard: null,
    configuration: null,
    customer: {
        id: null,
        name: null,
        email: null,
        contactNumber: null,
        description: null,
        delayTarget: null,
        jitterTarget: null,
        lossTarget: null,
        bandwidthSpeedTarget: null,
        availabilityTarget: null,
        mainCustomer: null,
    },
    settings: [],
    subcustomers: []
};

export default function authorizationReducer (
    state: StateT = initialState,
    action: ActionTypeT
): StateT {
    switch ( action.type ) {
        case CONFIRM:
        case LOGIN:
        case FORGET:
        case RECOVERY:
        case CHECK:
        case RENEW_TOKEN:
        case ASSIGN_ROLE:
        case UPDATE_PROFILE:
        case CHECK_USER_AVATAR:
        case CHECK_CUSTOMER_LOGO:
        case LOGOUT:
            return { ...state, loading: true, message: null, error: null };
        case UPLOAD_PHOTO:
        case REMOVE_PHOTO:
        case UPLOAD_LOGO:
        case REMOVE_LOGO:
        case CHANGE_PASSWORD:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case CONFIRM_SUCCESS:
        case LOGIN_SUCCESS:
        case FORGET_SUCCESS:
        case RECOVERY_SUCCESS:
        case CHECK_SUCCESS:
        case RENEW_TOKEN_SUCCESS:
        case CHECK_USER_AVATAR_SUCCESS:
        case CHECK_CUSTOMER_LOGO_SUCCESS:
        case LOGOUT_SUCCESS:
            return { ...state, loading: false, ...action.payload };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, loaded: true };
        case UPLOAD_PHOTO_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }
        case UPLOAD_LOGO_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }
        case CONFIRM_FAILURE:
        case LOGIN_FAILURE:
            return { ...state, loading: false, loaded: true, error: action.error };
        case FORGET_FAILURE:
        case RECOVERY_FAILURE:
            return { ...state, loading: false, loaded: true, error: action.error };
        case CHECK_FAILURE:
        case RENEW_TOKEN_FAILURE:
        case LOGOUT_FAILURE:
        case UPLOAD_PHOTO_FAILURE:
        case REMOVE_PHOTO_FAILURE:
        case UPLOAD_LOGO_FAILURE:
        case REMOVE_LOGO_FAILURE:
        case ASSIGN_ROLE_FAILURE:
        case CHANGE_PASSWORD_FAILURE:
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, loading: false, loaded: true, message: "Profile settings changed" };
        case UPDATE_CUSTOMER_SUCCESS:
            return { ...state, loading: false, loaded: true, message: "Company settings changed" };
        case UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CHECK_USER_AVATAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                photoLink: ''
            };
        case CHECK_CUSTOMER_LOGO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                photoLinkCustomer: ''
            };
        default:
            return state;
    }
}

export function confirmUser ( data: UserConfirmT ) {
    return {
        type: CONFIRM,
        data,
    };
}

export function loginUser ( data: UserLoginT ) {
    return {
        type: LOGIN,
        data,
    };
}

export function assignRole ( data: UserAssignRoleT ) {
    return {
        type: ASSIGN_ROLE,
        data,
    }
}

export function logOut () {
    return {
        type: LOGOUT,
    };
}

export function forgetPassword ( data: ForgetT ) {
    return {
        type: FORGET,
        data,
    };
}

export function checkUser () {
    return {
        type: CHECK,
    };
}

export function renewToken () {
    return {
        type: RENEW_TOKEN,
    };
}

export function recoveryPassword ( data: RecoveryT ) {
    return {
        type: RECOVERY,
        data,
    };
}

export function uploadPhoto ( file: FormData ) {
    return {
        type: UPLOAD_PHOTO,
        file,
    };
}

export function removePhoto () {
    return {
        type: REMOVE_PHOTO,
    };
}
export function uploadLogo ( file: FormData, id: string ) {
    return {
        type: UPLOAD_LOGO,
        file,
        id
    };
}

export function removeLogo () {
    return {
        type: REMOVE_LOGO,
    };
}

export function changePassword ( data: ChangingPasswordT ) {
    return {
        type: CHANGE_PASSWORD,
        data,
    };
}

export function updateProfile ( data: any ) {
    return {
        type: UPDATE_PROFILE,
        data
    }
}

export function updateCustomer ( data: any ) {
    return {
        type: UPDATE_CUSTOMER,
        data
    }
}


export function checkUserAvatar () {
    return {
        type: CHECK_USER_AVATAR,
    };
}
