export const GET_USERS = "GET_USERS";
export const GET_USERS_DONE = "GET_USERS_DONE";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GetUsers = (id: string) => ({
  type: GET_USERS,
  id,
});

export const GetUsersDone = (data: any) => ({
  type: GET_USERS_DONE,
  payload: data,
});

export const GetUsersFailed = (error: any) => ({
  type: GET_USERS_FAILED,
  error,
});
