export const GetApplicationMs365Dashboard = () => [
  {
    title: "Overall EUX performance score for Ms365",
    id: "app2-gauge-results-old",
    type: "gauge-results-old",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Important Metrics",
    id: "app2-important-metrics-old",
    type: "important-metrics-old",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Breakdown of Office 365 services delivered by performance",
    id: "app2-gauge-half-office365",
    type: "gauge-half-old",
    dashboardType: "app2",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Auto discovery time",
    id: "app2-most-active-discovery",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Open Mailbox time",
    id: "app2-most-active-mailbox",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Create email time",
    id: "app2-most-active-email",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Create meeting time",
    id: "app2-most-active-meeting",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Send item time",
    id: "app2-most-active-item-send",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Download item time",
    id: "app2-most-active-download",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Login time",
    id: "app2-most-active-login",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "OneDrive performance time",
    id: "app2-most-active-onedrive",
    type: "most-active",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "App performance - best location",
    id: "app2-column-line-chart-old-app-best-location",
    type: "column-line-chart-old",
    dashboardType: "app2",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "App performance - worse location",
    id: "app2-column-line-chart-old-app-worse-location",
    type: "column-line-chart-old",
    dashboardType: "app2",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Location performance - best location",
    id: "app2-column-line-chart-old-best-location",
    type: "column-line-chart-old",
    dashboardType: "app2",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Location performance - worse location",
    id: "app2-column-line-chart-old-worse-location",
    type: "column-line-chart-old",
    dashboardType: "app2",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Path for Office 365 service",
    id: "app2-path-analytics-office365",
    type: "path-analytics-old",
    dashboardType: "app2",
    size: 3,
    // availableSizes: [3, 4],
  },
  {
    title: "Important Metrics",
    id: "app2-important-metrics-old-2",
    type: "important-metrics-old",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Office 365 performnce heatmap",
    id: "app2-heatmap-office365",
    type: "heatmap",
    dashboardType: "app2",
    size: 3,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Availability",
    id: "app2-gauge-results-old-availability",
    type: "gauge-results-old",
    dashboardType: "app2",
    size: 1,
    // availableSizes: [1, 2],
  },
];
