export const SET_CURRENT_DASHBOARD = "SET_CURRENT_DASHBOARD";
export const SET_CURRENT_DASHBOARD_DONE = "SET_CURRENT_DASHBOARD_DONE";
export const SET_CURRENT_DASHBOARD_FAILED = "SET_CURRENT_DASHBOARD_FAILED";
export const GET_PRESETS = "GET_PRESETS";
export const GET_PRESETS_DONE = "GET_PRESETS_DONE";
export const GET_PRESETS_FAILED = "GET_PRESETS_FAILED";
export const SET_CUSTOM_PRESETS = "SET_CUSTOM_PRESETS";
export const SET_CUSTOM_PRESETS_DONE = "SET_CUSTOM_PRESETS_DONE";
export const SET_CUSTOM_PRESETS_FAILED = "SET_CUSTOM_PRESETS_FAILED";
export const DELETE_PRESETS = "DELETE_PRESETS";
export const DELETE_PRESETS_DONE = "DELETE_PRESETS_DONE";
export const DELETE_PRESETS_FAILED = "DELETE_PRESETS_FAILED";
export const SHARE_CUSTOM_PRESET = "SHARE_CUSTOM_PRESET";
export const UNSHARE_DEFAULT_PRESET = "UNSHARE_DEFAULT_PRESET";
export const ADD_TO_CUSTOM_PRESETS = "ADD_TO_CUSTOM_PRESETS";


export type CurrentPresetT = {
  userId: string | null,
  settingId?: string,
  settings: {
    title: string;
    id: string;
  },
  settingsType: "currentDashboardPreset",
  defaultSettings: boolean
}

export type SetCurrentDashboardT = {
  type: typeof SET_CURRENT_DASHBOARD;
  preset: CurrentPresetT;
}

export type SetCurrentDashboardDoneT = {
  type: typeof SET_CURRENT_DASHBOARD_DONE;
  payload: any;
}

export type SetCurrentDashboardFailedT = {
  type: typeof SET_CURRENT_DASHBOARD_FAILED;
}
