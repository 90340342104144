import React, { useState, useEffect } from "react";
import styles from "./TimePicker.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = (props: ITimePicker) => {
  const { initialTime, handleTime, startTime, endNewValue } = props;
  const [time, setTime] = useState<Date>(initialTime);

  const filterPassedTime = (time: any) => {
    const currentDate = new Date(startTime);
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    handleTime(time);
    if (endNewValue) {
      setTime(endNewValue);
    }
  }, [time, handleTime, endNewValue]);

  return (
    <div className={styles.wrapper}>
      <DatePicker
        className={styles.element}
        selected={time}
        onChange={(date: Date) => setTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        popperPlacement="bottom"
        popperClassName={styles.popper}
        filterTime={startTime && filterPassedTime}
      />
    </div>
  );
};

export default TimePicker;

interface ITimePicker {
  initialTime: Date;
  handleTime: (e: any) => void;
  endNewValue?: Date | null;
  startTime?: any;
}
