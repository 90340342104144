import React from "react";
import { Form, Formik } from "formik";
import styles from "./ModalExportReport.module.scss";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/file.svg";

interface IModalExportReport {
  setModalOpen: (e: boolean) => void;
}

const ModalExportReport = (props: IModalExportReport) => {
  const { setModalOpen } = props;

  const initialValues = {
    format: "",
  };

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
            setModalOpen(false);
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <SelectInput
              touched={touched}
              errors={errors}
              name={"format"}
              label={"format"}
              options={[
                { value: "csv", label: "CSV" },
                { value: "pdf", label: "PDF" },
                { value: "xml", label: "XML" },
              ]}
              placeholder={"Select format"}
              isSearchable={false}
              required
              onTop
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Export"
                typeAttr="submit"
                icon={
                  <IconButton
                    className={styles.icon}
                    width="1.2rem"
                    height="1.5rem"
                  />
                }
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalExportReport;
