import {takeEvery} from "redux-saga/effects";
import {TOGGLE_NOTIFICATIONS_BAR} from "../reducers/notifications";


export const toggleNotificationsBar = () => ({
    type: TOGGLE_NOTIFICATIONS_BAR,
});

export default function* notifications() {
    yield takeEvery(TOGGLE_NOTIFICATIONS_BAR, toggleNotificationsBar);
}
