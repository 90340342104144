export const checkIfHortiumAdmin = (
  permissions: any,
  hortium: boolean | null
) => {
  return (
    hortium &&
    permissions.organization.fullAccess &&
    permissions.structure.fullAccess
  );
};
