import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container } from "../../components/Bootstrap/Bootstrap";
import styles from "../SingleAgent/SingleAgent.module.scss";
import { AppState } from "../../store/reducers";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import SingleDeviceForm from "./SingleDeviceForm/SingleDeviceForm";
import Loader from "../../components/Loader/Loader";
import SingleAgentFormNoEdit from "./SingleDeviceFormNoEdit/SingleDeviceFormNoEdit";
import { CleanDeviceDetails, GetDevice } from "../../store/actions/Device";

const SingleDeviceView = () => {
  let { id } = useParams<{ id: string }>();

  const [isMounted, setIsMounted] = useState(false);

  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const contextId = useSelector(
    (state: AppState) => state.customerContextState.id
  );
  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : contextId;
  const { data } = useSelector((state: AppState) => state.DeviceState);

  const isInitialMount = useRef(true);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (customerContextId) {
        history.push("/devices-management/added");
      }
    }
  }, [customerContextId, history]);

  useEffect(() => {
    setIsMounted(true);
    setTimeout(() => {
      isMounted && id && dispatch(GetDevice(id));
    }, 1000);
    return () => {
      dispatch(CleanDeviceDetails());
      setIsMounted(false);
    };
  }, [dispatch, id, isMounted]);

  return (
    <Container className={styles.container}>
      {data ? (
        checkEditAccess(permissions, customer, contextId) ? (
          <SingleDeviceForm
            data={data}
            handleUpdateData={() => dispatch(GetDevice(id))}
          />
        ) : (
          <SingleAgentFormNoEdit data={data} />
        )
      ) : (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default SingleDeviceView;
