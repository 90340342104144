import React from "react";
import styles from "./SingleAccount.module.scss";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/check.svg";
import { ReactComponent as IconDownload } from "../../assets/icons/download.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import { useParams } from "react-router-dom";
// import Users from "../../widgets/Users/Users";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
// import Modal from "../../components/Modal/Modal";
// import ModalInviteUser from "../../components/Modal/ModalInviteUser/ModalInviteUser";

const SingleAccountView = () => {
  let { id } = useParams<{ id: string }>();

  // const [data] = useState([
  // {
  //   user: {
  //     name: "John Doe",
  //     email: "john.doe@fireart.studio",
  //     photoLink: "https://randomuser.me/api/portraits/men/94.jpg",
  //     link: "/account/fireart-studio/john-doe",
  //   },
  //   customers: "1 Customer",
  //   role: "User",
  //   phone: "(480) 555-0103",
  //   expireDate: 1626874290,
  //   status: "active",
  // },
  // {
  //   user: {
  //     name: "James Doe",
  //     email: "james.doe@fireart.studio",
  //     photoLink: "https://randomuser.me/api/portraits/men/78.jpg",
  //     link: "/account/fireart-studio/james-doe",
  //   },
  //   customers: "1 Customer",
  //   role: "Admin",
  //   phone: "(480) 555-0103",
  //   expireDate: 1626874290,
  //   status: "active",
  // },
  // ]);

  return (
    <>
      <Container fluid>
        <LayoutHeader pathBack="/user-management" title={`${id}`}>
          <Button
            type="plain"
            icon={<IconDownload />}
            icon_position="right"
            title="Download Account Info"
            className={styles.button}
          />
          <Button title="Save" icon={<IconButton />} icon_position="right" />
        </LayoutHeader>
        <div className="d-flex align-items-center mb-5">
          <FilterTabs className="mb-0 mr-3" controls={["Users", "Customers"]} />
          <Button
            title="+Add"
            type="outline"
            // handleClick={() => setModalOpen(true)}
          />
        </div>
        {/* <Users data={data} /> */}
      </Container>
      {/* <Modal
        title="Invite People"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalInviteUser
          setModalOpen={setModalOpen}
          // roleOptions={[
          //   {
          //     value: "user",
          //     label: "User",
          //   },
          //   {
          //     value: "customer",
          //     label: "Customer",
          //   },
          // ]}
        />
      </Modal> */}
    </>
  );
};

export default SingleAccountView;
