
import {all, put, takeEvery} from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import {GetPathAnalyticsDone, GetPathAnalyticsFailed} from "../../actions/widgets/PathAnalytics.action";
import {GetPathAnalyticsT, GET_PATH_ANALYTICS} from "../../types/widgets/PathAnalytics.types";


function* GetPathAnalyticsHandler({params}: GetPathAnalyticsT) {
  try {

    const {status, data} = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
        startHour: params.startHour,
        endHour: params.endHour,
        ...(params.isTop === null || params.isTop === undefined ? {}: {isTop: params.isTop}),
        ...(params.userId === null || params.userId === undefined ? {}: {userId: params.userId}),
        ...(params.isTraceroute === null || params.isTraceroute === undefined ? {}: {isTraceroute: params.isTraceroute}),
        ...(params.url && { url: params.url }),
        ...(params.location && { location: params.location }),
        ...(params.agentId && { agentId: params.agentId })
      },
    })
    if (status === 204) {
      yield put(GetPathAnalyticsDone(params.chartType, null));
    } else if (status === 200) {
      yield put(GetPathAnalyticsDone(params.chartType, data));
    }
  } catch (error) {
    yield put(GetPathAnalyticsFailed(params.chartType));
  }
}


export default function* WatchPathAnalytics() {
  yield all([
    takeEvery(GET_PATH_ANALYTICS, GetPathAnalyticsHandler)
  ]);
}
