import React, { useEffect, useState, useCallback } from "react";
import styles from "./Features.module.scss";
import cn from "classnames";
import { Field, FieldProps } from "formik";
import axios from "../../../api/axiosConfig";

const Features = (props: IFeatures) => {
  const { name, label, touched, errors } = props;

  const [fields, setFields] = useState<IFeaturesField[]>([]);

  const fetchData = useCallback(async () => {
    try {
      await axios("/configuration/features").then((res) => {
        if (res && res.status === 200) {
          setFields(
            res.data.map((elem: { id: string; code: string; name: string }) => {
              return {
                id: elem.id,
                title: elem.name,
              };
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  return (
    <div className={styles.wrapper}>
      {label && <span className={styles.title}>{label}</span>}
      <div
        className={styles.element}
        role="group"
        aria-labelledby="checkbox-group"
      >
        {fields &&
          fields.map((item, key) => {
            return (
              <Field
                component={FeaturesField}
                key={key}
                {...item}
                name={name}
                touched={touched}
                errors={errors}
                value={item.id}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Features;

const FeaturesField = (props: IFeaturesField) => {
  const { field, value, title, form } = props;

  const handleInitValue = () => {
    return (
      form.values[field.name] &&
      form.values[field.name].some((item: any) => item === value)
    );
  };

  const [active, setActive] = useState(handleInitValue);

  const handleClick = (e: any) => {
    setActive(!active);
    field.onChange(e);
  };

  return (
    <div className={cn(styles.fieldWrapper, active && styles["isActive"])}>
      <label className={styles.label}>
        <input
          type="checkbox"
          name={field.name}
          value={value}
          onChange={(e) => handleClick(e)}
        />
        {title}
      </label>
    </div>
  );
};

export interface IFeaturesField extends FieldProps {
  title: string;
  id: string;
  item: { id: string; title: string };
  value: string;
}

export interface IFeatures {
  label?: string;
  errors: any;
  touched: any;
  direction?: "row" | "column" | "grid";
  name: string;
}
