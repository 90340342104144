import React from "react";
import styles from "./CustomPresetsList.module.scss";
import CustomPresetsListElem from "./CustomPresetsListElem/CustomPresetsListElem";
import { PresetT } from "../../../../../store/reducers/DashboardPresets";

const CustomPresetsList = (props: ICustomPresetsList) => {
  const { customPresetsList } = props;

  return (
    <div className={styles.element}>
      {customPresetsList.length > 0 ? (
        customPresetsList.map((elem: PresetT, key: number) => {
          return (
            <li className={styles.listItem} key={key}>
              <CustomPresetsListElem preset={elem} index={key} {...props} />
            </li>
          );
        })
      ) : (
        <div className={styles.empty}>
          <span>You have no custom presets.</span>
        </div>
      )}
    </div>
  );
};

export default CustomPresetsList;

interface ICustomPresetsList {
  showCustomPresetWidgets: (data: PresetT) => void;
  handleDeleteCustomPreset: (index: number) => void;
  customPresetsList: PresetT[];
}
