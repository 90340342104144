import React from "react";
import moment from "moment";

const TableCellDate = (props: ITableCellDate) => {
  const { children } = props;

  return (
    <span>{children ? moment.unix(children).format("DD/MM/YY") : "-"}</span>
  );
};

export default TableCellDate;

export interface ITableCellDate {
  children?: any;
}
