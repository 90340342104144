import React from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import { EmployeeT } from "../../store/types/Employees";
import TableCellId, {
  ITableCellId,
} from "../../components/tableElements/TableCellId/TableCellId";
import { TableSortByName } from "../../functions/TableSortByName/TableSortByName";

const Employees = (props: IEmployees) => {
  const { data, noData } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "employee",
        sortType: TableSortByName,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellId } }) => (
          <TableCellId {...value} />
        ),
      },
      {
        Header: "Number",
        accessor: "number",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Account Enabled",
        accessor: "accountEnabled",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Given Name",
        accessor: "givenName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Surname",
        accessor: "surname",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "User Type",
        accessor: "userType",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "City",
        accessor: "city",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Office Location",
        accessor: "officeLocation",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Company Name",
        accessor: "companyName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Department",
        accessor: "department",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "User Principal Name",
        accessor: "userPrincipalName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Mail",
        accessor: "mail",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Mobile Phone",
        accessor: "mobilePhone",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Preferred Language",
        accessor: "preferredLanguage",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Business Phones",
        accessor: "businessPhones",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "IM Addresses",
        accessor: "imAddresses",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "License",
        accessor: "license",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 1",
        accessor: "attribute1",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 2",
        accessor: "attribute2",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 3",
        accessor: "attribute3",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 4",
        accessor: "attribute4",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 5",
        accessor: "attribute5",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 6",
        accessor: "attribute6",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 7",
        accessor: "attribute7",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 8",
        accessor: "attribute8",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 9",
        accessor: "attribute9",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 10",
        accessor: "attribute10",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 11",
        accessor: "attribute11",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 12",
        accessor: "attribute12",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 13",
        accessor: "attribute13",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 14",
        accessor: "attribute14",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Attribute 15",
        accessor: "attribute15",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Agent Id",
        accessor: "agentId",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Agent Name",
        accessor: "agentName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Last Update Test",
        accessor: "lastUpdateTest",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ReactTable columns={columns} data={data} noData={noData} />
    </>
  );
};

export default Employees;

interface IEmployees {
  data: Array<EmployeeT>;
  noData: boolean;
}
