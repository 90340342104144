import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetConferenceCallsTableDone, GetConferenceCallsTableFailed } from "../../actions/widgets/ConferenceCalls";
import { GetConferenceCallsTableT, GET_TABLE_CONFERENCE_CALLS } from "../../types/widgets/ConferenceCallsTable.type";

function* GetConferenceCallsTableHandler({ params }: GetConferenceCallsTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate
      }
    });

    if (data.length) {
      yield put(GetConferenceCallsTableDone(data));
    } else {
      yield put(GetConferenceCallsTableDone([]));
    }
  } catch (error) {
    yield put(GetConferenceCallsTableFailed());
  }
}

export default function* WatchConferenceCallsTable() {
  yield all([takeEvery(GET_TABLE_CONFERENCE_CALLS, GetConferenceCallsTableHandler)]);
}
