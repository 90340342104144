import React, { useCallback, useEffect, useState } from "react";
import styles from "./Notifications.module.scss";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import DateRangePicker from "../../components/RangePicker/RangePicker";
import NotificationsItem, {
  INotificationsItem,
} from "./NotificationsItem/NotificationsItem";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
import _ from "lodash";
import moment from "moment";

import axios from "../../api/axiosDashboard";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

const NotificationsView = () => {
  const { start, end } = useSelector((state: AppState) => state.dateRangeState);

  const [initialView, setInitialView] = useState<any[]>([]);
  const [view, setView] = useState<IView[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: start,
    endDate: end,
  });

  const fetchData = useCallback(
    async (endpoint: string) => {
      try {
        return await axios(endpoint, {
          params: dateRange,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [dateRange]
  );

  useEffect(() => {
    fetchData("/notifications").then((res) => {
      console.log(res);
      if (res && res.status === 200) {
        const groups = _.groupBy(res.data, function (elem) {
          return moment(elem.date * 1000)
            .startOf("day")
            .format("MM/DD/YYYY");
        });

        //TODO: Endpoint with dateRange params returns always the same part of notifications with fixed date
        const result = _.map(groups, function (group, day) {
          return {
            day: day,
            elements: group,
          };
        });
        setInitialView(result);
        setView(result);
      }
    });
  }, [fetchData]);

  const handleFilter = (type: string) => {
    let s = type;

    switch (type) {
      case "notifications":
        s = "invitation_accepted";
        break;
      case "alerts":
        s = "alert";
        break;
      default:
        s = "all";
        break;
    }

    if (s !== "all") {
      let t: IView[] = [];

      initialView.map((elem) => {
        t.push({
          day: elem.day,
          elements: _.filter(elem.elements, { type: s }),
        });
        return null;
      });
      setView(t);
    } else {
      setView(initialView);
    }
  };

  const handleChange = (e: moment.MomentInput[]) => {
    setDateRange({
      startDate: moment(e[0]).unix(),
      endDate: moment(e[1]).unix(),
    });
  };

  return (
    <Container fluid>
      <LayoutHeader title="Notifications">
        <DateRangePicker handleChange={handleChange} />
      </LayoutHeader>
      <FilterTabs
        handleClick={handleFilter}
        controls={["All", "Alerts", "Notifications"]}
      />
      <div className={styles.wrapper}>
        {view.length > 0 ? (
          view.map((v, key) => {
            return (
              <table key={key} className={styles.element}>
                <thead>
                  <tr>
                    <th className={styles.headerCell}>
                      <span className={styles.date}>{v.day}</span>
                      <div className="ml-auto">
                        <Button title="Mark All As Read" type="outline" />
                        <Button
                          title="Delete All"
                          type="outline"
                          color="orange"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {v.elements &&
                    v.elements.map((item, key: number) => {
                      return <NotificationsItem key={key} {...item} />;
                    })}
                </tbody>
              </table>
            );
          })
        ) : (
          <Loader className="m-auto" />
        )}
      </div>
    </Container>
  );
};

export default NotificationsView;

interface IView {
  day: string;
  elements: INotificationsItem[];
}
