import { ComparisonBoxActionT, GET_COMPARISON_BOX, GET_COMPARISON_BOX_DONE, GET_COMPARISON_BOX_FAILED } from "../../types/widgets/ComparisonBox.types";


export const initialState: any = {};

const ComparisonBoxState = (
  state = initialState,
  action: ComparisonBoxActionT
) => {
  switch ( action.type ) {
    case GET_COMPARISON_BOX:
      return { ...state, [action.params.chartType]: { data: null, loading: true, error: false } };
    case GET_COMPARISON_BOX_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_COMPARISON_BOX_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default ComparisonBoxState;
