export const SET_SETTINGS = "SET_SETTINGS";


export type SetSettingsT = {
  type: "SET_SETTINGS";
  data: SettingsT[]
};

export type StateT = SettingsT[];

export type SettingsT = {
  id: string;
  userId: string;
  defaultSettings: boolean;
  settingsType: "currentDashboardPreset";
  settings: {
    title: string;
    id: string;
  };
};

const initialState: StateT = []

export default function SettingsState ( state = initialState, action: SetSettingsT ): StateT {
  if ( action.type === SET_SETTINGS ) {
    return action.data;
  }
  return state;
}
