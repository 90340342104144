import React from "react";
import styles from "./Panel.module.scss";
import cn from "classnames";

const Panel = (props: IPanel) => {
    const {
        children,
        className,
        size,
        padding = true
    } = props;

    return(
        <div className={cn(styles.element, className, size && styles[`hasSize-${size}`], !padding && styles['noPadding'])}>
            {children}
        </div>
    )
}
export default Panel;

export interface IPanel {
    children: any;
    className?: string;
    size?: 2 | 3 | 4;
    padding?: boolean;
}
