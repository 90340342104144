import {SET_CUSTOMER_CONTEXT} from "../reducers/customerContext";
import {takeEvery} from "redux-saga/effects";


export const setCustomerContext = (action: any) => {
    return(
        {
            type: SET_CUSTOMER_CONTEXT,
            data: action
        }
    )
}


export default function* customerContext() {
    yield takeEvery(SET_CUSTOMER_CONTEXT, setCustomerContext);
}
