import {
  GaugeResultsParamsT,
  GaugeResultsT,
  GetGaugeResultsDoneT,
  GetGaugeResultsFailedT,
  GetGaugeResultsT,
  GET_GAUGE_RESULTS,
  GET_GAUGE_RESULTS_DONE,
  GET_GAUGE_RESULTS_FAILED,
} from "../../types/widgets/GaugeResults.types";

export const GetGaugeResults = (
  params: GaugeResultsParamsT
): GetGaugeResultsT => ( {
  type: GET_GAUGE_RESULTS,
  params,
} );

export const GetGaugeResultsDone = (
  chartType: string,
  data: GaugeResultsT
): GetGaugeResultsDoneT => ( {
  type: GET_GAUGE_RESULTS_DONE,
  chartType,
  data,
} );

export const GetGaugeResultsFailed = ( chartType: string ): GetGaugeResultsFailedT => ( {
  type: GET_GAUGE_RESULTS_FAILED,
  chartType,
} );