/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import axiosConfig from "../../../api/axiosConfig";
import styles from "./ModalAddNewApi.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import DatePicker from "../../formElements/DatePicker/DatePicker";
import Label from "../../Label/Label";
import Group from "../../CustomFields/Group/Group";
import StatusField from "../../StatusField/StatusField";
import { customerContextSelector } from "../../../selectors";
import { useSelector } from "react-redux";
import moment from "moment";
import ModalError from "../ModalError/ModalError";
import SelectInput from "../../formElements/SelectInput/SelectInput";

interface IModalAddNewApi {
  newConnection: boolean;
  setNewConnection: (e: boolean) => void;
  setModalOpen: (e: boolean) => void;
  setSelectedAPI: (e: any) => void;
  handleAdd: () => void;
  api?: IApiValues | null;
}

const ModalAddNewApi = (props: IModalAddNewApi) => {
  const { setModalOpen, api, setNewConnection, newConnection, handleAdd, setSelectedAPI } = props;

  const isEditing = api && !newConnection;
  const [sent, setSent] = useState(false);
  const [notSent, setNotSent] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [testNameOptions, setTestNameOptions] = useState([]);

  const customerContextState = useSelector(customerContextSelector);

  const fetchData = async () => {
    try {
      await axiosConfig(`/configuration/test`).then((res) => {
        if (res && res.status === 200) {
          setTestNameOptions(
            res.data.map((test: any) => ({
              label: test.aliesName,
              value: test.name,
            }))
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      setSelectedAPI(null);
    }
  }, []);

  const initialValues = {
    name: "",
    aliesName: "",
    customerId: customerContextState.id,
    tenantId: "",
    agentId: "",
    appId: "",
    secretKey: "",
    apiKey: "",
    startDate: "",
    apiUrl: "",
    description: "",
    slaTransactionTime: "",
    slaEUX: "",
    slaAvailability: "",
    slaCostPerMinDowntime: "",
  };

  const handleSubmit = async (values: any) => {
    try {
      if (isEditing) {
        await axiosConfig
          .put(`/configuration/api`, {
            ...values,
            ...{ startDate: moment(values.startDate).unix() },
          })
          .then((res) => {
            if (res && res.status === 200) {
              setUpdated(true);
            }
          });
      } else {
        await axiosConfig
          .post(`/configuration/api`, {
            ...values,
            ...{ startDate: moment(values.startDate).unix() },
          })
          .then((res) => {
            if (res && res.status === 200) {
              setSent(true);
            }
          });
      }
    } catch (error: any) {
      setNotSent(true);
    }
  };

  useEffect(() => {
    if (sent || updated) {
      handleAdd();
      setSelectedAPI(null);
      setTimeout(() => {
        setSent(false);
        setNotSent(false);
        setUpdated(false);
      }, 4000);
    }
  }, [sent, notSent, updated]);

  const closeModal = () => {
    setModalOpen(false);
    setNewConnection(false);
  };

  if (updated) {
    return <ModalSuccess text={`API was successfully updated`} />;
  }

  if (sent) {
    return <ModalSuccess text={`API has been added`} />;
  }

  if (notSent) {
    return <ModalError text={`Something is wrong, please try again later`} />;
  }

  return (
    <div className={styles.content}>
      <Formik
        initialValues={isEditing ? api : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <SelectInput
              touched={touched}
              errors={errors}
              name="name"
              options={testNameOptions}
              label=" API name"
              placeholder="Select API"
              isSearchable={true}
              required={true}
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="aliesName"
              label="Alies name"
              placeholder="Enter alies name"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="tenantId"
              label="Tenant ID"
              placeholder="Enter tenant ID"
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="agentId"
              label="Agent ID"
              placeholder="Enter agent ID"
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="appId"
              label="App ID"
              placeholder="Enter app ID"
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="secretKey"
              label="Client secret key"
              placeholder="Enter your client secret key"
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="apiKey"
              label="API key"
              placeholder="Enter API key"
            />
            <DatePicker
              touched={touched}
              errors={errors}
              name="startDate"
              label="Start date"
              type="date"
              minDate={new Date()}
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="apiUrl"
              label="API url"
              placeholder="Enter API URL"
            />
            <TextareaInput
              touched={touched}
              errors={errors}
              name="description"
              label="Description"
              placeholder="Enter Description"
            />
            {isEditing && (
              <>
                <div className="hLine" />
                <Group className={styles.group}>
                  <Label title="Connection status" />
                  <StatusField title="connected" />
                </Group>
                <TextareaInput
                  touched={touched}
                  errors={errors}
                  name="connection_errors"
                  label="Connection errors"
                  placeholder="No errors found"
                  disabled
                />
              </>
            )}
            <div className="hLine" />
            <Label title="API performance SLA targets" type="heading" />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaTransactionTime"
              label="Transaction time SLA target (ms)"
              placeholder="Add transaction time SLA target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaEUX"
              label="EUX SLA target (%)"
              placeholder="Add EUX SLA target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaAvailability"
              label="API availability target (%)"
              placeholder="Add API availability target"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaCostPerMinDowntime"
              label="API cost of downtime per min target"
              placeholder="Add API cost of downtime target"
              required
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={closeModal}
              />
              <Button
                title={isEditing ? "Update" : "Connect"}
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalAddNewApi;

export interface IApiValues {
  id: string | number;
  app: {
    image: React.ReactElement;
    name: string;
    version: string;
  };
  priority: {
    label: string;
  };
  description: string;
  status: string;
  name: string;
  subtests: [];
}
