import React from "react";
import styles from "../SingleAccount/SingleAccount.module.scss";
import { useParams } from "react-router-dom";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconDownload } from "../../assets/icons/download.svg";
import { ReactComponent as IconButton } from "../../assets/icons/check.svg";
import { Container } from "../../components/Bootstrap/Bootstrap";
import Heading from "../../components/Heading/Heading";
// import Users from "../../widgets/Users/Users";
// import Modal from "../../components/Modal/Modal";
// import ModalInviteUser from "../../components/Modal/ModalInviteUser/ModalInviteUser";

const SingleUserView = () => {
  let { id, user } = useParams<{ id: string; user: string }>();
  // const [modalOpen, setModalOpen] = useState(false);

  // const [data] = useState([
  //   {
  //     user: {
  //       name: "John Doe",
  //       email: "john.doe@fireart.studio",
  //       photoLink: "https://randomuser.me/api/portraits/men/94.jpg",
  //       link: "/account/fireart-studio/john-doe",
  //     },
  //     customers: "1 User",
  //     role: "Admin",
  //     phone: "(480) 555-0103",
  //     expireDate: 1626874290,
  //     status: "active",
  //   },
  //   {
  //     user: {
  //       name: "James Doe",
  //       email: "james.doe@fireart.studio",
  //       photoLink: "https://randomuser.me/api/portraits/men/78.jpg",
  //       link: "/account/fireart-studio/james-doe",
  //     },
  //     customers: "3 Users",
  //     role: "Admin",
  //     phone: "(480) 555-0103",
  //     expireDate: 1626874290,
  //     status: "active",
  //   },
  // ]);

  return (
    <>
      <Container fluid>
        <LayoutHeader pathBack={`/account/${id}`} title={`${user}`}>
          <Button
            type="plain"
            icon={<IconDownload />}
            icon_position="right"
            title="Download User Info"
            className={styles.button}
          />
          <Button title="Save" icon={<IconButton />} icon_position="right" />
        </LayoutHeader>
        <div className="d-flex align-tems-center mb-5">
          <Heading title="Customers" Tag="h3" weight="medium" />
          <Button
            title="+Add"
            type="outline"
            // handleClick={() => setModalOpen(true)}
          />
        </div>
        {/* <Users data={data} /> */}
      </Container>
      {/* <Modal
        title="Invite People"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalInviteUser
          setModalOpen={setModalOpen}
          // roleOptions={[
          //   {
          //     value: "customer",
          //     label: "Customer",
          //   },
          // ]}
        />
      </Modal> */}
    </>
  );
};
export default SingleUserView;
