import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./reducers";
import rootSaga from "./sagas";

export const history = createBrowserHistory();
const saga = createSagaMiddleware();
const composedMiddleware = composeWithDevTools(
  applyMiddleware(saga, routerMiddleware(history))
);
const store = createStore(reducer(history), composedMiddleware);
saga.run(rootSaga);

export default store;
