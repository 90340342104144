import { GetPathAnalyticsDoneT, GetPathAnalyticsFailedT, GetPathAnalyticsT, GET_PATH_ANALYTICS, GET_PATH_ANALYTICS_DONE, GET_PATH_ANALYTICS_FAILED, PathAnalyticsDataT, PathAnalyticsParamsT } from "../../types/widgets/PathAnalytics.types";

export const GetPathAnalytics = (
  params: PathAnalyticsParamsT
): GetPathAnalyticsT => ( {
  type: GET_PATH_ANALYTICS,
  params
} );

export const GetPathAnalyticsDone = (
  chartType: string,
  data: PathAnalyticsDataT
): GetPathAnalyticsDoneT => ( {
  type: GET_PATH_ANALYTICS_DONE,
  chartType,
  data,
} );

export const GetPathAnalyticsFailed = ( chartType: string ): GetPathAnalyticsFailedT => ( {
  type: GET_PATH_ANALYTICS_FAILED,
  chartType,
} );