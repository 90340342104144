import React, { useState, useEffect } from "react";
import styles from "../MenuElem/MenuElem.module.scss";
import cn from "classnames";
import {
  PresetT,
  WidgetT,
} from "../../../../../store/reducers/DashboardPresets";

const MenuElemCustom = (props: IMenuElemCustom) => {
  const {
    title,
    setModalInside,
    setActiveIndex,
    activeIndex,
    setSelectedPreset,
    setCurrentWidgets,
    modalInside,
    customPresetsList,
  } = props;

  const [active, setActive] = useState<boolean>(false);

  const handleClick = () => {
    if (customPresetsList.length) {
      setModalInside("customPresets");
    } else {
      setCurrentWidgets([]);
      setModalInside("widgets");
    }
    setActiveIndex(9);
    setSelectedPreset(null);
    setActive(!active);
  };

  useEffect(() => {
    if (activeIndex !== 9) setActive(false);
  }, [activeIndex]);

  return (
    <div
      typeof="button"
      onClick={handleClick}
      className={cn(
        styles.element,
        (active || modalInside === "customPresets") && styles["isActive"]
      )}
    >
      <div className={styles.heading} onClick={handleClick}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default MenuElemCustom;

export interface IMenuElemCustom {
  title: string;
  setModalInside: (e: string) => void;
  setSelectedPreset: (preset: { id: string; title: string } | any) => void;
  setActiveIndex: (e: number) => void;
  setCurrentWidgets: (e: WidgetT[]) => void;
  activeIndex: number;
  modalInside: string;
  customPresetsList: PresetT[];
}
