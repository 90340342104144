import React from "react";
import styles from "./Sidebar.module.scss";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { ReactComponent as IconOverview } from "../../assets/icons/overview.svg";
// import { ReactComponent as IconMessages } from "../../assets/icons/messages.svg";
import { ReactComponent as IconAlert } from "../../assets/icons/megaphone.svg";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import SidebarMenuSystem from "./SidebarMenu/SidebarMenuSystem/SidebarMenuSystem";
import SidebarMenuRegular from "./SidebarMenu/SidebarMenuRegular/SidebarMenuRegular";
import SidebarMenuReports from "./SidebarMenu/SidebarMenuReports/SidebarMenuReports";
import SidebarMenuLogs from "./SidebarMenu/SidebarMenuLogs/SidebarMenuLogs";
import SidebarMenuContext from "./SidebarMenu/SidebarMenuContext/SidebarMenuContext";
import cn from "classnames";
import TopBarUser from "../TopBar/TopBarUser/TopBarUser";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import SidebarMenuLogo from "./SidebarMenu/SidebarMenuLogo/SidebarMenuLogo";
import { CheckDashboardAccess } from "../../functions/CheckDashboardAccess/CheckDashboardAccess";

const Sidebar = (props: { menuState: boolean }) => {
  const { menuState } = props;
  const { permissions, management, configuration } = useSelector(
    (state: AppState) => state.authorization
  );
  const customersNoAccess =
    !permissions.structure.fullAccess && !permissions.structure.data;
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["xl"]}px)`,
  });

  const reportsMenu = {
    label: "Reports",
    // labelLink: "/reports",
    list: [
      {
        title: "Standard",
        path: "/reports/standard",
      },
    ],
  };
  const systemMenu = {
    label: "System",
    list: [
      {
        title: "Overview",
        icon: <IconOverview />,
        path: "/",
      },
      // {
      //   title: "Notifications",
      //   icon: <IconMessages />,
      //   count: 123,
      //   path: "/notifications",
      // },
      {
        title: "Alerts",
        icon: <IconAlert className={styles.alert} />,
        path: "/alerts-management",
      },
    ],
  };
  const managementMenu = {
    label: "Management",
    list: CheckDashboardAccess(
      [
        {
          title: "Users",
          path: "/users-management",
        },
        {
          title: "Customers",
          path: "/customers-management",
        },
      ],
      management
    ),
  };
  const configurationMenu = {
    label: "Configuration",
    list: CheckDashboardAccess(
      [
        {
          title: "Dashboard",
        },
        {
          title: "API and APP Library",
          path: "/api-and-app-management",
        },
        {
          title: "Agents",
          path: "/agents-management",
        },
        {
          title: "Devices",
          path: "/devices-management",
        },
        {
          title: "Employees",
          path: "/employees-management",
        },
      ],
      configuration
    ),
  };

  return (
    <div className={cn(styles.element, !menuState && styles["isVisible"])}>
      <div className={styles.scroller}>
        {!customersNoAccess && (
          <SidebarMenu label="Customer">
            <SidebarMenuContext />
          </SidebarMenu>
        )}
        <SidebarMenu
          label={reportsMenu.label}
          // labelLink={reportsMenu.labelLink}
        >
          <SidebarMenuReports list={reportsMenu.list} />
        </SidebarMenu>
        <SidebarMenu label={systemMenu.label}>
          <SidebarMenuSystem list={systemMenu.list} />
        </SidebarMenu>
        {/* @ts-ignore */}
        {management?.length > 0 && (
          <SidebarMenu label={managementMenu.label}>
            <SidebarMenuRegular list={managementMenu.list} />
          </SidebarMenu>
        )}
        {/* @ts-ignore */}
        {configuration?.length > 0 &&
        (
          <SidebarMenu label={configurationMenu.label}>
            <SidebarMenuRegular list={configurationMenu.list} />
          </SidebarMenu>
        )}
        <div style={{ display: "none" }}>
          <SidebarMenu label="Logs">
            <SidebarMenuLogs />
          </SidebarMenu>
        </div>
        {isResponsiveView && (
          <SidebarMenu className="mt-auto">
            <TopBarUser />
          </SidebarMenu>
        )}
        <SidebarMenu className="no-border-bottom mt-auto" withLogo>
          <SidebarMenuLogo />
        </SidebarMenu>
      </div>
      <ThemeSwitch />
    </div>
  );
};

export default Sidebar;
