export const CheckDashboardAccess = (
  toBeFiltered: Array<any>,
  toFilterWith?: any
) => {
  if (toFilterWith?.length === 0) {
    return [];
  }
  return toBeFiltered.filter((option) =>
    toFilterWith?.includes(option.title || option.label)
  );
};
