export const GetResourcesDashboard = () => [
  {
    title: "Cost over time of resource usage",
    id: "resources-most-active-cost",
    type: "most-active",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Monthly cost by resource type",
    id: "resources-circle-chart-cost",
    type: "circle-chart",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "EUX score",
    id: "resources-circle-chart-eux",
    type: "circle-chart",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top servers by cost",
    id: "resources-most-active-top",
    type: "most-active",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Revenue by service type",
    id: "resources-circle-chart-eux",
    type: "circle-chart",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Estimated monthly cost",
    id: "resources-status-table-cost",
    type: "status-table",
    dashboardType: "resources",
    size: 3,
    // availableSizes: [3, 4],
  },
  {
    title: "Top service by cost AWS",
    id: "resources-gauge-results-old-multi-cost-aws",
    type: "gauge-results-old-multi",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top service by cost Microsoft Azure",
    id: "resources-gauge-results-old-multi-cost-azure",
    type: "gauge-results-old-multi",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "AWS projected costs",
    id: "resources-gauge-results-old-multi-projected-aws",
    type: "gauge-results-old-multi",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Microsoft Azure projected costs",
    id: "resources-gauge-results-old-multi-projected-azure",
    type: "gauge-results-old-multi",
    dashboardType: "resources",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Cost by service AWS",
    id: "resources-column-line-chart-old-service-aws",
    type: "column-line-chart-old",
    dashboardType: "resources",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost by account AWS",
    id: "resources-column-line-chart-old-account-aws",
    type: "column-line-chart-old",
    dashboardType: "resources",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost by service Microsoft Azure",
    id: "resources-column-line-chart-old-service-azure",
    type: "column-line-chart-old",
    dashboardType: "resources",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost by account Microsoft Azure",
    id: "resources-column-line-chart-old-account-azure",
    type: "column-line-chart-old",
    dashboardType: "resources",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Service usage",
    id: "resources-tests-table",
    type: "tests-table",
    dashboardType: "resources",
    size: 4,
    // availableSizes: [3, 4],
  },
];
