export const GET_CALLER_CALLEE_DEVICES = "GET_CALLER_CALLEE_DEVICES";
export const GET_CALLER_CALLEE_DEVICES_DONE = "GET_CALLER_CALLEE_DEVICES_DONE";
export const GET_CALLER_CALLEE_DEVICES_FAILED = "GET_CALLER_CALLEE_DEVICES_FAILED";

export type  CallerCalleeCallsT = {
  id: string;
  employeeId: string;
  employeeName: string;
  callerName: string;
  caleeName: string;
  timeOfCall: number | null;
  duration: number | null;
  direction: string;
  dialedNumber: string;
  destination: string;
  quality: boolean;
  callType: string;
};

export type CallerCalleeDevicesTableDataT = Array< CallerCalleeCallsT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type CallerCalleeDevicesTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
  userId?: string;
};

export type GetCallerCalleeDevicesTableT = {
  type: typeof GET_CALLER_CALLEE_DEVICES;
  params: CallerCalleeDevicesTableParamsT;
};

export type GetCallerCalleeDevicesTableDoneT = {
  type: typeof GET_CALLER_CALLEE_DEVICES_DONE;
  data: CallerCalleeDevicesTableDataT;
};

export type GetCallerCalleeDevicesTableFailedT = {
  type: typeof GET_CALLER_CALLEE_DEVICES_FAILED;
};

export type TableStateActionT = GetCallerCalleeDevicesTableT | GetCallerCalleeDevicesTableDoneT | GetCallerCalleeDevicesTableFailedT;
