import React, { useState } from "react";
import styles from "./ProfileSettings.module.scss";
import Button from "../../components/Button/Button";
import { Container, Row } from "../../components/Bootstrap/Bootstrap";
import { ReactComponent as IconLogout } from "../../assets/icons/logout.svg";
import Modal from "../../components/Modal/Modal";
import ModalConfirmLogout from "../../components/Modal/ModalConfirmLogout/ModalConfirmLogout";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
import ProfileSettingsUser from "./ProfileSettingsUser/ProfileSettingsUser";
import ProfileSettingsCustomer from "./ProfileSettingsCustomer/ProfileSettingsCustomer";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

const ProfileSettingsView = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState<string>("profile");
  const { permissions } = useSelector((state: AppState) => state.authorization);

  const editAccess = permissions.organization.fullAccess;

  const handleClick = (e: string) => {
    switch (e) {
      case "profile":
        setActive("profile");
        break;
      case "company":
        setActive("company");
        break;
    }
  };

  return (
    <>
      <Container>
        {editAccess && (
          <FilterTabs
            controls={["Profile", "Company"]}
            handleClick={handleClick}
            className={styles.tabs}
          />
        )}
        {active === "profile" && <ProfileSettingsUser />}
        {active === "company" && <ProfileSettingsCustomer />}
        <Row>
          <div className={styles.buttons}>
            <Button
              title="Logout"
              handleClick={() => setModalOpen(true)}
              icon={<IconLogout />}
              icon_position="right"
            />
            <Button
              title="API keys"
              icon_position="right"
              path="/profile-edit/key"
            />
          </div>
        </Row>
      </Container>
      <Modal title="Logout" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <ModalConfirmLogout setModalOpen={setModalOpen} />
      </Modal>
    </>
  );
};
export default ProfileSettingsView;
