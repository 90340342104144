import React, { useState, useEffect, forwardRef } from "react";
import styles from "./ModalAddInterface.module.scss";
import stylesModal from "../Modal.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import tableStyles from "../../tableElements/ReactTable/ReactTable.module.scss";
import TableCellText, {
  ITableCellText,
} from "../../tableElements/TableCellText/TableCellText";
import { useRowSelect, useTable } from "react-table";
import cn from "classnames";
import Loader from "../../Loader/Loader";
import Heading from "../../Heading/Heading";
import { createPortal } from "react-dom";

const IndeterminateCheckbox = forwardRef(
  //@ts-ignore
  ({ indeterminate, ...rest }: any, ref) => {
    //@ts-ignore
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, rest]);

    return (
      <div className={cn(tableStyles.fieldWrapper, styles.fieldWrapper)}>
        <label className={tableStyles.label}>
          <input
            type="checkbox"
            //@ts-ignore
            ref={resolvedRef}
            {...rest}
          />
          <div className={tableStyles.box}>
            {
              //@ts-ignore
              rest.checked && <IconCheck className={tableStyles.icon} />
            }
          </div>
        </label>
      </div>
    );
  }
);

const ModalAddInterface = (props: IModalAddInterface) => {
  const { modalOpen, setModalOpen, handleUpdate, selectedItems, items, title } =
    props;
  const [data, setTableData] = useState<any[]>([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "title",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellText["children"] };
        }) => <TableCellText>{value}</TableCellText>,
      },
      {
        Header: "",
        accessor: "description",
        className: "customCell",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellText["children"] };
        }) => <TableCellText>{value}</TableCellText>,
      },
      {
        Header: "",
        accessor: "number",
        className: "customCell",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellText["children"] };
        }) => <TableCellText>{value}</TableCellText>,
      },
    ],
    []
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setTableData(items);
    return () => {
      isMounted = false;
    };
  }, [items]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: selectedItems,
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: () => null,
          headerClassName: "d-none",
          className: "checkboxCell",
          Cell: ({ row }) => {
            return (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            );
          },
        },
        ...columns,
      ]);
    }
  );

  if (!modalOpen) return null;

  const ModalTemplate = (
    <div className={stylesModal.overlay} onClick={(e) => setModalOpen(false)}>
      <div
        className={styles.element}
        role="dialog"
        aria-labelledby=""
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.header}>
          <Heading title={title} weight="medium" />
          <button className={styles.button} onClick={() => setModalOpen(false)}>
            <IconClose width="1.8rem" height="1.8rem" />
          </button>
        </div>
        <div className={tableStyles.wrapper}>
          <div className={tableStyles.scrollable}>
            <table {...getTableProps()} className={tableStyles.element}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className={cn(
                                tableStyles.td,
                                styles.td,
                                // @ts-ignore
                                styles[`${cell.column.className}`]
                              )}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <Loader
                        container
                        className="p-5 d-flex align-items-center justify-content-center"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            title="Cancel"
            type="plain"
            typeAttr="button"
            handleClick={() => {
              setModalOpen(false);
            }}
          />
          <Button
            title="Add"
            typeAttr="submit"
            icon={<IconCheck />}
            icon_position="right"
            handleClick={() => {
              setModalOpen(false);
              handleUpdate(
                selectedFlatRows.map((d) => {
                  return {
                    key: d.id,
                    details: d.original,
                  };
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
  return createPortal(ModalTemplate, document.body);
};

export default ModalAddInterface;

interface IModalAddInterface {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
  handleUpdate: (e: any) => void;
  selectedItems: any;
  items: any;
  title: string;
}
