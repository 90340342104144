import React, {useState} from "react";
import styles from "./NotificationsBarFilter.module.scss";
import cn from "classnames";

const NotificationsBarFilter = (props: INotificationBarFilter) => {

    const {list, handleClick} = props;
    const [activeIndex, setActiveIndex] = useState(0);

    const thisHandleClick = (elem:any, key:number) => {

        handleClick(elem);
        setActiveIndex(key);
    }

    return(
        <div className={styles.element}>
            {list.map((elem, key) => {
                return <NotificationsBarFilterElem {...elem} key={key} handleClick={() => thisHandleClick(elem.slug, key)} active={activeIndex === key}/>
            })}
        </div>
    )
}
export default NotificationsBarFilter;



export const NotificationsBarFilterElem = (props: INotificationBarFilterElem) => {


    const {
        active,
        title,
        handleClick
    } = props;

    return(
        <button onClick={handleClick} className={cn(styles.item, active && styles['isActive'])}>
            {title}
        </button>
    )
}
interface INotificationBarFilter {
    list: Array<{
        title: string;
        slug: string;
    }>
    handleClick: any;
}

interface INotificationBarFilterElem {
    active: boolean,
    title: string;
    handleClick: any;
}
