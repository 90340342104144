import React from "react";
import cn from "classnames";
import styles from "./Group.module.scss";


const Group = ({children, horizontal, grid, className}: any) => <div
    className={
        cn(
            styles.group,
            horizontal && styles['isHorizontal'],
            grid && styles['isGrid'],
            className && className
        )
    }>{children}</div>

export default Group;
