import React, { useEffect, useState } from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellReport, {
  ITableCellReport,
} from "../../components/tableElements/TableCellReport/TableCellReport";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";

const ReportsTable = (props: any) => {
  const { setModalEmailOpen, setModalExportOpen } = props;
  const [data, setTableData] = useState<IData[]>([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellReport } }) => (
          <TableCellReport>{value}</TableCellReport>
        ),
      },
      {
        Header: "Latest saved",
        accessor: "latestSaved",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Last run",
        accessor: "lastRun",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Scheduled",
        accessor: "scheduled",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "File size",
        accessor: "fileSize",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        Cell: () => (
          <ActionTools type="inTable">
            <ActionToolsItem>
              <Button
                path="/reports/id/:id"
                title="Edit Report"
                type="tooltip"
                scale={false}
              />
            </ActionToolsItem>
            <ActionToolsItem>
              <Button
                title="Email Report"
                type="tooltip"
                scale={false}
                handleClick={() => setModalEmailOpen(true)}
              />
            </ActionToolsItem>
            <ActionToolsItem>
              <Button
                title="Export Report"
                type="tooltip"
                scale={false}
                handleClick={() => setModalExportOpen(true)}
              />
            </ActionToolsItem>
          </ActionTools>
        ),
      },
    ],
    [setModalEmailOpen, setModalExportOpen]
  );

  useEffect(() => {
    let isMounted = true;
    const data = [
      {
        title: "file_12/31/18.log",
        latestSaved: 1610056356,
        lastRun: 1611352356,
        scheduled: "Weekly",
        fileSize: "34 Gb",
      },
      {
        title: "report_12/31/18.log",
        latestSaved: 1603403556,
        lastRun: 1603576356,
        scheduled: "Regular",
        fileSize: "4 Gb",
      },
      {
        title: "file_12/31/18.log",
        latestSaved: 1609278756,
        lastRun: 1609365156,
        scheduled: "Once",
        fileSize: "34 Gb",
      },
      {
        title: "report_7/27/13.log",
        latestSaved: 1610056356,
        lastRun: 1611352356,
        scheduled: "Monthly",
        fileSize: "4 Gb",
      },
    ];
    setTimeout(() => {
      if (isMounted) setTableData(data);
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, []);

  return <ReactTable columns={columns} data={data} />;
};

export default ReportsTable;

interface IData {
  title: string;
  latestSaved: number;
  lastRun: number;
  scheduled: string;
  fileSize: string;
}
