import React, { useState } from "react";
import styles from "./ReceivedPresetsListElem.module.scss";
import { ReactComponent as IconTrash } from "../../../../../../assets/icons/trash.svg";
import { PresetT } from "../../../../../../store/reducers/DashboardPresets";
import Modal from "../../../../Modal";
import ModalAddUser from "../../../../ModalSharePreset/ModalSharePreset";
import { ReactComponent as IconPlus } from "../../../../../../assets/icons/plus-thick.svg";

const ReceivedPresetsListElem = (props: IReceivedPresetsListElem) => {
  const {
    index,
    preset,
    showCustomPresetWidgets,
    handleDeleteReceivedPreset,
    handleMovePresetToCustom,
  } = props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className={styles.element}>
      <button
        type="button"
        onClick={() => showCustomPresetWidgets(preset)}
        className={styles.title}
      >
        <span className={styles.index}>{index + 1}</span>
        <span className={styles.label}>{preset.title}</span>
      </button>
      <div className={styles.actions}>
        <button
          className={styles.delete}
          onClick={() => handleDeleteReceivedPreset(index)}
        >
          <IconTrash />
        </button>
        <button
          className={styles.add}
          onClick={() => handleMovePresetToCustom(preset, index)}
        >
          <IconPlus />
        </button>
      </div>
      <Modal
        title="Share your custom preset"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalAddUser setModalOpen={setModalOpen} preset={preset} />
      </Modal>
    </div>
  );
};

export default ReceivedPresetsListElem;

export interface IReceivedPresetsListElem {
  index: number;
  preset: PresetT;
  showCustomPresetWidgets: (e: PresetT) => void;
  handleDeleteReceivedPreset: (index: number) => void;
  handleMovePresetToCustom: (preset: PresetT, e: number) => void;
}
