import { TOGGLE_THEME } from "../actions/ToggleTheme.action";

export default function ToggleThemeState(
    state = "light",
    action: {
        type: 'TOGGLE_THEME';
        data: "light" | "dark"
    }
) {
    if (action.type === TOGGLE_THEME) {
      return action.data;
    }
    return state;
}