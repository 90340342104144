import {
  GetCallerCalleeDevicesTableDoneT,
  GetCallerCalleeDevicesTableFailedT,
  GetCallerCalleeDevicesTableT,
  GET_CALLER_CALLEE_DEVICES,
  GET_CALLER_CALLEE_DEVICES_DONE,
  GET_CALLER_CALLEE_DEVICES_FAILED,
  CallerCalleeDevicesTableDataT,
  CallerCalleeDevicesTableParamsT
} from "../../types/widgets/CallerCalleeDevicesTable.types";

export const GetCallerCalleeDevicesTable = (params: CallerCalleeDevicesTableParamsT): GetCallerCalleeDevicesTableT => ({
  type: GET_CALLER_CALLEE_DEVICES,
  params
});

export const GetCallerCalleeDevicesTableDone = (data: CallerCalleeDevicesTableDataT): GetCallerCalleeDevicesTableDoneT => ({
  type: GET_CALLER_CALLEE_DEVICES_DONE,
  data
});

export const GetCallerCalleeDevicesTableFailed = (): GetCallerCalleeDevicesTableFailedT => ({
  type: GET_CALLER_CALLEE_DEVICES_FAILED
});
