import { ColumnLineChartActionT, GET_COLUMN_LINE_CHART, GET_COLUMN_LINE_CHART_DONE, GET_COLUMN_LINE_CHART_FAILED } from "../../types/widgets/ColumnLineChart";

export const initialState: any = {};

const ColumnLineChartState = (
  state = initialState,
  action: ColumnLineChartActionT
) => {
  switch (action.type) {
    case GET_COLUMN_LINE_CHART:
      return { ...state, [action.chartType]: { data: null, loading: true, error: false } };
    case GET_COLUMN_LINE_CHART_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_COLUMN_LINE_CHART_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default ColumnLineChartState;
