import React from "react";
import styles from "./Grid.module.scss";
import cn from "classnames";

const Grid = (props: { children: any }) => {
  return <div className={styles.area}>{props.children}</div>;
};
export default Grid;

export const GridElem = (props: {
  children: any;
  size?: 2 | 3 | 4 | number;
  display?: string | null;
}) => {
  const { children, size, display } = props;
  return (
    <div
      style={display ? { display: display } : {}}
      className={cn(styles.element, size && styles[`hasSize-${size}`])}
    >
      {children}
    </div>
  );
};
