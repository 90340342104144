import React, { useState } from "react";
import styles from "./SidebarMenuRegular.module.scss";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconChevron } from "../../../../assets/icons/chevron.svg";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";
import { useDispatch } from "react-redux";
import { ToggleMenu } from "../../../../store/actions/ToggleMenu.action";
import ModalManageDashboard from "../../../Modal/ModalManageDashboard/ModalManageDashboard";
import Modal from "../../../Modal/Modal";

const SidebarMenuRegular = (props: ISidebarMenuManagement) => {
  const { list } = props;

  return (
    <ul className={styles.list}>
      {list.map((elem, key) => {
        return <SidebarMenuManagementElem {...elem} key={key} />;
      })}
    </ul>
  );
};

export default SidebarMenuRegular;

const SidebarMenuManagementElem = (props: ISidebarMenuManagementElem) => {
  const { title, path } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();
  const [modalWide, setModalWide] = useState(true);

  if (!path) {
    return (
      <>
        <li>
          <button onClick={() => setModalOpen(true)} className={styles.element}>
            <span className={styles.icon}>
              <IconChevron />
            </span>
            <span className={styles.title}>{title}</span>
          </button>
        </li>
        <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          isWide={modalWide}
        >
          <ModalManageDashboard
            setModalOpen={setModalOpen}
            setModalWide={setModalWide}
          />
        </Modal>
      </>
    );
  }
  return (
    <li>
      <NavLink
        activeClassName={styles["isActive"]}
        to={path}
        onClick={() => isResponsiveView && dispatch(ToggleMenu())}
        className={styles.element}
      >
        <span className={styles.icon}>
          <IconChevron />
        </span>
        <span className={styles.title}>{title}</span>
      </NavLink>
    </li>
  );
};

interface ISidebarMenuManagement {
  list: Array<ISidebarMenuManagementElem>;
}

export interface ISidebarMenuManagementElem {
  title: string;
  path?: string;
}
