import axios from "../api/axiosConfig";
import axiosDashboard from "../api/axiosDashboard";

type SaveTokensT = {
    token: string;
    refreshToken: string;
};

export function setToken ( token: string ) {
    axios.defaults.headers.common["Authorization"] = token
        ? `Bearer ${token}`
        : "";
}

export function saveTokens ( { token, refreshToken }: SaveTokensT ) {
    axiosDashboard.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem( "token", token );
    localStorage.setItem( "refreshToken", refreshToken );
}

export function saveApiUrl ( { apiUrl }: { apiUrl: string } ) {
    localStorage.setItem( "apiUrl", apiUrl );
    axiosDashboard.defaults.baseURL = apiUrl;
}

export function removeUser () {
    axios.defaults.headers.common["Authorization"] = "";
    axiosDashboard.defaults.headers.common['Authorization'] = "";
    localStorage.removeItem( "token" );
    localStorage.removeItem( "refreshToken" );
    localStorage.removeItem( "apiUrl" );
    localStorage.removeItem( "customerContext" );
    localStorage.removeItem( "dateRange" );
    localStorage.removeItem( "timeRange" );

}

export function getTokens () {
    return {
        token: localStorage.getItem( "token" ),
        refreshToken: localStorage.getItem( "refreshToken" ),
    };
}

export function setTokenForRefresh () {
    const token = localStorage.getItem( "refreshToken" );
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axiosDashboard.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}