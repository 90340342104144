import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import styles from "../ModalInviteUser/ModalInviteUser.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import Button from "../../Button/Button";
import { ReactComponent as IconSave } from "../../../assets/icons/check.svg";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import Heading from "../../Heading/Heading";
import { useDispatch, useSelector } from "react-redux";
import { EditSLATargets } from "../../../store/actions/SetSLATargets.action";
import { AppState } from "../../../store/reducers";
import ModalError from "../ModalError/ModalError";

interface IModalEditSLATargets {
  setModalOpen: (e: boolean) => void;
  selectedUser: any;
}

const ModalEditSLATargets = (props: IModalEditSLATargets) => {
  const { setModalOpen, selectedUser } = props;
  const { data, message, error } = useSelector(
    (state: AppState) => state.SLATargetsState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setModalOpen(false);
      }, 3000);
    }
  }, [message, setModalOpen]);

  const initialValues = {
    delayTarget: data?.delayTarget || 0,
    jitterTarget: data?.jitterTarget || 0,
    lossTarget: data?.lossTarget || 0,
    bandwidthSpeedTarget: data?.bandwidthSpeedTarget || 0,
    availabilityTarget: data?.availabilityTarget || 0,
    dnsNumberTarget: "",
    hopsTarget: "",
    transactionTimeTarget: "",
    bandwidthScoreTarget: "",
    availabilityAppTarget: "",
    mosTarget: "",
    transactionTimeAppTarget: "",
    slaScoreTarget: "",
    latencyTarget: "",
    lossAppTarget: "",
    loadTimeTarget: "",
    dnsTimeTarget: "",
    employeeCostOfDowntimeTarget: "",
    agentCostOfDowntimeTarget: "",
    bandwidthCostMonthTarget: "",
    bandwidthCostMinuteTarget: "",
    locationCostTarget: "",
  };

  if (message) {
    return <ModalSuccess text={message} />;
  }

  if (error) {
    return <ModalError />;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          dispatch(
            EditSLATargets(selectedUser.id, {
              ...values,
            })
          );
        }, 500);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Heading title="Network SLA targets" weight="medium" />
          <div className={styles.content}>
            <TextInput
              touched={touched}
              errors={errors}
              name="delayTarget"
              label="Delay target (ms)"
              placeholder="Add delay target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="jitterTarget"
              label="Jitter target (ms)"
              placeholder="Add jitter target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="lossTarget"
              label="Loss target (ms)"
              placeholder="Add loss target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="bandwidthSpeedTarget"
              label="Bandwidth speed target (Mbps)"
              placeholder="Add bandwidth speed target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="availabilityTarget"
              label="Availability target (%)"
              placeholder="Add availability target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="dnsNumberTarget"
              label="DNS number target"
              placeholder="Add DNS number target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="hopsTarget"
              label="Number of hops target"
              placeholder="Add number of hops target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="transactionTimeTarget"
              label="Transaction time target (s)"
              placeholder="Add transaction time target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="bandwidthScoreTarget"
              label="Bandwidth score target"
              placeholder="Add bandwidth score target"
              textInputType="number"
              required
            />
          </div>
          <div className="hLine" />
          <Heading title="Application SLA targets" weight="medium" />
          <div className={styles.content}>
            <TextInput
              touched={touched}
              errors={errors}
              name="availabilityAppTarget"
              label="Availability target (%)"
              placeholder="Add availability target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="mosTarget"
              label="MOS target"
              placeholder="Add MOS target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="transactionTimeAppTarget"
              label="Transaction time target (s)"
              placeholder="Add transaction time target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="slaScoreTarget"
              label="SLA score target"
              placeholder="Add SLA score target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="latencyTarget"
              label="Latency target"
              placeholder="Add latency target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="lossAppTarget"
              label="Loss target"
              placeholder="Add loss target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="loadTimeTarget"
              label="Load time target"
              placeholder="Add load time target"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="dnsTimeTarget"
              label="DNS time target"
              placeholder="Add DNS time target"
              textInputType="number"
              required
            />
          </div>
          <div className="hLine" />
          <Heading title="Agent financial SLA targets" weight="medium" />
          <div className={styles.content}>
            <TextInput
              touched={touched}
              errors={errors}
              name="employeeCostOfDowntimeTarget"
              label="Employee Cost of downtime per min Target"
              placeholder="Add employee cost of downtime"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="agentCostOfDowntimeTarget"
              label="Agent cost of downtime per min Target"
              placeholder="Add agent cost of downtime"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="bandwidthCostMonthTarget"
              label="Bandwidth Cost per MB per month Target"
              placeholder="Add bandwidth cost"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="bandwidthCostMinuteTarget"
              label="Bandwidth Cost per MB per min Target"
              placeholder="Add bandwidth cost"
              textInputType="number"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="locationCostTarget"
              label="Location cost of downtime Target"
              placeholder="Add location cost of downtime Target"
              textInputType="number"
              required
            />
          </div>
          <div className={styles.footer}>
            <Button
              title="Cancel"
              type="plain"
              typeAttr="button"
              handleClick={() => setModalOpen(false)}
            />
            <Button
              title="Save"
              typeAttr="submit"
              icon={<IconSave />}
              icon_position="right"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ModalEditSLATargets;
