import React from "react";
import { Form, Formik } from "formik";
import styles from "../ModalAddTitle/ModalAddTitle.module.scss";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";

interface IModalAddDescription {
  setModalOpen: (e: boolean) => void;
  handleData: (e: string) => void;
  data: string;
}

const ModalAddDescription = (props: IModalAddDescription) => {
  const { setModalOpen, handleData, data } = props;

  const initialValues = {
    description: data,
  };

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleData(values.description);
            setSubmitting(false);
            setModalOpen(false);
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <TextareaInput
              touched={touched}
              errors={errors}
              name="description"
              label="Description"
              placeholder="Enter Description"
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Add"
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ModalAddDescription;
