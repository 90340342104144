export const GET_TABLE_EMPLOYEE_CALLS = "GET_TABLE_EMPLOYEE_CALLS";
export const GET_TABLE_EMPLOYEE_CALLS_DONE = "GET_TABLE_EMPLOYEE_CALLS_DONE";
export const GET_TABLE_EMPLOYEE_CALLS_FAILED = "GET_TABLE_EMPLOYEE_CALLS_FAILED";

export type EmployeeCallsT = {
  id: string;
  employeeId: string;
  employeeName: string;
  callerName: string;
  caleeName: string;
  timeOfCall: number | null;
  duration: number | null;
  direction: string;
  dialedNumber: string;
  destination: string;
  quality: boolean;
  callType: string;
};

export type EmployeeCallsTableDataT = Array<EmployeeCallsT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type EmployeeCallsTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
};

export type GetEmployeeCallsTableT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLS;
  params: EmployeeCallsTableParamsT;
};

export type GetEmployeeCallsTableDoneT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLS_DONE;
  data: EmployeeCallsTableDataT;
};

export type GetEmployeeCallsTableFailedT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLS_FAILED;
};

export type TableStateActionT = GetEmployeeCallsTableT | GetEmployeeCallsTableDoneT | GetEmployeeCallsTableFailedT;
