import React from "react";
import styles from "./DatePicker.module.scss";
import { Field } from "formik";
import DatePickerField from "./DatePickerField/DatePickerField";
import RangePickerField from "./RangePickerField/RangePickerField";
import cn from "classnames";

const DatePicker = (props: IDatePicker) => {
  const {
    errors,
    touched,
    name,
    label,
    isBgGray,
    required,
    type,
    minDate,
    maxDate,
  } = props;

  function validate(value: any) {
    let error;
    if (!value) {
      error = "This field is required";
    }
    return error;
  }

  const handleDatePicker = (type: string) => {
    switch (type) {
      case "date":
        return DatePickerField;
      case "range":
        return RangePickerField;
    }
  };

  return (
    <div
      className={cn(
        styles.wrapper,
        errors[name] && touched[name] && styles["isError"],
        isBgGray && styles["isPlain"]
      )}
    >
      {label && <label className={styles.label}>{label}</label>}
      <Field
        component={handleDatePicker(type)}
        validate={required && ((e: any) => validate(e))}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        required
      />
      {errors[name] && touched[name] && (
        <span className={styles.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default DatePicker;

export interface IDatePicker {
  errors: any;
  touched: any;
  name: string;
  label?: string;
  isBgGray?: boolean;
  required?: boolean;
  type: "date" | "range";
  minDate?: Date;
  maxDate?: Date;
}
