import React from "react";
import LayoutUserAuth from "../../../components/LayoutUserAuth/LayoutUserAuth";
import UserFormTemplate from "../../../components/UserFormTemplate/UserFormTemplate";
import RoleSelectForm from "../../../components/UserFormTemplate/RoleSelectForm";


const RoleSelectView = () => {



    return (
        <LayoutUserAuth title="Select role">
            <UserFormTemplate>
                <RoleSelectForm/>
            </UserFormTemplate>
        </LayoutUserAuth>
    );

};

export default RoleSelectView;
