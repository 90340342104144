import React from "react";
import styles from "./StatusField.module.scss";
import cn from "classnames";

const StatusField = (props: IHeading) => {
  const { title } = props;

  return (
    <div className={cn(styles.element)}>
      <span className={cn(styles.status, styles[title])} />
      <span className={styles.title}>{title}</span>
    </div>
  );
};

export default StatusField;

export interface IHeading {
  title: string;
}
