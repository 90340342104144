import React from "react";
import styles from "./SharedWidgetsTable.module.scss";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellDelete from "../../components/tableElements/TableCellDelete/TableCellDelete";
import tableStyles from "../tableElements/ReactTable/ReactTable.module.scss";
import { usePagination, useTable } from "react-table";
import cn from "classnames";
import Loader from "../Loader/Loader";
import { ReactComponent as IconChevron } from "../../assets/icons/calendar-chevron.svg";

const SharedWidgetsTable = (props: { data: any }) => {
  const { data } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "index",
        className: "sm",
        Cell: ({ cell: { row } }: { cell: { row: any } }) => (
          <TableCellText>{row.index + 1}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "title",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        className: "sm",
        Cell: () => <TableCellDelete></TableCellDelete>,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );
  return (
    <div className={styles.element}>
      <div className={tableStyles.wrapper}>
        <div className={tableStyles.scrollable}>
          <table
            {...getTableProps()}
            className={cn(tableStyles.element, styles.table)}
          >
            <thead>{headerGroups.map(() => null)}</thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={cn(
                              tableStyles.td,
                              // @ts-ignore
                              styles[`${cell.column.className}`]
                            )}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <Loader
                      container
                      className="p-5 d-flex align-items-center justify-content-center"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={tableStyles.pagination}>
          <div className={tableStyles.buttonsWrapper}>
            <button
              className={tableStyles.button}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {
                <>
                  <IconChevron className={tableStyles.iconChevron} />
                  <IconChevron className={tableStyles.iconChevron} />
                </>
              }
            </button>
            <button
              className={tableStyles.button}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {<IconChevron className={tableStyles.iconChevron} />}
            </button>
            <div className={tableStyles.inputWrapper}>
              <input
                className={tableStyles.input}
                type="number"
                placeholder={String(pageIndex + 1)}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
              <span className={tableStyles.inputOf}>
                of {pageOptions.length}
              </span>
            </div>
            <button
              className={tableStyles.button}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {
                <IconChevron
                  className={cn(
                    tableStyles.iconChevron,
                    tableStyles["isRight"]
                  )}
                />
              }
            </button>
            <button
              className={tableStyles.button}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {
                <>
                  <IconChevron
                    className={cn(
                      tableStyles.iconChevron,
                      tableStyles["isRight"]
                    )}
                  />
                  <IconChevron
                    className={cn(
                      tableStyles.iconChevron,
                      tableStyles["isRight"]
                    )}
                  />
                </>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedWidgetsTable;
