import React from "react";

import styles from "./TableCellQuality.module.scss";
import cn from "classnames";

interface ITableCellQuality {
  quality: boolean;
}

const TableCellQuality: React.FC<ITableCellQuality> = ({ quality }) => {
  return (
    <div className={"d-flex align-items-center"}>
      <div className={cn(styles.progressBar, quality ? styles.greenFill : styles.redFill)} />
    </div>
  );
};

export default TableCellQuality;
