import React from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import Heading from "../Heading/Heading";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import cn from "classnames";

const Modal = (props: IModal) => {
  const { modalOpen, children, setModalOpen, title, isWide, noButton, setNewConnection } = props;
  const closeModal = () => {
    setModalOpen(false);
    setNewConnection && setNewConnection(false);
  }
  if (!modalOpen) return null;

  const ModalTemplate = (
    <div className={styles.overlay} onClick={closeModal}>
      <div
        className={cn(
          styles.element,
          isWide && styles["isWide"],
          noButton && styles["noButton"]
        )}
        role="dialog"
        aria-labelledby={title}
        onClick={(e) => e.stopPropagation()}
      >
        {title && <Heading title={title} weight="medium" />}
        {children}
        {!noButton && (
          <button className={styles.button} onClick={closeModal}>
            <IconClose width="1.8rem" height="1.8rem" />
          </button>
        )}
      </div>
    </div>
  );

  return createPortal(ModalTemplate, document.body);
};

export default Modal;

interface IModal {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
  setNewConnection?: (e: boolean) => void;
  children: any;
  title?: string;
  isWide?: boolean;
  noButton?: boolean;
}
