import React from "react";
import styles from "./LayoutUserAuth.module.scss";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import Heading from "../Heading/Heading";
import { ReactComponent as IconIllustration } from "../../assets/icons/illustration.svg";
import { ReactComponent as IconHeading } from "../../assets/icons/arrow.svg";
import GlobalStyles from "../../assets/styles/theme/global";
import { lightTheme } from "../../assets/styles/theme/light-theme";

interface ILayoutUserAuth {
  children: React.ReactElement;
  stepBackPath?: string;
  title?: string | null;
  icon?: React.ReactElement;
  handleStepFn?: () => void;
}

const LayoutUserAuth = (props: ILayoutUserAuth) => {
  const { title, stepBackPath, children, icon, handleStepFn } = props;

  return (
    <>
      <GlobalStyles theme={lightTheme} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Logo isLink={false} />
          <div className={styles.formWrapper}>
            {stepBackPath && (
              <div className={styles.headingWrapper}>
                <Link to={stepBackPath} className={styles.link}>
                  <IconHeading className={styles.icon} />
                </Link>
                {title && <Heading Tag="h1" title={title} />}
              </div>
            )}
            {handleStepFn && (
              <div className={styles.headingWrapper}>
                <button className={styles.link} onClick={handleStepFn}>
                  <IconHeading className={styles.icon} />
                </button>
                {title && <Heading Tag="h1" title={title} />}
              </div>
            )}
            {!stepBackPath && !handleStepFn && title && (
              <Heading Tag="h1" title={title} />
            )}
            {children}
          </div>
          <p className={styles.text}>
            Copyright <span>{new Date().getFullYear()}</span> Hortium EUX. All
            rights reserved.
          </p>
        </div>
        <div className={styles.illustration}>
          {icon || <IconIllustration className={styles.image} />}
        </div>
      </div>
    </>
  );
};

export default LayoutUserAuth;
