import React, { useCallback, useEffect, useState } from "react";
import styles from "./PresetSwitch.module.scss";
import { DropdownIndicator } from "../formElements/SelectInput/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { SetCurrentDashboard } from "../../store/actions/DashboadPresets.action";
import Select, { GroupType, SingleValueProps } from "react-select";
import Label from "../Label/Label";
import { AppState } from "../../store/reducers";
import { GetDefaultCurrentPreset } from "../../functions/ManageDashboard/GetDefaultCurrentPreset/GetDefaultCurrentPreset";
import { GetSelectedPresetDetails } from "../../functions/ManageDashboard/GetSelectedPresetDetails/GetSelectedPresetDetails";
import { PresetT } from "../../store/reducers/DashboardPresets";
import { SettingsT } from "../../store/reducers/SetSettings";
import { CurrentPresetT } from "../../store/types/DashboardPresets.types";
import { CheckDashboardAccess } from "../../functions/CheckDashboardAccess/CheckDashboardAccess";

const PresetSwitch = () => {
  const [presets, setPresets] = useState<PresetsT>();
  const customPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.customPresets
  );
  const constantPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantPresets
  );
  const constantReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantReceivedPresets
  );
  const currentDashboardId = useSelector(
    (state: AppState) => state.dashboardPresetsState.currentDashboardId
  );
  const currentDashboard = useSelector(
    (state: AppState) => state.dashboardPresetsState.currentDashboard
  );
  const { id, dashboard } = useSelector(
    (state: AppState) => state.authorization
  );
  const settings = useSelector((state: AppState) => state.SettingsState);

  const [value, setValue] = useState<IDefaultValue>({
    label: GetDefaultCurrentPreset(
      currentDashboard,
      settings,
      constantPresets,
      customPresets,
      constantReceivedPresets
    ).title,
    value: GetDefaultCurrentPreset(
      currentDashboard,
      settings,
      constantPresets,
      customPresets,
      constantReceivedPresets
    ).id,
  });

  const dispatch = useDispatch();

  const handleCurrentDashboardPresetData = useCallback(
    (data: IDefaultValue) => {
      const currentPreset: CurrentPresetT = {
        userId: id,
        defaultSettings: true,
        settingsType: "currentDashboardPreset",
        settings: {
          title: data.label,
          id: data.value,
        },
      };

      if (!settings.length) {
        if (currentDashboardId) {
          currentPreset["settingId"] = currentDashboardId;
          return currentPreset;
        } else return currentPreset;
      } else {
        const currentDashboardPreset = settings.find(
          (elem: SettingsT) => elem.settingsType === "currentDashboardPreset"
        );
        if (currentDashboardPreset) {
          currentPreset["settingId"] = currentDashboardPreset.id;
          return currentPreset;
        } else return currentPreset;
      }
    },
    [id, settings, currentDashboardId]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setPresets([
        {
          label: "Default",
          options: CheckDashboardAccess(
            constantPresets
              .concat(constantReceivedPresets)
              .map((elem: { id: string; title: string }) => {
                return { value: elem.id, label: elem.title };
              }),
            dashboard
          ),
        },
        {
          label: "Custom",
          options: customPresets.map((elem: PresetT) => {
            return { value: elem.id, label: elem.title };
          }),
        },
      ]);
      if (
        currentDashboard &&
        !GetSelectedPresetDetails(
          constantPresets,
          customPresets,
          constantReceivedPresets,
          currentDashboard
        )
      )
        setValue({
          label: GetDefaultCurrentPreset(
            currentDashboard,
            settings,
            constantPresets,
            customPresets,
            constantReceivedPresets
          ).title,
          value: GetDefaultCurrentPreset(
            currentDashboard,
            settings,
            constantPresets,
            customPresets,
            constantReceivedPresets
          ).id,
        });
    }
    return () => {
      isMounted = false;
    };
  }, [
    currentDashboard,
    settings,
    constantPresets,
    customPresets,
    constantReceivedPresets,
    dashboard,
  ]);

  const formatGroupLabel = (data: GroupType<IDefaultValue>) => {
    return <Label title={data.label} />;
  };

  const singleValueCustom = (
    data: React.PropsWithChildren<SingleValueProps<IDefaultValue>>
  ) => {
    return <div className={styles.singleValue}>{data.data.label}</div>;
  };

  const handleChange = useCallback(
    (value) => {
      setValue(value);
      dispatch(SetCurrentDashboard(handleCurrentDashboardPresetData(value)));
    },
    [dispatch, handleCurrentDashboardPresetData]
  );

  if (presets && (presets[0].options.length > 0 || presets[1].options.length > 0)) {
    return (
      <Select
        className={styles.select}
        classNamePrefix={styles.select}
        formatGroupLabel={formatGroupLabel}
        options={presets}
        value={value}
        onChange={(value) => handleChange(value)}
        styles={customSelectStyles}
        isSearchable={false}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          SingleValue: (data) => singleValueCustom(data),
        }}
      />
    );
  }
  return null;
};

export default PresetSwitch;

const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 6,
    boxShadow: state.isFocused ? null : null,
    cursor: "pointer",
    fontSize: "1.2rem",
    minHeight: "4rem",
    maxHeight: "4rem",
    padding: "0.6rem 1.6rem",
    opacity: state.isDisabled ? 0.5 : 1,
    backgroundColor: "var(--color-panel-bg)",
    border: 0,
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: "0",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 6,
    hyphens: "auto",
    marginTop: "0.2rem",
    textAlign: "left",
    wordWrap: "break-word",
    border: "1px solid rgba(25, 40, 60, 0.04)",
    backgroundColor: "var(--color-panel-bg)",
    zIndex: 99,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    fontSize: "1.2rem",
    color: "#8A97AD",
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#566ff6",
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    padding: "0.8rem 1.6rem",
    backgroundColor: [
      state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
      state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
    ],
    "&:active": {
      backgroundColor: [
        state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
        state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
      ],
    },
    color: state.isSelected ? "#3A4EB8 !important" : null,
    fontWeight: state.isSelected ? "600" : null,
    fontSize: ["1.2rem", state.isSelected ? "1.2rem" : null],
    cursor: "pointer",
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    height: "2.2rem",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "#8a97ad",
    };
  },
  groupHeading: (base: any) => ({
    ...base,
    paddingLeft: "1.6rem",
    paddingRight: "1.6rem",
  }),
};

type PresetsT = Array<SinglePresetT>;

type SinglePresetT = {
  id?: string | number;
  label: string;
  options: IDefaultValue[];
};

interface IDefaultValue {
  label: string;
  value: string;
}
