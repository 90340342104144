import React, { useCallback, useEffect, useState } from "react";
import styles from "./ProfileSettingsUser?.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import axios from "../../../api/axiosConfig";
import ProfileSettingsAvatar from "../ProfileSettingsAvatar/ProfileSettingsAvatar";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import cn from "classnames";
import Button from "../../../components/Button/Button";
import { Form, Formik } from "formik";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import Modal from "../../../components/Modal/Modal";
import ChangePasswordModal from "../../../components/Modal/ModalChangePassword/ModalChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../store/reducers/authorization";
import { useParams } from "react-router-dom";

const ProfileSettingsUser = () => {
  const auth = useSelector((state: any) => state.authorization);
  const [user, setUser] = useState<InitialValuesT | null>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string | null>(
    user?.photoLink ? user.photoLink : null
  );
  let { id } = useParams<{ id: string }>();


  const fetchCustomer = useCallback(async (endpoint: string) => {
    try {
      await axios(endpoint).then((res) => {
        if (res && res.status === 200) {
          setUser(res.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("user")) {
      fetchCustomer(`/user?loginId=${id}`);
    } else {
      setUser(auth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const initialValues = {
    photoLinkUser: user?.photoBlob,
    photoTypeUser: user?.photoType,
    email: user?.email,
    name: user?.name,
    contactNumber: user?.contactNumber || user?.customer?.contactNumber || "",
    jobTitle: user?.jobTitle ?? "",
    description: user?.description || user?.customer.description || "",
  };

  type InitialValuesT = {
    email: string;
    name: string | undefined;
    photoLink?: string | undefined | null;
    photoType?: string | undefined;
    photoBlob?: Blob | null;
    contactNumber?: string;
    jobTitle?: string;
    description?: string | null;
    customer?: any;
  };

  const dispatch = useDispatch();

  async function handleSubmit(values: any) {
    await dispatch(updateProfile({...values, ...{userId: id}}));
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 500);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className={styles.element}>
            <LayoutHeader title="Profile Settings">
              <Button
                title="Save"
                typeAttr={"submit"}
                icon={<IconButton />}
                icon_position="right"
              />
            </LayoutHeader>
            <div className={styles.wrapper}>
              <ProfileSettingsAvatar
                onSetValue={setFieldValue}
                userName={user?.name}
                setAvatar={setAvatar}
                avatar={avatar}
              />
              <Row>
                <Column className={cn(styles.column, "col-12 col-md-12")}>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Personal Information
                    </span>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="email"
                      label="Email"
                      placeholder="Email address"
                      textInputType="email"
                      isBgGray={true}
                      required
                    />
                    <Button
                      title="Change password"
                      type="plain"
                      small
                      handleClick={() => setModalOpen(true)}
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="name"
                      label="Full name"
                      placeholder="Full name"
                      textInputType="text"
                      isBgGray={true}
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="jobTitle"
                      label="Job Title"
                      placeholder="Job Title"
                      textInputType="text"
                      isBgGray={true}
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="contactNumber"
                      label="Contact number"
                      placeholder="Add your contact number"
                      isBgGray={true}
                    />
                    <TextareaInput
                      touched={touched}
                      errors={errors}
                      name="description"
                      label="Description"
                      placeholder="Description/Comment"
                      rows={3}
                      isBgGray={true}
                    />
                  </div>
                </Column>
                <Column className={cn(styles.column, "col-12 col-md-6")}>
                  {/* <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Notification Delivery
                    </span>
                    <CheckboxGroup
                      name="notifications"
                      touched={touched}
                      errors={errors}
                      options={[
                        {
                          value: "notification1",
                          label: "Email Alert notifications to all users",
                        },
                        {
                          value: "notification2",
                          label:
                            "Email notifications to your email address only",
                        },
                      ]}
                    />
                  </div>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Notification Frequency
                    </span>
                    <SelectInput
                      touched={touched}
                      errors={errors}
                      name={"frequency"}
                      options={[
                        { value: 1, label: "Every 1 minute" },
                        { value: 2, label: "Every 5 minutes" },
                        { value: 3, label: "Every 15 minutes" },
                        { value: 4, label: "Every 30 minutes" },
                        { value: 5, label: "Every 45 minutes" },
                        { value: 6, label: "At most every hour" },
                        { value: 7, label: "Once a day" },
                      ]}
                      placeholder={"Notification Frequency"}
                      isSearchable={false}
                      isBgGray={true}
                    />
                  </div>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Choose Destination
                    </span>
                    <CheckboxGroup
                      name="destination"
                      touched={touched}
                      errors={errors}
                      options={[
                        {
                          value: "api",
                          label: "API",
                        },
                        {
                          value: "email",
                          label: "Email",
                        },
                        {
                          value: "trap",
                          label: "Trap",
                        },
                      ]}
                    />
                  </div> */}
                </Column>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        title="Change password"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ChangePasswordModal setModalOpen={setModalOpen} />
      </Modal>
    </>
  );
};

export default ProfileSettingsUser;
