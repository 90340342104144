export const checkIfHortium = (
  customer: any,
  contextId: string
) => {
  return (
    customer.mainCustomer &&
    customer.id ===
    contextId
  );
};
