import React, { ChangeEvent } from "react";
import styles from "../../../formElements/FilterInput/FilterInput.module.scss";
import cn from "classnames";

const ModalSelectCustomerSearch = (props: IModalSelectCustomerSearch) => {
  const {
    name,
    label,
    placeholder,
    value,
    isBgGray,
    handleFocus,
    handleChange,
  } = props;

  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      //@ts-ignore
      handleChange(e, true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={cn(styles.input, isBgGray && styles["isPlain"])}
        type="text"
        name="name"
        id={name}
        placeholder={placeholder}
        value={value}
        onFocus={() => handleFocus()}
        onKeyDown={(e) => handleClick(e)}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
};
export default ModalSelectCustomerSearch;

interface IModalSelectCustomerSearch {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  isBgGray?: boolean;
  handleFocus: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>, clear?: boolean) => void;
}
