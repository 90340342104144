import React, { useState } from "react";
import styles from "./FilterTabs.module.scss";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { NavLink } from "react-router-dom";

const FilterTabs = (props: IFilterTabs) => {
  const {
    controls,
    title,
    type = "normal",
    handleClick,
    className,
    isBgWhite,
    links,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  const thisHandleClick = (action: string, key: number) => {
    if (handleClick) {
      handleClick(action);
    }
    setActiveIndex(key);
  };

  return (
    <div className={cn(styles.element, className)}>
      {!isResponsiveView && title && title}
      <div
        className={cn(
          styles.list,
          type === "chart" && styles["inChart"],
          isBgWhite && styles["isBgWhite"]
        )}
      >
        {links
          ? links.map((elem, key) => {
              return (
                <NavLink
                  key={key}
                  to={elem.path}
                  activeClassName={styles["isActive"]}
                  className={styles.button}
                >
                  {elem.title}
                </NavLink>
              );
            })
          : controls &&
            controls.map((elem, key) => {
              return (
                <button
                  type="button"
                  className={cn(
                    styles.button,
                    type === "chart" && styles["inChart"],
                    activeIndex === key && styles["isActive"]
                  )}
                  key={key}
                  onClick={() => thisHandleClick(elem?.toLowerCase(), key)}
                >
                  {elem}
                </button>
              );
            })}
      </div>
    </div>
  );
};

export default FilterTabs;

interface IFilterTabs {
  controls?: Array<string>;
  title?: string;
  type?: "chart";
  handleClick?: (action: string) => void;
  className?: string;
  isBgWhite?: boolean;
  links?: Array<{
    title: string;
    path: string;
  }>;
}
