import React from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import { IUserCard } from "../../components/UserCard/UserCard";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import { IUserValues } from "../../views/UsersManagement/UsersManagement.view";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import TableCellRole, {
  ITableCellRole,
} from "../../components/tableElements/TableCellRole/TableCellRole";
import { TableSortByName } from "../../functions/TableSortByName/TableSortByName";
import TableCellLink from "../../components/tableElements/TableCellLink/TableCellLink";
import { showTableEdit } from "../../functions/ShowTableEdit/ShowTableEdit";

const Users = (props: IUsers) => {
  const { data, noData, openEdit, openDelete, openReivite } = props;
  const { permissions, customer, subcustomers } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id, parentId } = useSelector((state: AppState) => state.customerContextState);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "userDetails",
        sortType: TableSortByName,
        Cell: ({ cell: { value }, row }: { cell: { value: IUserCard }, row: any }) => {
          return <TableCellLink
            path={value.name}
            name={value.name}
            stateProps={"user"}
            endpoint={`user/${row.original.id}`}
            type="bold"
          />
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellRole } }) => (
          <TableCellRole {...value} />
        ),
      },
      {
        Header: "Phone",
        accessor: "phone",

        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Expire date",
        accessor: "expireDate",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value?.toLowerCase()} />,
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        show: customer.name === "Horitium" || showTableEdit({permission: permissions.organization, customer, id, parentId, subcustomers}) ? true : false,
        className: "p-0",
        Cell: (value: { row: { original: any } }) => (
          <>
            <ActionTools type="inTable">
              {value.row.original.status !== "Active" && (
                <ActionToolsItem>
                  <Button
                    title="Resend Invite"
                    type="tooltip"
                    scale={false}
                    handleClick={() =>
                      openReivite && openReivite(value.row.original)
                    }
                  />
                </ActionToolsItem>
              )}
              <ActionToolsItem>
                <Button
                  title="Edit User"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit && openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete User"
                  type="tooltip"
                  scale={false}
                  handleClick={() =>
                    openDelete && openDelete(value.row.original)
                  }
                />
              </ActionToolsItem>
            </ActionTools>
          </>
        ),
      },
    ],
    [openEdit, openDelete, openReivite, permissions, customer, id, parentId, subcustomers]
  );

  return (
    <>
      <ReactTable columns={columns} data={data} noData={noData} />
    </>
  );
};

export default Users;

interface IUsers {
  data: Array<SingleUserT>;
  noData: boolean;
  configuration: Array<string>;
  dashboard: Array<string>;
  management: Array<string>;
  openEdit?: (e: IUserValues) => void;
  openDelete?: (e: IUserValues) => void;
  openReivite?: (e: IUserValues) => void;
}

export type SingleUserT = {
  id: string;
  photoData: {
    content: {
      type: "Buffer";
      data: number[];
    };
    mime: string;
  };
  name: string;
  email: string;
  contactNumber: string;
  expireDate: number;
  status: string;
  orgPermission: "fullAccess" | "data" | "none";
  structurePermission: "fullAccess" | "data" | "none";
  isAccount: boolean;
  configuration: Array<string>;
  dashboard: Array<string>;
  management: Array<string>;
};
