import React from "react";
import styles from "./ReactTable.module.scss";
import { usePagination, useSortBy, useTable } from "react-table";
import Panel from "../../Panel/Panel";
import Loader from "../../Loader/Loader";
import { ReactComponent as IconArrow } from "../../../assets/icons/chevron.svg";
import { ReactComponent as IconChevron } from "../../../assets/icons/calendar-chevron.svg";
import cn from "classnames";
import Select from "react-select";
import { DropdownIndicator } from "../../formElements/SelectInput/SelectInput";
// import PanelHeader from "../../Panel/PanelHeader/PanelHeader";
// import Heading from "../../Heading/Heading";
// import PanelTools from "../../Panel/PanelTools/PanelTools";
// import ActionTools from "../../ActionTools/ActionTools";
// import ActionToolsItem from "../../ActionTools/ActionToolsItem/ActionToolsItem";
import { ITable, ISortType } from './ReactTable.type'

export type { ISortType }

const Table = (props: ITable) => {
  const {
    columns,
    data,
    noData,
    sort = true,
    paddings = true,
    status,
    // heading,
    performanceTable
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: performanceTable ? 5 : 10,
        hiddenColumns: columns
          .filter((column) => column.show === false)
          .map((column) => column.accessor),
        sortBy: [
          {
            id: columns[0]?.accessor,
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <Panel size={4} padding={false}>
      {/* {heading && (
        <PanelHeader className={styles.header}>
          <Heading title={heading} Tag="h3" weight="medium" />
          <PanelTools>
            <ActionTools>
              <ActionToolsItem>
                <button>Switch to Agents</button>
              </ActionToolsItem>
            </ActionTools>
          </PanelTools>
        </PanelHeader>
      )} */}
      <div className={styles.wrapper}>
        <div className={styles.scrollable}>
          <table
            {...getTableProps()}
            className={cn(styles.element, status && styles["withStatus"])}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={cn(
                        styles.th,
                        !paddings && "px-0",
                        // @ts-ignore
                        styles[`${column.className}`]
                      )}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {sort && column.canSort && (
                        <span className={styles.sorting}>
                          <IconArrow
                            className={cn(
                              styles.iconArrow,
                              column.isSorted &&
                              column.isSortedDesc &&
                              styles["isActive"]
                            )}
                          />
                          <IconArrow
                            className={cn(
                              styles.iconArrow,
                              styles["isDown"],
                              column.isSorted &&
                              !column.isSortedDesc &&
                              styles["isActive"]
                            )}
                          />
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {!noData ? (
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className={cn(styles.td, !paddings && "px-0")}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0]?.headers.length}>
                      <Loader
                        container
                        className="p-5 d-flex align-items-center justify-content-center"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            ) : null}
          </table>
        </div>
        {!noData ? (
          <div className={cn(styles.pagination, !paddings && "px-0")}>
            <div className={styles.buttonsWrapper}>
              <button
                className={styles.button}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {
                  <>
                    <IconChevron className={styles.iconChevron} />
                    <IconChevron className={styles.iconChevron} />
                  </>
                }
              </button>
              <button
                className={styles.button}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {<IconChevron className={styles.iconChevron} />}
              </button>
              <div className={styles.inputWrapper}>
                <input
                  className={styles.input}
                  type="number"
                  placeholder={String(pageIndex + 1)}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
                <span className={styles.inputOf}>of {pageOptions.length}</span>
              </div>
              <button
                className={styles.button}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {
                  <IconChevron
                    className={cn(styles.iconChevron, styles["isRight"])}
                  />
                }
              </button>
              <button
                className={styles.button}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {
                  <>
                    <IconChevron
                      className={cn(styles.iconChevron, styles["isRight"])}
                    />
                    <IconChevron
                      className={cn(styles.iconChevron, styles["isRight"])}
                    />
                  </>
                }
              </button>
            </div>
            {data.length > 10 && !performanceTable && (
              <Select
                styles={customTableSelectStyles}
                className={styles.select}
                options={[
                  { value: 10, label: 10 },
                  { value: 20, label: 20 },
                  { value: 30, label: 30 },
                ]}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                value={{ value: pageSize, label: pageSize }}
                onChange={(value) => value && setPageSize(value.value)}
                menuPlacement="auto"
                isSearchable={false}
              />
            )}
          </div>
        ) : null}
      </div>
    </Panel>
  );
};

export default Table;




export const customTableSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 3,
    boxShadow: state.isFocused ? null : null,
    cursor: "pointer",
    fontSize: "1.2rem",
    minHeight: "3.4rem",
    opacity: state.isDisabled ? 0.5 : 1,
    width: "6rem",
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: "0",
    minWidth: "2.4rem",
    justifyContent: "center",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 3,
    hyphens: "auto",
    marginBottom: "0.4rem",
    textAlign: "left",
    wordWrap: "break-word",
    boxShadow: "0 8px 6px 0px rgba(25, 40, 60, 0.04)",
    border: "1px solid rgba(25, 40, 60, 0.04)",
    width: "6rem",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    fontSize: "1.2rem",
    color: "#8A97AD",
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#566ff6",
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    padding: "0.8rem 1.6rem",
    backgroundColor: [
      state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
      state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
    ],
    "&:active": {
      backgroundColor: [
        state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
        state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
      ],
    },
    color: state.isSelected ? "#3A4EB8 !important" : null,
    fontWeight: state.isSelected ? "600" : null,
    fontSize: ["1.2rem", state.isSelected ? "1.2rem" : null],
    cursor: "pointer",
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    height: "2.2rem",
    alignItems: "center",
    justifyContent: "center",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "#8a97ad",
    };
  },
};
