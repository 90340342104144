import React, { useEffect, useState } from "react";
import styles from "../ProfileSettingsUser/ProfileSettingsUser.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import ProfileSettingsLogo from "../ProfileSettingsLogo/ProfileSettingsLogo";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import { Form, Formik } from "formik";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import { updateCustomer } from "../../../store/reducers/authorization";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import axios from "../../../api/axiosConfig";
import { AppState } from "../../../store/reducers";

const ProfileSettingsCustomer = () => {
  
  const auth = useSelector((state: any) => state.authorization);
  const customerContextState = useSelector(
    (state: AppState) => state.customerContextState
  );
  const [user] = useState<InitialValuesT>(auth);
  const [logo, setLogo] = useState('');
  const [companyData, setCompanyData] = useState<any>({});
  // @ts-ignore
  const companyInfo = JSON.parse(localStorage.getItem("customerContext"));

  useEffect(() => {
    (async () => {
      try {
        const result = await axios(`/configuration/company/${companyInfo.id}`);
        if (result && result.status === 200) {
          setCompanyData(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [companyInfo.id, customerContextState.id]);

  const initialValues = {
    photoLinkCustomer: user?.photoBlobCustomer ?? "",
    photoTypeCustomer: user?.photoTypeCustomer ?? "",
    clientId: companyData?.id ?? "",
    email: companyData?.email ?? "",
    name: companyData?.name ?? "",
    contactNumber: companyData?.contactNumber ?? "",
    description: companyData?.description ?? "",
    delayTarget: companyData?.slaTargets?.networkSla?.delayTarget ?? "",
    jitterTarget: companyData?.slaTargets?.networkSla?.jitterTarget ?? "",
    lossTarget: companyData?.slaTargets?.networkSla?.lossTarget ?? "",
    bandwidthSpeedTarget:
      companyData?.slaTargets?.networkSla?.bandwidthSpeedTarget ?? "",
    availabilityTarget:
      companyData?.slaTargets?.networkSla?.availabilityTarget ?? "",
    dnsNumberTarget: companyData?.slaTargets?.networkSla?.dnsNumberTarget ?? "",
    hopsTarget: companyData?.slaTargets?.networkSla?.numberOfHopsTarget ?? "",
    transactionTimeTarget:
      companyData?.slaTargets?.networkSla?.transactionTimeTarget ?? "",
    bandwidthScoreTarget:
      companyData?.slaTargets?.networkSla?.bandwidthScoreTarget ?? "",
    availabilityAppTarget:
      companyData?.slaTargets?.appSla?.availabilityTarget ?? "",
    mosTarget: companyData?.slaTargets?.appSla?.mosTarget ?? "",
    transactionTimeAppTarget:
      companyData?.slaTargets?.appSla?.transactionTimeTarget ?? "",
    slaScoreTarget: companyData?.slaTargets?.appSla?.slaScoreTarget ?? "",
    latencyTarget: companyData?.slaTargets?.appSla?.latencyTarget ?? "",
    lossAppTarget: companyData?.slaTargets?.appSla?.lossTarget ?? "",
    loadTimeTarget: companyData?.slaTargets?.appSla?.loadTimeTarget ?? "",
    dnsTimeTarget: companyData?.slaTargets?.appSla?.dnsTimeTarget ?? "",
    employeeCostOfDowntimeTarget:
      companyData?.slaTargets?.agentFinancialSla?.employeeCostTarget ?? "",
    agentCostOfDowntimeTarget:
      companyData?.slaTargets?.agentFinancialSla?.agentCostTarget ?? "",
    bandwidthCostMonthTarget:
      companyData?.slaTargets?.agentFinancialSla?.bandwidthCostMonthTarget,
    bandwidthCostMinuteTarget:
      companyData.slaTargets?.agentFinancialSla?.bandwidthCostMinuteTarget,
    locationCostTarget:
    companyData?.slaTargets?.agentFinancialSla?.locationCostTarget,
    costDowntime: companyData.costDowntime ?? "",
    costDowntimeCurrency: companyData.costDowntimeCurrency ?? "",
  };

  type InitialValuesT = {
    photoBlobCustomer?: Blob | null;
    photoLinkCustomer?: string | undefined | null;
    photoTypeCustomer?: string | undefined;
    customer: {
      id: string;
      email: string;
      name: string;
      contactNumber?: string;
      description?: string | null;
      delayTarget: number | null;
      jitterTarget: number;
      lossTarget: number;
      bandwidthSpeedTarget: number;
      availabilityTarget: number;
      costDowntime: number;
      costDowntimeCurrency: string;
      slaTargets: any;
    };
  };

  const dispatch = useDispatch();

  async function handleSubmit(values: any) {
    await dispatch(updateCustomer(values));
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 500);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className={styles.element}>
            <LayoutHeader title="Company Settings">
              <Button
                title="Save"
                typeAttr={"submit"}
                icon={<IconButton />}
                icon_position="right"
              />
            </LayoutHeader>
            <div className={styles.wrapper}>
              <ProfileSettingsLogo
                onSetValue={setFieldValue}
                userName={user.customer.name}
                setLogo={setLogo}
                logo={logo}
              />
              <Row>
                <Column className={cn(styles.column, "col-12 col-md-6")}>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Company Information
                    </span>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="clientId"
                      label="Client ID"
                      isBgGray={true}
                      required
                      disabled
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="email"
                      label="Email"
                      placeholder="Email address"
                      textInputType="email"
                      isBgGray={true}
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="name"
                      label="Company name"
                      placeholder="Company name"
                      textInputType="text"
                      isBgGray={true}
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="contactNumber"
                      label="Contact number"
                      placeholder="Add contact number"
                      isBgGray={true}
                    />
                    <TextareaInput
                      touched={touched}
                      errors={errors}
                      name="description"
                      label="Description"
                      placeholder="Description/Comment"
                      rows={3}
                      isBgGray={true}
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="costDowntime"
                      label="Cost Downtime"
                      placeholder="Add Cost Downtime"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <SelectInput
                      touched={touched}
                      errors={errors}
                      name="costDowntimeCurrency"
                      options={[
                        { label: "$", value: "$" },
                        { label: "€", value: "€" },
                        { label: "£", value: "£" },
                      ]}
                      label="Cost Downtime Currency"
                      placeholder="Select Currency"
                      required
                    />
                  </div>
                </Column>
                <Column className={cn(styles.column, "col-12 col-md-6")}>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Agent financial SLA targets
                    </span>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="employeeCostOfDowntimeTarget"
                      label="Employee Cost of downtime per min Target"
                      placeholder="Add employee cost of downtime"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="agentCostOfDowntimeTarget"
                      label="Agent cost of downtime per min Target"
                      placeholder="Add agent cost of downtime"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="bandwidthCostMonthTarget"
                      label="Bandwidth Cost per MB per month Target"
                      placeholder="Add bandwidth cost"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="bandwidthCostMinuteTarget"
                      label="Bandwidth Cost per MB per min Target"
                      placeholder="Add bandwidth cost"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="locationCostTarget"
                      label="Location cost of downtime Target"
                      placeholder="Add location cost of downtime Target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                  </div>
                </Column>
              </Row>
              <Row>
                <Column className={cn(styles.column, "col-12 col-md-6")}>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Network SLA targets
                    </span>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="delayTarget"
                      label="Delay target (ms)"
                      placeholder="Add delay target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="jitterTarget"
                      label="Jitter target (ms)"
                      placeholder="Add jitter target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="lossTarget"
                      label="Loss target (ms)"
                      placeholder="Add loss target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="bandwidthSpeedTarget"
                      label="Bandwidth speed target (Mbps)"
                      placeholder="Add bandwidth speed target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="availabilityTarget"
                      label="Availability target (%)"
                      placeholder="Add availability target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="dnsNumberTarget"
                      label="DNS number target"
                      placeholder="Add DNS number target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="hopsTarget"
                      label="Number of hops target"
                      placeholder="Add number of hops target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="transactionTimeTarget"
                      label="Transaction time target (s)"
                      placeholder="Add transaction time target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="bandwidthScoreTarget"
                      label="Bandwidth score target"
                      placeholder="Add bandwidth score target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                  </div>
                </Column>
                <Column className={cn(styles.column, "col-12 col-md-6")}>
                  <div className={styles.section}>
                    <span className={styles.sectionTitle}>
                      Application SLA targets
                    </span>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="availabilityAppTarget"
                      label="Availability target (%)"
                      placeholder="Add availability target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="mosTarget"
                      label="MOS target"
                      placeholder="Add MOS target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="transactionTimeAppTarget"
                      label="Transaction time target (s)"
                      placeholder="Add transaction time target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="slaScoreTarget"
                      label="SLA score target"
                      placeholder="Add SLA score target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="latencyTarget"
                      label="Latency target"
                      placeholder="Add latency target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="lossAppTarget"
                      label="Loss target"
                      placeholder="Add loss target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="loadTimeTarget"
                      label="Load time target"
                      placeholder="Add load time target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="dnsTimeTarget"
                      label="DNS time target"
                      placeholder="Add DNS time target"
                      isBgGray={true}
                      textInputType="number"
                      required
                    />
                  </div>
                </Column>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProfileSettingsCustomer;
