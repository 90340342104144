/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./ModalAddApiApp.module.scss";
import Button from "../../Button/Button";
import {
  ReactComponent as IconCheck,
  ReactComponent as IconButton,
} from "../../../assets/icons/check.svg";
import tableStyles from "../../tableElements/ReactTable/ReactTable.module.scss";
import axiosConfig from "../../../api/axiosConfig";
import Select from "react-select";

const ModalAddItem = (props: IModalAddItem) => {
  const {
    setModalOpen,
    handleUpdate,
    list,
    preselected,
    setSubtestNames,
    subtestNames,
    options,
  } = props;

  const [data, setTableData] = useState<any[]>([]);
  const [tests, setTests] = useState<any[]>([]);
  const [selectedApps, setSelectedApps] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");

  const GetTests = async () => {
    try {
      await axiosConfig(`/configuration/test`).then((res) => {
        if (res && res.status === 200) {
          setTests(res.data);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      if (preselected?.length) {
        setSelectedApps(preselected);
      }
    }
  };

  useEffect(() => {
    if (!tests.length) {
      GetTests();
    }
  }, []);

  const formatSubtests = (value: string) => {
    const pos = tests.findIndex((test: any) => test.name === value);
    return tests[pos]?.subtests.map(
      ({ aliesName, name }: { aliesName: string; name: string }) => ({
        label: aliesName,
        value: name,
      })
    );
  };

  const showSelectedSubtests = (id: string) => {
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    return selectedApps[index].subtests;
  };

  const setSelectedSubTests = (subtestNames: any, id: string) => {
    setSubtestNames((prevState: any) => ({ ...prevState, [id]: subtestNames }));
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    let newArr = [...selectedApps];
    if (subtestNames?.length > newArr[index].subtests.length) {
      newArr[index].subtests = [
        ...newArr[index].subtests,
        ...subtestNames.slice(-1)?.map((subTest: any) => ({
          name: subTest.value,
          countOfDay: 1,
        })),
      ];
      setSelectedApps(newArr);
    } else {
      newArr[index].subtests = newArr[index].subtests.filter((obj1: any) =>
        subtestNames.some((obj2: any) => {
          return obj1.name === obj2.value;
        })
      );
      setSelectedApps(newArr);
    }
  };

  const setFrequencyOfSubTests = (
    frequency: { value: string; label: string } | null,
    id: string,
    subTestIndex: number
  ) => {
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    let newArr = [...selectedApps];
    newArr[index].subtests[subTestIndex].countOfDay = frequency?.value;
    setSelectedApps(newArr);
  };

  const setFrequencyOfApp = (
    frequency: { value: string; label: string } | null,
    id: string
  ) => {
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    let newArr = [...selectedApps];
    newArr[index].countOfDay = frequency?.value;
    setSelectedApps(newArr);
  };

  const frequencyAppValues = (id: string) => {
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    return selectedApps[index]?.countOfDay || 1;
  };

  const frequencyAppLabel = (id: string) => {
    const value = frequencyAppValues(id);
    const optionsIndex = options.findIndex((apps: any) => apps.value === value);
    return options[optionsIndex]?.label;
  };

  const frequencyDefValues = (id: string, subTestIndex: number) => {
    const index = selectedApps.findIndex((apps: any) => apps.id === id);
    return selectedApps[index].subtests[subTestIndex].countOfDay;
  };

  const frequencyDefLabel = (id: string, subTestIndex: number) => {
    const value = frequencyDefValues(id, subTestIndex);
    const optionsIndex = options.findIndex((apps: any) => apps.value === value);
    return options[optionsIndex].label;
  };

  const selectApp = (selectedApp: any) => {
    const index = selectedApps.findIndex((object) => {
      return object.id === selectedApp.id;
    });
    if (index >= 0) {
      setSelectedApps(
        selectedApps.filter(function (el, i) {
          return index !== i;
        })
      );
    } else {
      if (selectedApp.type === "app") {
        selectedApp.countOfDay = 1;
      }
      setSelectedApps([...selectedApps, selectedApp]);
    }
  };

  const setAppBeforeFrequency = (selectedApp: any) => {
    const index = selectedApps.findIndex((object) => {
      return object.id === selectedApp.id;
    });
    if (index >= 0) {
      return;
    } else {
      selectedApp.countOfDay = 1;
      setSelectedApps([...selectedApps, selectedApp]);
    }
  };

  useEffect(() => {
    const timer = () =>
      setTimeout(() => {
        setTableData(list);
      }, 600);
    const timerId = timer();

    return () => {
      clearTimeout(timerId);
    };
  }, [list]);

  return (
    <div className={styles.element}>
      <input
        className={styles.filter}
        placeholder="Search App"
        onChange={(e) =>
          setFilterText(
            e.target.value.replace(/(^\w|\s\w)/g, (m) => m.toLowerCase())
          )
        }
      />
      <div>
        <ul className={styles.appContainer}>
          {data
            .filter((value: any) => {
              return value.name.toLowerCase().includes(filterText);
            })
            .map(
              ({ id, name, type, subtests, aliesName }: any, AppApiIndex) => {
                return (
                  <li className={styles.list} key={id}>
                    <div
                      onClick={() => selectApp({ id, name, type, subtests })}
                      className={tableStyles.box}
                    >
                      {selectedApps.find(
                        (selected: any) => selected.id === id
                      ) && <IconCheck className={tableStyles.icon} />}
                    </div>
                    <div className={type === "app" ? styles.app : styles.api}>
                      <p>{name + `${aliesName ? ` - ${aliesName}` : ""}`}</p>
                      {type === "app" && (
                        <div>
                          <p>Frequency</p>
                          <Select
                          on
                          onMenuOpen={() => setAppBeforeFrequency({ id, name, type, subtests })}
                            options={options}
                            onChange={(option: any) =>
                              setFrequencyOfApp(option, id)
                            }
                            value={{
                              value: frequencyAppValues(id),
                              label: frequencyAppLabel(id),
                            }}
                            menuPlacement="auto"
                            isSearchable={false}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      )}
                      {type === "api" &&
                        selectedApps.find(
                          (selected: any) => selected.id === id
                        ) && (
                          <>
                            <Select
                              styles={selectStyle}
                              isMulti
                              options={formatSubtests(name)}
                              onChange={(option: any) =>
                                setSelectedSubTests(option, id)
                              }
                              menuPlacement="auto"
                              isSearchable={false}
                              placeholder="Select Subtests"
                              value={subtestNames[id]}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                            {showSelectedSubtests(id)?.map(
                              (subTest: any, subTestIndex: number) => {
                                return (
                                  <div
                                    className={styles.subTestsList}
                                    key={subTest.name}
                                  >
                                    <p>{subTest.name}</p>
                                    <div>
                                      <p>Frequency</p>
                                      <Select
                                        options={options}
                                        onChange={(option: any) =>
                                          setFrequencyOfSubTests(
                                            option,
                                            id,
                                            subTestIndex
                                          )
                                        }
                                        value={{
                                          value: frequencyDefValues(
                                            id,
                                            subTestIndex
                                          ),
                                          label: frequencyDefLabel(
                                            id,
                                            subTestIndex
                                          ),
                                        }}
                                        menuPlacement="auto"
                                        isSearchable={false}
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                    </div>
                  </li>
                );
              }
            )}
        </ul>
      </div>
      <div className={styles.footer}>
        <Button
          title="Cancel"
          type="plain"
          typeAttr="button"
          handleClick={() => {
            setModalOpen(false);
          }}
        />
        <Button
          title="Add"
          typeAttr="submit"
          icon={<IconButton />}
          icon_position="right"
          handleClick={() => {
            setModalOpen(false);
            handleUpdate(selectedApps);
          }}
        />
      </div>
    </div>
  );
};

export default ModalAddItem;

const selectStyle = {
  control: (provided: any) => ({
    ...provided,
    marginTop: "10px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
};

interface IModalAddItem {
  subtestNames: any[];
  list: Array<any>;
  preselected?: any;
  setSubtestNames: any;
  options: Array<{ label: string; value: number }>;
  setModalOpen: (e: boolean) => void;
  handleUpdate: (e: any) => void;
}
