import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import styles from "./UserFormTemplate.module.scss";
import TextInput from "../formElements/TextInput/TextInput";
import Button from "../Button/Button";
import { loginUser } from "../../store/reducers/authorization";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconLogin } from "../../assets/icons/logout.svg";

const SignInForm = ({ setNoAccess }: any) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const dispatch = useDispatch();
  const [hasError, setError] = useState(null);
  const { error } = useSelector((state: any) => state.authorization);

  useEffect(() => {
    if (error === 403) {
      setNoAccess(true);
    } else setError(error);
  }, [error, setNoAccess]);

  useEffect(() => {
    setError(null);
  }, []);

  async function submitLogin(values: any) {
    await dispatch(
      loginUser({
        login: values.email,
        password: values.password,
      })
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitLogin(values);
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          className={styles.element}
        >
          <TextInput
            touched={touched}
            errors={errors}
            name="email"
            label="Email"
            placeholder="Email address"
            textInputType="email"
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="password"
            label="Password"
            placeholder="Password"
            textInputType="password"
            forgetPasswordLink
            required
          />

          <div className={styles.footer}>
            {hasError && <div className={styles.errorMessage}>{hasError}</div>}
            <Button
              typeAttr="submit"
              title="Sign in"
              icon={<IconLogin />}
              icon_position="right"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default SignInForm;
