import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import styles from "./Search.module.scss";
import {Container} from "../../components/Bootstrap/Bootstrap";
import {NavLink} from "react-router-dom";

const SearchView = () => {

    const [query, setQuery] = useState<string | null>('');

    const history = useHistory();

    const params = new URLSearchParams(history.location.search).get('query');

    useEffect(() => {
        setQuery(params);
    }, [params]);



    const view = [
        {
            title: "Partner",
            in: "Partners list",
            url: "/partner/3"
        },
        {
            title: "Partner",
            in: "Partners list",
            url: "/partner/3"
        },
        {
            title: "Partner",
            in: "Partners list",
            url: "/partner/3"
        }
    ]

    if (query)
        return (
            <Container fluid>
                <LayoutHeader title={query}/>
                <div className={styles.element}>
                    <div className={styles.title}>Search results</div>
                    <table className={styles.table}>
                        <tbody>
                        {view.map((row, key) => {
                            return (
                                <tr className={styles.tr} key={key}>
                                    <td className={styles.td}>
                                        <NavLink className={styles.link} to={row.url}>
                                            <span className={styles.itemTitle}>{row.title}</span>{row.in && <span className={styles.itemIn}>&nbsp;in {row.in}</span>}
                                        </NavLink>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>

            </Container>
        )
    return null;
}

export default SearchView;
