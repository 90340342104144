import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import authorization, { ActionTypeT, LOGOUT_SUCCESS } from "./authorization";
import menuState from "./ToggleMenuState";
import themeState from "./ToggleThemeState";
import notificationsBarState from "./notifications";
import dateRangeState from "./dateRange";
import timeRangeState from "./SetTimeRange";
import customerContextState from "./customerContext";
import filterState from "./filter";
import customersState from "./ManageCustomers";
import usersState from "./ManageUsers";
import dashboardPresetsState from "./DashboardPresets";
import SettingsState from "./SetSettings";
import SLATargetsState from "./SLATargets";
import DevicesState from "./Devices";
import DeviceState from "./Device";
import EmployeesState from "./Employees";

import ImportantMetricsState from "./widgets/ImportantMetrics";
import GaugeResultsState from "./widgets/GaugeResults";
import ComparisonBoxState from "./widgets/ComparisonBox";
import TableState from "./widgets/Table";
import TableDevicesState from "./widgets/TableDevices";
import ColumnLineChartState from "./widgets/ColumnLineChart";
import heatmapState from "./widgets/Heatmap";
import PathAnalyticsState from "./widgets/PathAnalytics";
import agentDiscoveredDeviceState from './widgets/AgentsDiscoveredDevicesTable';
import employeeCallsState from './widgets/EmployeeCallsTable';
import conferenceCallsState from './widgets/ConferenceCallsTable';
import employeeDevicesState from './widgets/EmployeeDevicesTable';
import AppsPerformanceExtendedTable from './widgets/AppsPerformanceExtendedTable';
import CallerCalleeDevicesTable from './widgets/CallerCalleeDevicesTable';
import employeeCallerMetricsState from './widgets/EmployeeCallerMetricsTable';
import employeeCalleeOriginatorMetricsState from './widgets/EmployeeCalleeOriginatorMetricsTable';

const createAppReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authorization,
    menuState,
    themeState,
    notificationsBarState,
    dateRangeState,
    timeRangeState,
    customerContextState,
    filterState,
    customersState,
    usersState,
    dashboardPresetsState,
    SettingsState,
    SLATargetsState,
    ImportantMetricsState,
    GaugeResultsState,
    ComparisonBoxState,
    TableState,
    TableDevicesState,
    ColumnLineChartState,
    heatmapState,
    PathAnalyticsState,
    DevicesState,
    DeviceState,
    EmployeesState,
    employeeCallsState,
    employeeCallerMetricsState,
    employeeCalleeOriginatorMetricsState,
    conferenceCallsState,
    employeeDevicesState,
    agentDiscoveredDeviceState,
    AppsPerformanceExtendedTable,
    CallerCalleeDevicesTable
  });

type AppReducerT = ReturnType<typeof createAppReducer>;
export type AppState = ReturnType<AppReducerT>;

const initialState: AppState = {} as AppState;

let appReducer: AppReducerT;

function Container(history: History) {
  if (!appReducer) {
    appReducer = createAppReducer(history);
  }

  return function (
    state: AppState = initialState,
    action: ActionTypeT
  ): AppState {
    if (action.type === LOGOUT_SUCCESS) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
}
export default Container;
