export const GET_TABLE = "GET_TABLE";
export const GET_TABLE_DONE = "GET_TABLE_DONE";
export const GET_TABLE_FAILED = "GET_TABLE_FAILED";

export type TableDataT = Array<any>;

export type TableColumnsT = Array<
  {
    Header: string,
    accessor: string,
    type: string,
  }>;

export type TableParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  agentId: string
}

export type GetTableT = {
  type: typeof GET_TABLE;
  params: TableParamsT
}

export type GetTableDoneT = {
  type: typeof GET_TABLE_DONE;
  chartType: string,
  data: TableDataT
}

export type GetTableFailedT = {
  type: typeof GET_TABLE_FAILED;
  chartType: string,
}

export type TableStateActionT = GetTableT | GetTableDoneT | GetTableFailedT