import React, { useCallback, useEffect, useState } from "react";
import styles from "../AgentsKnownManagement/AgentsKnownManagement.module.scss";
import DevicesAdded from "../../widgets/DevicesAdded/DevicesAdded";
import Modal from "../../components/Modal/Modal";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/hyperlink.svg";
import ModalConnectNewDevice from "../../components/Modal/ModalConnectNewDevice/ModalConnectNewDevice";
import { GetDevicesList } from "../../store/actions/Devices";
import { DeviceT } from "../../store/types/Devices";
import { DeleteDevice } from "../../store/actions/Device";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";

const DevicesAddedManagementView = () => {
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<DeviceT[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<DeviceT>();
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id } = useSelector((state: AppState) => state.customerContextState);
  const { devicesAdded, isLoading, error } = useSelector(
    (state: AppState) => state.DevicesState
  );
  const [noData, setNoData] = useState<boolean>(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(
    (id, isAdded) => {
      dispatch(GetDevicesList(id, isAdded));
    },
    [dispatch]
  );

  useEffect(() => {
    let isMounted = true;
    isMounted && id && fetchData(id, true);
    return () => {
      isMounted = false;
    };
  }, [fetchData, id]);

  useEffect(() => {
    if (isLoading) {
      setTableData([]);
      setNoData(false);
    } else {
      if (devicesAdded) {
        if (devicesAdded.length) {
          setTableData(devicesAdded);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } else {
        setNoData(true);
      }
    }
  }, [isLoading, devicesAdded]);

  const openDelete = (device: DeviceT) => {
    setSelectedDevice(device);
    setModalDeleteOpen(true);
  };

  const handleDelete = () => {
    selectedDevice?.id && dispatch(DeleteDevice(selectedDevice.id));
  };

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Devices">
          {checkEditAccess(permissions, customer, id) && (
            <Button
              title="Add Device"
              icon={<IconButton />}
              icon_position="right"
              handleClick={() => setModalAddOpen(true)}
            />
          )}
        </LayoutHeader>
        {customer.mainCustomer && (
          <FilterTabs
            links={[
              {
                title: "Discovered Devices",
                path: "/devices-management/discovered",
              },
              {
                title: "Added Devices",
                path: "/devices-management/added",
              },
            ]}
          />
        )}
        <DevicesAdded
          data={tableData}
          openDelete={openDelete}
          noData={noData}
        />
        {noData && (
          <NoDataPlaceholder
            image
            title={
              error
                ? "Something goes wrong. Try again later"
                : "No devices were added"
            }
          />
        )}
      </Container>
      {selectedDevice && (
        <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
          <ModalDelete
            name="Device"
            endpoint={""}
            setModalOpen={setModalDeleteOpen}
            handleClick={handleDelete}
            handleUpdateData={() => fetchData(id, true)}
          >
            <ModalDeleteElem>
              <div>
                <span className={styles.name}>
                  {selectedDevice.device.name}
                </span>
                <span className={styles.id}>
                  ID: {selectedDevice.device.id}
                </span>
              </div>
            </ModalDeleteElem>
          </ModalDelete>
        </Modal>
      )}
      <Modal modalOpen={modalAddOpen} setModalOpen={setModalAddOpen}>
        <ModalConnectNewDevice
          setModalOpen={setModalAddOpen}
          handleUpdateData={() => fetchData(id, true)}
        />
      </Modal>
    </>
  );
};

export default DevicesAddedManagementView;
