import { ComparisonBoxParamsT, ComparisonBoxT, GetComparisonBoxDoneT, GetComparisonBoxFailedT, GetComparisonBoxT, GET_COMPARISON_BOX, GET_COMPARISON_BOX_DONE, GET_COMPARISON_BOX_FAILED } from "../../types/widgets/ComparisonBox.types";


export const GetComparisonBox = (
  params: ComparisonBoxParamsT
): GetComparisonBoxT => ( {
  type: GET_COMPARISON_BOX,
  params,
} );

export const GetComparisonBoxDone = (
  chartType: string,
  data: ComparisonBoxT
): GetComparisonBoxDoneT => ( {
  type: GET_COMPARISON_BOX_DONE,
  chartType,
  data,
} );

export const GetComparisonBoxFailed = ( chartType: string ): GetComparisonBoxFailedT => ( {
  type: GET_COMPARISON_BOX_FAILED,
  chartType,
} );