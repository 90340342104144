import {GetTableDoneT, GetTableFailedT, GetTableT, GET_TABLE_DEVICES, GET_TABLE_DEVICES_DONE, GET_TABLE_DEVICES_FAILED, TableDataT, TableParamsT} from "../../types/widgets/TableDevices.types";

export const GetTable = (
  params: TableParamsT
): GetTableT => ({
  type: GET_TABLE_DEVICES,
  params
});

export const GetTableDone = (
  chartType: string,
  data: TableDataT
): GetTableDoneT => ({
  type: GET_TABLE_DEVICES_DONE,
  chartType,
  data,
});

export const GetTableFailed = (chartType: string): GetTableFailedT => ({
  type: GET_TABLE_DEVICES_FAILED,
  chartType,
});