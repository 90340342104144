import React, { useEffect, useState } from "react";
import styles from "./ThemeSwitch.module.scss";
import { useDispatch } from "react-redux";

import { ReactComponent as IconMoon } from "../../assets/icons/moon.svg";
import { ReactComponent as IconSun } from "../../assets/icons/sun.svg";
import { ToggleTheme } from "../../store/actions/ToggleTheme.action";

const ThemeSwitch = () => {
  const [theme, setTheme] = useState<"dark" | "light">(
    window.localStorage.getItem("theme")
      ? (window.localStorage.getItem("theme") as "dark" | "light")
      : "light"
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  useEffect(() => {
    dispatch(ToggleTheme(theme));
    window.localStorage.setItem("theme", theme);
  }, [theme, dispatch]);

  return (
    <button className={styles.element} onClick={() => handleClick()}>
      {theme === "dark" ? (
        <IconSun className={styles.icon} />
      ) : (
        <IconMoon className={styles.icon} />
      )}
      {theme === "dark" ? "Light Mode" : "Dark Mode"}
    </button>
  );
};

export default ThemeSwitch;
