import React from "react";
import styles from "./ExpandGroup.module.scss";
import cn from "classnames";
// import {IExpand} from "../Expand";


const ExpandGroup = (props: IExpandGroup) => {

    const {children, border = true} = props;


    return(
        <div className={cn(styles.element, border && styles['hasBorder'])}>
            {children}
        </div>
    )
}

export default ExpandGroup;


interface IExpandGroup {
    children: any;
    border?: boolean;
}
