import React, { useState } from "react";
import styles from "./SidebarMenuSystem.module.scss";
import { NavLink } from "react-router-dom";
import Button from "../../../Button/Button";
import { ReactComponent as IconExpand } from "../../../../assets/icons/expand.svg";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";
import { useDispatch } from "react-redux";
import { ToggleMenu } from "../../../../store/actions/ToggleMenu.action";

const SidebarMenuSystem = (props: ISidebarMenuSystem) => {
  const { list } = props;

  return (
    <ul className={styles.list}>
      {list.map((elem, key) => {
        return <SidebarMenuSystemElem {...elem} key={key} />;
      })}
    </ul>
  );
};

export default SidebarMenuSystem;

const SidebarMenuSystemElem = (props: ISidebarMenuSystemElem) => {
  const { title, icon, count, path } = props;

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();

  if (!path) {
    return <MenuItemExpandable {...props} />;
  }

  return (
    <li>
      <NavLink
        onClick={() => isResponsiveView && dispatch(ToggleMenu())}
        exact
        activeClassName={styles["isActive"]}
        to={path}
        className={styles.element}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.title}>{title}</span>
        {count && (
          <span className={styles.counter}>{count < 100 ? count : "99+"}</span>
        )}
      </NavLink>
    </li>
  );
};

const MenuItemExpandable = (props: ISidebarMenuSystemElem) => {
  const { children, icon, title, add_button } = props;
  const [open, setOpen] = useState(false);
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();

  return (
    <li className={cn(children && styles.hasChildren)}>
      <div
        itemType="button"
        className={styles.element}
        onClick={() => setOpen(!open)}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.title}>
          {title}
          <IconExpand
            className={cn(styles.iconExpand, open && styles["isExpanded"])}
          />
        </span>
        {add_button && (
          <Button
            type="outline"
            handleClick={() => isResponsiveView && dispatch(ToggleMenu())}
            path={add_button}
            title="+Add"
            className={cn("ml-auto", styles.addBtn)}
            scale={false}
          />
        )}
      </div>
      {children && open && <MenuItemChildren children={children} />}
    </li>
  );
};

const MenuItemChildren = (props: IMenuItemChildren) => {
  const { children } = props;
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();
  return (
    <ul className={styles.children}>
      {children.map((child, key) => {
        return (
          <li className={styles.childrenItem} key={key}>
            <NavLink
              onClick={() => isResponsiveView && dispatch(ToggleMenu())}
              exact
              activeClassName={styles["isActive"]}
              className={cn(styles.element, styles.child)}
              to={child.path}
            >
              {child.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

interface ISidebarMenuSystem {
  list: Array<ISidebarMenuSystemElem>;
}

export interface ISidebarMenuSystemElem {
  title: string;
  icon: React.ReactElement;
  add_button?: string;
  count?: number;
  path?: string;
  children?: Array<{
    title: string;
    path: string;
  }>;
}

interface IMenuItemChildren {
  children: Array<{
    title: string;
    path: string;
  }>;
}
