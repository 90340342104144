import React, { useState, useEffect } from "react";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import Panel from "../../../components/Panel/Panel";
import Heading from "../../../components/Heading/Heading";
import Label from "../../../components/Label/Label";
import styles from "../../SingleAgent/SingleAgentForm/SingleAgentForm.module.scss";
import { Form, Formik } from "formik";
import AddDescription from "../../../components/formElements/AddDescription/AddDescription";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import ModalSuccess from "../../../components/Modal/ModalSuccess/ModalSuccess";
import CheckboxGroup from "../../../components/formElements/CheckboxGroup/CheckboxGroup";
import RadioGroup from "../../../components/formElements/RadioGroup/RadioGroup";
import AddInterface from "../../../components/formElements/AddInterface/AddInterface";
import cn from "classnames";
import { DeviceT } from "../../../store/types/Device";
import AgentsSelect from "../../../components/formElements/AgentsSelect/AgentsSelect";
import { useDispatch, useSelector } from "react-redux";
import { EditDevice } from "../../../store/actions/Device";
import { AppState } from "../../../store/reducers";
import DeviceLocationGroupSelect from "../../../components/formElements/DeviceLocationGroupSelect copy/DeviceLocationGroupSelect";

const SingleDeviceForm = (props: ISingleDeviceForm) => {
  const { data, handleUpdateData } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { message } = useSelector((state: AppState) => state.DeviceState);

  const initialValues = {
    name: data.name || "",
    locationGroup: data.locationGroup || "",
    country: data.country || "",
    province: data.province || "",
    zipCode: data.zipCode || "",
    city: data.city || "",
    street: data.street || "",
    alias: data.alias || "",
    description: data.description || "",
    bandwidthUpload: data.bandwidthUpload || "",
    bandwidthDownload: data.bandwidthDownload || "",
    deviceType: data.deviceType || "",
    connectionType: data.connectionType || "",
    vendor: data.vendor || "",
    model: data.model || "",
    wifiPassword: "",
    wifiSSID: "",
    snmpVersion: data.snmpVersion || "v2c",
    communityString: data.communityString || "",
    userName: data.userName || "",
    privProtocol: data.privProtocol || "des",
    authProtocol: data.authProtocol || "md5",
    privKey: data.privKey || "",
    authKey: data.authKey || "",
    agent: data?.agent || { value: "", label: "" },
    ip: data.ip || "",
    siteLink: "",
    hybridWan: "",
    interface: data.interface || "",
    interfaceTests: "",
    test: "",
  };

  const restDeviceData = {
    customerId: data.customerId,
    circuitId: data.circuitId,
    mac: data.mac,
    osVersion: data.osVersion,
    latency: data.latency,
    lastTestDate: data.lastTestDate,
    agentName: data.agentName,
  };

  const [version, setVersion] = useState<number>(1);

  const handleToggleVersionFields = (
    version: number,
    errors: any,
    touched: any
  ) => {
    switch (version) {
      case 1:
        return (
          <>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="communityString"
                    label="Community String"
                    placeholder="Enter community string"
                    required
                  />
                </Group>
              </Column>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="userName"
                    label="Username"
                    placeholder="Enter username"
                    required
                  />
                </Group>
              </Column>
            </Row>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Priv protocol" />
                  <RadioGroup
                    name="privProtocol"
                    options={[
                      {
                        value: "des",
                        label: "DES",
                      },
                      {
                        value: "aes128",
                        label: "AES128",
                      },
                    ]}
                    className={styles.radioGroup}
                  />
                </Group>
              </Column>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Auth protocol" />
                  <RadioGroup
                    name="authProtocol"
                    options={[
                      {
                        value: "md5",
                        label: "MD5",
                      },
                      {
                        value: "sha1",
                        label: "SHA1",
                      },
                    ]}
                    className={styles.radioGroup}
                  />
                </Group>
              </Column>

              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="privKey"
                    label="Priv key"
                    placeholder="Enter priv key"
                    required
                  />
                </Group>
              </Column>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="authKey"
                    label="Auth key"
                    placeholder="Enter auth key"
                    required
                  />
                </Group>
              </Column>
            </Row>
          </>
        );
    }
  };

  const handleToggle = (e: number) => {
    setVersion(e + 1);
  };

  useEffect(() => {
    if (message) {
      setModalOpen(true);
      handleUpdateData();
    }
  }, [message, handleUpdateData]);

  useEffect(() => {
    if (modalOpen) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [modalOpen]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const agentId = {
            agentId: values.agent.value,
          };
          const { agent, ...resut } = values;
          setTimeout(() => {
            setSubmitting(false);
            data?.id &&
              dispatch(
                EditDevice(data.id, {
                  ...resut,
                  ...agentId,
                  ...restDeviceData,
                })
              );
          }, 500);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className={styles.element}>
            <LayoutHeader
              pathBack="/devices-management/added"
              title={data.name}
            >
              <Button
                title="Save"
                typeAttr={"submit"}
                icon={<IconButton />}
                icon_position="right"
              />
            </LayoutHeader>
            <Panel className={styles.panel}>
              <Heading
                title="General"
                className={styles.sectionTitle}
                weight="medium"
              />
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Group>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="name"
                      label="Name"
                      placeholder="Enter name"
                    />
                  </Group>
                  <Row>
                    <Column className="col-12 col-sm-6 pr-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="country"
                        label="Country"
                        placeholder="Enter Country"
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pl-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="province"
                        label="State/Province/Region"
                        placeholder="Enter Region"
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pr-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="zipCode"
                        label="Postal Code"
                        placeholder="Enter Postal Code"
                      />
                    </Column>
                    <Column className="col-12 col-sm-6 pl-sm-2">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="city"
                        label="City"
                        placeholder="Enter City"
                      />
                    </Column>
                    <Column className="col-12">
                      <TextInput
                        touched={touched}
                        errors={errors}
                        name="street"
                        label="Street"
                        placeholder="Enter Street, suite, apt."
                      />
                    </Column>
                  </Row>
                  <Group>
                    <Label title="Location group" />
                    <DeviceLocationGroupSelect
                      id={data.customerId}
                      touched={touched}
                      errors={errors}
                      name="locationGroup"
                      className={styles.select}
                    />
                  </Group>
                  <Group>
                    <Label title="Alias" />
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="alias"
                      placeholder="Enter Alias"
                    />
                  </Group>
                  <Group>
                    <Label title="Description" />
                    <AddDescription
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      name="description"
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="bandwidthUpload"
                          label="Bandwidth upload (MB)"
                          placeholder="Enter bandwidth upload in MB"
                          textInputType="number"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="bandwidthDownload"
                          label="Bandwidth download (MB)"
                          placeholder="Enter bandwidth download in MB"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="deviceType"
                          label="Device type"
                          placeholder="Enter device type"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="connectionType"
                          label="Connection type"
                          placeholder="Enter connection type"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="vendor"
                          label="Vendor"
                          placeholder="Enter device vendor"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <TextInput
                          touched={touched}
                          errors={errors}
                          name="model"
                          label="Model"
                          placeholder="Enter device model"
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row>
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Heading
                    title="Network"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Internal IP" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="External IP" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Uptime" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                  </Row>
                </Column>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Heading
                    title="System Info"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="CPU" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Memory" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="OS Type" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="OS Version" />
                        <Heading
                          title={data.osVersion || "-"}
                          Tag="h4"
                          weight="semi-bold"
                        />
                      </Group>
                    </Column>
                    <Column className="col-12 col-lg-6">
                      <Group>
                        <Label title="Internal system name" />
                        <Heading title={"-"} Tag="h4" weight="semi-bold" />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row>
              <Row spacing="large" className={cn(styles.row, styles.noBorder)}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Heading
                    title="Advanced Settings"
                    className={styles.sectionTitle}
                    weight="medium"
                  />
                  <Row>
                    <Column className="col-12">
                      <Group>
                        <Label title="SNMP" />
                        <RadioGroup
                          name="snmpVersion"
                          options={[
                            {
                              value: "v2c",
                              label: "SNMP v2c",
                            },
                            {
                              value: "v3",
                              label: "SNMP v3",
                            },
                          ]}
                          className={styles.radioGroup}
                          handleToggle={handleToggle}
                        />
                      </Group>
                    </Column>
                  </Row>
                </Column>
              </Row>
              {handleToggleVersionFields(version, errors, touched)}
              <Row spacing="large">
                <Column spacing="large" className="col-12 col-lg-6">
                  <Group>
                    <AgentsSelect
                      touched={touched}
                      errors={errors}
                      name="agent"
                      label="Agent"
                      required
                      customValue
                    />
                  </Group>
                </Column>
              </Row>
              <Row spacing="large" className={styles.row}>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Group>
                    <TextInput
                      touched={touched}
                      errors={errors}
                      name="ip"
                      label="Device IP"
                      placeholder="Enter device IP"
                      required
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-3">
                  <Group>
                    <CheckboxGroup
                      className={styles.checkbox}
                      name="siteLink"
                      touched={touched}
                      errors={errors}
                      direction="row"
                      isBgGray
                      options={[
                        {
                          value: "site-link",
                          label: "Site Link",
                        },
                      ]}
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-3">
                  <Group>
                    <CheckboxGroup
                      className={styles.checkbox}
                      name="hybridWan"
                      touched={touched}
                      errors={errors}
                      direction="row"
                      isBgGray
                      options={[
                        {
                          value: "hybrid-wan",
                          label: "Hybrid WAN",
                        },
                      ]}
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-6">
                  <Group>
                    <TextInput
                      className={styles.input}
                      touched={touched}
                      errors={errors}
                      name="interface"
                      label="Interface"
                      placeholder="Enter interface"
                      required
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-3">
                  <Group>
                    <CheckboxGroup
                      className={styles.checkbox}
                      name="test"
                      touched={touched}
                      errors={errors}
                      direction="row"
                      isBgGray
                      options={[
                        {
                          value: "tech-test",
                          label: "Tech Test available to customers",
                        },
                      ]}
                    />
                  </Group>
                </Column>
                <Column spacing="large" className="col-12 col-lg-3">
                  <Group>
                    <Label title="Interface tests" />
                    <AddInterface
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      name="interfaceTests"
                      title="Select interface to test"
                      items={[
                        {
                          key: "1",
                          id: "interface1",
                          title: "Interface 1",
                          description: "Description of interface",
                          number: "45",
                        },
                        {
                          key: "2",
                          id: "interface2",
                          title: "Interface 2",
                          description: "Some text",
                          number: "5",
                        },
                        {
                          key: "3",
                          id: "interface3",
                          title: "Interface 3",
                          description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pretium augue vel magna ultricies finibus vel sit amet sapien. Donec arcu dui, ornare id dolor sit amet, scelerisque lacinia enim.",
                          number: "15",
                        },
                        {
                          key: "4",
                          id: "interface4",
                          title: "Interface 4",
                          description: "",
                          number: "150",
                        },
                        {
                          key: "5",
                          id: "interface5",
                          title: "Interface 5",
                          description: "Description of interface",
                          number: "3",
                        },
                        {
                          key: "6",
                          id: "interface6",
                          title: "Interface 6",
                          description: "Some text",
                          number: "68",
                        },
                        {
                          key: "7",
                          id: "interface7",
                          title: "Interface 7",
                          description: "Description of interface",
                          number: "55",
                        },
                        {
                          key: "8",
                          id: "interface8",
                          title: "Interface 8",
                          description: "",
                          number: "1",
                        },
                        {
                          key: "9",
                          id: "interface9",
                          title: "Interface 9",
                          description: "Description of interface",
                          number: "3",
                        },
                        {
                          key: "10",
                          id: "interface10",
                          title: "Interface 10",
                          description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pretium augue vel magna ultricies finibus vel sit amet sapien. Donec arcu dui, ornare id dolor sit amet, scelerisque lacinia enim.",
                          number: "3",
                        },
                        {
                          key: "11",
                          id: "interface11",
                          title: "Interface 11",
                          description: "Description of interface",
                          number: "3",
                        },
                        {
                          key: "12",
                          id: "interface12",
                          title: "Interface 12",
                          description: "",
                          number: "3",
                        },
                        {
                          key: "13",
                          id: "interface13",
                          title: "Interface 13",
                          description: "Description of interface",
                          number: "3",
                        },
                      ]}
                    />
                  </Group>
                </Column>
              </Row>
            </Panel>
          </Form>
        )}
      </Formik>
      <ModalSuccess
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        text="Device has been successfully updated"
      />
    </>
  );
};

export default SingleDeviceForm;

const Group = (props: {
  children: Array<React.ReactChild> | React.ReactChild;
}) => <div className={styles.group}>{props.children}</div>;

interface ISingleDeviceForm {
  data: DeviceT;
  handleUpdateData: () => void;
}
