import { SET_TIME_RANGE } from "../actions/SetTimeRange.action";

export type StateT = {
  start: number;
  end: number;
};

const initialState: StateT = {
  start: 32400,
  end: 64800,
};

export default function TimeRangeState(
  state = initialState,
  action: {
    type: "SET_TIME_RANGE";
    data: StateT;
  }
) {
  if (action.type === SET_TIME_RANGE) {
    return action.data;
  }
  return state;
}
