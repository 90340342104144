import {
  GetAgentDiscoveredDeviceDoneT,
  GetAgentDiscoveredDeviceFailedT,
  GetAgentDiscoveredDeviceTableT,
  GET_TABLE_AGENT_DISCOVERED_DEVICE,
  GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE,
  GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED,

  AgentDiscoveredDeviceTableParamsT,
  AgentDiscoveredDeviceTableDataT
} from "../../types/widgets/AgentsDiscoveredDevicesTable.types";

export const GetAgentDiscoveredDeviceTable = (params: AgentDiscoveredDeviceTableParamsT): GetAgentDiscoveredDeviceTableT => {
  return {
    type: GET_TABLE_AGENT_DISCOVERED_DEVICE,
    params
  }
};

export const GetAgentDiscoveredDeviceTableDone = (data: AgentDiscoveredDeviceTableDataT): GetAgentDiscoveredDeviceDoneT => ({
  type: GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE,
  data
});

export const GetAgentDiscoveredDeviceFailed = (): GetAgentDiscoveredDeviceFailedT => ({
  type: GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED
});
