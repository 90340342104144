import {ActionTypeT, CLEAN_DEVICE_DETAILS, DELETE_DEVICE, DELETE_DEVICE_DONE, DELETE_DEVICE_FAILED, DeviceT, EDIT_DEVICE, EDIT_DEVICE_DONE, EDIT_DEVICE_FAILED, GET_DEVICE, GET_DEVICE_DONE, GET_DEVICE_FAILED} from "../types/Device";

export type StateT = {
  isLoading: boolean;
  message: boolean;
  data: DeviceT | null;
  error: boolean;
};

const initialState: StateT = {
  isLoading: false,
  message: false,
  data: null,
  error: false,
};

const reducerDevice = (state = initialState, action: ActionTypeT): StateT => {
  switch (action.type) {
    case GET_DEVICE:
      return {...state, isLoading: true, message: false, error: false};
    case GET_DEVICE_DONE:
      return {...state, isLoading: false, data: action.payload};
    case GET_DEVICE_FAILED:
      return {...state, isLoading: false, error: true};
    case CLEAN_DEVICE_DETAILS:
      return {...state, data: null, message: false, error: false};
    case EDIT_DEVICE:
      return {...state, isLoading: true};
    case EDIT_DEVICE_DONE:
      return {...state, isLoading: false, message: true};
    case EDIT_DEVICE_FAILED:
      return {...state, isLoading: false, error: true};
    case DELETE_DEVICE:
      return {...state, isLoading: true};
    case DELETE_DEVICE_DONE:
      return {...state, isLoading: false};
    case DELETE_DEVICE_FAILED:
      return {...state, isLoading: false, error: true};
    default:
      return state;
  }
};

export default reducerDevice;
