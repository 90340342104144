import React, { useCallback, useEffect, useState } from "react";
import DevicesDiscovered from "../../widgets/DevicesDiscovered/DevicesDiscovered";
import Modal from "../../components/Modal/Modal";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import FilterTabs from "../../components/FilterTabs/FilterTabs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import styles from "../AgentsKnownManagement/AgentsKnownManagement.module.scss";
import { GetDevicesList } from "../../store/actions/Devices";
import { DeviceT } from "../../store/types/Devices";
import ModalEditDevice from "../../components/Modal/ModalEditDevice/ModalEditDevice";
import { DeleteDevice } from "../../store/actions/Device";

const DevicesDiscoveredManagementView = () => {
  const [tableData, setTableData] = useState<DeviceT[]>([]);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceT>();
  const [noData, setNoData] = useState<boolean>(false);
  // const { customer } = useSelector((state: AppState) => state.authorization);
  const { id } = useSelector((state: AppState) => state.customerContextState);
  const { devicesDiscovered, isLoading, error } = useSelector(
    (state: AppState) => state.DevicesState
  );
  const dispatch = useDispatch();

  const fetchData = useCallback(
    (id, isAdded) => {
      dispatch(GetDevicesList(id, isAdded));
    },
    [dispatch]
  );

  useEffect(() => {
    let isMounted = true;
    isMounted && id && fetchData(id, false);
    return () => {
      isMounted = false;
    };
  }, [fetchData, id]);

  useEffect(() => {
    if (isLoading) {
      setTableData([]);
      setNoData(false);
    } else {
      if (devicesDiscovered) {
        if (devicesDiscovered.length) {
          setTableData(devicesDiscovered);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } else {
        setNoData(true);
      }
    }
  }, [isLoading, devicesDiscovered]);

  const openEdit = (device: DeviceT) => {
    setSelectedDevice(device);
    setModalEditOpen(true);
  };

  const openDelete = (device: DeviceT) => {
    setSelectedDevice(device);
    setModalDeleteOpen(true);
  };

  const handleDelete = () => {
    selectedDevice?.id && dispatch(DeleteDevice(selectedDevice.id));
  };

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Devices" />
        <FilterTabs
          links={[
            {
              title: "Discovered Devices",
              path: "/devices-management/discovered",
            },
            {
              title: "Added Devices",
              path: "/devices-management/added",
            },
          ]}
        />
        <DevicesDiscovered
          data={tableData}
          openDelete={openDelete}
          openEdit={openEdit}
          noData={noData}
        />
        {noData && (
          <NoDataPlaceholder
            image
            title={
              error
                ? "Something goes wrong. Try again later"
                : "No devices were discovered"
            }
          />
        )}
      </Container>
      {selectedDevice && (
        <>
          <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
            <ModalDelete
              name="Device"
              endpoint={""}
              setModalOpen={setModalDeleteOpen}
              handleClick={handleDelete}
              handleUpdateData={() => fetchData(id, false)}
            >
              <ModalDeleteElem>
                <div>
                  <span className={styles.name}>{selectedDevice.name}</span>
                  <span className={styles.id}>ID: {selectedDevice.id}</span>
                </div>
              </ModalDeleteElem>
            </ModalDelete>
          </Modal>
          <Modal modalOpen={modalEditOpen} setModalOpen={setModalEditOpen}>
            <ModalEditDevice
              device={selectedDevice}
              setModalOpen={setModalEditOpen}
              handleUpdateData={() => fetchData(id, false)}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default DevicesDiscoveredManagementView;
