import React from "react";
import styles from "./TableCellText.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";

const TableCellText = (props: ITableCellText) => {
  const { children, type, noWrap, path } = props;

  if (!path) {
    return (
      <span
        className={cn(
          styles.element,
          type === "bold" && styles["isBold"],
          type === "light" && styles["isLight"],
          noWrap && styles["noWrap"]
        )}
      >
        {children}
      </span>
    );
  }

  return (
    <Link
      to={path}
      className={cn(
        styles.element,
        styles.link,
        type === "bold" && styles["isBold"],
        type === "light" && styles["isLight"],
        noWrap && styles["noWrap"]
      )}
    >
      {children}
    </Link>
  );
};

export default TableCellText;

export interface ITableCellText {
  children?: any;
  type?: "bold" | "light";
  noWrap?: boolean;
  path?: string;
}
