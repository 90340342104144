import React, { useState } from "react";
import styles from "./SwitchElem.module.scss";
import cn from "classnames";

const SwitchElem = (props: ISwitchElem) => {
  const { disabled, label, handleSwitch, className } = props;
  const [active, setActive] = useState(false);

  const handleClick = () => {
    if (handleSwitch) handleSwitch(!active);
    setActive(!active);
  };

  return (
    <div className={cn("d-flex align-items-center", className && className)}>
      <div className={styles.wrapper}>
        <div
          className={cn(
            styles.element,
            active && styles["isActive"],
            disabled && styles["isDisabled"]
          )}
          onClick={() => handleClick()}
        />
      </div>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export default SwitchElem;
interface ISwitchElem {
  disabled: boolean;
  label?: string;
  handleSwitch?: (e: boolean) => void;
  className?: any;
}
