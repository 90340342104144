import { ColumnLineChartParamsT, ColumnLineChartT, GetColumnLineChartDoneT, GetColumnLineChartFailedT, GetColumnLineChartT, GET_COLUMN_LINE_CHART, GET_COLUMN_LINE_CHART_DONE, GET_COLUMN_LINE_CHART_FAILED } from "../../types/widgets/ColumnLineChart";


export const GetColumnLineChart = (
  params: ColumnLineChartParamsT,
  chartType: string
): GetColumnLineChartT => ({
  type: GET_COLUMN_LINE_CHART,
  params,
  chartType
});

export const GetColumnLineChartDone = (
  chartType: string,
  data: ColumnLineChartT
): GetColumnLineChartDoneT => ({
  type: GET_COLUMN_LINE_CHART_DONE,
  chartType,
  data,
});

export const GetColumnLineChartFailed = (chartType: string): GetColumnLineChartFailedT => ({
  type: GET_COLUMN_LINE_CHART_FAILED,
  chartType,
});