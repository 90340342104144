import React, { useState, useCallback, useEffect } from "react";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/user.svg";
import Modal from "../../components/Modal/Modal";
import ModalInviteUser from "../../components/Modal/ModalInviteUser/ModalInviteUser";
import ModalEditUser from "../../components/Modal/ModalEditUser/ModalEditUser";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import ModalReinviteUser from "../../components/Modal/ModalReinviteUser/ModalReinviteUser";
import Users, { SingleUserT } from "../../widgets/Users/Users";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import axios from "../../api/axiosConfig";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import UserCard from "../../components/UserCard/UserCard";

const UsersManagementView = () => {
  const [modalInviteOpen, setModalInviteOpen] = useState<boolean>(false);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [modalReinviteOpen, setModalReinviteOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUserValues>();
  const [data, setTableData] = useState<SingleUserT[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id, name } = useSelector(
    (state: AppState) => state.customerContextState
  );

  const openEdit = (user: IUserValues) => {
    setSelectedUser(user);
    setModalEditOpen(true);
  };

  const openDelete = (user: IUserValues) => {
    setSelectedUser(user);
    setModalDeleteOpen(true);
  };

  const openReivite = (user: IUserValues) => {
    setSelectedUser(user);
    setModalReinviteOpen(true);
  };

  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : id;
  const customerContextName = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).name
    : name;

  const fetchData = useCallback(async () => {
    try {
      await axios(`/customer/${customerContextId}`, {
        params: {
          withUsers: "orgUsers",
          includeUserPhoto: true,
        },
      }).then((res) => {
        if (res && res.status === 200) {
          if (res.data.users.length) {
            setNoData(false);
            setTableData(
              res.data.users.map((elem: SingleUserT) => {
                return {
                  id: elem.id,
                  userDetails: {
                    name: elem.name,
                    email: elem.email,
                    photoLink: elem.photoData
                      ? {
                          type: elem.photoData.mime,
                          data: elem.photoData.content.data,
                        }
                      : null,
                  },
                  role: {
                    organization: elem.orgPermission,
                    customers: elem.structurePermission,
                  },
                  phone: elem.contactNumber,
                  expireDate: elem.expireDate,
                  status: elem.status,
                  orgPermission: elem.orgPermission,
                  structurePermission: elem.structurePermission,
                  configuration: elem.configuration ?? [],
                  dashboard: elem.dashboard ?? [],
                  management: elem.management ?? []
                };
              })
            );
          } else {
            setNoData(true);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [customerContextId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Users Management">
          {checkEditAccess(permissions, customer, id) && (
            <Button
              title="Invite User"
              icon={<IconButton width="1.4rem" height="1.4rem" />}
              icon_position="right"
              handleClick={() => {
                setModalInviteOpen(true);
              }}
            />
          )}
        </LayoutHeader>
        {/* @ts-ignore */}
        <Users
          data={data}
          noData={noData}
          openEdit={openEdit}
          openDelete={openDelete}
          openReivite={openReivite}
        />
      </Container>
      {noData && (
        <NoDataPlaceholder
          image
          title="No users added yet"
          subTitle="Add your first user by clicking the invite button"
        />
      )}
      <Modal modalOpen={modalInviteOpen} setModalOpen={setModalInviteOpen}>
        <ModalInviteUser
          setModalOpen={setModalInviteOpen}
          handleUpdateData={fetchData}
          customer={customerContextName}
        />
      </Modal>
      {selectedUser && (
        <>
          <Modal modalOpen={modalEditOpen} setModalOpen={setModalEditOpen}>
            <ModalEditUser
              setModalOpen={setModalEditOpen}
              handleUpdateData={fetchData}
              selectedUser={selectedUser}
            />
          </Modal>
          <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
            <ModalDelete
              name="User"
              endpoint={`/user/${selectedUser.id}`}
              setModalOpen={setModalDeleteOpen}
              handleUpdateData={fetchData}
            >
              <ModalDeleteElem>
                <UserCard {...selectedUser.userDetails} />
              </ModalDeleteElem>
            </ModalDelete>
          </Modal>
          <Modal
            modalOpen={modalReinviteOpen}
            setModalOpen={setModalReinviteOpen}
          >
            <ModalReinviteUser
              selectedUser={selectedUser}
              customerContextId={id}
              setModalOpen={setModalReinviteOpen}
              handleUpdateData={fetchData}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default UsersManagementView;

export interface IUserValues {
  id: string;
  userDetails: {
    name: string;
    email: string;
    photoLink: {
      type: string;
      data: number[];
    };
  };
  role: {
    organization: string;
    customers: string;
  };
  phone: string;
  expireDate: Date;
  status: string;
}
