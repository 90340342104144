import axios from "./axiosConfig";
import {setTokenForRefresh} from "../helpers/authorization";

export type AuthT = {
    email?: string;
    password?: string;
    repeatPassword?: string;
};

export type UserConfirmT = {
    name: string;
    registrationId: string;
    password: string;
    contactNumber?: string;
    jobTitle: string
};

export type UserLoginT = {
    login: string;
    password: string;
};

export type UserAssignRoleT = {
    role: string;
    loginId: string;
}

export type RecoverPasswordT = {
    recoverId: string;
    password: string;
};

export type ForgetPasswordT = {
    email: string;
};

export type TokensT = {
    token: string;
    refreshToken: string;
};

export type ChangingPasswordT = {
    email: string;
    oldPassword: string;
    newPassword: string;
};

export type SendUserInviteDataT = {
    email: string;
    name: string;
    contactNumber?: number | string;
    expireDate?: number | null;
    customerContextId: string;
    orgPermission?: string;
    structurePermission?: string;
    management: Array<string>;
    configuration: Array<string>;
    dashboard: Array<string>;
}

export type SendUserReinviteDataT = {
    loginId: string;
    customerContextId: string
}

export type UpdateUserDataT = {
    name: string;
    email: string;
    contactNumber?: number | string;
    expireDate?: number | null;
    orgPermission?: string;
    structurePermission?: string;
    description?: string;
    jobTitle?: string;
    management: Array<string>;
    configuration: Array<string>;
    dashboard: Array<string>;
}
export type UpdateCustomerDataT = {
    name: string;
    email: string;
    contactNumber?: number | string;
    description?: string;
}

export type SendCustomerReinviteDataT = {
    customerId: string;
}


export function confirmRegistration(data: UserConfirmT) {
    return axios.post("/auth/confirm", data);
}

export function loginUser(data: UserLoginT) {
    const {login, password} = data;
    return axios.post("/auth/login", {login, password});
}

export function assignRole(data: UserAssignRoleT) {
    const {role, loginId} = data;
    return axios.put('/user/assignrole', {loginId, role});
}

export function forgetPassword(data: ForgetPasswordT) {
    return axios.post("/auth/forget", data);
}

export function checkUser() {
    return axios.get("/me",{ params : {customerTree: true}});
}

export function checkAvatar() {
    return axios.get('/user/photo', {
        responseType: 'blob'
    }).then(res => {
        if (res.status === 200) {
            return res;
        } else {
            return '';
        }
    });
}

export function checkLogo(id: string) {
    return axios.get(`/configuration/company/${id}/logo`, {
        responseType: 'blob'
    }).then(res => {
        if (res.status === 200) {
            return res;
        } else {
            return '';
        }
    });
}

export function renewToken() {
    setTokenForRefresh();
    return axios.post("/auth/refresh");
}

export function recoveryPassword(data: RecoverPasswordT) {
    return axios.post("/auth/recover", data);
}

export function uploadPhoto(file: File) {
    const form = new FormData();
    form.append('avatar', file);
    return axios.post("/user/photo", form, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function removePhoto() {
    return axios.delete("/user/photo");
}

export function uploadLogo(file: File, id: string) {
    
    const form = new FormData();
    form.append('logo', file);
    const response = axios.post(`/configuration/company/${id}/logo`, form, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response;
}

export function removeLogo(id: string) {
    return axios.delete(`/configuration/company/${id}/logo`);
}

export function changePassword(data: ChangingPasswordT) {
    return axios.post("/auth/password", data);
}

export function sendUserInvite(data: SendUserInviteDataT) {
    return axios.post("/auth/invite", data);
}

export function sendUserReinvite(data: SendUserReinviteDataT) {
    return axios.post("/auth/reinvite", data);
}

export function updateUser(data: UpdateUserDataT, id: string) {
    return axios.patch(`/user/${id}`, data);
}

export function updateCustomer(data: UpdateCustomerDataT, id: string) {
    return axios.patch(`/configuration/company/${id}`, data);
}