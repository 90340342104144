export const GetSelectedPresetDetails = (
  constantPresets: any,
  customPresets: any,
  constantReceivedPresets: any,
  currentDashboard: any
) => {
  const selectedPreset =
    (constantPresets &&
      constantPresets.find((elem: any) => elem.id === currentDashboard.id)) ||
    (customPresets &&
      customPresets.find((elem: any) => elem.id === currentDashboard.id)) ||
    (constantReceivedPresets &&
      constantReceivedPresets.find(
        (elem: any) => elem.id === currentDashboard.id
      ));
  return selectedPreset;
};
