import { GetApplicationDashboard } from "./GetApplicationDashboard/GetApplicationDashboard";
import { GetApplicationMs365Dashboard } from "./GetApplicationMs365Dashboard/GetApplicationMs365Dashboard";
import { GetApplicationUCCDashboard } from "./GetApplicationUCCDashboard/GetApplicationUCCDashboard";
import { GetDeviceDashboard } from "./GetDeviceDashboard/GetDeviceDashboard";
import { GetLicenseDashboard } from "./GetLicenseDashboard/GetLicenseDashboard";
import { GetLicensesDashboard } from "./GetLicensesDashboard/GetLicensesDashboard";
import { GetResourceDashboard } from "./GetResourceDashboard/GetResourceDashboard";
import { GetResourcesDashboard } from "./GetResourcesDashboard/GetResourcesDashboard";
import { GetLocationDashboard } from "./GetLocationDashboard/GetLocationDashboard";
import { GetAgentDashboard } from "./GetAgentDashboard/GetAgentDashboard";
import { GetEmployeeCallDashboard } from "./GetEmployeeCallDashboard/GetEmployeeCallDashboard";
import { GetConferenceCallDashboard } from "./GetConferenceCallDashboard/GetConferenceCallDashboard";
import { GetCallQueueDashboard } from "./GetCallQueueDashboard/GetCallQueueDashboard";
import { GetUCCAppDashboard } from "./GetUCCAppDashboard/GetUCCAppDashboard";

export const GetDashboardDrillDowns = (dashboardType: string) => {
  switch (dashboardType) {
    case "app1":
      return GetApplicationDashboard();
    case "app2":
      return GetApplicationMs365Dashboard();
    case "app3":
      return GetApplicationUCCDashboard();
    case "device":
      return GetDeviceDashboard();
    case "license":
      return GetLicenseDashboard();
    case "licenses":
      return GetLicensesDashboard();
    case "resource":
      return GetResourceDashboard();
    case "resources":
      return GetResourcesDashboard();
    case "location":
      return GetLocationDashboard();
    case "agent":
      return GetAgentDashboard();
    case "employee-call":
      return GetEmployeeCallDashboard();
    case "conference-call":
      return GetConferenceCallDashboard();
    case "call-queue":
      return GetCallQueueDashboard();
    case "ucc-app":
      return GetUCCAppDashboard();
  }
};
