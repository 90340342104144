import {
  DELETE_PRESETS,
  DELETE_PRESETS_DONE,
  DELETE_PRESETS_FAILED,
  GET_PRESETS,
  GET_PRESETS_DONE,
  GET_PRESETS_FAILED,
  SET_CURRENT_DASHBOARD,
  SET_CURRENT_DASHBOARD_DONE,
  SET_CURRENT_DASHBOARD_FAILED,
  SET_CUSTOM_PRESETS,
  SET_CUSTOM_PRESETS_DONE,
  SET_CUSTOM_PRESETS_FAILED,
  SHARE_CUSTOM_PRESET,
  UNSHARE_DEFAULT_PRESET,
} from "../types/DashboardPresets.types";
import defaultPresets from "./defaultDashboards";

export type StateT = {
  isLoading: boolean;
  currentDashboard: { id: string; value: string } | null;
  currentDashboardId: string | null;
  message: string | null;
  customPresets: PresetT[];
  constantPresets: PresetT[];
  customReceivedPresets: PresetT[];
  constantReceivedPresets: PresetT[];
};

export type PresetT = {
  title: string;
  id: string;
  widgets: WidgetT[];
};

// all available props that defined in dashboards
export type WidgetT = {
  title: string;
  id: string;
  type: string;
  dashboardType: string;
  availableSizes: number[];
  size?: 2 | 3 | 4 | number;
  data?: any[] | any;
  path?: any;
  endpoint?: string;
  chartType?: string;
  disableIfHortium?: boolean;
  disable?: boolean;
  userId?: string;
  isTop?: boolean;
  isByLocation?: boolean;
  columns?: Array<any>;
  queryParams?: any;
  display?: string | null;

  // for gauge
  payloadAccessor?: (data: any) => any;
  sections?: Array<any>;

  // For heatmap
  accessDifferentTypes?: boolean

  // For column-line-chart request
  requestType?: string
};

const initialState: StateT = {
  isLoading: false,
  currentDashboard: null,
  currentDashboardId: null,
  message: null,
  customPresets: [],
  constantPresets: defaultPresets,
  customReceivedPresets: [],
  constantReceivedPresets: [],
};

const reducerDashboardPresets = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_DASHBOARD:
      return { ...state, isLoading: true };
    case SET_CURRENT_DASHBOARD_DONE:
      return {
        ...state,
        isLoading: false,
        currentDashboard: action.payload.settings,
        currentDashboardId: action.payload.id,
      };
    case SET_CURRENT_DASHBOARD_FAILED:
      return { ...state, isLoading: false };
    case SET_CUSTOM_PRESETS:
    case SET_CUSTOM_PRESETS_DONE:
      return {
        ...state,
        message: "Dashboard presets has been successfully updated",
      };
    case SET_CUSTOM_PRESETS_FAILED:
      return { ...state };
    case GET_PRESETS:
      return { ...state, isLoading: true };
    case GET_PRESETS_DONE:
      return {
        ...state,
        isLoading: false,
        customPresets: action.payload.custom,
        customReceivedPresets: action.payload.receivedCustom,
        constantReceivedPresets: action.payload.receivedConstant,
      };
    case GET_PRESETS_FAILED:
      return { ...state, isLoading: false };
    case DELETE_PRESETS:
      return { ...state, isLoading: true };
    case DELETE_PRESETS_DONE:
      return { ...state, isLoading: false };
    case DELETE_PRESETS_FAILED:
      return { ...state, isLoading: false };
    case SHARE_CUSTOM_PRESET:
      return { ...state };
    case UNSHARE_DEFAULT_PRESET:
      return { ...state };
    default:
      return state;
  }
};

export default reducerDashboardPresets;
