import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetComparisonBoxDone, GetComparisonBoxFailed } from "../../actions/widgets/ComparisonBox.action";
import { GetComparisonBoxT, GET_COMPARISON_BOX } from "../../types/widgets/ComparisonBox.types";


function* GetComparisonBoxHandler ( { params }: GetComparisonBoxT ) {
  try {

    const { status, data } = yield axios.get( `${params.endpoint}/${params.customerId}`, {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
        startHour: params.startHour,
        endHour: params.endHour,
        chartType: params.chartType,
        agentId: params.agentId
      },
    } )

    if ( status === 204 ) {
      yield put( GetComparisonBoxDone( params.chartType, null ) );
    } else if ( status === 200 ) {
      yield put( GetComparisonBoxDone( params.chartType, data ) );
    }
  } catch ( error ) {
    yield put( GetComparisonBoxFailed( params.chartType ) );
  }
}

export default function* WatchComparisonBox () {
  yield all( [
    takeEvery( GET_COMPARISON_BOX, GetComparisonBoxHandler )
  ] );
}
