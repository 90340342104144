import {GET_TABLE_DEVICES, GET_TABLE_DEVICES_DONE, GET_TABLE_DEVICES_FAILED, TableStateActionT} from "../../types/widgets/TableDevices.types";

export const initialState: any = {};

const TableDevicesState = (
  state = initialState,
  action: TableStateActionT
) => {
  switch (action.type) {
    case GET_TABLE_DEVICES:
      return {...state, [action.params.chartType]: {data: [], loading: true, error: false}};
    case GET_TABLE_DEVICES_DONE:
      return {...state, [action.chartType]: {data: action.data, loading: false, error: false}};
    case GET_TABLE_DEVICES_FAILED:
      return {...state, [action.chartType]: {data: [], loading: false, error: true}};
    default:
      return state;
  }
};

export default TableDevicesState;
