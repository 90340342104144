import React from "react";
import styles from "./NoDataPlaceholder.module.scss";
import { ReactComponent as Image } from "../../assets/icons/illustration3.svg";
import cn from "classnames";

const NoDataPlaceholder = (props: INoDataPlacehoder) => {
  const { image, title, subTitle, className } = props;

  return (
    <div
      className={cn(
        styles.element,
        image && styles["withImage"],
        className && className
      )}
    >
      {image && <Image className={styles.image} />}
      <span className={styles.title}>
        {title ? title : "No information available"}
      </span>
      {subTitle && <span className={styles.subtitle}>{subTitle}</span>}
    </div>
  );
};

export default NoDataPlaceholder;

interface INoDataPlacehoder {
  image?: boolean;
  title?: string;
  subTitle?: string;
  className?: string;
}
