import {
  GetEmployeeDevicesTableDoneT,
  GetEmployeeDevicesTableFailedT,
  GetEmployeeDevicesTableT,
  GET_TABLE_EMPLOYEE_DEVICES,
  GET_TABLE_EMPLOYEE_DEVICES_DONE,
  GET_TABLE_EMPLOYEE_DEVICES_FAILED,
  EmployeeDevicesTableDataT,
  EmployeeDevicesTableParamsT
} from "../../types/widgets/EmployeeDevicesTable";

export const GetEmployeeDevicesTable = (params: EmployeeDevicesTableParamsT): GetEmployeeDevicesTableT => ({
  type: GET_TABLE_EMPLOYEE_DEVICES,
  params
});

export const GetEmployeeDevicesTableDone = (data: EmployeeDevicesTableDataT): GetEmployeeDevicesTableDoneT => ({
  type: GET_TABLE_EMPLOYEE_DEVICES_DONE,
  data
});

export const GetEmployeeDevicesTableFailed = (): GetEmployeeDevicesTableFailedT => ({
  type: GET_TABLE_EMPLOYEE_DEVICES_FAILED
});
