import React, { useEffect, useState } from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellAgent, {
  ITableCellAgent,
} from "../../components/tableElements/TableCellAgent/TableCellAgent";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import { SingleAgentT } from "../../views/AgentsKnownManagement/AgentsKnownManagement.view";

interface IAgentsKnown {
  data: Array<SingleAgentT>;
  noData: boolean;
  openDelete: (e: SingleAgentT) => void;
}

const AgentsKnown = (props: IAgentsKnown) => {
  const { data, openDelete, noData } = props;
  const [agentsList, setAgentsList] = useState<SingleAgentT[]>(data);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id } = useSelector((state: AppState) => state.customerContextState);

  useEffect(() => {
    setAgentsList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "agent",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellAgent } }) => (
          <TableCellAgent name={value.name} id={value.id} />
        ),
      },
      {
        Header: "Client Name",
        accessor: "clientName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value} view="agents" />,
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Alias",
        accessor: "alias",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Registration Date",
        accessor: "registrationDate",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Version",
        accessor: "version",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Circuit Id",
        accessor: "circuitId",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Last Connection",
        accessor: "lastConnection",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Uptime",
        accessor: "uptime",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Last Successful Test",
        accessor: "lastSuccessfulTest",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        show:
          checkEditAccess(permissions, customer, id) ||
          (customer.mainCustomer &&
            permissions.structure.fullAccess &&
            permissions.structure.data)
            ? true
            : false,
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => (
          <div className={"d-flex align-items-center"}>
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  path={`/agents-management/agent/${value.row.original.agent.id}`}
                  title="Edit Agent"
                  type="tooltip"
                  scale={false}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete Agent"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openDelete(value.row.original)}
                />
              </ActionToolsItem>
            </ActionTools>
          </div>
        ),
      },
    ],
    [openDelete, permissions, customer, id]
  );

  return <ReactTable columns={columns} data={agentsList} noData={noData} />;
};

export default AgentsKnown;
