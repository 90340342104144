
import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetTableDone, GetTableFailed } from "../../actions/widgets/Table.action";
import { GetTableT, GET_TABLE } from "../../types/widgets/Table.types";


function* GetTableHandler ( { params }: GetTableT ) {
  try {
    const baseParams: any = {
      startDate: params.startDate,
      endDate: params.endDate,
      startHour: params.startHour,
      endHour: params.endHour,
      agentId: params.agentId
    }
    const { status, data } = yield axios.get( `${params.endpoint}/${params.customerId}`, {
      params: {
        ...baseParams
      },})

    if ( status === 204 ) {
      yield put( GetTableDone( params.chartType, [] ) );
    } else if ( status === 200 ) {
      yield put( GetTableDone( params.chartType, data ) );
    }
  } catch ( error ) {
    yield put( GetTableFailed( params.chartType ) );
  }
}


export default function* WatchTable () {
  yield all( [
    takeEvery( GET_TABLE, GetTableHandler )
  ] );
}
