import React from "react";
import styles from "../formElements/CheckboxGroup/CheckboxGroup.module.scss";
import cn from "classnames";
import { ReactComponent as IconCheck } from "../../assets/icons/check.svg";

const Checkbox = (props: ICheckbox) => {
  const {
    name,
    // value,
    label,
    active,
    isBgGray,
    disabled,
    className,
  } = props;

  return (
    <div
      className={cn(
        styles.fieldWrapper,
        isBgGray && styles["isBgGray"],
        disabled && styles["isDisabled"],
        className && className
      )}
    >
      <label className={styles.label}>
        <input
          type="checkbox"
          name={name}
          // value={value}
        />
        <div className={styles.box}>
          {active.length > 0 && <IconCheck className={styles.icon} />}
        </div>
        <span className={styles.text}>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;

export interface ICheckbox {
  name: string;
  label: string;
  value: string;
  active: Array<string>;
  isBgGray?: boolean;
  disabled?: boolean;
  className?: string;
}
