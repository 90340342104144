export const GET_TABLE_AGENT_DISCOVERED_DEVICE = "GET_TABLE_AGENT_DISCOVERED_DEVICE";
export const GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE = "GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE";
export const GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED = "GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED";

export type AgentDiscoveredDeviceT = {
  DeviceName: string;
  DeviceType: string;
  IpAddress: string;
  Latency: number;
  MacAddress: string;
  OsVersion: string;
  Status: string | null;
};

export type AgentDiscoveredDeviceTableDataT = Array<AgentDiscoveredDeviceT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type AgentDiscoveredDeviceTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
  agentId?: string
};

export type GetAgentDiscoveredDeviceTableT = {
  type: typeof GET_TABLE_AGENT_DISCOVERED_DEVICE;
  params: AgentDiscoveredDeviceTableParamsT;
};

export type GetAgentDiscoveredDeviceDoneT = {
  type: typeof GET_TABLE_AGENT_DISCOVERED_DEVICE_DONE;
  data: AgentDiscoveredDeviceTableDataT;
};

export type GetAgentDiscoveredDeviceFailedT = {
  type: typeof GET_TABLE_AGENT_DISCOVERED_DEVICE_FAILED;
};

export type TableStateActionT = GetAgentDiscoveredDeviceTableT | GetAgentDiscoveredDeviceDoneT | GetAgentDiscoveredDeviceFailedT;
