export const GET_DEVICE = "GET_DEVICE";
export const GET_DEVICE_DONE = "GET_DEVICE_DONE";
export const GET_DEVICE_FAILED = "GET_DEVICE_FAILED";
export const CLEAN_DEVICE_DETAILS = "CLEAN_DEVICE_DETAILS";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const EDIT_DEVICE_DONE = "EDIT_DEVICE_DONE";
export const EDIT_DEVICE_FAILED = "EDIT_DEVICE_FAILED";
export const ADD_DEVICE = "ADD_DEVICE";
export const ADD_DEVICE_DONE = "ADD_DEVICE_DONE";
export const ADD_DEVICE_FAILED = "ADD_DEVICE_FAILED";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const DELETE_DEVICE_DONE = "DELETE_DEVICE_DONE";
export const DELETE_DEVICE_FAILED = "DELETE_DEVICE_FAILED";

export type DeviceT = {
  id: string,
  customerId: string,
  name: string,
  status: string,
  deviceType: string,
  vendor: string,
  connectionType: string,
  model: string,
  alias: string,
  circuitId: string,
  description: string,
  mac: string,
  ip: string,
  osVersion: string,
  latency: number,
  lastTestDate: number,
  country: string,
  province: string,
  zipCode: string,
  city: string,
  street: string,
  locationGroup: string,
  bandwidthDownload: string,
  bandwidthUpload: string,
  snmpVersion: string,
  communityString: string,
  userName: string,
  privProtocol: string,
  privKey: string,
  authProtocol: string,
  authKey: string,
  agentId: string,
  agentName: string,
  agent?: {value: string, label: string},
  interface: string,
  addedDevice: boolean
}

export type ActionTypeT = GetDeviceT | GetDeviceDoneT | GetDeviceFailedT | CleanDeviceDetailsT | EditDeviceT | EditDeviceDoneT | EditDeviceFailedT | AddDeviceT | AddDeviceDoneT | AddDeviceFailedT | DeleteDeviceT | DeleteDeviceDoneT | DeleteDeviceFailedT

export type GetDeviceT = {
  type: typeof GET_DEVICE;
  id: string
}

export type GetDeviceDoneT = {
  type: typeof GET_DEVICE_DONE;
  payload: DeviceT | null,
}

export type GetDeviceFailedT = {
  type: typeof GET_DEVICE_FAILED;
  error: any
}

export type CleanDeviceDetailsT = {
  type: typeof CLEAN_DEVICE_DETAILS;
}

export type EditDeviceT = {
  type: typeof EDIT_DEVICE;
  id: string,
  data: any
}

export type EditDeviceDoneT = {
  type: typeof EDIT_DEVICE_DONE;
}

export type EditDeviceFailedT = {
  type: typeof EDIT_DEVICE_FAILED;
  error: any
}

export type AddDeviceT = {
  type: typeof ADD_DEVICE;
  data: any
}

export type AddDeviceDoneT = {
  type: typeof ADD_DEVICE_DONE;
}

export type AddDeviceFailedT = {
  type: typeof ADD_DEVICE_FAILED;
  error: any
}

export type DeleteDeviceT = {
  type: typeof DELETE_DEVICE;
  id: string
}

export type DeleteDeviceDoneT = {
  type: typeof DELETE_DEVICE_DONE;
}

export type DeleteDeviceFailedT = {
  type: typeof DELETE_DEVICE_FAILED;
  error: any
}