import {all, put, takeLatest} from "redux-saga/effects";
import axios from "../../api/axiosDashboard";
import {AddDeviceT, ADD_DEVICE, ADD_DEVICE_DONE, ADD_DEVICE_FAILED, DeleteDeviceT, DELETE_DEVICE, DELETE_DEVICE_DONE, DELETE_DEVICE_FAILED, EditDeviceT, EDIT_DEVICE, EDIT_DEVICE_DONE, EDIT_DEVICE_FAILED, GetDeviceT, GET_DEVICE, GET_DEVICE_DONE, GET_DEVICE_FAILED} from "../types/Device";

function* GetDeviceHandler({id}: GetDeviceT) {
  try {
    const {data} = yield axios
      .get(`/management/device/${id}`)

    if (data) {
      const res = {
        id: data.id || "",
        customerId: data.customerId || "",
        name: data.name || "",
        status: (data.status === false && "offline") || (data.status === true && "online") || "",
        deviceType: data.deviceType || "",
        vendor: data.vendor || "",
        connectionType: data.connectionType || "",
        model: data.model || "",
        alias: data.alias || "",
        circuitId: data.circuitId || "",
        description: data.description || "",
        mac: data.mac || "",
        ip: data.ip || "",
        osVersion: data.osVersion || "",
        latency: data.latency || "",
        lastTestDate: data.lastTestDate || "",
        country: data.country || "",
        zipCode: data.zipCode || "",
        city: data.city || "",
        street: data.street || "",
        province: data.province || "",
        locationGroup: data.locationGroup || "",
        bandwidthDownload: data.bandwidthDownload || "",
        bandwidthUpload: data.bandwidthUpload || "",
        snmpVersion: data.snmpVersion || "",
        communityString: data.communityString || "",
        userName: data.userName || "",
        privProtocol: data.privProtocol || "",
        privKey: data.privKey || "",
        authProtocol: data.authProtocol || "",
        authKey: data.authKey || "",
        agentId: data.agentId || "",
        agent: {value: data.agentId || "", label: data.agentName || ""},
        interface: data.interface || "",
        addedDevice: data.addedDevice
      }

      yield put({type: GET_DEVICE_DONE, payload: res});
    } else {

      yield put({type: GET_DEVICE_DONE, payload: null});
    }

  } catch (error) {
    yield put({type: GET_DEVICE_FAILED, error});
  }
}

function* EditDeviceHandler({id, data}: EditDeviceT) {
  try {
    yield axios.patch(`/management/device/${id}`, data);
    yield put({type: EDIT_DEVICE_DONE});
  }
  catch (error) {
    yield put({type: EDIT_DEVICE_FAILED, error});
  }
}

function* AddDeviceHandler({data}: AddDeviceT) {
  try {
    yield axios.post("/management/device", data);
    yield put({type: ADD_DEVICE_DONE});
  }
  catch (error) {
    yield put({type: ADD_DEVICE_FAILED, error});
  }
}

function* DeleteDeviceHandler({id}: DeleteDeviceT) {
  try {
    yield axios.delete(`management/device/${id}`);
    yield put({type: DELETE_DEVICE_DONE});
  }
  catch (error) {
    yield put({type: DELETE_DEVICE_FAILED, error});
  }
}

export default function* WatchDevice() {
  yield all([
    takeLatest(GET_DEVICE, GetDeviceHandler),
    takeLatest(EDIT_DEVICE, EditDeviceHandler),
    takeLatest(ADD_DEVICE, AddDeviceHandler),
    takeLatest(DELETE_DEVICE, DeleteDeviceHandler),
  ]);
}
