export const GET_TABLE_DEVICES = "GET_TABLE_DEVICES";
export const GET_TABLE_DEVICES_DONE = "GET_TABLE_DEVICES_DONE";
export const GET_TABLE_DEVICES_FAILED = "GET_TABLE_DEVICES_FAILED";

// export type TableT =
//   {
//     agent: {
//       id: string,
//       name: string,
//       dashboardType: string
//     },
//     alias: string,
//     hostname: string,
//     cpu: string,
//     memory: string,
//     numberOfProcessess: string,
//     topProcess: string,
//     connectionType: string,
//     wifiStrength: string,
//     ssid: string,
//     txRate: string,
//     connectionSpeed: string,
//     noise: string,
//     rssi: string,
//     networkLatencystring: string,
//     packetLoss: string,
//     dnsTime: string,
//     transactionTime: string,
//     hops: string,
//     euxScore: string,
//     slaScore: string,
//     availability: string,
//     bwUpload: string,
//     bwDownload: string,
//     bwLocation: string,
//     numberOfDiscoveredDevices: string,
//     numberOfEmployees: string,
//     uptime: string,
//     numberOfAppsTestRunning: string,
//     avPerformanceOfApp: string,
//     appscore: string,
//     numberOfTransactions: string,
//     avTransactionTime: string,
//     avQuailityOrMoSScore: string,
//     totalDowntimeOfAgentMins: string,
//     totalDownTimeOfApps: string,
//     agentIP: string
//   };

export type TableDataT = Array<any>;

export type TableColumnsT = Array<
  {
    Header: string,
    accessor: string,
    type: string,
  }>;

export type TableParamsT = {
  endpoint: string,
  customerId: string,
  chartType: string,
  agentId: string | null
}

export type GetTableT = {
  type: typeof GET_TABLE_DEVICES;
  params: TableParamsT
}

export type GetTableDoneT = {
  type: typeof GET_TABLE_DEVICES_DONE;
  chartType: string,
  data: TableDataT
}

export type GetTableFailedT = {
  type: typeof GET_TABLE_DEVICES_FAILED;
  chartType: string,
}

export type TableStateActionT = GetTableT | GetTableDoneT | GetTableFailedT