import axios, {AxiosResponse} from "axios";
import store from "../store/index";
import {LOGOUT} from "../store/reducers/authorization";
import {renewToken} from "./authorization";
import {saveTokens} from "../helpers/authorization";

export const apiURL = () => {
    if (process.env.REACT_APP_AUTH_API_ENDPOINT) {
        return process.env.REACT_APP_AUTH_API_ENDPOINT;
    } else {
        switch (process.env.NODE_ENV) {
            case "development":
                return "http://hortium-backend-dev.eu-west-2.elasticbeanstalk.com";

            case "production":
                return "http://hortium-backend-dev.eu-west-2.elasticbeanstalk.com";

            case "sandbox":
                return "https://api-sandbox.hortium.cloud";

            default:
                return "https://api-sandbox.hortium.cloud";
        }
    }
};

// eslint-disable-next-line no-console
console.log("test check env", process.env);
const instance = axios.create({
    baseURL: apiURL(),
});

let isRefreshing = false;
let refreshSubscribers: Array<any> = [];

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error) => {
        const {
            config,
            response: { status },
        } = error;
        if (!error.response) {
            console.error(error);
            return Promise.reject(error.message);
        }

        const errorMessage = error.response?.data?.error || error.message;

        const originalRequest = config;

        if (status === 403) {
            store.dispatch({ type: LOGOUT });
        }


        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                renewToken().then(({data: {token, refreshToken}}) => {
                    isRefreshing = false;
                    onRrefreshed(token);
                    saveTokens({token, refreshToken});
                });
            }

            return new Promise((resolve, reject) => {
                subscribeTokenRefresh((token: string) => {
                    originalRequest.headers["Authorization"] = `Bearer ${token}`;
                    resolve(axios(originalRequest));
                });
            });
        } else {
            console.error(error);
            return Promise.reject(errorMessage);
        }
    }
);

function subscribeTokenRefresh(cb: any) {
    refreshSubscribers.push(cb);
}

function onRrefreshed(token: string) {
    refreshSubscribers.map((cb) => cb(token));
}

export default instance;
