import React, { useState, useEffect } from "react";
import styles from "./SelectInput.module.scss";
import { Field, FieldProps } from "formik";
import Select, { components } from "react-select";
import { ReactComponent as IconChevron } from "../../../assets/icons/chevron.svg";
import cn from "classnames";

const SelectInput = (props: ISelectInput) => {
  const { errors, touched, name, label, required, className } = props;

  function validate(value: any) {
    let error;
    if (!value) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <Field
        component={CustomSelect}
        validate={required && ((e: any) => validate(e))}
        {...props}
      />
      {errors[name] && touched[name] && (
        <span className={styles.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default SelectInput;

export interface ISelectInput {
  options: Array<{ value: number | string; label: string }>;
  placeholder: string;
  isSearchable?: boolean;
  errors?: any;
  touched?: any;
  name: string;
  label?: string;
  isBgGray?: boolean;
  required?: boolean;
  className?: string;
  isDisabled?: boolean;
  type?: "status";
  onTop?: boolean;
  fetchData?: any;
  hasWatchedFields?: boolean;
  customValue?: boolean;
  onChange?: any;
  isMulti?: boolean;
}

const CustomSelect = (props: ICustomSelect) => {
  const {
    options,
    placeholder,
    isSearchable,
    form,
    field,
    isBgGray,
    isDisabled,
    type,
    onTop,
    fetchData,
    hasWatchedFields,
    customValue,
    onChange,
    isMulti,
  } = props;

  const [optionList, setOptionList] =
    useState<ISelectInput["options"]>(options);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoadOptions = () => {
    fetchData();
    setOptionsLoaded(true);
    setIsLoading(false);
  };

  const maybeLoadOptions = () => {
    if ((!optionsLoaded && !optionList.length) || hasWatchedFields) {
      setIsLoading(true);
      handleLoadOptions();
    }
  };

  useEffect(() => {
    setOptionList(options);
  }, [options]);

  const customSingleValue = (options: any) => {
    return (
      <div>
        {options.selectProps.type === "status" && (
          <span className={cn(styles.status, styles[options.data.label])} />
        )}
        <span>{options.data.label}</span>
      </div>
    );
  };

  return (
    <Select
      classNamePrefix={styles.select}
      className={cn(
        styles.select,
        isBgGray && styles["isPlain"],
        form.errors[field.name] && form.touched[field.name] && styles["isError"]
      )}
      styles={customSelectStyles}
      options={optionList}
      placeholder={placeholder}
      type={type}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        SingleValue: customSingleValue,
      }}
      isSearchable={isSearchable}
      maxMenuHeight={175}
      name={field.name}
      defaultValue={
        field.value &&
        (!customValue
          ? {
              value: field.value,
              label: field.value,
            }
          : field.value)
      }
      isDisabled={isDisabled}
      onChange={(option) => {
        form.setFieldValue(field.name, customValue ? option : option.value);
        onChange && onChange(option);
      }}
      menuPortalTarget={onTop ? document.body : null}
      onFocus={maybeLoadOptions}
      isLoading={isLoading}
      isMulti={isMulti}
    />
  );
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconChevron className={styles.icon} />
    </components.DropdownIndicator>
  );
};

export interface ICustomSelect extends FieldProps {
  options: ISelectInput["options"];
  placeholder: string;
  title: string;
  isSearchable?: boolean;
  name: string;
  isBgGray?: boolean;
  isDisabled: boolean;
  type?: string;
  onTop?: boolean;
  fetchData?: any;
  hasWatchedFields?: boolean;
  customValue?: boolean;
  onChange?: any;
  isMulti?: boolean;
}

export const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 3,
    boxShadow: state.isFocused ? null : null,
    cursor: "pointer",
    fontSize: "1.2rem",
    minHeight: "4rem",
    padding: "0.6rem 1.6rem",
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: "0",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 3,
    hyphens: "auto",
    marginTop: "0.4rem",
    textAlign: "left",
    wordWrap: "break-word",
    boxShadow: "0 8px 6px 0px rgba(25, 40, 60, 0.04)",
    overflow: "hidden",
    border: "1px solid rgba(25, 40, 60, 0.04)",
    maxHeight: "175px",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    fontSize: "1.2rem",
    color: "#8A97AD",
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#566ff6",
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    padding: "0.8rem 1.6rem",
    backgroundColor: [
      state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
      state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
    ],
    "&:active": {
      backgroundColor: [
        state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
        state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
      ],
    },
    color: state.isSelected ? "#3A4EB8 !important" : null,
    fontWeight: state.isSelected ? "600" : null,
    fontSize: ["1.2rem", state.isSelected ? "1.2rem" : null],
    cursor: "pointer",
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    height: "2.2rem",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#8a97ad",
  }),
};
