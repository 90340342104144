import { GET_HEATMAP, GET_HEATMAP_DONE, GET_HEATMAP_FAILED, HeatmapStateActionT } from "../../types/widgets/Heatmap.types";


export const initialState: any = {};

const HeatmapState = (
  state = initialState,
  action: HeatmapStateActionT
) => {
  switch (action.type) {
    case GET_HEATMAP:
      return { ...state, [action.params.chartType || action.params.id || '']: { data: null, loading: true, error: false } };
    case GET_HEATMAP_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_HEATMAP_FAILED:
      return { ...state, [action.chartType]: { data: null, loading: false, error: true } };
    default:
      return state;
  }
};

export default HeatmapState;
