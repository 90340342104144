import moment from "moment";

export const SET_DATE_RANGE = "SET_DATE_RANGE";

const today = new Date();
const weekAgo = new Date();

const initialState = {
    start: moment(weekAgo).unix(),
    end: moment(today).unix()
}

export default function dateRangeState(state = initialState, action: {
    type: "SET_DATE_RANGE";
    data: any
}) {
    if (action.type === SET_DATE_RANGE) {
        return action.data;
    }
    return state;
}
