import React from "react";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import { Column, Row } from "../../../components/Bootstrap/Bootstrap";
import Panel from "../../../components/Panel/Panel";
import Heading from "../../../components/Heading/Heading";
import Label from "../../../components/Label/Label";
import styles from "../../SingleAgent/SingleAgentForm/SingleAgentForm.module.scss";
// import { ReplaceToAsterisk } from "../../../functions/ReplaceToAsterisk/ReplaceToAsterisk";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DeviceT } from "../../../store/types/Device";

interface ISingleDeviceFormNoEdit {
  data: DeviceT;
}

const SingleDeviceFormNoEdit = (props: ISingleDeviceFormNoEdit) => {
  const { data } = props;

  // const showInterfaceList = (data: string[]) => {
  //   if (data.length) {
  //     return data.map((elem: any, key: number) => elem).join(", ");
  //   } else return "-";
  // };

  const handleToggleVersionFields = (snmp: string) => {
    switch (snmp) {
      case "v2c":
        return (
          <>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Community String" />
                  <Heading
                    title={data.communityString || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
          </>
        );
      case "v3":
        return (
          <>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Username" />
                  <Heading
                    title={data.userName || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
            <Row spacing="large" className={styles.row}>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Priv protocol" />
                  <Heading
                    title={data.privProtocol || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Auth protocol" />
                  <Heading
                    title={data.authProtocol || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>

              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Priv key" />
                  <Heading
                    title={data.privKey || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column spacing="large" className="col-12 col-lg-6">
                <Group>
                  <Label title="Auth key" />
                  <Heading
                    title={data.authKey || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
          </>
        );
    }
  };

  return (
    <div>
      <LayoutHeader pathBack="/devices-management/added" title={data.name} />
      <Panel className={styles.panel}>
        <Heading
          title="General"
          className={styles.sectionTitle}
          weight="medium"
        />
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Group>
              <Label title="Name" />
              <Heading title={data.name || "-"} Tag="h4" weight="semi-bold" />
            </Group>
            <Group>
              <Label title="Location group" />
              <Heading
                title={data.locationGroup || "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Location" />
              <Heading
                // title={data.city}
                title={
                  data.street &&
                  data.zipCode &&
                  data.city &&
                  data.province &&
                  data.country
                    ? `${data.street}, ${data.zipCode} ${data.city}, ${data.province}, ${data.country}`
                    : "-"
                }
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
            <Group>
              <Label title="Alias" />
              <Heading title={data.alias || ""} Tag="h4" weight="semi-bold" />
            </Group>
            <Group>
              <Label title="Description" />
              <Heading
                title={data.description || "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-6">
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Bandwidth upload (MB)" />
                  <Heading
                    title={data.bandwidthUpload || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Bandwidth download (MB)" />
                  <Heading
                    title={data.bandwidthDownload || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Device type" />
                  <Heading
                    title={data.deviceType || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Connection type" />
                  <Heading
                    title={data.connectionType || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Vendor" />
                  <Heading
                    title={data.vendor || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Model" />
                  <Heading
                    title={data.model || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Heading
              title="Network"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Internal IP" />
                  <Heading title={"-"} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="External IP" />
                  <Heading title={"-"} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Uptime" />
                  <Heading title={"-"} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
            </Row>
          </Column>
          <Column spacing="large" className="col-12 col-lg-6">
            <Heading
              title="System Info"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="CPU" />
                  <Heading title={"-"} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Memory" />
                  <Heading title="-" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="OS Type" />
                  <Heading title="-" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="OS Version" />
                  <Heading
                    title={data.osVersion || "-"}
                    Tag="h4"
                    weight="semi-bold"
                  />
                </Group>
              </Column>
              <Column className="col-12 col-lg-6">
                <Group>
                  <Label title="Internal system name" />
                  <Heading title="-" Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row spacing="large" className={cn(styles.row, styles.noBorder)}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Heading
              title="Advanced Settings"
              className={styles.sectionTitle}
              weight="medium"
            />
            <Row>
              <Column className="col-12">
                <Group>
                  <Label title="SNMP" />
                  <Heading title={"snmp-v3"} Tag="h4" weight="semi-bold" />
                </Group>
              </Column>
            </Row>
          </Column>
        </Row>
        {handleToggleVersionFields(data.snmpVersion)}
        <Row spacing="large">
          <Column spacing="large" className="col-12 col-lg-6">
            <Group>
              <Label title="Agent" />
              <Heading title={"-"} Tag="h4" weight="semi-bold" />
            </Group>
          </Column>
        </Row>
        <Row spacing="large" className={styles.row}>
          <Column spacing="large" className="col-12 col-lg-6">
            <Group>
              <Label title="Device IP" />
              <Heading title={data.ip || "-"} Tag="h4" weight="semi-bold" />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-3">
            <Group>
              <Checkbox
                className={styles.checkbox}
                name="siteLink"
                active={["true"]}
                isBgGray
                disabled
                value="site-link"
                label="Site Link"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-3">
            <Group>
              <Checkbox
                className={styles.checkbox}
                name="siteLink"
                active={["true"]}
                isBgGray
                disabled
                value="hybrid-wan"
                label="Hybrid WAN"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-6">
            <Group>
              <Label title="Interface" />
              <Heading
                title={data.interface || "-"}
                Tag="h4"
                weight="semi-bold"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-3">
            <Group>
              <Checkbox
                className={styles.checkbox}
                name="test"
                isBgGray
                disabled
                active={["true"]}
                value="tech-test"
                label="Tech Test available to customers"
              />
            </Group>
          </Column>
          <Column spacing="large" className="col-12 col-lg-3">
            <Group>
              <Label title="Interface tests" />
              <Heading title={"-"} Tag="h4" weight="semi-bold" />
            </Group>
          </Column>
        </Row>
      </Panel>
    </div>
  );
};

export default SingleDeviceFormNoEdit;

const Group = (props: {
  children: Array<React.ReactChild> | React.ReactChild;
}) => <div className={styles.group}>{props.children}</div>;
