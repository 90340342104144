import {
  GetConferenceCallsTableDoneT,
  GetConferenceCallsTableFailedT,
  GetConferenceCallsTableT,
  GET_TABLE_CONFERENCE_CALLS,
  GET_TABLE_CONFERENCE_CALLS_DONE,
  GET_TABLE_CONFERENCE_CALLS_FAILED,
  ConferenceCallsTableDataT,
  ConferenceCallsTableParamsT
} from "../../types/widgets/ConferenceCallsTable.type";

export const GetConferenceCallsTable = (params: ConferenceCallsTableParamsT): GetConferenceCallsTableT => ({
  type: GET_TABLE_CONFERENCE_CALLS,
  params
});

export const GetConferenceCallsTableDone = (data: ConferenceCallsTableDataT): GetConferenceCallsTableDoneT => ({
  type: GET_TABLE_CONFERENCE_CALLS_DONE,
  data
});

export const GetConferenceCallsTableFailed = (): GetConferenceCallsTableFailedT => ({
  type: GET_TABLE_CONFERENCE_CALLS_FAILED
});
