import {
  GetImportantMetricsDoneT,
  ImportantMetricsParamsT,
  GetImportantMetricsT,
  GET_IMPORTANT_METRICS,
  GET_IMPORTANT_METRICS_DONE,
  ImportantMetricsT,
  GET_IMPORTANT_METRICS_FAILED,
  GetImportantMetricsFailedT,
} from "../../types/widgets/ImportantMetrics.types";

export const GetImportantMetrics = (
  params: ImportantMetricsParamsT
): GetImportantMetricsT => ( {
  type: GET_IMPORTANT_METRICS,
  params,
} );

export const GetImportantMetricsDone = (
  chartType: string,
  data: ImportantMetricsT
): GetImportantMetricsDoneT => ( {
  type: GET_IMPORTANT_METRICS_DONE,
  chartType,
  data,
} );

export const GetImportantMetricsFailed = ( chartType: string ): GetImportantMetricsFailedT => ( {
  type: GET_IMPORTANT_METRICS_FAILED,
  chartType,
} );