import {
  GetEmployeeCalleeOriginatorMetricsDoneT,
  GetEmployeeCalleeOriginatorMetricsFailedT,
  GetEmployeeCalleeOriginatorMetricsTableT,
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS,
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_DONE,
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_FAILED,
  EmployeeCalleeOriginatorMetricsTableDataT,
  EmployeeCalleeOriginatorMetricsTableParamsT
} from "../../types/widgets/EmployeeCalleeOriginatorMetricsTable.types";

export const GetEmployeeCalleeOriginatorMetricsTable = (params: EmployeeCalleeOriginatorMetricsTableParamsT): GetEmployeeCalleeOriginatorMetricsTableT => ({
  type: GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS,
  params
});

export const GetEmployeeCalleeOriginatorMetricsTableDone = (data: EmployeeCalleeOriginatorMetricsTableDataT): GetEmployeeCalleeOriginatorMetricsDoneT => ({
  type: GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_DONE,
  data
});

export const GetEmployeeCalleeOriginatorMetricsTableFailed = (): GetEmployeeCalleeOriginatorMetricsFailedT => ({
  type: GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_FAILED
});
