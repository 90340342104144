export type StateT = {
  isLoading: boolean;
  message: string | null;
  users: any[] | null;
  error: string | null;
};

const initialState: StateT = {
  isLoading: false,
  message: null,
  users: null,
  error: null,
};

const reducerUsers = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, isLoading: true };
    case "GET_USERS_DONE":
      return { ...state, isLoading: false, users: action.payload };
    case "GET_USERS_FAILED":
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default reducerUsers;
