import {DeviceT, GetDevicesListDoneT, GetDevicesListFailedT, GetDevicesListT, GET_DEVICES_LIST, GET_DEVICES_LIST_DONE, GET_DEVICES_LIST_FAILED} from "../types/Devices";


export const GetDevicesList = (id: string, isAdded: boolean): GetDevicesListT => ({
  type: GET_DEVICES_LIST,
  id,
  isAdded
});

export const GetDevicesListDone = (data: DeviceT[] | null, isAdded: boolean): GetDevicesListDoneT => ({
  type: GET_DEVICES_LIST_DONE,
  payload: data,
  isAdded
});

export const GetDevicesListFailed = (error: any): GetDevicesListFailedT => ({
  type: GET_DEVICES_LIST_FAILED,
  error,
});
