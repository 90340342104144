import React, { useState } from "react";
import styles from "./CustomPresetsListElem.module.scss";
import { ReactComponent as IconTrash } from "../../../../../../assets/icons/trash.svg";
import { ReactComponent as IconShare } from "../../../../../../assets/icons/fidget-spinner.svg";
import { PresetT } from "../../../../../../store/reducers/DashboardPresets";
import Modal from "../../../../Modal";
import ModalAddUser from "../../../../ModalSharePreset/ModalSharePreset";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../store/reducers";
import ModalDeletePreset from "../../../../ModalDeletePreset/ModalDeletePreset";
import { UnshareDefaultPreset } from "../../../../../../store/actions/DashboadPresets.action";
import { checkEditAccess } from "../../../../../../functions/CheckEditAccess/CheckEditAccess";

const CustomPresetsListElem = (props: ICustomPresetsListElem) => {
  const { index, preset, showCustomPresetWidgets, handleDeleteCustomPreset } =
    props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id } = useSelector((state: AppState) => state.customerContextState);
  const constantReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantReceivedPresets
  );

  const dispatch = useDispatch();

  const checkIfDefault = (preset: PresetT) => {
    return constantReceivedPresets.find(
      (elem: any) => elem.sharedId === preset.id && elem.title === preset.title
    );
  };

  const handleDelete = (index: number) => {
    if (checkIfDefault(preset)) {
      setModalDeleteOpen(true);
    } else {
      handleDeleteCustomPreset(index);
    }
  };

  const handleDeleteDefaultPreset = () => {
    const defaultPreset = checkIfDefault(preset);
    dispatch(UnshareDefaultPreset([defaultPreset.id]));
    setModalDeleteOpen(false);
    handleDeleteCustomPreset(index);
  };

  return (
    <div className={styles.element}>
      <button
        type="button"
        onClick={() => showCustomPresetWidgets(preset)}
        className={styles.title}
      >
        <span className={styles.index}>{index + 1}</span>
        <span className={styles.label}>{preset.title}</span>
      </button>
      {checkIfDefault(preset) && <span className={styles.tag}>DEFAULT</span>}
      <div className={styles.actions}>
        <button className={styles.delete} onClick={() => handleDelete(index)}>
          <IconTrash />
        </button>
        {checkEditAccess(permissions, customer, id) && (
          <button className={styles.share} onClick={() => setModalOpen(true)}>
            <IconShare />
          </button>
        )}
      </div>
      <Modal
        title="Share your custom preset"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalAddUser setModalOpen={setModalOpen} preset={preset} />
      </Modal>
      <Modal
        title={`Delete ${preset.title}`}
        modalOpen={modalDeleteOpen}
        setModalOpen={setModalOpen}
      >
        <ModalDeletePreset
          setModalOpen={setModalDeleteOpen}
          handleDelete={handleDeleteDefaultPreset}
        />
      </Modal>
    </div>
  );
};

export default CustomPresetsListElem;

export interface ICustomPresetsListElem {
  index: number;
  preset: PresetT;
  showCustomPresetWidgets: (e: PresetT) => void;
  handleDeleteCustomPreset: (index: number) => void;
}
