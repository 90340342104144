import {
  GetEmployeeCallerMetricsDoneT,
  GetEmployeeCallerMetricsFailedT,
  GetEmployeeCallerMetricsTableT,
  GET_TABLE_EMPLOYEE_CALLER_METRICS,
  GET_TABLE_EMPLOYEE_CALLER_METRICS_DONE,
  GET_TABLE_EMPLOYEE_CALLER_METRICS_FAILED,
  EmployeeCallerMetricsTableDataT,
  EmployeeCallerMetricsTableParamsT
} from "../../types/widgets/EmployeeCallerMetricsTable.types";

export const GetEmployeeCallerMetricsTable = (params: EmployeeCallerMetricsTableParamsT): GetEmployeeCallerMetricsTableT => ({
  type: GET_TABLE_EMPLOYEE_CALLER_METRICS,
  params
});

export const GetEmployeeCallerMetricsTableDone = (data: EmployeeCallerMetricsTableDataT): GetEmployeeCallerMetricsDoneT => ({
  type: GET_TABLE_EMPLOYEE_CALLER_METRICS_DONE,
  data
});

export const GetEmployeeCallerMetricsTableFailed = (): GetEmployeeCallerMetricsFailedT => ({
  type: GET_TABLE_EMPLOYEE_CALLER_METRICS_FAILED
});
