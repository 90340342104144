import React from "react";
import { useParams } from "react-router-dom";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/check.svg";
import styles from "../Reports/Reports.module.scss";
import { Container, Row } from "../../components/Bootstrap/Bootstrap";
import { Form, Formik } from "formik";
import ExpandGroup from "../../components/Expand/ExpandGroup/ExpandGroup";
import Expand from "../../components/Expand/Expand";
import SingleReportGeneral from "./SingleReportGeneral/SingleReportGeneral";

const SingleReportView = () => {
  let { id } = useParams<{ id: string }>();
  const initialValues = {
    general_repeatable: "",
    generalDate: "",
    general_boundaries: "",
    generalDuration: "",
    generalDuration_filter: "",
    generalCost_from: "",
    generalCost_to: "",
    generalCost_filter: "",
    generalDirection: "",
    generalSetviceType: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => console.log(values)}
    >
      {({ errors, touched }) => (
        <Container fluid>
          <Form>
            <LayoutHeader title={`Report ID: ${id}`}>
              <Button title="Cancel" type="plain" />
              <Button
                title="Save"
                icon={
                  <IconButton
                    className={styles.icon}
                    width="1.2rem"
                    height="1.5rem"
                  />
                }
                icon_position="right"
                typeAttr="submit"
              />
            </LayoutHeader>
            <Row>
              <ExpandGroup>
                <Expand title="General" active>
                  <SingleReportGeneral touched={touched} errors={errors} />
                </Expand>
                <Expand title="Organization Structure" level={0}>
                  Empty
                </Expand>
                <Expand title="Response Group" level={0}>
                  Empty
                </Expand>
                <Expand title="Chart Options" level={0}>
                  Empty
                </Expand>
              </ExpandGroup>
            </Row>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default SingleReportView;
