export const GetCallQueueDashboard = () => [
  {
    title: "Call Queue / Response group details",
    id: "call-queue-important-metrics-old",
    type: "important-metrics-old",
    dashboardType: "call-queue",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Success rate of answered calls",
    id: "call-queue-gauge-results-old",
    type: "gauge-results-old",
    dashboardType: "call-queue",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Call type of inbound calls",
    id: "call-queue-circle-chart",
    type: "circle-chart",
    dashboardType: "call-queue",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Overview of calls",
    id: "call-queue-gauge-half",
    type: "gauge-half-old",
    dashboardType: "call-queue",
    size: 1,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Call queue details over time",
    id: "call-queue-column-line-chart-old",
    type: "column-line-chart-old",
    dashboardType: "call-queue",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Call type / call volume by type",
    id: "call-queue-column-line-chart-old-type",
    type: "column-line-chart-old",
    dashboardType: "call-queue",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Call Agents summary list",
    id: "call-queue-simple-table",
    type: "simple-table",
    dashboardType: "call-queue",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Call list",
    id: "call-queue-status-table",
    type: "status-table",
    dashboardType: "call-queue",
    size: 4,
    // availableSizes: [3, 4],
  },
];
