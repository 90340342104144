import {
  GET_CALLER_CALLEE_DEVICES,
  GET_CALLER_CALLEE_DEVICES_DONE,
  GET_CALLER_CALLEE_DEVICES_FAILED,
  TableStateActionT
} from "../../types/widgets/CallerCalleeDevicesTable.types";

export const initialState = {
  data: [],
  loading: false,
  error: false
};

const employeeCallsState = (state = initialState, action: TableStateActionT) => {
  switch (action.type) {
    case GET_CALLER_CALLEE_DEVICES:
      return { ...state, data: [], loading: true, error: false };
    case GET_CALLER_CALLEE_DEVICES_DONE:
      return { ...state, data: action.data, loading: false, error: false };
    case GET_CALLER_CALLEE_DEVICES_FAILED:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default employeeCallsState;
