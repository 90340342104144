import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetColumnLineChartDone, GetColumnLineChartFailed } from "../../actions/widgets/ColumnLineChart";
import { GetColumnLineChartT, GET_COLUMN_LINE_CHART } from "../../types/widgets/ColumnLineChart";


function* GetColumnLineChartHandler({ params, chartType }: GetColumnLineChartT) {
  try {
    const baseParams: any = {
      startDate: params.startDate,
      endDate: params.endDate,
      startHour: params.startHour,
      endHour: params.endHour,
      type: params.type
    }

    const { status, data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        ...baseParams,
        ...(params.percent && { percent: params.percent }),
        ...(params.agentId && { agentId: params.agentId }),
        ...(params.userId && { userId: params.userId }),
        ...(params.requestType && { type: params.requestType }),
        ...(params.deviceName && { deviceName: params.deviceName }),
        ...(params.url && { url: params.url }),
        ...(params.location && { location: params.location }),
        ...((params.isLatency !== null || params.isLatency) && { isLatency: params.isLatency }),
        ...(params.companyName && { companyName: params.companyName }),
        ...(params.isOrganisation !== null && params.isOrganisation !== undefined && { isOrganisation: params.isOrganisation }),
        ...(params.isUser !== null && params.isUser !== undefined && { isUser: params.isUser }),
        ...(params.isTraceroute !== null && params.isTraceroute !== undefined && { isTraceroute: params.isTraceroute }),
        ...(params.callId && { callId: params.callId }),
      },
    })

    if (status === 204) {
      yield put(GetColumnLineChartDone(chartType, null));
    } else if (status === 200) {
      yield put(GetColumnLineChartDone(chartType, data));
    }
  } catch (error) {
    yield put(GetColumnLineChartFailed(chartType));
  }
}

export default function* WatchColumnLineChart() {
  yield all([
    takeEvery(GET_COLUMN_LINE_CHART, GetColumnLineChartHandler)
  ]);
}
