import React from "react";
import styles from "./SignUpRole.module.scss";
import { Field } from "formik";

const SignUpRole = (props: ISignUpRole) => {
  const { name, title, text, icon, groupName } = props;

  return (
    <>
      <label className={styles.wrapper}>
        <Field
          className={styles.input}
          type="radio"
          name={groupName}
          value={name}
        />
        <span className={styles.info}>
          <span className={styles.iconWrapper}>{icon}</span>
          <span className={styles.heading}>
            <span className={styles.title}>{title}</span>
            <span className={styles.text}>{text}</span>
          </span>
        </span>
        <span className={styles.border}/>
      </label>
    </>
  );
};

export default SignUpRole;

export interface ISignUpRole {
  name: string;
  title: string;
  text?: string;
  icon?: React.ReactElement;
  value: string;
  groupName: string;
}
