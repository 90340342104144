export const GET_TABLE_EMPLOYEE_CALLER_METRICS = "GET_TABLE_EMPLOYEE_CALLER_METRICS";
export const GET_TABLE_EMPLOYEE_CALLER_METRICS_DONE = "GET_TABLE_EMPLOYEE_CALLER_METRICS_DONE";
export const GET_TABLE_EMPLOYEE_CALLER_METRICS_FAILED = "GET_TABLE_EMPLOYEE_CALLER_METRICS_FAILED";

type DeviceT = {
  CaptureDeviceDriver: string,
  CaptureDeviceName: string,
  CaptureNotFunctioningEventRatio: null | string,
  CpuInsufficentEventRatio: null | string,
  DeviceClippingEventRatio: null | string,
  DeviceGlitchEventRatio: null | string,
  HowlingEventCount: null | string,
  InitialSignalLevelRootMeanSquare: null | string,
  LowSpeechLevelEventRatio: null | string,
  LowSpeechToNoiseEventRatio: null | string,
  MicGlitchRate: null | string,
  ODataType: null | string,
  ReceivedNoiseLevel: null | string,
  ReceivedSignalLevel: null | string,
  RenderDeviceDriver: string,
  RenderDeviceName: string,
  RenderMuteEventRatio: null | string,
  RenderNotFunctioningEventRatio: null | string,
  RenderZeroVolumeEventRatio: null | string,
  SentNoiseLevel: null | string,
  SentSignalLevel: null | string,
  SpeakerGlitchRate: null | string,
}

type NetworkT = {
  BandwidthLowEventRatio: number,
  BasicServiceSetIdentifier: null | string,
  ConnectionType: string,
  DelayEventRatio: null | string,
  DnsSuffix: string,
  IpAddress: string,
  LinkSpeed: number,
  MacAddress: null | string,
  ODataType: null | string,
  Port: number,
  ReceivedQualityEventRatio: number,
  ReflexiveIPAddress: string,
  RelayIPAddress: string,
  RelayPort: number,
  SentQualityEventRatio: null | string,
  Subnet: string,
  WifiBand: string,
  WifiBatteryCharge: number,
  WifiChannel: number,
  WifiMicrosoftDriver: string,
  WifiMicrosoftDriverVersion: string,
  WifiRadioType: string,
  WifiSignalStrength: number,
  WifiVendorDriver: string,
  WifiVendorDriverVersion: string,
}

type CallerT = {
  displayName: string,
  id: string,
}

export type EmployeeCallerMetricsT = {
  callDuration: string,
  callEndTime: string,
  callStartTime: string,
  callType: string,
  caller: CallerT,
  callerDevice: DeviceT,
  callerNetwork: NetworkT,
  participants: Array<{
    device: DeviceT,
    network: NetworkT,
    jitter: number
    latency: number,
    packetLoss: number,
    quality: number,
    startTime: string,
    endTime: string,
    type: string,
    user: CallerT & {
      TenantId: string,
      RegistrantId: null | string,
    }
  }>,
};

export type EmployeeCallerMetricsTableDataT = Array<EmployeeCallerMetricsT>;

export type TableColumnsT = Array<{
  Header: string;
  accessor: string;
  type: string;
}>;

export type EmployeeCallerMetricsTableParamsT = {
  endpoint: string;
  customerId: string;
  startDate: number;
  endDate: number;
  startHour: number;
  endHour: number;
  userId?: string;
};

export type GetEmployeeCallerMetricsTableT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLER_METRICS;
  params: EmployeeCallerMetricsTableParamsT;
};

export type GetEmployeeCallerMetricsDoneT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLER_METRICS_DONE;
  data: EmployeeCallerMetricsTableDataT;
};

export type GetEmployeeCallerMetricsFailedT = {
  type: typeof GET_TABLE_EMPLOYEE_CALLER_METRICS_FAILED;
};

export type TableStateActionT = GetEmployeeCallerMetricsTableT | GetEmployeeCallerMetricsDoneT | GetEmployeeCallerMetricsFailedT;
