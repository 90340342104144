import React, { useState, useCallback, useEffect } from "react";
import styles from "./ModalDelete.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import Heading from "../../Heading/Heading";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import axiosConfig from "../../../api/axiosConfig";
import axiosDashboard from "../../../api/axiosDashboard";

const ModalDelete = (props: IModalDelete) => {
  const {
    setModalOpen,
    handleUpdateData,
    children,
    name,
    endpoint,
    handleClick,
    dashboard
  } = props;

  const [sent, setSent] = useState<boolean>(false);

  const handleDelete = useCallback(() => {
    if (handleClick) {
      handleClick();
      setSent(true);
    } else {
      (dashboard ? axiosDashboard : axiosConfig)
        .delete(endpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            handleUpdateData();
            setSent(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [handleUpdateData, endpoint, handleClick, dashboard]);

  useEffect(() => {
    if (sent) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
          handleClick && handleUpdateData();
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
    setSent(false);
  }, [sent, setModalOpen, handleClick, handleUpdateData]);

  if (sent) {
    return <ModalSuccess text={`${name} has been successfully deleted`} />;
  }

  return (
    <>
      <Heading title={`Delete ${name}`} weight="medium" />
      <div className={styles.element}>
        <span
          className={styles.text}
        >{`Are you sure you want to delete this ${name}?`}</span>
        {children}
        <div className={styles.footer}>
          <Button
            title="Cancel"
            type="plain"
            typeAttr="button"
            handleClick={() => setModalOpen(false)}
          />
          <Button
            title="Delete"
            icon={<IconButton />}
            icon_position="right"
            handleClick={handleDelete}
          />
        </div>
      </div>
    </>
  );
};
export default ModalDelete;

interface IModalDelete {
  setModalOpen: (e: boolean) => void;
  handleClick?: () => void;
  handleUpdateData: () => void;
  children: React.ReactChild;
  name: string;
  endpoint: string;
  dashboard?: boolean;
}
