import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetEmployeeDevicesTableDone, GetEmployeeDevicesTableFailed } from "../../actions/widgets/EmployeeDevicesTable";
import { GetEmployeeDevicesTableT, GET_TABLE_EMPLOYEE_DEVICES } from "../../types/widgets/EmployeeDevicesTable";

function* GetEmployeeDevicesTableHandler({ params }: GetEmployeeDevicesTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate
      }
    });

    if (data.length) {
      yield put(GetEmployeeDevicesTableDone(data));
    } else {
      yield put(GetEmployeeDevicesTableDone([]));
    }
  } catch (error) {
    yield put(GetEmployeeDevicesTableFailed());
  }
}

export default function* WatchEmployeeDevicesTable() {
  yield all([takeEvery(GET_TABLE_EMPLOYEE_DEVICES, GetEmployeeDevicesTableHandler)]);
}
