import React from "react";
import styles from "./UserFormTemplate.module.scss";


export interface IUserFormTemplate {
    children: any;
}

const UserFormTemplate = (props: IUserFormTemplate) => {
    const {children} = props;


  return (
    <div className={styles.wrapper}>
        {children}
    </div>
  );
};

export default UserFormTemplate;
