import axios, { AxiosResponse } from "axios";
import store from "../store/index";
import { LOGOUT } from "../store/reducers/authorization";
import { renewToken } from "./authorization";
import { saveTokens } from "../helpers/authorization";


const instance = axios.create();

let isRefreshing = false;
let refreshSubscribers: Array<any> = [];

instance.defaults.baseURL = localStorage.getItem( "apiUrl" ) || "";
instance.defaults.headers.common["Authorization"] = localStorage.getItem( "token" )
    ? `Bearer ${localStorage.getItem( "token" )}`
    : "";

instance.interceptors.response.use(
    ( response: AxiosResponse ) => {
        const {
            config
        } = response;
        const originalRequest = config;

        if ( !originalRequest.baseURL ) {


            return new Promise( ( resolve ) => {
                //@ts-ignore eslint-disable-next-line no-console
                originalRequest.baseURL = store.getState().authorization.apiUrl;
                resolve( axios( originalRequest ) );
            } );
        } else {
            return response;
        }

    },
    ( error ) => {
        const {
            config,
            response: { status },
        } = error;

        if ( !error.response ) {
            console.error( error );
            return Promise.reject( error.message );
        }

        const errorMessage = error.response?.data?.error || error.message;

        const originalRequest = config;

        if ( status === 403 ) {
            store.dispatch( { type: LOGOUT } );
        }

        if ( status === 401 ) {
            if ( !isRefreshing ) {
                isRefreshing = true;
                renewToken().then( ( { data: { token, refreshToken } } ) => {
                    isRefreshing = false;
                    onRefreshed( token );
                    saveTokens( { token, refreshToken } );
                } );
            }

            return new Promise( ( resolve ) => {
                subscribeTokenRefresh( ( token: string ) => {
                    originalRequest.headers["Authorization"] = `Bearer ${token}`;
                    resolve( axios( originalRequest ) );
                } );
            } );
        } else {
            return Promise.reject( errorMessage );
        }
    }
);

function subscribeTokenRefresh ( cb: ( token: string ) => void ) {
    refreshSubscribers.push( cb );
}

function onRefreshed ( token: string ) {
    refreshSubscribers.map( ( cb ) => cb( token ) );
}

export default instance;
