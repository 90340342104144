export const GetEmployeeCallDashboard = () => [
  {
    title: "Location of caller and callee",
    id: "employee-call-map",
    type: "map",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "/metric/ucc/getLocationCalls",
    // availableSizes: [3, 4],
  },
  {
    title: "Quality of the call session SLA/MOS",
    id: "employee-call-gauge-results-quality",
    type: "gauge-results",
    dashboardType: "employee-call",
    size: 1,
    endpoint: '/metric/ucc/getPercentOfQuality'
    // availableSizes: [1, 2],
  },
  {
    title: "Heatmap location/employee",
    id: "employee-call-heatmap",
    type: "heatmap",
    dashboardType: "employee-call",
    size: 3,
    endpoint: "/metric/ucc/getCallMetrics",
    accessDifferentTypes: false,
  },
  {
    title: "User and devices statistics",
    id: "employee-call-column-line-chart",
    type: "column-line-chart",
    dashboardType: "employee-call",
    endpoint: "/metric/ucc/getMetricByType",
    chartType: "ucc",
    requestType: 'call',
    size: 4,
    data: {
      column: [
        {
          title: "Calls count",
          id: "callsCount",
          unit: ""
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%"
        }
      ]
    }
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Caller/conference call/meeting room originator metrics",
    id: "employee-call-simple-table-caller",
    type: "employee-callee-originator-metrics-table",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "/metric/ucc/getCalls",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  {
    title: "Callee/destination originator metrics",
    id: "employee-call-simple-table-callee",
    type: "employee-caller-metrics-table",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "/metric/ucc/getCalls",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  {
    title: "Applications performance",
    id: "employee-call-apps-performance-extended",
    type: "apps-performance-extended",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "/metric/ucc/getApplicationsMetric",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  {
    title: "Best path from the caller and callee",
    id: "employee-call-path-analytics",
    type: "path-analytics",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "/metric/pathAnalytics",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  {
    title: "Conference call/meeting room details for the user",
    id: "employee-call-tests-table",
    type: "tests-table",
    dashboardType: "employee-call",
    size: 4,
    endpoint: 'metric/ucc/getCalls',
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  {
    title: "Device used by caller and callee/conference call/meeting room",
    id: "employee-call-simple-table-device",
    type: "caller-callee-table",
    dashboardType: "employee-call",
    size: 4,
    endpoint: "metric/ucc/getCalls",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  /* CAN BE USED LATER */
  // {
  //   title: "Call Queue used queues/response groups",
  //   id: "employee-call-gauge-half-used",
  //   type: "gauge-half-old",
  //   dashboardType: "employee-call",
  //   size: 2,
  //   // availableSizes: [2, 3, 4],
  //   // path: {
  //   //   pathname: "/call-queue",
  //   //   dashboardType: "call-queue",
  //   //   name: "Call Queue",
  //   // },
  // },
  // {
  //   title: "Call Queue unused queues/response groups",
  //   id: "employee-call-gauge-half-unused",
  //   type: "gauge-half-old",
  //   dashboardType: "employee-call",
  //   size: 2,
  //   // availableSizes: [2, 3, 4],
  //   // path: {
  //   //   pathname: "/call-queue",
  //   //   dashboardType: "call-queue",
  //   //   name: "Call Queue",
  //   // },
  // },
];
