import React, { useState } from "react";
import { Container } from "../../components/Bootstrap/Bootstrap";
import Button from "../../components/Button/Button";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import axios from "../../api/axiosDashboard";
import { ReactComponent as LoginKey } from "../../assets/icons/login-key.svg";
import { ReactComponent as Key } from "../../assets/icons/key.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import styles from "./APIKeyGenerator.module.scss";
import ReactTooltip from "../../components/ReactTooltip/ReactTooltip";

function APIKeyGenerator() {
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [showApiKeyTooltip, setShowApiKeyTooltip] = useState(false);
  const [showSecretKeyTooltip, setShowSecretKeyTooltip] = useState(false);

  const fetchAPIKey = async () => {
    try {
      await axios.post("/api/generateKeys").then((res) => {
        if (res && res.status === 200) {
          setApiKey(res.data.apiKey);
          setSecretKey(res.data.secretKey);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const copySecretKey = () => {
    setShowSecretKeyTooltip(true);
    navigator.clipboard.writeText(secretKey);
  };

  const copyApiKey = () => {
    setShowApiKeyTooltip(true);
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <Container fluid>
      <LayoutHeader
        description="To connect securely to the service, your application needs an API key with permission to access the cluster and resources such as tables."
        title="Standard Reports"
      ></LayoutHeader>
      <div>
        <Button
          // className={styles.button}
          title={`Generate ${apiKey && secretKey ? "new" : ""} API key`}
          icon={apiKey && secretKey ? <Key /> : <LoginKey />}
          icon_position="right"
          handleClick={fetchAPIKey}
        />
        {apiKey && secretKey && (
          <>
            <div className={styles.apiKeyWrapper}>
              <p>API KEY</p>
              <input type="text" id="apiKey" disabled value={apiKey} />
              <ReactTooltip
                visible={showApiKeyTooltip}
                text="Copied to clipboard"
              >
                <Copy
                  onClick={copyApiKey}
                  onMouseLeave={() => setShowApiKeyTooltip(false)}
                  className={styles.copy}
                />
              </ReactTooltip>
            </div>
            <div className={styles.apiKeyWrapper}>
              <p>SECRET KEY</p>
              <input
                type="text"
                id="secretKey"
                disabled
                value={secretKey}
              />
              <ReactTooltip
                visible={showSecretKeyTooltip}
                text="Copied to clipboard"
              >
                <Copy
                  className={styles.copy}
                  onMouseLeave={() => setShowSecretKeyTooltip(false)}
                  onClick={copySecretKey}
                />
              </ReactTooltip>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default APIKeyGenerator;
