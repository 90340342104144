import React, { useState, useEffect } from "react";
import styles from "./MultiSelect.module.scss";
import { Field, FieldProps } from "formik";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import cn from "classnames";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";

const MultiSelectInput = (props: IMultiSelectInput) => {
  const {
    errors,
    touched,
    name,
    placeholder,
    label,
    isBgGray,
    required,
    className,
    isDisabled,
    type,
  } = props;

  function validateRequired(value: any) {
    let error;
    if (!value.length) {
      error = "This field is required";
    } else {
      value.forEach((item: any) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.value)) {
          error = "Please enter a valid email address";
        }
      });
    }
    return error;
  }
  function validateCorrect(value: any) {
    let error;
    if (value.length) {
      value.forEach((item: any) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.value)) {
          error = "Please enter a valid email address";
        }
      });
    }
    return error;
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <Field
        component={CustomSelect}
        validate={(required && validateRequired) || validateCorrect}
        name={name}
        placeholder={placeholder}
        isBgGray={isBgGray}
        isDisabled={isDisabled}
        type={type}
      />
      {errors[name] && touched[name] && (
        <span className={styles.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default MultiSelectInput;

export interface IMultiSelectInput {
  placeholder: string;
  errors?: any;
  touched?: any;
  name: string;
  label?: string;
  isBgGray?: boolean;
  required?: boolean;
  className?: string;
  isDisabled?: boolean;
  type?: "status";
}

const CustomSelect = (props: ICustomSelect) => {
  const { placeholder, form, field, isBgGray, isDisabled, type } = props;
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<[{ label: string; value: string }] | []>(
    []
  );

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleChange = (value: any) => {
    if (value) {
      setValue(value);
    } else setValue([]);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e);
  };

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        //@ts-ignore
        setValue((prevState: [{ label: string; value: string }] | []) => {
          return [...prevState, createOption(inputValue)];
        });
        setInputValue("");
        event.preventDefault();
    }
  };

  useEffect(() => {
    form.setFieldValue(field.name, value);
    // TODO: fix dependencies
    // eslint-disable-next-line
  }, [value]);

  return (
    <CreatableSelect
      classNamePrefix={styles.select}
      className={cn(
        styles.select,
        isBgGray && styles["isPlain"],
        form.errors[field.name] && form.touched[field.name] && styles["isError"]
      )}
      styles={customSelectStyles}
      placeholder={placeholder}
      type={type}
      components={{
        DropdownIndicator: null,
        ClearIndicator: null,
        MultiValueRemove,
      }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      name={field.name}
      value={value}
      isDisabled={isDisabled}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconClose className={styles.icon} />
    </components.MultiValueRemove>
  );
};

export interface ICustomSelect extends FieldProps {
  placeholder: string;
  title: string;
  value: any;
  name: string;
  isBgGray?: boolean;
  isDisabled: boolean;
  type?: string;
}

export const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 3,
    boxShadow: state.isFocused ? null : null,
    cursor: "auto",
    fontSize: "1.2rem",
    minHeight: "4rem",
    padding: "0.4rem",
    opacity: state.isDisabled ? 0.5 : 1,
    backgroundColor: "transparent",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "0",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 3,
    hyphens: "auto",
    marginTop: "0.2rem",
    textAlign: "left",
    wordWrap: "break-word",
    boxShadow: "0 8px 6px 0px rgba(25, 40, 60, 0.04)",
    overflow: "hidden",
    border: "1px solid rgba(25, 40, 60, 0.04)",
    maxHeight: "175px",
  }),
  multiValue: (base: any) => ({
    ...base,
    borderRadius: 3,
    margin: "0",
    backgroundColor: "transparent",
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    fontSize: "1.2rem",
    lineHeight: "2.2rem",
    padding: "0.2rem 1.1rem",
    borderRadius: 3,
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    padding: "0.8rem 1rem",
    ":hover": {
      backgroundColor: "transparent",
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#8a97ad",
  }),
};
