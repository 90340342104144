import React from "react";
import styles from "../TableCellAgent/TableCellAgent.module.scss";
import { Link } from "react-router-dom";

const TableCellDevice = (props: ITableCellDevice) => {
  const { name, id, children } = props;

  return (
    <>
      {id ? (
        <Link
          to={`/devices-management/device/${id}`}
          className={styles.element}
        >
          <div className={"d-flex flex-column"}>
            <span className={styles.name}>{name}</span>
            <span className={styles.id}>ID: {id}</span>
          </div>
        </Link>
      ) : (
        <span className={styles.name}>{children}</span>
      )}
    </>
  );
};

export default TableCellDevice;

export interface ITableCellDevice {
  name?: string;
  id?: number;
  children?: any;
}
