import React, { useCallback, useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import { useSelector } from "react-redux";
import GlobalStyles from "../../assets/styles/theme/global";
import { darkTheme } from "../../assets/styles/theme/dark-theme";
import { lightTheme } from "../../assets/styles/theme/light-theme";
import Sidebar from "../Sidebar/Sidebar";
import TopBar from "../TopBar/TopBar";
import NotificationsBar from "../Notifications/NotificationsBar/NotificationsBar";
import cn from "classnames";
import Toast, { IToast } from "../Toast/Toast";
import PresetSwitch from "../PresetSwitch/PresetSwitch";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { AppState } from "../../store/reducers";

interface ILayout {
  children: Array<React.ReactChild>;
}

const Layout = (props: ILayout) => {
  const { children } = props;

  const themeState = useSelector((state: AppState) => state.themeState);
  const notificationsBarState = useSelector(
    (state: AppState) => state.notificationsBarState
  );
  const menuState = useSelector((state: AppState) => state.menuState);
  const { message, error } = useSelector(
    (state: AppState) => state.authorization
  );
  const [toastList, setToastList] = useState<IToast[] | []>([]);

  const showToast = useCallback(
    (type: "message" | "error", message: string) => {
      const toast = {
        id: Math.floor(Math.random() * 101 + 1),
        type: type,
        message: message,
      };

      setToastList([...toastList, toast]);
    },
    [toastList]
  );

  useEffect(() => {
    if (message && message.length > 0) {
      showToast("message", message);
    }
    if (error && error.length > 0) {
      showToast("error", error);
    }
  }, [error, message, showToast]);

  const [theme, setTheme] = useState(themeState ? themeState : "light");

  const localTheme = window.localStorage.getItem("theme")
    ? window.localStorage.getItem("theme")
    : "light";

  useEffect(() => {
    setTheme(localTheme as "dark" | "light");
  }, [localTheme]);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  const isDashboard = window.location.pathname === "/";

  return (
    <>
      <GlobalStyles theme={theme === "dark" ? darkTheme : lightTheme} />
      <div className={styles.element}>
        <TopBar />
        {isDashboard && isResponsiveView && menuState && (
          <div className={styles.selectBar}>
            <PresetSwitch />
          </div>
        )}

        <div className={styles.content}>
          <Sidebar menuState={menuState} />
          <div
            className={cn(styles.wrapper, menuState && styles["isExpanded"])}
          >
            {children}
          </div>
          {notificationsBarState && <NotificationsBar />}
        </div>
        {toastList.length > 0 && <Toast toastList={toastList} />}
      </div>
    </>
  );
};

export default Layout;
