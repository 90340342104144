import React from "react";
import styles from "./MenuElem.module.scss";
import cn from "classnames";
import { ReactComponent as IconChevron } from "../../../../../assets/icons/chevron.svg";

const MenuElem = (props: IMenuElem) => {
  const { title, index, active, children, setActiveIndex } = props;

  const handleClick = () => {
    if (index !== active) {
      setActiveIndex(index);
    } else {
      setActiveIndex(-1);
    }
  };

  return (
    <div className={cn(styles.element, active === index && styles["isOpen"])}>
      <div className={styles.heading} onClick={handleClick}>
        <div className={styles.title}>{title}</div>
        <IconChevron className={styles.arrow} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default MenuElem;

export interface IMenuElem {
  title: string;
  index: number;
  active: number;
  children: any;
  setActiveIndex: (e: number) => void;
}
