import React, { useEffect, useState } from "react";
import styles from "./CheckboxGroup.module.scss";
import cn from "classnames";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
import { Field, FieldProps } from "formik";

const CheckboxGroup = (props: ICheckboxGroup) => {
  const {
    name,
    label,
    options,
    touched,
    errors,
    direction = "column",
    isBgGray,
    className,
    disabled,
  } = props;

  const [fields, setFields] = useState<ICheckboxField[]>([]);

  useEffect(() => {
    // @ts-ignore
    setFields(options);
  }, [options]);

  return (
    <div className={styles.wrapper}>
      {label && <span className={styles.title}>{label}</span>}
      <div
        className={cn(
          styles.element,
          direction === "row" && styles["isRow"],
          direction === "grid" && styles["isGrid"],
          className && className
        )}
      >
        {fields &&
          fields.map((item, key) => {
            return (
              <Field
                component={CheckboxField}
                key={key}
                {...item}
                name={name}
                touched={touched}
                value={item.value}
                errors={errors}
                isBgGray={isBgGray}
                disabled={disabled}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CheckboxGroup;

const CheckboxField = (props: ICheckboxField) => {
  const { form, field, value, label, isBgGray, disabled } = props;

  const handleInitValue = () => {
    return (
      form.values[field.name] &&
      form.values[field.name].some((item: string) => item === value)
    );
  };
  const [active, setActive] = useState<boolean>(handleInitValue);

  return (
    <div
      className={cn(
        styles.fieldWrapper,
        isBgGray && styles["isBgGray"],
        disabled && styles["isDisabled"]
      )}
    >
      <label className={styles.label}>
        <input
          type="checkbox"
          name={field.name}
          value={value}
          onBlur={(e: any) => field.onBlur(e)}
          onChange={(e: any) => {
            setActive(!active);
            field.onChange(e);
          }}
        />
        <div className={styles.box}>
          {active && <IconCheck className={styles.icon} />}
        </div>
        <span className={styles.text}>{label}</span>
      </label>
    </div>
  );
};

export interface ICheckboxField extends FieldProps {
  name?: string;
  label: string;
  value: string;
  isBgGray?: boolean;
  disabled?: boolean;
}

export interface ICheckboxGroup {
  label?: string;
  options: Array<{ value: string; label: string }>;
  errors: any;
  touched: any;
  direction?: "row" | "column" | "grid";
  isBgGray?: boolean;
  name: string;
  className?: string;
  disabled?: boolean;
}
