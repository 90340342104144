import React, { useState, useEffect } from "react";
import styles from "./TimeRangePicker.module.scss";
import { SetTimeRange } from "../../store/actions/SetTimeRange.action";
import { useDispatch } from "react-redux";
import TimePicker from "./TimePicker/TimePicker";
import moment from "moment";

const TimeRangePicker = () => {
  const [startTime, setStartTime] = useState<Date>(
    // @ts-ignore
    JSON.parse(window.localStorage.getItem("timeRange"))
      ? new Date(
          // @ts-ignore
          JSON.parse(localStorage.getItem("timeRange")).start * 1000
        )
      : moment(new Date("1970-01-01").setHours(9, 0, 0)).toDate()
  );

  const [endTime, setEndTime] = useState<Date>(
    // @ts-ignore
    JSON.parse(window.localStorage.getItem("tPmeRange"))
      ? new Date(
          // @ts-ignore
          JSON.parse(localStorage.getItem("timeRange")).end * 1000
        )
      : moment(new Date("1970-01-01").setHours(18, 0, 0)).toDate()
  );

  const [endNewValue, setEndNewValue] = useState<Date | null>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (moment(startTime).isAfter(endTime)) {
      setEndTime(startTime);
      const midnight = moment(
        new Date("1970-01-01 UTC").setHours(23, 59, 59)
      ).toDate();
      if (
        moment(startTime).isBefore(
          moment(new Date("1970-01-01 UTC").setHours(23, 0, 0)).toDate()
        )
      ) {
        setEndNewValue(moment(startTime).add(1, "hours").toDate());
      } else {
        setEndNewValue(new Date(midnight));
      }
    } else {
      const data = {
        start: moment(new Date(startTime).toUTCString()).unix(),
        end: moment(new Date(endTime).toUTCString()).unix(),
      };
      dispatch(SetTimeRange(data));
      localStorage.setItem("timeRange", JSON.stringify(data));
      setEndNewValue(null);
    }
  }, [startTime, endTime, dispatch]);

  return (
    <div className={styles.wrapper}>
      <TimePicker initialTime={startTime} handleTime={setStartTime} />
      <span className={styles.dash}> - </span>
      <TimePicker
        initialTime={endTime}
        handleTime={setEndTime}
        startTime={startTime}
        endNewValue={endNewValue}
      />
    </div>
  );
};

export default TimeRangePicker;
