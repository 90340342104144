import React, { useEffect, useState, useCallback } from "react";
import { Form, Formik } from "formik";
import axios from "../../../api/axiosConfig";
import styles from "../ModalInviteUser/ModalInviteUser.module.scss";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import DatePicker from "../../formElements/DatePicker/DatePicker";
import RadioGroup from "../../../components/formElements/RadioGroup/RadioGroup";
import Button from "../../Button/Button";
import { ReactComponent as IconSave } from "../../../assets/icons/check.svg";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import moment from "moment";
import { updateUser } from "../../../api/authorization";
import Heading from "../../Heading/Heading";
import SelectInput from "../../formElements/SelectInput/SelectInput";

interface IModalEditUser {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  selectedUser: any;
}

const ModalEditUser = (props: IModalEditUser) => {
  const { setModalOpen, handleUpdateData, selectedUser } = props;
  const [sent, setSent] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>({
    management: [],
    configuration: [],
    dashboard: [],
  });

  const fetchData = useCallback(async () => {
    try {
      await axios(`auth/getPermissionEnums`).then((res) => {
        if (res && res.status === 200) {
          setPermissions({
            management: toInputData(Object.values(res.data.management)),
            configuration: toInputData(Object.values(res.data.configuration)),
            dashboard: toInputData(Object.values(res.data.dashboard)),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toInputData = (permissionsData: Array<string>) => {
    return (
      permissionsData.map((value: string) => {
        return {
          value,
          label: value,
        };
      }) || []
    );
  };

  const toBackendData = (
    permissionsData: Array<{ value: string; label: string }>
  ) => {
    return permissionsData.map((obj: { value: string; label: string }) => {
      return obj.value;
    });
  };


  const handleSubmit = useCallback(
    (values, setErrors) => {
      updateUser(
        {
          name: values.name,
          email: values.email,
          contactNumber: values.phone,
          expireDate: values.expireDate
            ? moment(new Date(values.expireDate).setHours(23, 59, 59)).unix()
            : null,
          orgPermission: values.companyAuth,
          structurePermission: values.customersAuth,
          management: values.management ? toBackendData(values.management) : [],
          configuration: values.configuration ? toBackendData(values.configuration) : [],
          dashboard: values.dashboard ? toBackendData(values.dashboard) : [],
        },
        selectedUser.id
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            handleUpdateData();
            setSent(true);
          }
        })
        .catch((error) => console.log(error));
    },

    [handleUpdateData, selectedUser]
  );

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
        setModalOpen(false);
      }, 3000);
    }
  }, [sent, setModalOpen]);

  const initialValues = {
    name: selectedUser.userDetails.name,
    email: selectedUser.userDetails.email,
    phone: selectedUser.phone,
    expireDate: selectedUser.expireDate
      ? new Date(selectedUser.expireDate * 1000)
      : null,
    companyAuth: selectedUser.orgPermission,
    customersAuth: selectedUser.structurePermission,
    management: selectedUser.management ? toInputData(selectedUser.management) : null,
    configuration: selectedUser.configuration ? toInputData(selectedUser.configuration) : null,
    dashboard: selectedUser.dashboard ? toInputData(selectedUser.dashboard) : null,
  };

  if (sent) {
    return <ModalSuccess text={`User data has been successfully changed`} />;
  }

  return (
    <>
      <Heading title="Edit User" weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              setSubmitting(false);
              handleSubmit(values, setErrors);
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="email"
                label="Email"
                placeholder="Email"
                textInputType="email"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Name"
                placeholder="Full name"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="phone"
                label="Phone"
                placeholder="(000) 000-0000"
                textInputType="tel"
              />
              <SelectInput
                placeholder="Select managements"
                touched={touched}
                errors={errors}
                name="management"
                label="Managements"
                options={permissions.management}
                isMulti
                customValue
              />
              <SelectInput
                placeholder="Select configurations"
                touched={touched}
                errors={errors}
                name="configuration"
                label="Configurations"
                options={permissions.configuration}
                isMulti
                customValue
              />
              <SelectInput
                placeholder="Select dashboards"
                touched={touched}
                errors={errors}
                name="dashboard"
                label="Dashboards"
                options={permissions.dashboard}
                isMulti
                customValue
              />
              <DatePicker
                touched={touched}
                errors={errors}
                label="Expire date"
                name="expireDate"
                type="date"
                minDate={new Date()}
              />
              <RadioGroup
                name="companyAuth"
                label="Permissions Level within Company"
                options={[
                  {
                    value: "data",
                    label: "Read Only",
                  },
                  {
                    value: "fullAccess",
                    label: "Full Access",
                  },
                  {
                    value: "none",
                    label: "No Access",
                  },
                ]}
                className={styles.radioGroup}
              />
              <RadioGroup
                name="customersAuth"
                label="Permissions Level at Customers"
                options={[
                  {
                    value: "data",
                    label: "Read Only",
                  },
                  {
                    value: "fullAccess",
                    label: "Full Access",
                  },
                  {
                    value: "none",
                    label: "No Access",
                  },
                ]}
                className={styles.radioGroup}
              />
              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Save"
                  typeAttr="submit"
                  icon={<IconSave />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ModalEditUser;
