import React from "react";
import styles from "./ModalSuccess.module.scss";
import { ReactComponent as IconSuccess } from "../../../assets/icons/pass-success.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import stylesModal from "../Modal.module.scss";
import { createPortal } from "react-dom";

interface IModalSuccess {
  text: string;
  setModalOpen?: (e: boolean) => void;
  modalOpen?: boolean;
}

const ModalSuccess = (props: IModalSuccess) => {
  const { text, setModalOpen, modalOpen } = props;

  if (setModalOpen) {
    if (!modalOpen) return null;

    const ModalTemplate = (
      <div className={stylesModal.overlay} onClick={(e) => setModalOpen(false)}>
        <div
          className={stylesModal.element}
          role="dialog"
          aria-labelledby=""
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className={stylesModal.button}
            onClick={() => setModalOpen(false)}
          >
            <IconClose width="1.8rem" height="1.8rem" />
          </button>
          <div className={styles.element}>
            <IconSuccess className={styles.icon} />
            <div className={styles.text}>{text}</div>
          </div>
        </div>
      </div>
    );
    return createPortal(ModalTemplate, document.body);
  } else {
    return (
      <div className={styles.element}>
        <IconSuccess className={styles.icon} />
        <div className={styles.text}>{text}</div>
      </div>
    );
  }
};

export default ModalSuccess;
