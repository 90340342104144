import {all, put, takeLatest} from "redux-saga/effects";
import axios from "../../api/axiosDashboard";
import {GetDevicesListT, GET_DEVICES_LIST, GET_DEVICES_LIST_DONE, GET_DEVICES_LIST_FAILED} from "../types/Devices";

function* GetDevicesListHandler({id, isAdded}: GetDevicesListT) {
  try {
    const {data} = yield axios
      .get(`/management/devices/${id}`, {
        params: {
          addedDevice: isAdded
        },
      })

    if (data.length) {
      const res = data.map((elem: any) => {
        return {
          id: elem.id || "",
          device: {
            name: elem.name || "Unknown",
            id: elem.id || ""
          },
          customerId: elem.customerId || "",
          name: elem.name || "Unknown",
          agentName: elem.agentName || "",
          status: (elem.status === false && "offline") || (elem.status === true && "online") || "unknown",
          deviceType: elem.deviceType || "",
          vendor: elem.vendor || "",
          connectionType: elem.connectionType || "",
          model: elem.model || "",
          alias: elem.alias || "",
          circuitId: elem.circuitId || "",
          description: elem.description || "",
          mac: elem.mac || "",
          ip: elem.ip || "",
          osVersion: elem.osVersion || "",
          latency: elem.latency || "",
          lastTestDate: elem.lastTestDate || "",
          location: `${elem.country ? elem.country : ""} ${elem.province ? elem.province : ""
            } ${elem.city ? elem.city : ""} ${elem.zipCode ? elem.zipCode : ""
            } ${elem.street ? elem.street : ""}`,
          country: elem.country || "",
          province: elem.province || "",
          zipCode: elem.zipCode || "",
          city: elem.city || "",
          street: elem.street || "",
          locationGroup: elem.locationGroup || "",
          bandwidthDownload: elem.bandwidthDownload || "",
          bandwidthUpload: elem.bandwidthUpload || "",
          snmpVersion: elem.snmpVersion || "",
          communityString: elem.communityString || "",
          userName: elem.userName || "",
          privProtocol: elem.privProtocol || "",
          privKey: elem.privKey || "",
          authProtocol: elem.authProtocol || "",
          authKey: elem.authKey || "",
          agentId: elem.agentId || "",
          interface: elem.interface || "",
          addedDevice: elem.addedDevice || isAdded
        }
      })
      yield put({type: GET_DEVICES_LIST_DONE, payload: res, isAdded});
    } else {

      yield put({type: GET_DEVICES_LIST_DONE, payload: null, isAdded});
    }

  } catch (error) {
    console.log(error);
    yield put({type: GET_DEVICES_LIST_FAILED, error});
  }
}

export default function* WatchDevices() {
  yield all([
    takeLatest(GET_DEVICES_LIST, GetDevicesListHandler),
  ]);
}
