import React, { useState, useEffect } from "react";
import styles from "../TextInput/TextInput.module.scss";
import cn from "classnames";
import { Field, FieldProps } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";

const CurrencyInput = (props: ICurrencyInput) => {
  const { name, label, placeholder, errors, touched, setFieldValue, required } =
    props;

  function validate(value: number) {
    let error;
    if (!value) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <div
      className={cn(
        styles.wrapper,
        errors[name] && touched[name] && styles["isError"]
      )}
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        component={CustomCurrencyInput}
        className={styles.input}
        id={name}
        name={name}
        placeholder={placeholder}
        setFieldValue={setFieldValue}
        validate={required && ((e: number) => validate(e))}
      />
      {errors[name] && touched[name] && (
        <span className={styles.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default CurrencyInput;

const CustomCurrencyInput = (props: ICustomCurrencyInput) => {
  const { form, field, placeholder, setFieldValue } = props;
  const [currency, setCurrency] = useState<string>(form.initialValues.currency);

  const handlePrefix = (val: string) => {
    switch (val) {
      case "GBP":
        return "£";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "AED":
        return "د.إ";
      case "AUD":
        return "$";
      case "JPY":
        return "¥";
      case "CHF":
        return "CHF";
      case "CAD":
        return "$";
      case "CNH":
        return "¥";
      case "INR":
        return "₹";
      default:
        return "";
    }
  };

  useEffect(() => {
    setCurrency(form.values.currency);
  }, [form.values.currency]);

  return (
    <div className={styles.element}>
      <NumberFormat
        className={styles.input}
        thousandSeparator={true}
        decimalSeparator="."
        prefix={handlePrefix(currency)}
        decimalScale={2}
        placeholder={placeholder}
        {...field}
        onValueChange={(values) => {
          setFieldValue(field.name, values.floatValue);
        }}
      />
    </div>
  );
};
export interface ICurrencyInput {
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  touched?: any;
  className?: string;
  setFieldValue: (e: string, val: NumberFormatValues["floatValue"]) => void;
  required?: boolean;
}

interface ICustomCurrencyInput extends FieldProps {
  placeholder?: string;
  setFieldValue: (e: string, val: NumberFormatValues["floatValue"]) => void;
}
