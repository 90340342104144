import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetEmployeeCalleeOriginatorMetricsTableDone, GetEmployeeCalleeOriginatorMetricsTableFailed } from "../../actions/widgets/EmployeeCalleeOriginatorMetricsTable";
import { GetEmployeeCalleeOriginatorMetricsTableT, GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS } from "../../types/widgets/EmployeeCalleeOriginatorMetricsTable.types";

function* GetEmployeeCalleeOriginatorMetricsHandler({ params }: GetEmployeeCalleeOriginatorMetricsTableT) {
  try {
    const { data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startHour: params.startHour,
        endHour: params.endHour,
        startDate: params.startDate,
        endDate: params.endDate,
       ...(params.userId && {userId: params.userId})
      }
    });

    if (data.length) {
      yield put(GetEmployeeCalleeOriginatorMetricsTableDone(data));
    } else {
      yield put(GetEmployeeCalleeOriginatorMetricsTableDone([]));
    }
  } catch (error) {
    yield put(GetEmployeeCalleeOriginatorMetricsTableFailed());
  }
}

export default function* WatchEmployeeCalleeOriginatorMetricsTable() {
  yield all([takeEvery(GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS, GetEmployeeCalleeOriginatorMetricsHandler)]);
}
