import React, { FormEvent } from "react";
import styles from "./TextareaInput.module.scss";
import cn from "classnames";
import { Field } from "formik";

const TextareaInput = (props: ITextareaInput) => {
  const {
    name,
    label,
    placeholder,
    errors,
    touched,
    rows = 5,
    resize,
    isBgGray,
    required,
    disabled,
  } = props;

  function validate(value: ITextareaInput["value"]) {
    let error;
    if (!value) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <div
      className={cn(
        styles.wrapper,
        errors[name] && touched[name] && styles["isError"]
      )}
    >
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field
        component={CustomTextareaInput}
        className={cn(
          styles.input,
          isBgGray && styles["isPlain"],
          disabled && styles["isDisabled"]
        )}
        id={name}
        name={name}
        placeholder={placeholder}
        rows={rows}
        resize={resize}
        validate={required && ((e: ITextareaInput["value"]) => validate(e))}
        disabled={disabled}
      />
      {errors[name] && touched[name] && (
        <span className={styles.error}>{errors[name]}</span>
      )}
    </div>
  );
};

export default TextareaInput;

const CustomTextareaInput = ({ field, resize, ...props }: any) => {
  const handleResize = (e: FormEvent<HTMLTextAreaElement>) => {
    const element = e.target as HTMLTextAreaElement;

    element.style.height = "4rem";
    element.style.height = element.scrollHeight + "px";
  };

  return (
    <div className={styles.element}>
      <textarea
        className={styles.input}
        type="text"
        {...field}
        {...props}
        onChange={(e) => {
          field.onChange(e);
        }}
        onInput={(e) => resize && handleResize(e)}
      />
    </div>
  );
};

export interface ITextareaInput {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  errors?: any;
  touched?: any;
  rows?: number;
  resize?: boolean;
  isBgGray?: boolean;
  required?: boolean;
  disabled?: boolean;
}
