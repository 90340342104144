import React from "react";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import CheckboxGroup from "../../../components/formElements/CheckboxGroup/CheckboxGroup";

const ReportTemplateGeneral = ({ ...props }: any) => {
  return (
    <div>
      <TextInput
        name="generalDescription"
        label="Description"
        placeholder="Type anything"
        isBgGray
        required
        {...props}
      />
      <CheckboxGroup
        name="generalDescription_SummaryFormat"
        options={[
          {
            value: "summary_format",
            label: "Summary format",
          },
        ]}
        {...props}
      />
      <CheckboxGroup
        name="generalSharing"
        label="Sharing"
        direction="grid"
        options={[
          {
            value: "personal",
            label: "Personal",
          },
          {
            value: "current-site",
            label: "Current Site",
          },
          {
            value: "common",
            label: "Common to all sites",
          },
        ]}
        {...props}
      />
      <CheckboxGroup
        name="generalOrientation"
        label="Orientation"
        direction="grid"
        options={[
          {
            value: "portrait",
            label: "Portrait",
          },
          {
            value: "landscape",
            label: "Landscape",
          },
        ]}
        {...props}
      />
    </div>
  );
};
export default ReportTemplateGeneral;
