import React, { useEffect, useState } from "react";
import styles from "./TopBar.module.scss";
import axios from "../../api/axiosConfig";
import PresetSwitch from "../PresetSwitch/PresetSwitch";
import TopBarUser from "./TopBarUser/TopBarUser";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import SidebarHeader from "../Sidebar/SidebarHeader/SidebarHeader";

const TopBar = () => {
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  const isResponsiveLgView = useMediaQuery({
    query: `(max-width: ${breakpoints["xl"]}px)`,
  });

  const isDashboard = window.location.pathname === "/";

  const [version, setVersion] = useState();

  useEffect(() => {
    (async () => {
      try {
        await axios("version").then((res) => {
          if (res && res.status === 200) {
            setVersion(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className={styles.element}>
      <SidebarHeader />
      <div className={styles.wrapper}>
        {!isResponsiveView && (
          <div className={styles.left}>
            <p>Version - {version}</p>
          </div>
        )}
        <div className="ml-auto d-flex align-items-center">
          {isDashboard && !isResponsiveView && <PresetSwitch />}
          {!isResponsiveLgView && <TopBarUser />}
        </div>
      </div>
    </div>
  );
};
export default TopBar;
