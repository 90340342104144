import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "../../../api/axiosConfig";
import { AppState } from "../../../store/reducers";
import SelectInput from "../SelectInput/SelectInput";

const AgentsSelect = (props: IAgentsSelect) => {
  const { touched, errors, label, name, required, customValue } = props;
  const [data, setData] = useState<Array<{ value: string; label: string }>>([]);
  const { id } = useSelector((state: AppState) => state.customerContextState);

  const fetchData = useCallback(async (id: string) => {
    try {
      await axios(`/configuration/agents/list/${id}`).then((res) => {
        if (res && res.status === 200) {
          setData(
            res.data.map((elem: any) => {
              return {
                value: elem.id,
                label: elem.name,
              };
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData(id);
    return () => {
      isMounted = false;
    };
  }, [fetchData, id]);

  return (
    <SelectInput
      touched={touched}
      errors={errors}
      name={name}
      options={data}
      label={label}
      placeholder="Choose agent"
      isSearchable={true}
      required={required}
      customValue={customValue}
    />
  );
};

export default AgentsSelect;

interface IAgentsSelect {
  touched: any;
  errors: any;
  name: string;
  label: string;
  required?: boolean;
  customValue?: boolean;
}
