import React from "react";
import { IUserCard } from "../../components/UserCard/UserCard";
import ActionToolsItem from "../../components/ActionTools/ActionToolsItem/ActionToolsItem";
import Button from "../../components/Button/Button";
import ActionTools from "../../components/ActionTools/ActionTools";
import TableCellUsersBelong, {
  ITableCellUsersBelong,
} from "../../components/tableElements/TableCellUsersBelong/TableCellUsersBelong";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import { ICustomerValues } from "../../views/CustomersManagement/CustomersManagement.view";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import { TableSortByName } from "../../functions/TableSortByName/TableSortByName";
import TableCellUser from "../../components/tableElements/TableCellUser/TableCellUser";

const Customers = (props: ICustomers) => {
  const { data, noData, openEdit, openDelete, openSLA } = props;
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id } = useSelector((state: AppState) => state.customerContextState);

  const showAccess =
    (permissions.structure.data && permissions.structure.fullAccess) ||
    permissions.structure.data;

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerDetails",
        sortType: TableSortByName,
        Cell: ({ cell: { value } }: { cell: { value: IUserCard } }) => (
          <TableCellUser {...value} />
        ),
      },
      {
        Header: "Users",
        accessor: "users",
        show: showAccess ? true : false,
        disableSortBy: true,
        Cell: ({
          cell: { value, column },
        }: {
          cell: { value: ITableCellUsersBelong["children"]; column: any };
        }) => (
          <TableCellUsersBelong title={column.Header}>
            {value}
          </TableCellUsersBelong>
        ),
      },
      {
        Header: "Customers",
        accessor: "customers",
        show: showAccess ? true : false,
        disableSortBy: true,
        Cell: ({
          cell: { value, column },
        }: {
          cell: { value: ITableCellUsersBelong["children"]; column: any };
        }) => (
          <TableCellUsersBelong title={column.Header} type="Customer">
            {value}
          </TableCellUsersBelong>
        ),
      },
      {
        Header: "Customer Id",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        disableSortBy: true,
        show: checkEditAccess(permissions, customer, id) ? true : false,
        className: "p-0",
        Cell: (value: { row: { original: any } }) => (
          <>
            <ActionTools type="inTable">
              <ActionToolsItem>
                <Button
                  title="Edit Customer"
                  type="tooltip"
                  scale={false}
                  handleClick={() => openEdit && openEdit(value.row.original)}
                />
              </ActionToolsItem>
              <ActionToolsItem>
                <Button
                  title="Delete Customer"
                  type="tooltip"
                  scale={false}
                  handleClick={() =>
                    openDelete && openDelete(value.row.original)
                  }
                />
              </ActionToolsItem>
              {customer.mainCustomer && (
                <ActionToolsItem>
                  <Button
                    title="Edit SLA targets"
                    type="tooltip"
                    scale={false}
                    handleClick={() => openSLA && openSLA(value.row.original)}
                  />
                </ActionToolsItem>
              )}
            </ActionTools>
          </>
        ),
      },
    ],
    [openEdit, openDelete, openSLA, permissions, customer, id, showAccess]
  );

  return (
    <>
      <ReactTable columns={columns} data={data} noData={noData} />
    </>
  );
};

export default Customers;

interface ICustomers {
  data: Array<SingleCustomerT>;
  noData: boolean;
  openEdit?: (e: ICustomerValues) => void;
  openDelete?: (e: ICustomerValues) => void;
  openSLA?: (e: ICustomerValues) => void;
}

export type SingleCustomerT = {
  customer: {
    id: string;
    name: string;
    email: string;
    logo: {
      content: any;
      id: string;
      mime: string;
    };
  };
  isAccount?: boolean;
  users: Array<SingleCustomerUsersT>;
  subCustomers: Array<SingleCustomerCustomersT>;
};

export type SingleCustomerUsersT = {
  name: string;
  email: string;
  photoData: {
    content: any;
    id: string;
    mime: string;
  };
  id: string;
  orgPermission: string;
  structurePermission: string;
};
export type SingleCustomerCustomersT = {
  customer: {
    name: string;
    email: string;
    logo: {
      content: any;
      id: string;
      mime: string;
    };
    id: string;
  };
};
