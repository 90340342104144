import React from "react";
import DatePicker from "react-date-picker";
import styles from "./DatePickerField.module.scss";
import { FieldProps, useFormikContext } from "formik";
import { ReactComponent as IconCalendar } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as IconChevron } from "../../../../assets/icons/calendar-chevron.svg";
import { ReactComponent as IconClose } from "../../../../assets/icons/close.svg";

const DatePickerField = (props: IDatePickerField) => {
  const { field, minDate, maxDate } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <DatePicker
      className={styles.element}
      value={field.value}
      name={field.name}
      clearIcon={<IconClose />}
      calendarIcon={<IconCalendar />}
      format="dd/MM/y"
      prev2Label={null}
      next2Label={null}
      prevLabel={<IconChevron />}
      nextLabel={<IconChevron />}
      showNeighboringMonth={false}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(val: any) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default DatePickerField;

interface IDatePickerField extends FieldProps {
  minDate?: Date;
  maxDate?: Date;
}
