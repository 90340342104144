import TableCellQuality from "../../../../components/tableElements/TableCellQuality/TableCellQuality";
import TableCellText from "../../../../components/tableElements/TableCellText/TableCellText";
import TableCellWaterfall from "../../../../components/tableElements/TableCellWaterfall/TableCellWaterfall";

export const GetApplicationDashboard = () => [
  {
    title: "EUX score of the App",
    id: "app1-gauge-results",
    type: "gauge-results",
    dashboardType: "app1",
    size: 1,
    availableSizes: [1, 2],
    endpoint: "/metric/app/getEux"
  },
  {
    title: "About the app",
    id: "app1-important-metrics",
    type: "important-metrics",
    dashboardType: "app1",
    size: 1,
    availableSizes: [1, 2],
    endpoint: "/metric/app/important",
    chartType: "app"
  },
  {
    title: "Apps locations",
    id: "app1-important-metrics-locations",
    type: "important-metrics",
    dashboardType: "app1",
    size: 1,
    availableSizes: [1, 2],
    endpoint: "/metric/app/important",
    chartType: "location"
  },
  {
    title: "Availability score",
    id: "app1-gauge-results-availability",
    type: "gauge-results",
    dashboardType: "app1",
    size: 1,
    availableSizes: [1, 2],
    endpoint: '/metric/app/getAvailibility'
  },
  // {
  //   title: "Cost of downtime",
  //   id: "app1-circle-chart-downtime",
  //   type: "circle-chart",
  //   dashboardType: "app1",
  //   size: 1,
  //   availableSizes: [1, 2],
  //   endpoint: "/metric/app/getDowntimeCost"
  // },
  {
    title: "Apps by location",
    id: "app1-apps-performance-extended",
    type: "apps-performance-extended",
    dashboardType: "app1",
    size: 4,
    availableSizes: [3, 4],
    endpoint: "/metric/app/getApplicationsMetric",
    isByLocation: true,
  },
  {
    title: "Heatmap app performance across locations",
    id: "app2-heatmap",
    type: "heatmap",
    dashboardType: "app1",
    size: 4,
    endpoint: "/metric/app/getHeatMap",
    chartType: "agents",
    isByLocation: true
  },
  {
    title: "Best path",
    id: "app1-path-analytics-best",
    type: "path-analytics",
    dashboardType: "app1",
    size: 3,
    availableSizes: [3, 4],
    endpoint: "/metric/app/getTopWorstPathAnalytic",
    isTop: true
  },
  {
    title: "Metrics best of ",
    id: "app1-important-metrics-best",
    type: "important-metrics",
    dashboardType: "best",
    chartType: "best",
    size: 1,
    availableSizes: [1, 2],
    endpoint: "/metric/app/topWorstImportant",
    isTop: true,
  },
  {
    title: "Worse path",
    id: "app1-path-analytics-worse",
    type: "path-analytics",
    dashboardType: "app1",
    size: 3,
    availableSizes: [3, 4],
    endpoint: "/metric/app/getTopWorstPathAnalytic",
    chartType: "agents",
  },
  {
    title: "Worse of",
    id: "app1-important-metrics-worse",
    type: "important-metrics",
    dashboardType: "worse",
    chartType: "worse",
    size: 1,
    availableSizes: [1, 2],
    endpoint: "/metric/app/topWorstImportant",
    isTop: false,
  },
  {
    title: "Application issues",
    id: "app1-tests-table-issues",
    type: "simple-table",
    dashboardType: "app1",
    size: 4,
    availableSizes: [3, 4],
    endpoint: "/metric/app/getApplicationInfo",
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "EUX",
        accessor: "eux",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Availibility",
        accessor: "availability",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Performance",
        accessor: "perfomance",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      }
    ],
  },
  {
    title: "App running across agents",
    id: "app1-simple-table-agents",
    type: "simple-table",
    dashboardType: "app1",
    queryParams: { type: "app" },
    size: 4,
    availableSizes: [2, 3, 4],
    endpoint: "/metric/app/getApplicationInfo",
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Transaction",
        accessor: "transactionTime",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Latency",
        accessor: "latency",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "Connections",
        accessor: "connections",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: Array<any> } }) => {
          return <TableCellText>{value?.length}</TableCellText>;
        },
      }
    ],
  },
  {
    title: "Network metrics for the app",
    id: "app1-column-line-chart-network",
    type: "column-line-chart",
    dashboardType: "app1",
    size: 2,
    availableSizes: [2, 3, 4],
    endpoint: "/metric/app/getApplicationLineColumnData",
    chartType: "appNetwork",
    data: {
      column: [
        {
          title: "Network",
          id: "network",
        },
        {
          title: "Hops",
          id: "hops",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "App performance metrics",
    id: "app1-column-line-chart-perf",
    type: "column-line-chart",
    dashboardType: "app2",
    size: 2,
    availableSizes: [2, 3, 4],
    endpoint: "/metric/app/getApplicationLineColumnData",
    chartType: "appPerformance",
    data: {
      column: [
        {
          title: "Performance",
          id: "performance",
        },
        {
          title: "Transaction",
          id: "transaction",
        },
        {
          title: "Timeout",
          id: "timeout",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "App analyzer table",
    id: "app-analyzer-table",
    type: "simple-table",
    dashboardType: "app1",
    size: 4,
    availableSizes: [2, 3, 4],
    endpoint: "/metric/app/getAppAnalizerTable",
    columns: [
      {
        Header: "Application",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "eux score",
        accessor: "eux",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => {
          return <TableCellText>{value}</TableCellText>;
        },
      },
      {
        Header: "performance",
        accessor: "performance",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => {
          return <TableCellText>{value}</TableCellText>;
        },
      },
      {
        Header: "accessability",
        accessor: "accessability",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "dns Time",
        accessor: "dnsTime",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "url",
        accessor: "url",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "hops",
        accessor: "hops",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "latency",
        accessor: "latency",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "transaction Time",
        accessor: "transactionTime",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "network Rtt",
        accessor: "networkRtt",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "status Code",
        accessor: "statusCode",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <TableCellText noWrap>{value}</TableCellText>
        ),
      },
      {
        Header: "waterfall",
        accessor: "waterfall",
        Cell: ({ cell: { value }, row }: { cell: { value: any }, row: any }) => {
          return <TableCellWaterfall rowData={row.original} gateway={row.original.name} chart={value} />
        },
      },
      {
        Header: "Https",
        accessor: "isHttps",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        }
      },
    ],
  },
  {
    title: "Applications cost of downtime",
    id: "applications-status-table",
    type: "apps-downtime-cost-table",
    dashboardType: "applications",
    availableSizes: [3, 4],
    size: 4
  }
];
