import React, { useState } from "react";
import { Form, Formik } from "formik";
import styles from "./ModalChangePassword.module.scss";
import TextInput from "../../formElements/TextInput/TextInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../store/reducers/authorization";
import ModalSuccess from "../ModalSuccess/ModalSuccess";

interface IChangePasswordForm {
  setModalOpen: (e: boolean) => void;
}

const ChangePasswordForm = (props: IChangePasswordForm) => {
  const { setModalOpen } = props;
  const auth = useSelector((state: any) => state.authorization);

  const [done, setDone] = useState(false);

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const dispatch = useDispatch();

  async function handleSubmit(values: any) {
    await dispatch(changePassword(values));
    //TODO: Temp auth.error === {}. Waiting for CORS fix
    if (auth.error === null || Object.keys(auth.error).length === 0) {
      setDone(true);
    }
  }

  if (done) {
    return (
      <ModalSuccess text={`Your password was<br/> successfully updated!`} />
    );
  }

  return (
    <div className={styles.content}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit({
              loginId: auth.id,
              email: auth.email,
              oldPassword: values.oldPassword,
              newPassword: values.confirmPassword,
            });
          }, 500);
        }}
      >
        {({ errors, touched }) => (
          <Form className={styles.element}>
            <TextInput
              touched={touched}
              errors={errors}
              name="oldPassword"
              label="Current password"
              placeholder="Type your password"
              textInputType="password"
              noAutocomplete
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="password"
              label="New password"
              placeholder="Create new password"
              textInputType="password"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="confirmPassword"
              label="Confirm new password"
              placeholder="Type your new password"
              textInputType="password"
              confirmPassword={true}
              required
            />
            <div className={styles.footer}>
              <Button
                title="Cancel"
                type="plain"
                typeAttr="button"
                handleClick={() => setModalOpen(false)}
              />
              <Button
                title="Save"
                typeAttr="submit"
                icon={<IconButton />}
                icon_position="right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ChangePasswordForm;
