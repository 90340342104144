
import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetHeatmapDone, GetHeatmapFailed } from "../../actions/widgets/Heatmap.action";
import { GetHeatmapT, GET_HEATMAP } from "../../types/widgets/Heatmap.types";


function* GetHeatmapHandler({ params }: GetHeatmapT) {
  try {

    const { status, data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
        startHour: params.startHour,
        endHour: params.endHour,
        chartType: params.label,
        ...(params.isByLocation !== null && params.isByLocation !== undefined && {isByLocation: params.isByLocation}),
        ...(params.id && {userId: params.id}),
        ...(params.agentId && {agentId: params.agentId}),
        ...(params.url && {url: params.url}),
        ...(params.location && {location: params.location}),
        ...(params.isTraceroute !== null && params.isTraceroute !== undefined && {isTraceroute: params.isTraceroute}),
      },
    })
    if (status === 204) {
      yield put(GetHeatmapDone(params.chartType || params.id || '', null));

    } else if (status === 200) {
      yield put(GetHeatmapDone(params.chartType || params.id || '', data));
    }
  } catch (error) {
    yield put(GetHeatmapFailed(params.chartType || params.id || ''));
  }
}


export default function* WatchHeatmap() {
  yield all([
    takeEvery(GET_HEATMAP, GetHeatmapHandler)
  ]);
}
