import React from "react";
import LayoutUserAuth from "../../../components/LayoutUserAuth/LayoutUserAuth";
import UserFormTemplate from "../../../components/UserFormTemplate/UserFormTemplate";
import PasswordRecoveryForm from "../../../components/UserFormTemplate/PasswordRecoveryForm";

const PasswordRecoveryView = () => {
  return (
    <LayoutUserAuth title="Password Recovery" stepBackPath="/auth/sign-in">
      <UserFormTemplate>
        <PasswordRecoveryForm />
      </UserFormTemplate>
    </LayoutUserAuth>
  );
};

export default PasswordRecoveryView;
