import {ActionTypeT, EmployeeT, GET_EMPLOYEES_LIST, GET_EMPLOYEES_LIST_DONE, GET_EMPLOYEES_LIST_FAILED} from "../types/Employees";


export type StateT = {
  isLoading: boolean;
  message: string | null;
  list: EmployeeT[] | null;
  error: boolean;
};

const initialState: StateT = {
  isLoading: false,
  message: null,
  list: null,
  error: false,
};

const reducerEmployees = (state = initialState, action: ActionTypeT): StateT => {
  switch (action.type) {
    case GET_EMPLOYEES_LIST:
      return {...state, list: null, isLoading: true, error: false};
    case GET_EMPLOYEES_LIST_DONE:
      return {...state, isLoading: false, list: action.payload};
    case GET_EMPLOYEES_LIST_FAILED:
      return {...state, list: null, isLoading: false, error: true};
    default:
      return state;
  }
};

export default reducerEmployees;
