import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import styles from "./UserFormTemplate.module.scss";
import TextInput from "../formElements/TextInput/TextInput";
import Button from "../Button/Button";
import { ReactComponent as IconNewPassword } from "../../assets/icons/paper-plane.svg";
import { forgetPassword } from "../../store/reducers/authorization";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

const PasswordRecoveryForm = () => {
  const { error, message } = useSelector(
    (state: AppState) => state.authorization
  );
  const [hasError, setError] = useState<string | null>(null);
  const [hasMessage, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  const initialValues: initialValuesT = {
    email: "",
  };

  type initialValuesT = {
    email: string;
  };

  useEffect(() => {
    setMessage(null);
    if (message) {
      setMessage(message);
    }
  }, [message]);

  useEffect(() => {
    setError(error);
  }, [error]);

  async function submitForm(values: any) {
    await dispatch(forgetPassword(values));
  }

  if (hasMessage) {
    return <div className={styles.confirmMessage}>{hasMessage}</div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitForm(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className={styles.element}>
          <TextInput
            touched={touched}
            errors={errors}
            name="email"
            label="Email"
            placeholder="Email address"
            textInputType="email"
            required
          />

          <div className={styles.footer}>
            {hasError && <div className={styles.errorMessage}>{hasError}</div>}
            <Button
              typeAttr="submit"
              title="Send Reset Link"
              icon={<IconNewPassword />}
              icon_position="right"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default PasswordRecoveryForm;
