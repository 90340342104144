import React, { useCallback, useEffect, useState } from "react";
import AgentsUnknown from "../../widgets/AgentsUnknown/AgentsUnknown";
import axios from "../../api/axiosConfig";
import Modal from "../../components/Modal/Modal";
import ModalConnectNewAgent from "../../components/Modal/ModalConnectNewAgent/ModalConnectNewAgent";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import FilterTabs from "../../components/FilterTabs/FilterTabs";

const AgentsUnknownManagementView = () => {
  const [tableData, setTableData] = useState<SingleAgentT[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<SingleAgentT>();
  const [noData, setNoData] = useState<boolean>(false);
  const { customer } = useSelector((state: AppState) => state.authorization);
  const { id, name } = useSelector(
    (state: AppState) => state.customerContextState
  );
  const activeContext = id !== customer.id;

  const fetchData = useCallback(async () => {
    try {
      await axios("/configuration/agent", {
        params: {
          knownAgents: false,
        },
      }).then((res) => {
        if (res && res.status === 200) {
          if (res.data.length) {
            setNoData(false);
            setTableData(
              res.data.map((elem: SingleAgentT) => {
                return {
                  id: elem.id ? elem.id : "",
                  name: elem.name ? elem.name : "",
                  device: elem.machineName ? elem.machineName : "",
                  status: elem.status ? elem.status : "",
                  location: `${elem.country ? elem.country : ""} ${
                    elem.province ? elem.province : ""
                  } ${elem.city ? elem.city : ""} ${
                    elem.zipCode ? elem.zipCode : ""
                  } ${elem.street ? elem.street : ""}`,
                  country: elem.country ? elem.country : "",
                  province: elem.province ? elem.province : "",
                  zipCode: elem.zipCode ? elem.zipCode : "",
                  city: elem.city ? elem.city : "",
                  street: elem.street ? elem.street : "",
                  agentType: elem.agentType ? elem.agentType : "",
                  alias: elem.alias ? elem.alias : "",
                  registrationDate: elem.registrationDate
                    ? elem.registrationDate
                    : "",
                  internalIPaddress: elem.internalIp ? elem.internalIp : "",
                  externalIPaddress: elem.ip ? elem.ip : "",
                  internalSystemName: elem.internalSystemName
                    ? elem.internalSystemName
                    : "",
                  description: elem.description ? elem.description : "",
                  clientId: activeContext ? id : "",
                  clientName: activeContext ? name : "",
                  numberOfTests: elem.numberOfTests ? elem.numberOfTests : null,
                  applications: elem.applications
                    ? elem.applications.map((elem: any) => {
                        return {
                          id: elem.id,
                          title: elem.name,
                        };
                      })
                    : [],
                  frequencyOfTests: elem.frequencyOfTests
                    ? elem.frequencyOfTests
                    : null,
                  features: elem.features
                    ? elem.features.map((elem: any) => {
                        return elem.id;
                      })
                    : [],
                  currency: elem.currency ? elem.currency : "",
                  employeeCost: elem.employeeCost ? elem.employeeCost : null,
                  bandwidthCost: elem.bandwidthCost ? elem.bandwidthCost : null,
                  locationDowntimeCost: elem.locationDowntimeCost
                    ? elem.locationDowntimeCost
                    : null,
                  agentDowntimeCost: elem.agentDowntimeCost
                    ? elem.agentDowntimeCost
                    : null,
                };
              })
            );
          } else {
            setNoData(true);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, name, activeContext]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  const openConnect = (agent: SingleAgentT) => {
    setSelectedAgent(agent);
    setModalOpen(true);
  };

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Agents" />
        <FilterTabs
          links={[
            {
              title: "Unknown Agents",
              path: "/agents-management/unknown",
            },
            {
              title: "Known Agents",
              path: "/agents-management/known",
            },
          ]}
        />
        <AgentsUnknown
          data={tableData}
          openConnect={openConnect}
          noData={noData}
        />
        {noData && (
          <NoDataPlaceholder image title="No unknown agents were found" />
        )}
      </Container>
      {selectedAgent && (
        <Modal
          title="Connect New Agent"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        >
          <ModalConnectNewAgent
            agent={selectedAgent}
            setModalOpen={setModalOpen}
            activeContext={activeContext}
          />
        </Modal>
      )}
    </>
  );
};

export default AgentsUnknownManagementView;

export type SingleAgentT = {
  id: string;
  name: string;
  machineName: string;
  status: string;
  location: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
  agentType: string;
  alias: string;
  registrationDate: number;
  internalIp: string;
  internalIPaddress: string;
  externalIPaddress: string;
  internalSystemName: string;
  description: string;
  clientId: string;
  clientName: string;
  numberOfTests: number;
  applications: Array<{
    details: {
      id: string;
      title: string;
    };
  }>;
  frequencyOfTests: number;
  features: Array<string>;
  currency: string;
  employeeCost: number;
  bandwidthCost: number;
  agentDowntimeCost: number;
  locationDowntimeCost: number;
  ip: string;
};
