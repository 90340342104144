

// import { networkDefaultDashboard } from './network'
import { agentsDefaultDashboard } from './agents'
import { applicationsDefaultDashboard } from './applications'
import { bandwidthTestingDefaultDashboard } from './bandwidthTesting'
import { devicesDefaultDashboard } from './devices'
import { financeDefaultDashboard } from './finance'
import { serviceDefaultDashboard } from './service'
// import { transformationAndAdoptionDefaultDashboard } from './transformationAndAdoption'
import { uccDefaultDashboard } from './ucc'

const defaultDashboards = [
  // networkDefaultDashboard,
  agentsDefaultDashboard,
  applicationsDefaultDashboard,
  bandwidthTestingDefaultDashboard,
  devicesDefaultDashboard,
  financeDefaultDashboard,
  serviceDefaultDashboard,
  // transformationAndAdoptionDefaultDashboard,
  uccDefaultDashboard
]
export default defaultDashboards
