import React, { useEffect, useState } from "react";
import TableCellText, {
  ITableCellText,
} from "../../components/tableElements/TableCellText/TableCellText";
import TableCellAgent, {
  ITableCellAgent,
} from "../../components/tableElements/TableCellAgent/TableCellAgent";
import TableCellStatus, {
  ITableCellStatus,
} from "../../components/tableElements/TableCellStatus/TableCellStatus";
import TableCellDate, {
  ITableCellDate,
} from "../../components/tableElements/TableCellDate/TableCellDate";
import ReactTable from "../../components/tableElements/ReactTable/ReactTable";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/hyperlink.svg";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { SingleAgentT } from "../../views/AgentsUnknownManagement/AgentsUnknownManagement.view";

interface IAgentsUnknown {
  data: Array<SingleAgentT>;
  noData: boolean;
  openConnect: (e: SingleAgentT) => void;
}

const AgentsUnknown = (props: IAgentsUnknown) => {
  const { data, openConnect, noData } = props;
  const [agentsList, setAgentsList] = useState<Array<SingleAgentT>>(data);
  const { permissions } = useSelector((state: AppState) => state.authorization);

  useEffect(() => {
    setAgentsList(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellAgent } }) => (
          <TableCellAgent>{value}</TableCellAgent>
        ),
      },
      {
        Header: "Device",
        accessor: "device",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
        }: {
          cell: { value: ITableCellStatus["status"] };
        }) => <TableCellStatus status={value} view="agents" />,
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Alias",
        accessor: "alias",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Registration Date",
        accessor: "registrationDate",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellDate } }) => (
          <TableCellDate>{value}</TableCellDate>
        ),
      },
      {
        Header: "Agent Id",
        accessor: "id",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Internal IP address",
        accessor: "internalIPaddress",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "External IP address",
        accessor: "externalIPaddress",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Internal system name",
        accessor: "internalSystemName",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: ITableCellText } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "",
        accessor: "tools",
        show:
          permissions.structure.fullAccess && permissions.structure.data
            ? true
            : false,
        disableSortBy: true,
        Cell: (value: { row: { original: any } }) => (
          <>
            <Button
              title="Connect"
              icon={<IconButton />}
              icon_position="right"
              handleClick={() => openConnect(value.row.original)}
              className={"d-flex ml-auto"}
            />
          </>
        ),
      },
    ],
    [openConnect, permissions]
  );

  return <ReactTable columns={columns} data={agentsList} noData={noData} />;
};

export default AgentsUnknown;
