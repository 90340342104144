import React, { useState, useEffect } from "react";
import styles from "../AddApp/AddApp.module.scss";
import Button from "../../Button/Button";
import ModalAddInterface from "../../Modal/ModalAddInterface/ModalAddInterface";
import { Field } from "formik";
import cn from "classnames";

const AddInterface = (props: IAddInterface) => {
  const {
    setFieldValue,
    label,
    errors,
    touched,
    name,
    required,
    items,
    title,
    disabled,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setTableData] = useState<any[]>([]);

  const handleUpdate = (values: any) => {
    setTableData(values);
  };

  useEffect(() => {
    setFieldValue(
      name,
      data.map((elem: any) => elem.details.id)
    );
  }, [data, setFieldValue, name]);

  const getSelectedIDs = (data: any) => {
    const ids: any = {};
    data.forEach((elem: any) => {
      ids[elem.key] = true;
    });
    return ids;
  };

  function validate(value: any) {
    let error;
    if (!value.length) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <>
      <div className={cn(styles.wrapper, disabled && styles["isDisabled"])}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.list}>
          <Field
            component={AddInterfaceField}
            validate={required && ((e: any) => validate(e))}
            data={data}
            name={name}
            setModalOpen={setModalOpen}
          />
        </div>
        {errors[name] && touched[name] && (
          <span className={styles.error}>{errors[name]}</span>
        )}
      </div>
      <ModalAddInterface
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleUpdate={handleUpdate}
        selectedItems={getSelectedIDs(data)}
        items={items}
        title={title}
      />
    </>
  );
};

export default AddInterface;

const AddInterfaceField = (props: IAddInterfaceField) => {
  const { data, setModalOpen } = props;
  return (
    <>
      {data.length > 0 &&
        data.map((app, key) => {
          return (
            <AddInterfaceElem
              key={key}
              last={key === data.length - 1}
              title={app.details.title}
            />
          );
        })}
      <Button
        title="+ Add"
        type="outline"
        handleClick={() => setModalOpen(true)}
      />
    </>
  );
};

const AddInterfaceElem = (props: IAddInterfaceElem) => {
  const { title, last } = props;
  return (
    <div className={styles.item}>
      {title}
      {!last && `,\u00A0`}
    </div>
  );
};

export interface IAddInterfaceElem {
  title: string;
  id?: string;
  last: boolean;
}

export interface IAddInterfaceField {
  data: any[];
  setModalOpen: (e: boolean) => void;
}

interface IAddInterface {
  label?: string;
  setFieldValue: (e: string, val: any) => void;
  errors?: any;
  touched?: any;
  name: string;
  required?: boolean;
  items: Array<{
    key: string;
    id: string;
    title: string;
    description?: string;
    number: string;
  }>;
  title: string;
  type?: string;
  disabled?: boolean;
}
