export const GetResourceDashboard = () => [
  {
    title: "Cost over time of resource usage",
    id: "resource-most-active-cost",
    type: "most-active",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Monthly cost by resource type",
    id: "resource-circle-chart-cost",
    type: "circle-chart",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "EUX score",
    id: "resource-circle-chart-eux",
    type: "circle-chart",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top servers by cost",
    id: "resource-most-active-top",
    type: "most-active",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Estimated monthly cost",
    id: "resource-status-table-cost",
    type: "status-table",
    dashboardType: "resource",
    size: 4,
    // availableSizes: [3, 4],
  },
  {
    title: "Revenue by service type",
    id: "resource-circle-chart-eux",
    type: "circle-chart",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top service by cost",
    id: "resource-gauge-results-old-multi-cost",
    type: "gauge-results-old-multi",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Projected costs",
    id: "resource-gauge-results-old-multi-projected",
    type: "gauge-results-old-multi",
    dashboardType: "resource",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Cost by service",
    id: "resource-column-line-chart-old-service",
    type: "column-line-chart-old",
    dashboardType: "resource",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost by account",
    id: "resource-column-line-chart-old-account",
    type: "column-line-chart-old",
    dashboardType: "resource",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Service usage",
    id: "resource-tests-table",
    type: "tests-table",
    dashboardType: "resource",
    size: 4,
    // availableSizes: [3, 4],
  },
];
