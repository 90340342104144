import React, { useState, useEffect, useCallback, Fragment } from "react";
import styles from "./AddApp.module.scss";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import { Field, FieldProps } from "formik";
import cn from "classnames";
import ModalAddApiApp from "../../Modal/ModalAddApiApp/ModalAddApiApp";
import axiosConfig from "../../../api/axiosConfig";
import { useSelector } from "react-redux";
import { customerContextSelector } from "../../../selectors";

const AddApp = (props: IAddApp) => {
  const {
    setFieldValue,
    label,
    errors,
    touched,
    name,
    required,
    title,
    disabled,
  } = props;

  const [apps, setApps] = useState<Array<AppT>>([]);
  const [apis, setApis] = useState<Array<AppT>>([]);
  const [apiModal, setApiModal] = useState<boolean>(false);
  const [appModal, setAppModal] = useState<boolean>(false);
  const customerContextState = useSelector(customerContextSelector);

  const fetchApps = useCallback(async () => {
    try {
      if (!apps.length && appModal) {
        await axiosConfig(
          `/configuration/app/list/${customerContextState.id}`
        ).then((res) => {
          if (res && res.status === 200) {
            setApps(
              res.data.map((elem: any) => {
                return {
                  id: elem.id,
                  name: elem.name,
                  type: "app",
                };
              })
            );
          }
        });
      }
      if (!apis.length && apiModal) {
        await axiosConfig(
          `/configuration/api/simpleList/${customerContextState.id}`
        ).then((res) => {
          if (res && res.status === 200) {
            setApis(
              res.data.map((elem: any) => {
                return {
                  id: elem.id,
                  name: elem.name,
                  type: "api",
                  aliesName: elem.aliesName,
                  subtests: [],
                };
              })
            );
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [customerContextState.id, apiModal, apis.length, appModal, apps.length]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchApps();
    }
    return () => {
      isMounted = false;
    };
  }, [fetchApps, apiModal, appModal]);

  function validate(value: any[]) {
    let error;
    if (!value.length) {
      error = "This field is required";
    }
    return error;
  }

  return (
    <>
      <div className={cn(styles.wrapper, disabled && styles["isDisabled"])}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.list}>
          <Field
            component={AddAppField}
            validate={required && ((e: any) => validate(e))}
            name={name}
            title={title}
            apps={appModal ? apps : apis}
            setFieldValue={setFieldValue}
            setApiModal={setApiModal}
            setAppModal={setAppModal}
          />
        </div>
        {errors[name] && touched[name] && (
          <span className={styles.error}>{errors[name]}</span>
        )}
      </div>
    </>
  );
};

export default AddApp;

const AddAppField = (props: IAddAppField) => {
  const { title, apps, setFieldValue, form, field, setApiModal, setAppModal } =
    props;
  const options = [
    { value: 288, label: "5 mins" },
    { value: 144, label: "10 mins" },
    { value: 96, label: "15 mins" },
    { value: 48, label: "30 mins" },
    { value: 32, label: "45 mins" },
    { value: 24, label: "1 hour" },
    { value: 8, label: "3 hours" },
    { value: 4, label: "6 hours" },
    { value: 2, label: "12 hours" },
    { value: 1, label: "24 hours" },
  ];
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [subtestNames, setSubtestNames] = useState<any[]>([]);

  const [data, setData] = useState<any[]>(
    form.initialValues[field.name] ? form.initialValues[field.name] : []
  );

  const handleUpdate = (values: Array<AppT>) => {
    setData(values);
  };

  const openAPIModal = () => {
    setAppModal(false);
    setApiModal(true);
    setModalOpen(true);
  };

  const openAppModal = () => {
    setApiModal(false);
    setAppModal(true);
    setModalOpen(true);
  };

  const showLabel = (countOfDay: number) => {
    return options[options.findIndex((apps: any) => apps.value === countOfDay)]?.label;
  };

  useEffect(() => {
    setFieldValue(
      field.name,
      data.map(({ id, name, subtests, type, title, countOfDay }: AppT) => {
        return {
          id,
          name: name || title,
          type,
          ...(countOfDay && { countOfDay }),
          ...(subtests && { subtests }),
        };
      })
    );
  }, [data, setFieldValue, field.name]);

  return (
    <>
      <div className={styles.appApiWrapper}>
        <div>
          <p>API</p>
          {data.length > 0 &&
            data.map((app, key) => {
              return (
                <AddAppElem
                  key={app.id}
                  last={key === data.length - 1}
                  name={app.name || app.title}
                  subtests={app.subtests}
                  showLabel={showLabel}
                />
              );
            })}
        </div>
        <div>
          <p>APP</p>
          {data.length > 0 &&
            data.map((app, key) => {
              return (
                <Fragment key={app.id}>
                  {!app.subtests && (
                    <div>
                      {app.name || app.title} - {showLabel(app.countOfDay)}
                    </div>
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button title="+ Add" type="outline" handleClick={openAPIModal} />
        <Button title="+ Add" type="outline" handleClick={openAppModal} />
      </div>
      <Modal title={title} modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <ModalAddApiApp
          setModalOpen={setModalOpen}
          handleUpdate={handleUpdate}
          setSubtestNames={setSubtestNames}
          list={apps}
          subtestNames={subtestNames}
          preselected={data}
          options={options}
        />
      </Modal>
    </>
  );
};

const AddAppElem = (props: IAddAppElem) => {
  const { name, last, subtests, showLabel } = props;
  return (
    <>
      {subtests?.length && (
        <div className={styles.item}>
          {name}
          {subtests.map((option: any, index) => {
            return (
              <span key={index} className={styles.item}>
                {" "}
                {option.name} - {showLabel(option.countOfDay)}
              </span>
            );
          })}
          {!last && `,\u00A0`}
        </div>
      )}
    </>
  );
};

export interface IAddAppElem {
  name: string;
  id?: string;
  last: boolean;
  subtests: Array<any>;
  showLabel: (e: number) => string;
}

export interface IAddAppField extends FieldProps {
  apps: Array<AppT>;
  title: string;
  name: string;
  setModalOpen: (e: boolean) => void;
  setFieldValue: (e: string, val: any) => void;
  setApiModal: (e: boolean) => void;
  setAppModal: (e: boolean) => void;
}

interface IAddApp {
  label?: string;
  setFieldValue: (e: string, val: Array<{ id: string; title: string }>) => void;
  errors?: any;
  touched?: any;
  name: string;
  required?: boolean;
  title: string;
  disabled?: boolean;
}

export type AppT = {
  id: string;
  name: string;
  title: string;
  type: string;
  subtests?: Array<any>;
  countOfDay?: number;
};
