import React from "react";
import styles from "../ModalDelete/ModalDelete.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";

const ModalDeletePreset = (props: IModalDeletePreset) => {
  const { setModalOpen, handleDelete } = props;

  return (
    <>
      <div className={styles.element}>
        <span className={styles.text}>
          Are you sure you want to delete this dashboard preset?
          <br />
          If you delete it, it will be deleted for all users.
        </span>
        <div className={styles.footer}>
          <Button
            title="Cancel"
            type="plain"
            typeAttr="button"
            handleClick={() => setModalOpen(false)}
          />
          <Button
            title="Delete"
            icon={<IconButton />}
            icon_position="right"
            handleClick={() => handleDelete()}
          />
        </div>
      </div>
    </>
  );
};
export default ModalDeletePreset;

interface IModalDeletePreset {
  setModalOpen: (e: boolean) => void;
  handleDelete: () => void;
}
