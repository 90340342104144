import React from "react";
import stylesModal from "../Modal.module.scss";
import styles from "./ModalShareWidgets.module.scss";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import { createPortal } from "react-dom";
import { Column, Row } from "../../Bootstrap/Bootstrap";
// import ExpandGroup from "../../Expand/ExpandGroup/ExpandGroup";
// import Expand from "../../Expand/Expand";
import cn from "classnames";
import Heading from "../../Heading/Heading";
// import ModalManageDashboardChooseElem from "../ModalManageDashboard/ModalManageDashboardChooseElem/ModalManageDashboardChooseElem";
// import ModalManageDashboardArea from "../ModalManageDashboard/ModalManageDashboardWidgets/ModalManageDashboardWidgetsArea/ModalManageDashboardWidgetsArea";
// import { useSelector } from "react-redux";
// import { dashboardStateT } from "../../../store/modules/dashboard";
import Button from "../../Button/Button";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
// import { AppState } from "../../../store/modules";
// import { WidgetElemT } from "../ModalManageDashboard/ModalManageDashboardWidgets/ModalManageDashboardWidgetsAreaElem/ModalManageDashboardWidgetsAreaElem";

const ModalShareWidgets = (props: IModalShareWidgets) => {
  const { modalOpen, setModalOpen } = props;

  // const widgets = [
  //   {
  //     title: "Network",
  //     list: [
  //       {
  //         id: "list-of-sites-locations",
  //         title: "List of Sites/locations",
  //       },
  //       {
  //         id: "list-of-locations-tested",
  //         title: "List of Locations tested",
  //       },
  //       {
  //         id: "map-view-of-locations",
  //         title: "Map View of Locations",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Application",
  //     list: [
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Finance",
  //     list: [
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Adoption",
  //     list: [
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Migration",
  //     list: [
  //       {
  //         id: "list-of-sites-applications",
  //         title: "List of Applications",
  //       },
  //       {
  //         id: "list-of-applications-tested",
  //         title: "List of Applications tested",
  //       },
  //       {
  //         id: "map-view-of-applications",
  //         title: "Map View of Applications",
  //       },
  //       {
  //         id: "map-view-of-bm-speed-test",
  //         title: "Map View of BW speed test",
  //       },
  //     ],
  //   },
  // ];

  // const dashboardState = useSelector((state: AppState) => state.dashboardState);

  // const [data, updateData] = useState(dashboardState.widgets);
  // useEffect(() => {
  //   updateData(dashboardState.widgets);
  // }, [dashboardState]);

  // function handleOnDragEnd(result: {
  //   destination: { index: number };
  //   source: { index: number };
  // }) {
  //   if (!result.destination) return;

  //   const items = Array.from(data);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   updateData(items);
  // }

  // function handleDelete(index: number) {
  //   const items = Array.from(data);
  //   items.splice(index, 1);
  //   updateData(items);
  // }

  // function handleSave(d: dashboardStateT["widgets"]) {
  //   setModalOpen(false);
  // }

  // function handleChangeSize(id: string, size: number) {
  //   const items = Array.from(data);
  //   console.log(data);
  //   const thisItem = data.findIndex((element: WidgetElemT) => {
  //     return element.id === id;
  //   });
  //   items[thisItem] = { ...data[thisItem], size: size };
  //   updateData(items);
  // }

  if (!modalOpen) return null;

  const ModalDashboardTemplate = (
    <div className={stylesModal.overlay} onClick={(e) => setModalOpen(false)}>
      <div
        className={cn(stylesModal.element, stylesModal["isWide"])}
        role="dialog"
        aria-labelledby="Manage Shared Widgets"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={stylesModal.button}
          onClick={() => setModalOpen(false)}
        >
          <IconClose width="1.8rem" height="1.8rem" />
        </button>
        <Row className="mx-0">
          <Column className="col-12 col-lg-4 px-0">
            <div className={styles.left}>
              {/* <ExpandGroup border={false}>
                {widgets.map((widget, key) => {
                  return (
                    <Expand
                      key={key}
                      active={key === 0}
                      title={`${widget.title} (${widget.list.length})`}
                      type="oneLevel"
                    >
                      <div className={styles.list}>
                        {widget.list.map((elem, key) => {
                          return (
                            <ModalManageDashboardChooseElem
                              title={elem.title}
                              key={key}
                              handleClick={() => console.log("clicked")}
                            />
                          );
                        })}
                      </div>
                    </Expand>
                  );
                })}
              </ExpandGroup> */}
            </div>
          </Column>
          <Column className="col-12 col-lg-8 px-0">
            <div className={styles.right}>
              <Heading title="Manage Shared Widgets" />
              {/* <ModalManageDashboardArea
                data={data}
                handleOnDragEnd={handleOnDragEnd}
                handleChangeSize={handleChangeSize}
                handleDelete={handleDelete}
              /> */}
              <div className={styles.footer}>
                <div className="ml-auto">
                  <Button
                    title="Cancel"
                    type="plain"
                    typeAttr="button"
                    handleClick={() => setModalOpen(false)}
                  />

                  <Button
                    title="Save"
                    icon={<IconCheck />}
                    icon_position="right"
                    // handleClick={() => handleSave(data)}
                  />
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
  return createPortal(ModalDashboardTemplate, document.body);
};

export default ModalShareWidgets;

interface IModalShareWidgets {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
}
