import React, { useCallback, useEffect, useState } from "react";
import axiosConfig from "../../api/axiosDashboard";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/megaphone.svg";
import Alerts from "../../widgets/Alerts/Alerts";
import Modal from "../../components/Modal/Modal";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import { useSelector } from "react-redux";
import { customerContextSelector } from "../../selectors";
import NewAlert from "../NewAlert/NewAlert.view";
import ModalSuccess from "../../components/Modal/ModalSuccess/ModalSuccess";

const AlertsManagementView = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const customerContextState = useSelector(customerContextSelector);

  const fetchAlerts = useCallback(async () => {
    try {
      await axiosConfig(`alert/list/${customerContextState.id}`).then((res) => {
        if (res && res.status === 200) {
          setTableData(res.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [customerContextState.id]);

  const openEdit = (api: any) => {
    setSelectedAlert(api);
    setModalOpen(true);
  };

  const openDelete = (api: any) => {
    setSelectedAlert(api);
    setModalDeleteOpen(true);
  };

  const openCreate = () => {
    setModalOpen(true);
    setSelectedAlert(null);
  };

  useEffect(() => {
    if (modalSuccessOpen) {
      const timer = () =>
        setTimeout(() => {
          setModalSuccessOpen(false);
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [modalSuccessOpen]);

  return (
    <Container fluid>
      <LayoutHeader title="My Alerts">
        <Button
          title="Create Alert"
          icon={<IconButton width="1.7rem" height="1.5rem" />}
          icon_position="right"
          // path="/alerts-management/new"
          handleClick={openCreate}
        />
      </LayoutHeader>
      <Alerts
        openEdit={openEdit}
        tableData={tableData}
        fetchAlerts={fetchAlerts}
        openDelete={openDelete}
      />
      <Modal
        title={selectedAlert ? "Edit Alert" : "Add New Alert"}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <NewAlert
          selectedAlert={selectedAlert}
          fetchAlerts={fetchAlerts}
          setModalOpen={setModalOpen}
          setModalSuccessOpen={setModalSuccessOpen}
        />
      </Modal>
      {selectedAlert && (
        <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
          <ModalDelete
            name="Alert"
            endpoint={`alert/${selectedAlert.id}`}
            setModalOpen={setModalDeleteOpen}
            handleUpdateData={fetchAlerts}
            dashboard
          >
            <ModalDeleteElem>
              <></>
            </ModalDeleteElem>
          </ModalDelete>
        </Modal>
      )}
      <ModalSuccess
        modalOpen={modalSuccessOpen}
        setModalOpen={setModalSuccessOpen}
        text={
          selectedAlert
            ? "Alert has been successfully updated"
            : "Alert has been successfully added"
        }
      />
    </Container>
  );
};

export default AlertsManagementView;
