import {
  GetAppPerformanceExtendedTableDoneT,
  GetAppPerformanceExtendedTableFailedT,
  GetAppPerformanceExtendedTableT,
  GET_APP_PERFORMANCE_EXTENDED_TABLE,
  GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE,
 GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED,
 GetAppPerformanceExtendedDataT,
 GetAppPerformanceExtendedParamsT
} from "../../types/widgets/AppsPerformanceExtendedTable.types";

export const GetAppPerformanceExtendedTable = (
  params: GetAppPerformanceExtendedParamsT
): GetAppPerformanceExtendedTableT => ({
  type: GET_APP_PERFORMANCE_EXTENDED_TABLE,
  params,
});

export const GetAppPerformanceExtendedTableDone = (
  chartType: string,
  data: GetAppPerformanceExtendedDataT
): GetAppPerformanceExtendedTableDoneT => ({
  type: GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE,
  chartType,
  data,
});

export const GetAppPerformanceExtendedTableFailed = (
  chartType: string
): GetAppPerformanceExtendedTableFailedT => ({
  type: GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED,
  chartType,
});
