export type StateT = {
  isLoading: boolean;
  message: string | null;
  customers: any[] | null;
  customersTree: any[] | null;
  error: string | null;
  customerLogo: string;
};

const initialState: StateT = {
  isLoading: false,
  message: null,
  customers: null,
  customersTree: null,
  error: null,
  customerLogo: "",
};

const reducerCustomers = (state = initialState, action: any) => {
  switch (action.type) {
    case "ADD_CUSTOMER":
      return { ...state };
    case "ADD_CUSTOMER_DONE":
      return { ...state, message: action.message };
    case "ADD_CUSTOMER_FAILED":
      return { ...state, error: action.error };
    case "GET_CUSTOMERS":
      return { ...state, isLoading: true };
    case "GET_CUSTOMERS_DONE":
      return { ...state, isLoading: false, customers: action.payload };
    case "GET_CUSTOMERS_FAILED":
      return { ...state, isLoading: false, error: action.error };
    case "GET_CUSTOMERS_TREE":
      return { ...state, isLoading: true };
    case "GET_CUSTOMERS_TREE_DONE":
      return {
        ...state,
        isLoading: false,
        customersTree: action.payload,
      };
    case "GET_CUSTOMERS_TREE_FAILED":
      return { ...state, isLoading: false, error: action.error };
    case "GET_CUSTOMER_LOGO":
      return { ...state, isLoading: true };
    case "GET_CUSTOMER_LOGO_SUCCESS":
      return { ...state, isLoading: false, customerLogo: action.payload };
    case "GET_CUSTOMER_LOGO_FAILURE":
      return { ...state, isLoading: false, error: action.error };
    case "CLEAR_STATE":
      return { ...state, error: null, message: null };
    default:
      return state;
  }
};

export default reducerCustomers;
