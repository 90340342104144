export const GetLicensesDashboard = () => [
  {
    title: "License usage",
    id: "license-circle-chart-usage",
    type: "circle-chart",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Monthly cost change against previous month",
    id: "license-most-active-cost",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Usage license per location",
    id: "license-most-active-location",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Potential savings across licenses",
    id: "license-important-metrics-old-savings",
    type: "important-metrics-old",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },

  {
    title: "Breakdown",
    id: "license-gauge-half-breakdown",
    type: "gauge-half-old",
    dashboardType: "license",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Cost of license usage",
    id: "license-column-line-chart-old-cost",
    type: "column-line-chart-old",
    dashboardType: "license",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "License usage",
    id: "license-simple-table-users",
    type: "simple-table",
    dashboardType: "license",
    size: 4,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Mailbox usage",
    id: "license-column-line-chart-old-mailbox",
    type: "column-line-chart-old",
    dashboardType: "license",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Top mailbox users per employee",
    id: "license-most-active-mailbox-users",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Top mailbox sizes",
    id: "license-most-active-mailbox-sizes",
    type: "most-active",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },

  {
    title: "Savings usage",
    id: "license-column-line-chart-old-savings",
    type: "column-line-chart-old",
    dashboardType: "license",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Active users",
    id: "license-column-line-chart-old-users",
    type: "column-line-chart-old",
    dashboardType: "license",
    size: 2,
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Employees/Users",
    id: "license-important-metrics-old-users",
    type: "important-metrics-old",
    dashboardType: "license",
    size: 1,
    // availableSizes: [1, 2],
  },
  {
    title: "Mailbox usage",
    id: "license-simple-table-mailbox",
    type: "simple-table",
    dashboardType: "license",
    size: 3,
    // availableSizes: [2, 3, 4],
  },
];
