import React from "react";
import styles from "./TableCellReport.module.scss";
import { ReactComponent as IconFile } from "../../../assets/icons/file.svg";
import { Link } from "react-router-dom";

const TableCellReport = (props: ITableCellReport) => {
  const { children, template } = props;

  const handlePath = (e: string) => {
    const path = e.replace(/\//g, "_");
    if (template) return `/reports/templates/id/${path}`;
    else return `/reports/id/${path}`;
  };

  return (
    <Link to={handlePath(children)} className={styles.element}>
      <IconFile className={styles.icon} />
      <span className={styles.text}>{children}</span>
    </Link>
  );
};

export default TableCellReport;

export interface ITableCellReport {
  children: any;
  template?: boolean;
}
