import { GET_TABLE, GET_TABLE_DONE, GET_TABLE_FAILED, TableStateActionT } from "../../types/widgets/Table.types";

export const initialState: any = {};

const TableState = (
  state = initialState,
  action: TableStateActionT
) => {
  switch ( action.type ) {
    case GET_TABLE:
      return { ...state, [action.params.chartType]: { data: [], loading: true, error: false } };
    case GET_TABLE_DONE:
      return { ...state, [action.chartType]: { data: action.data, loading: false, error: false } };
    case GET_TABLE_FAILED:
      return { ...state, [action.chartType]: { data: [], loading: false, error: true } };
    default:
      return state;
  }
};

export default TableState;
