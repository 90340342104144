/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import { Container } from "../../components/Bootstrap/Bootstrap";
// import ReportsTable from "../../widgets/ReportsTable/ReportsTable";
// import Modal from "../../components/Modal/Modal";
// import ModalEmailReport from "../../components/Modal/ModalEmailReport/ModalEmailReport";
// import ModalExportReport from "../../components/Modal/ModalExportReport/ModalExportReport";
import DateRangePicker from "../../components/RangePicker/RangePicker";
import TimeRangePicker from "../../components/TimeRangePicker/TimeRangePicker";
import Select from "react-select";
import {
  customerContextSelector,
  dateRangeSelector,
  timeRangeSelector,
} from "../../selectors";
import { useSelector } from "react-redux";
import { ReactComponent as IconButton } from "../../assets/icons/file.svg";
import axiosConfig from "../../api/axiosConfig";
import axios from "../../api/axiosDashboard";
import styles from "../Reports/Reports.module.scss";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

const StandardReportsView = () => {
  // const [modalEmailOpen, setModalEmailOpen] = useState(false);
  // const [modalExportOpen, setModalExportOpen] = useState(false);
  const [reports, setReports] = useState([]);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [fileFormat, setFileFormat] = useState({ value: "pdf", label: "PDF" });
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);

  const date = useSelector(dateRangeSelector);
  const time = useSelector(timeRangeSelector);
  const customerContextState = useSelector(customerContextSelector);

  const fetchReportsList = useCallback(async () => {
    if (customerContextState?.id) {
      try {
        await axiosConfig(`/reports/${customerContextState.id}/list`).then(
          (res) => {
            if (res && res.status === 200) {
              setReports(
                res.data.map(
                  (elem: { reportCode: string; reportName: string }) => {
                    return {
                      value: elem.reportCode,
                      label: elem.reportName,
                    };
                  }
                )
              );
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [customerContextState.id]);

  const fetchReportHtml = async () => {
    try {
      setShowLoader(true);
      await axios(`reports/${customerContextState.id}/report`, {
        params: {
          reportType: "html",
          startHour: time.start,
          endHour: time.end,
          startDate: date.start,
          endDate: date.end,
          reportName: selectedReport?.value,
        },
      }).then((response: any) => {
        setShowLoader(false);
        setHtmlPreview(response.data.html);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReportsList();
  }, [customerContextState.id, fetchReportsList]);

  useEffect(() => {
    selectedReport?.value && fetchReportHtml();
  }, [selectedReport?.value, customerContextState.id]);

  const downloadReport = () => {
    try {
      axios
        .get(`/reports/${customerContextState.id}/report`, {
          params: {
            reportName: selectedReport?.value,
            startDate: date.start,
            endDate: date.end,
            startHour: time.start,
            endHour: time.end,
            reportType: fileFormat.value,
          },
          responseType: "blob",
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `${selectedReport?.value}.${fileFormat.value}`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Standard Reports">
          <Select
            className={styles.select}
            styles={reportSelectStyles}
            options={reports}
            value={selectedReport}
            onChange={(value) => value && setSelectedReport(value)}
            menuPlacement="auto"
            isSearchable={false}
            placeholder="Select a report"
          />
          <div className={styles.dateWrapper}>
            <DateRangePicker />
            <TimeRangePicker />
          </div>
        </LayoutHeader>
        {/* <ReportsTable
          setModalEmailOpen={setModalEmailOpen}
          setModalExportOpen={setModalExportOpen}
        /> */}
        {htmlPreview && !showLoader && (
          <>
            <div
              className={styles.htmlWrapper}
              dangerouslySetInnerHTML={{ __html: htmlPreview }}
            ></div>
            <div className={styles.fileOptions}>
              <Select
                className={styles.select}
                styles={customTableSelectStyles}
                options={[
                  { value: "csv", label: "CSV" },
                  { value: "pdf", label: "PDF" },
                ]}
                value={fileFormat}
                onChange={(value) => value && setFileFormat(value)}
                menuPlacement="auto"
                isSearchable={false}
              />
              <Button
                disabled={!selectedReport}
                title="Download"
                handleClick={downloadReport}
                icon={
                  <IconButton
                    className={styles.icon}
                    width="1.2rem"
                    height="1.5rem"
                  />
                }
                icon_position="right"
              />
            </div>
          </>
        )}
        {showLoader && <Loader className={styles.htmlLoader} />}
      </Container>
      {/* <Modal
        title="Email Report"
        modalOpen={modalEmailOpen}
        setModalOpen={setModalEmailOpen}
      >
        <ModalEmailReport setModalOpen={setModalEmailOpen} />
      </Modal>
      <Modal
        title="Export Report"
        modalOpen={modalExportOpen}
        setModalOpen={setModalExportOpen}
      >
        <ModalExportReport setModalOpen={setModalExportOpen} />
      </Modal> */}
    </>
  );
};

export default StandardReportsView;

export const customTableSelectStyles = {
  control: (base: any) => ({
    ...base,
    marginRight: "16px",
    width: "85px",
  }),
};

export const reportSelectStyles = {
  control: (base: any) => ({
    ...base,
    marginRight: "16px",
    minWidth: "150px",
    width: "300px",
  }),
};
