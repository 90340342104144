import React from "react";
import { Form, Formik } from "formik";
import styles from "./UserFormTemplate.module.scss";
import Button from "../Button/Button";
import { ReactComponent as IconRole } from "../../assets/icons/send-right.svg";
import { ReactComponent as IconRoleFinance } from "../../assets/icons/finance.svg";
import { ReactComponent as IconRoleTechnical } from "../../assets/icons/technical.svg";
import { ReactComponent as IconRoleService } from "../../assets/icons/service.svg";
import SignUpRole from "./SignUpRole/SignUpRole";
import { useDispatch, useSelector } from "react-redux";
import { assignRole } from "../../store/reducers/authorization";

const RoleSelectForm = () => {
  const { id } = useSelector((state: any) => state.authorization);

  const roles = [
    {
      name: "finance",
      title: "Finance",
      text: "Higher Management, operational work",
      icon: <IconRoleFinance />,
      value: "",
      groupName: "role",
      required: true,
    },
    {
      name: "technical",
      title: "Technical",
      text: "Configurating databases and backend",
      icon: <IconRoleTechnical />,
      value: "",
      groupName: "role",
    },
    {
      name: "service",
      title: "Service Management",
      text: "Checking trends and comparing data",
      icon: <IconRoleService />,
      value: "",
      groupName: "role",
    },
  ];

  const initialValues = {
    role: "finance",
  };
  const dispatch = useDispatch();

  async function submitRole(values: any) {
    await dispatch(
      assignRole({
        loginId: id,
        role: values.role,
      })
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitRole(values);
      }}
    >
      {({ errors, touched, values }) => (
        <Form className={styles.element}>
          {roles.map((role, key) => (
            <SignUpRole key={key} {...role} />
          ))}
          <div className={styles.footer}>
            <Button
              typeAttr="submit"
              title="Continue"
              icon={<IconRole />}
              icon_position="right"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RoleSelectForm;
