export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_DONE = "ADD_CUSTOMER_DONE";
export const ADD_CUSTOMER_FAILED = "ADD_CUSTOMER_FAILED";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_DONE = "GET_CUSTOMERS_DONE";
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED";
export const GET_CUSTOMERS_TREE = "GET_CUSTOMERS_TREE";
export const GET_CUSTOMERS_TREE_DONE = "GET_CUSTOMERS_TREE_DONE";
export const GET_CUSTOMERS_TREE_FAILED = "GET_CUSTOMERS_TREE_FAILED";
export const CLEAR_STATE = "CLEAR_STATE";
export const GET_CUSTOMER_LOGO = "GET_CUSTOMER_LOGO";
export const GET_CUSTOMER_LOGO_SUCCESS = "GET_CUSTOMER_LOGO_SUCCESS";
export const GET_CUSTOMER_LOGO_FAILURE = "GET_CUSTOMER_LOGO_FAILURE";

export const AddCustomer = (data: any) => ({
  type: ADD_CUSTOMER,
  data,
});

export const AddCustomerDone = (message: string) => ({
  type: ADD_CUSTOMER_DONE,
  message,
});

export const AddCustomerFailed = (error: any) => ({
  type: ADD_CUSTOMER_FAILED,
  error,
});

export const GetCustomers = (id: string) => ({
  type: GET_CUSTOMERS,
  id,
});

export const getCustomerLogo = (id: string) => ({
  type: GET_CUSTOMER_LOGO,
  id,
});

export const GetCustomersDone = (data: any) => ({
  type: GET_CUSTOMERS_DONE,
  payload: data,
});

export const GetCustomersFailed = (error: any) => ({
  type: GET_CUSTOMERS_FAILED,
  error,
});

export const GetCustomersTree = () => ({
  type: GET_CUSTOMERS_TREE,
});

export const GetCustomersTreeDone = (data: any) => ({
  type: GET_CUSTOMERS_TREE_DONE,
  payload: data,
});

export const GetCustomersTreeFailed = (error: any) => ({
  type: GET_CUSTOMERS_TREE_FAILED,
  error,
});

export const ClearState = () => ({
  type: CLEAR_STATE,
});
