export const GetConnectionLevel = (value: number) => {
  if (value > -50) {
    return 4;
  } else if (value <= -50 && value > -60) {
    return 3;
  } else if (value <= -60 && value > -70) {
    return 2;
  } else if (value <= -70) {
    return 1;
  } else {
    return 0;
  }
};
