export const showTableEdit = ({
  permission,
  customer,
  id,
  parentId,
  subcustomers
}: {
  permission: any;
  customer: any;
  id?: string;
  parentId?: string;
  subcustomers: any
}) => {
  const editAccess =
    (customer.id === id || parentId === customer.id || !id || JSON.stringify(subcustomers).includes(`${id}`)) &&
    permission.fullAccess &&
    permission.data;
  return editAccess;
};
