/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import styles from "./TextInput.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Field, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";

const TextInput = (props: ITextInput) => {
  const {
    name,
    label,
    placeholder,
    textInputType = "text",
    errors,
    touched,
    confirmPassword,
    isBgGray,
    forgetPasswordLink,
    required,
    disabled,
    noAutocomplete,
    validateWithOther,
    stateValidation,
  } = props;

  const { values } = useFormikContext();

  const [pass, setPass] = useState("");
  const [filledName, setFilledName] = useState(false);
  const [filledEmail, setFilledEmail] = useState(false);
  const { error } = useSelector((state: AppState) => state.customersState);

  useEffect(() => {
    if (touched.password === true) {
      // @ts-ignore
      values.password && setPass(values.password);
    }
  }, [touched, values]);

  useEffect(() => {
    // @ts-ignore
    if (touched.adminFullName === true && values.adminFullName) {
      setFilledName(true);
    } else {
      setFilledName(false);
    }
  }, [touched, values]);

  useEffect(() => {
    // @ts-ignore
    if (touched.email === true && values.email) {
      setFilledEmail(true);
    } else {
      setFilledEmail(false);
    }
  }, [touched, values]);

  function validate(
    value: ITextInput["value"],
    textInputType: ITextInput["textInputType"]
  ) {
    let error;
    switch (textInputType) {
      case "email":
        if (validateWithOther) {
          if (filledName && !value) {
            error = "This field is required";
          } else if (
            filledName &&
            value &&
            !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value)
          ) {
            error = "Please enter a valid email address";
          }
        } else {
          if (!value) {
            error = "This field is required";
          } else if (!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value)) {
            error = "Please enter a valid email address";
          }
        }
        break;
      case "password":
        const passwordRegex = /(?=.*[0-9])/;
        if (!value) {
          error = "This field is required";
        } else if (confirmPassword) {
          if (pass && value) {
            if (pass !== value) {
              error = "Password not matched";
            }
          }
        } else {
          if (value.length < 8) {
            error = "Password must be at least 8 characters long";
          } else if (!passwordRegex.test(value)) {
            error = "Password must contain at least one number";
          }
        }
        break;
      default:
        if (validateWithOther) {
          if (filledEmail && !value) {
            error = "This field is required";
          }
        } else {
          if (!value) {
            error = "This field is required";
          }
        }
        break;
    }
    return error;
  }

  return (
    <div
      className={cn(
        styles.wrapper,
        ((errors[name] && touched[name]) || (stateValidation && error)) &&
          styles["isError"]
      )}
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}

      <Field
        component={CustomTextInput}
        className={cn(
          styles.input,
          isBgGray && styles["isPlain"],
          disabled && styles["isDisabled"]
        )}
        id={name}
        name={name}
        placeholder={placeholder}
        type={textInputType}
        validate={
          required && ((e: ITextInput["value"]) => validate(e, textInputType))
        }
        disabled={disabled}
        noAutocomplete={noAutocomplete}
      />

      {((errors[name] && touched[name]) || (stateValidation && error)) && (
        <span className={styles.error}>{error ? error : errors[name]}</span>
      )}
      {forgetPasswordLink && (
        <Link to="/auth/password-recovery" className={styles.link}>
          Forgot your password?
        </Link>
      )}
    </div>
  );
};

export default TextInput;

const CustomTextInput = ({ field, noAutocomplete, ...props }: any) => {
  return (
    <div className={styles.element}>
      <input
        className={styles.input}
        type="text"
        {...field}
        {...props}
        onChange={(e) => {
          field.onChange(e);
        }}
        // workaround to not to fill password during changing password
        autoComplete={noAutocomplete ? "new-password" : null}
      />
    </div>
  );
};
export interface ITextInput {
  name: string;
  label?: string;
  placeholder?: string;
  textInputType?:
    | "text"
    | "email"
    | "password"
    | "tel"
    | "number"
    | "validateWithOther";
  value?: string;
  errors?: any;
  touched?: any;
  values?: any;
  formType?: string;
  confirmPassword?: boolean;
  isBgGray?: boolean;
  forgetPasswordLink?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  noAutocomplete?: boolean;
  validateWithOther?: boolean;
  stateValidation?: boolean;
}
