import React from "react";
import styles from "./ModalSelectCustomerSearchResults.module.scss";
import customerStyles from "../ModalSelectCustomerElem/ModalSelectCustomerElem.module.scss";
import { ReactComponent as IconUser } from "../../../../assets/icons/user-placeholder-sm.svg";
import cn from "classnames";
import { toBase64 } from "../../../../functions/ToBase64/ToBase64";

const ModalSelectCustomerSearchResults = (
  props: IModalSelectCustomerSearchResult
) => {
  const { results, handleContext } = props;

  return (
    <>
      {results.length ? (
        <div>
          <ul className={customerStyles.list}>
            {results.map((result, key) => (
              <SearchResult
                {...result}
                handleContext={handleContext}
                key={key}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles.text}>No search results</div>
      )}
    </>
  );
};
export default ModalSelectCustomerSearchResults;

const SearchResult = (props: ISearchResult) => {
  const { customer, isAccount, handleContext } = props;

  return (
    <li className={customerStyles.element}>
      <div className={customerStyles.userWrapper}>
        <button
          className={cn(
            customerStyles.user,
            isAccount && customerStyles["isAccount"]
          )}
          onClick={() => handleContext(customer)}
        >
          <div className={customerStyles.imageWrapper}>
            {customer.logo ? (
              <img
                src={`data:${customer.logo.mime};base64,${toBase64(
                  customer.logo.content.data
                )}`}
                alt={`${customer.name} logo`}
              />
            ) : (
              <IconUser />
            )}
          </div>
          <div>
            <span className={customerStyles.name}>{customer.name}</span>
            <span className={customerStyles.email}>{customer.email}</span>
          </div>
        </button>
      </div>
    </li>
  );
};

interface IModalSelectCustomerSearchResult {
  results: ISearchResult[];
  handleContext: (e: any) => void;
  query: string;
}

export interface ISearchResult {
  customer: {
    name: string;
    email: string;
    logo?: {
      content: any;
      id: string;
      mime: string;
    };
  };
  isAccount?: boolean;
  handleContext: (e: ISearchResult["customer"]) => void;
}
