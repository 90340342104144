import React, { useState, useEffect, forwardRef } from "react";
import styles from "./ModalSharePreset.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconShare } from "../../../assets/icons/fidget-spinner.svg";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
import tableStyles from "../../tableElements/ReactTable/ReactTable.module.scss";
import TableCellUser from "../../tableElements/TableCellUser/TableCellUser";
import { IUserCard } from "../../UserCard/UserCard";
import FilterInput from "../../formElements/FilterInput/FilterInput";
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import cn from "classnames";
import Loader from "../../Loader/Loader";
import { ReactComponent as IconChevron } from "../../../assets/icons/calendar-chevron.svg";
import { useSelector, useDispatch } from "react-redux";
import { GetUsers } from "../../../store/actions/ManageUsers.action";
import { AppState } from "../../../store/reducers";
import {
  ShareCustomPreset,
  UnshareDefaultPreset,
  // SetCustomPresets,
} from "../../../store/actions/DashboadPresets.action";
import { ChangeWidgetsFormat } from "../../../functions/ManageDashboard/ChangeWidgetsFormat/ChangeWidgetsFormat";
import SwitchElem from "../../SwitchElem/SwitchElem";
import { checkIfHortiumAdmin } from "../../../functions/CheckIfHortiumAdmin/CheckIfHortiumAdmin";

const IndeterminateCheckbox = forwardRef(
  //@ts-ignore
  ({ indeterminate, ...rest }: any, ref) => {
    //@ts-ignore
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, rest]);

    return (
      <div className={tableStyles.fieldWrapper}>
        <label className={tableStyles.label}>
          <input
            type="checkbox"
            //@ts-ignore
            ref={resolvedRef}
            {...rest}
          />
          <div className={tableStyles.box}>
            {
              //@ts-ignore
              rest.checked && <IconCheck className={tableStyles.icon} />
            }
          </div>
        </label>
      </div>
    );
  }
);

const ModalSharePreset = (props: IModalSharePreset) => {
  const { setModalOpen, preset } = props;

  const { id } = useSelector((state: AppState) => state.customerContextState);
  const { users } = useSelector((state: AppState) => state.usersState);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const constantReceivedPresets = useSelector(
    (state: AppState) => state.dashboardPresetsState.constantReceivedPresets
  );
  const [data, setTableData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const checkIfDefault = (preset: any) => {
    return constantReceivedPresets.find(
      (elem: any) => elem.sharedId === preset.id && elem.title === preset.title
    );
  };

  const [defaultPreset, setDefaultPreset] = useState<boolean>(false);

  const UsersColumnFilter = ({
    column: { filterValue, setFilter },
  }: {
    column: { filterValue: any; setFilter: any };
  }) => {
    return (
      <FilterInput
        name="filter"
        value={filterValue || ""}
        handleChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder="Find User"
        label="People to share with"
      />
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "user",
        Filter: UsersColumnFilter,
        filter: "usersName",
        colSpan: 2,
        Cell: ({ cell: { value } }: { cell: { value: IUserCard } }) => (
          <TableCellUser {...value} />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(GetUsers(id));
  }, [dispatch, id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      users && setTableData(users);
    }
    return () => {
      isMounted = false;
    };
  }, [users]);

  const handleSwitch = (e: any) => {
    setDefaultPreset(e);
  };

  const filterTypes = React.useMemo(
    () => ({
      usersName: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id].name;
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: 6,
        // selectedRowIds: getSelectedIDs(),
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "selection",
          Header: () => null,
          Cell: ({ row }) => {
            return (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            );
          },
        },
      ]);
    }
  );

  const handleSubmit = () => {
    if (checkIfDefault(preset)) {
      const data =
        constantReceivedPresets.find((obj: any) => {
          return obj.sharedId === preset.id;
        }) ||
        constantReceivedPresets.find((obj: any) => {
          return obj.sharedId === "" && obj.title === preset.title;
        });

      dispatch(UnshareDefaultPreset([data.id]));
    } else {
      if (defaultPreset) {
        const data = [
          {
            settings: {
              id: preset.id,
              title: preset.title,
              widgets: ChangeWidgetsFormat(preset.widgets),
            },
            settingsType: "global",
          },
        ];
        dispatch(ShareCustomPreset(data));
      } else {
        const data = selectedFlatRows.map((elem) => {
          return {
            // @ts-ignore
            userId: elem.original.id,
            settings: {
              id: preset.id,
              title: preset.title,
              widgets: ChangeWidgetsFormat(preset.widgets),
            },
            settingsType: "shared",
          };
        });
        dispatch(ShareCustomPreset(data));
      }
    }
    setModalOpen(false);
  };
  return (
    <div className={styles.element}>
      {checkIfHortiumAdmin(permissions, customer.mainCustomer) &&
        !checkIfDefault(preset) && (
          <SwitchElem
            disabled={false}
            label="Share as default preset"
            handleSwitch={handleSwitch}
            className={styles.switch}
          />
        )}
      {!defaultPreset && !checkIfDefault(preset) ? (
        <div className={cn(tableStyles.wrapper, styles.table)}>
          <div className={tableStyles.scrollable}>
            <table {...getTableProps()} className={tableStyles.element}>
              {data.length > 5 && (
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className={cn(tableStyles.th, "px-0")}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          // @ts-ignore
                          colSpan={column.colSpan}
                        >
                          {column.render("Header")}
                          {column.canFilter ? column.render("Filter") : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className={cn(tableStyles.td, "px-0")}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <Loader
                        container
                        className="p-5 d-flex align-items-center justify-content-center"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {data.length > 6 && (
            <>
              <div className={cn(tableStyles.pagination, "px-0")}>
                <div className={tableStyles.buttonsWrapper}>
                  <button
                    className={tableStyles.button}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {
                      <>
                        <IconChevron className={tableStyles.iconChevron} />
                        <IconChevron className={tableStyles.iconChevron} />
                      </>
                    }
                  </button>
                  <button
                    className={tableStyles.button}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {<IconChevron className={tableStyles.iconChevron} />}
                  </button>
                  <div className={tableStyles.inputWrapper}>
                    <input
                      className={tableStyles.input}
                      type="number"
                      placeholder={String(pageIndex + 1)}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                    />
                    <span className={tableStyles.inputOf}>
                      of {pageOptions.length}
                    </span>
                  </div>
                  <button
                    className={tableStyles.button}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {
                      <IconChevron
                        className={cn(
                          tableStyles.iconChevron,
                          tableStyles["isRight"]
                        )}
                      />
                    }
                  </button>
                  <button
                    className={tableStyles.button}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {
                      <>
                        <IconChevron
                          className={cn(
                            tableStyles.iconChevron,
                            tableStyles["isRight"]
                          )}
                        />
                        <IconChevron
                          className={cn(
                            tableStyles.iconChevron,
                            tableStyles["isRight"]
                          )}
                        />
                      </>
                    }
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <span className={styles.text}>
          {checkIfDefault(preset)
            ? "This dashboard preset is shared to all users of the application"
            : "This dashboard preset will be available to all users of the application"}
        </span>
      )}

      <div className={styles.footer}>
        <Button
          title="Cancel"
          type="plain"
          typeAttr="button"
          handleClick={() => {
            setModalOpen(false);
          }}
        />
        <Button
          title={checkIfDefault(preset) ? "Unshare" : "Share"}
          typeAttr="submit"
          handleClick={() => {
            handleSubmit();
          }}
          icon={<IconShare className={styles.icon} />}
          icon_position="right"
        />
      </div>
    </div>
  );
};

export default ModalSharePreset;

interface IModalSharePreset {
  setModalOpen: (e: any) => void;
  preset: any;
}
