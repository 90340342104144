import React, { useState, useCallback, useEffect } from "react";
import styles from "../ModalDelete/ModalDelete.module.scss";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/paper-plane.svg";
import { sendUserReinvite } from "../../../api/authorization";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import Heading from "../../Heading/Heading";
import UserCard from "../../UserCard/UserCard";
import { IUserValues } from "../../../views/UsersManagement/UsersManagement.view";

interface IModalResendInviteUser {
  setModalOpen: (e: boolean) => void;
  handleUpdateData: () => void;
  selectedUser: IUserValues;
  customerContextId: string;
}

const ModalResendInviteUser = (props: IModalResendInviteUser) => {
  const { setModalOpen, selectedUser, handleUpdateData, customerContextId } =
    props;

  const [sent, setSent] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    sendUserReinvite({
      loginId: selectedUser.id,
      customerContextId: customerContextId,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          handleUpdateData();
          setSent(true);
        }
      })
      .catch((error) => console.log(error));
  }, [handleUpdateData, selectedUser.id, customerContextId]);

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
        setModalOpen(false);
      }, 3000);
    }
  }, [sent, setModalOpen]);

  if (sent) {
    return <ModalSuccess text={`Invitation has been successfully resent`} />;
  }

  return (
    <>
      <Heading title="Resend Invite" weight="medium" />
      <div className={styles.element}>
        <span className={styles.text}>
          Please confirm resending the invitation to this user
        </span>
        <UserCard {...selectedUser.userDetails} />
        <div className={styles.footer}>
          <Button
            title="Cancel"
            type="plain"
            typeAttr="button"
            handleClick={() => setModalOpen(false)}
          />
          <Button
            title="Send"
            icon={<IconButton />}
            icon_position="right"
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
export default ModalResendInviteUser;
