import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetGaugeResultsDone, GetGaugeResultsFailed } from "../../actions/widgets/GaugeResults.action";
import { GetGaugeResultsT, GET_GAUGE_RESULTS } from "../../types/widgets/GaugeResults.types";


function* GetGaugeResultsHandler({ params }: GetGaugeResultsT) {
  try {
    let requestParams: any = {
      startDate: params.startDate,
      endDate: params.endDate,
      startHour: params.startHour,
      endHour: params.endHour,
    }

    if (params.chartType) {
      requestParams['chartType'] = params.chartType
    }

    if (params.callId) {
      requestParams['callId'] = params.callId
    }

    if (params.userId) {
      requestParams['userId'] = params.userId
    }

    if (params.deviceName) {
      requestParams['deviceName'] = params.deviceName
    }

    if (params.isConference !== null && params.isConference !== undefined) {
      requestParams['isConference'] = params.isConference
    }
  
    if (params.url) {
      requestParams['url'] = params.url
    }

    if (params.location) {
      requestParams['location'] = params.location
    }

    if (params.agentId) {
      requestParams['agentId'] = params.agentId
    }

    if (params.isTraceroute !== null && params.isTraceroute) {
      requestParams['isTraceroute'] = params.isTraceroute
    }
  
    const { status, data } = yield axios.get(`${params.endpoint}/${params.customerId}`, {
      params: {
        ...requestParams
      },
    })

    if (status === 204) {
      // 204 response means 0 as data 
      yield put(GetGaugeResultsDone(params.chartType, 0));
    } else if (status === 200) {
      yield put(GetGaugeResultsDone(params.chartType, data));
    }
  } catch (error) {
    yield put(GetGaugeResultsFailed(params.chartType));
  }
}

export default function* WatchGaugeResults() {
  yield all([
    takeEvery(GET_GAUGE_RESULTS, GetGaugeResultsHandler)
  ]);
}
