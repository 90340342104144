import React, { useCallback, useEffect, useState } from "react";
import styles from "./SidebarMenuLogs.module.scss";
import { NavLink } from "react-router-dom";
import { ToggleMenu } from "../../../../store/actions/ToggleMenu.action";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../api/axiosDashboard";
import { AppState } from "../../../../store/reducers";

const SidebarMenuLogs = () => {
  const [logs, setLogs] = useState({
    errors: 0,
    issues: 0,
    messages: 0,
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const dateRange = useSelector((state: AppState) => state.dateRangeState);
  const fetchData = useCallback(
    async (endpoint: string) => {
      try {
        return await axios(endpoint, {
          params: {
            startDate: dateRange.start,
            endDate: dateRange.end,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [dateRange]
  );

  useEffect(() => {
    let isMounted = true;
    fetchData("/metric/logs").then((res) => {
      if (res && res.status === 200) {
        if (isMounted) setLogs(res.data);
        setIsLoaded(true);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  if (isLoaded) {
    return (
      <ul className={styles.list}>
        {logs.errors > 0 && (
          <SingleLogItem title="Errors" value={logs.errors} path="/" />
        )}
        {logs.issues > 0 && (
          <SingleLogItem title="Issues" value={logs.issues} path="/" />
        )}
        {logs.messages > 0 && (
          <SingleLogItem
            title="System Messages"
            value={logs.messages}
            path="/"
          />
        )}
      </ul>
    );
  }
  return null;
};

export default SidebarMenuLogs;

const SingleLogItem = (props: ISingleLogItem) => {
  const { title, path, value } = props;

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  const dispatch = useDispatch();

  return (
    <li>
      <NavLink
        exact
        to={path}
        onClick={() => isResponsiveView && dispatch(ToggleMenu())}
        className={styles.item}
      >
        <span className={styles.value}>{value}</span>
        <span className={styles.title}>&nbsp;{title}</span>
      </NavLink>
    </li>
  );
};

interface ISingleLogItem {
  title: string;
  path: string;
  value: number;
}
