import React, { useEffect, useState } from "react";
import styles from "./WidgetsListElem.module.scss";
import { ReactComponent as IconTrash } from "../../../../../../assets/icons/trash.svg";
import { ReactComponent as IconDrag } from "../../../../../../assets/icons/drag.svg";
import Select from "react-select";
import { DropdownIndicator } from "../../../../../formElements/SelectInput/SelectInput";

const WidgetsListElem = (props: IWidgetsListElem) => {
  const {
    index,
    title,
    size,
    id,
    availableSizes,
    handleDeleteWidget,
    handleChangeSize,
  } = props;

  const [thisSize, setThisSize] = useState<{ value: number; label: number }>();

  useEffect(() => {
    if (size) {
      setThisSize({ value: size, label: size });
    }
  }, [size]);

  const handleChange = (
    id: string,
    value: { value: number; label: number }
  ) => {
    setThisSize(value);
    handleChangeSize(id, value.value);
  };

  return (
    <div className={styles.element}>
      <span className={styles.index}>{index + 1}</span>
      <span className={styles.label}>{title}</span>
      <div className={styles.actions}>
        {availableSizes && (
          <Select
            classNamePrefix={styles.select}
            className={styles.select}
            options={availableSizes.map((s) => {
              return { value: s, label: s };
            })}
            value={
              thisSize
                ? thisSize
                : {
                    value: Math.min(...availableSizes),
                    label: Math.min(...availableSizes),
                  }
            }
            onChange={(value) => value && handleChange(id, value)}
            styles={customSelectStyles}
            menuPlacement="auto"
            isSearchable={false}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
          />
        )}

        <button
          className={styles.delete}
          onClick={(e) => handleDeleteWidget(index)}
        >
          <IconTrash />
        </button>
        <IconDrag />
      </div>
    </div>
  );
};

export default WidgetsListElem;

export interface IWidgetsListElem {
  index: number;
  title: string;
  size?: number;
  id: string;
  availableSizes: number[];
  handleDeleteWidget: (index: number) => void;
  handleChangeSize: (id: string, size: number) => void;
}

const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderRadius: 3,
    boxShadow: state.isFocused ? null : null,
    cursor: "pointer",
    fontSize: "1.2rem",
    minHeight: "3.4rem",
    width: "4.7rem",
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: "0",
    minWidth: "2.4rem",
    justifyContent: "center",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 3,
    hyphens: "auto",
    marginTop: "0.2rem",
    textAlign: "left",
    wordWrap: "break-word",
    boxShadow: "0 8px 6px 0px rgba(25, 40, 60, 0.04)",
    border: "1px solid rgba(25, 40, 60, 0.04)",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    fontSize: "1.2rem",
    color: "#8A97AD",
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#566ff6",
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    padding: "0.8rem 1.6rem",
    backgroundColor: [
      state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
      state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
    ],
    "&:active": {
      backgroundColor: [
        state.isFocused ? "rgba(86, 111, 246, 0.05)" : null,
        state.isSelected ? "rgba(86, 111, 246, 0.05)" : null,
      ],
    },
    color: state.isSelected ? "#3A4EB8 !important" : null,
    fontWeight: state.isSelected ? "600" : null,
    fontSize: ["1.2rem", state.isSelected ? "1.2rem" : null],
    cursor: "pointer",
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    height: "2.2rem",
    alignItems: "center",
    justifyContent: "center",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "#8a97ad",
    };
  },
};
