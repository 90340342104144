import {
  GET_APP_PERFORMANCE_EXTENDED_TABLE,
  GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE,
  GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED,
  AppPerformanceExtendedTableActionT,
} from "../../types/widgets/AppsPerformanceExtendedTable.types";

export const initialState: any = {};

const PathAnalyticsState = (
  state = initialState,
  action: AppPerformanceExtendedTableActionT
) => {
  switch (action.type) {
    case GET_APP_PERFORMANCE_EXTENDED_TABLE:
      return {
        ...state,
        [action.params.chartType]: { data: null, loading: true, error: false },
      };
    case GET_APP_PERFORMANCE_EXTENDED_TABLE_DONE:
      return {
        ...state,
        [action.chartType]: { data: action.data, loading: false, error: false },
      };
    case GET_APP_PERFORMANCE_EXTENDED_TABLE_FAILED:
      return {
        ...state,
        [action.chartType]: { data: null, loading: false, error: true },
      };
    default:
      return state;
  }
};

export default PathAnalyticsState;
