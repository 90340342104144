import {all, put, takeLatest} from "redux-saga/effects";
import axios from "../../api/axiosDashboard";
import {GetEmployeesListT, GET_EMPLOYEES_LIST, GET_EMPLOYEES_LIST_DONE, GET_EMPLOYEES_LIST_FAILED} from "../types/Employees";

function* GetEmployeesListHandler({id}: GetEmployeesListT) {
  try {
    const {data} = yield axios
      .get(`/management/employees/${id}`)

    if (data.length) {
      const res = data.map((elem: any) => {
        return {
          employee: {
            name: elem.displayName || "",
            id: elem.id || ""
          },
          number: elem.number || "",
          accountEnabled: elem.accountEnabled || "",
          givenName: elem.givenName || "",
          surname: elem.surname || "",
          userType: elem.userType || "",
          country: elem.country || "",
          city: elem.city || "",
          officeLocation: elem.officeLocation || "",
          companyName: elem.companyName || "",
          department: elem.department || "",
          jobTitle: elem.jobTitle || "",
          userPrincipalName: elem.userPrincipalName || "",
          mail: elem.mail || "",
          mobilePhone: elem.mobilePhone || "",
          preferredLanguage: elem.preferredLanguage || "",
          businessPhones: elem.businessPhones.length ? elem.businessPhones.join(", ") : "",
          imAddresses: elem.imAddresses.length ? elem.imAddresses.join(", ") : "",
          license: elem.license || "",
          attribute1: elem.attribute1 || "",
          attribute2: elem.attribute2 || "",
          attribute3: elem.attribute3 || "",
          attribute4: elem.attribute4 || "",
          attribute5: elem.attribute5 || "",
          attribute6: elem.attribute6 || "",
          attribute7: elem.attribute7 || "",
          attribute8: elem.attribute8 || "",
          attribute9: elem.attribute9 || "",
          attribute10: elem.attribute10 || "",
          attribute11: elem.attribute11 || "",
          attribute12: elem.attribute12 || "",
          attribute13: elem.attribute13 || "",
          attribute14: elem.attribute14 || "",
          attribute15: elem.attribute15 || "",
          customerId: elem.attribute1 || "",
          agentId: elem.agentId || "",
          agentName: elem.agentName || "",
          lastUpdateTest: elem.lastUpdateTest || 0
        }
      })
      yield put({type: GET_EMPLOYEES_LIST_DONE, payload: res});
    } else {

      yield put({type: GET_EMPLOYEES_LIST_DONE, payload: null});
    }

  } catch (error) {
    console.log(error);
    yield put({type: GET_EMPLOYEES_LIST_FAILED, error});
  }
}

export default function* WatchEmployees() {
  yield all([
    takeLatest(GET_EMPLOYEES_LIST, GetEmployeesListHandler),
  ]);
}
