
import { all, put, takeEvery } from "redux-saga/effects";
import axios from "../../../api/axiosDashboard";
import { GetImportantMetricsDone, GetImportantMetricsFailed } from "../../actions/widgets/ImportantMetrics.action";
import { GetImportantMetricsT, GET_IMPORTANT_METRICS, ImportantMetricsDataT } from "../../types/widgets/ImportantMetrics.types";


function* GetImportantMetricsHandler ( { params }: GetImportantMetricsT ) {
	try {

		const { status, data } = yield axios.get( `${params.endpoint}/${params.customerId}`, {
			params: {
				startDate: params.startDate,
				endDate: params.endDate,
				startHour: params.startHour,
				endHour: params.endHour,
				agentId: params.agentId,
				...(params.chartType !== null && params.chartType !== undefined && {chartType: params.chartType}),
				...(params.isTop !== null && params.isTop !== undefined && {isTop: params.isTop}),
				...(params.url !== null && params.url !== undefined && {url: params.url}),
				...(params.isPotentialSaving !== null && params.isPotentialSaving !== undefined && {isPotentialSaving: params.isPotentialSaving}),
				...(params.companyName !== null && params.companyName !== undefined && {companyName: params.companyName}),
				...(params.isTraceroute !== null && params.isTraceroute !== undefined && {isTraceroute: params.isTraceroute}),
				...(params.deviceName && {deviceName: params.deviceName}),
				...(params.userId && {userId: params.userId}),
				...(params.location && {location: params.location}),
				...(params.callId && {callId: params.callId})
			},
		} )

		if ( status === 204 ) {
			yield put( GetImportantMetricsDone( params.chartType, null ) );
		} else if ( status === 200 ) {
			const res = data.map( ( elem: ImportantMetricsDataT ) => {
				return ( {
					title: elem.name,
					count: elem.value,
					unit: elem.unit || null
				} )
			} )
			yield put( GetImportantMetricsDone( params.chartType, res ) );
		}
	} catch ( error ) {
		yield put( GetImportantMetricsFailed( params.chartType ) );
	}
}


export default function* WatchImportantMetrics () {
	yield all( [
		takeEvery( GET_IMPORTANT_METRICS, GetImportantMetricsHandler )
	] );
}
