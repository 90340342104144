import React, { useCallback, useEffect, useState } from "react";
import styles from "../ApiManagement/ApiManagement.module.scss";
import { useSelector } from "react-redux";
import ConnectedApps, {
  SingleAppT,
} from "../../../widgets/ConnectedApps/ConnectedApps";
import Modal from "../../../components/Modal/Modal";
import ModalDelete from "../../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import ModalAddNewApp, {
  IAppValues,
} from "../../../components/Modal/ModalAddNewApp/ModalAddNewApp";
import axiosConfig from "../../../api/axiosConfig";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../../components/Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/link.svg";
import { customerContextSelector } from "../../../selectors";

const AppManagement = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [data, setTableData] = useState<SingleAppT[]>([]);
  const [selectedApp, setSelectedApp] = useState<IAppValues>();
  const customerContextState = useSelector(customerContextSelector);

  const fetchData = useCallback(async () => {
    try {
      await axiosConfig(`/configuration/app/list/${customerContextState.id}`).then((res) => {
        if (res && res.status === 200) {
          setTableData(res.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [customerContextState.id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  const openEdit = (app: IAppValues) => {
    setSelectedApp(app);
    setModalOpen(true);
  };

  const openNew = () => {
    setModalOpen(true);
  };

  const openDelete = (app: IAppValues) => {
    setSelectedApp(app);
    setModalDeleteOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <LayoutHeader title="Connected Applications">
        <Button
          title="Connect New"
          icon={<IconButton width="1.4rem" height="1.4rem" />}
          icon_position="right"
          handleClick={() => openNew()}
        />
      </LayoutHeader>
      <ConnectedApps data={data} openEdit={openEdit} openDelete={openDelete} />
      <Modal
        title={selectedApp ? "Edit Application" : "Add New Application"}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <ModalAddNewApp
          app={selectedApp}
          handleAdd={fetchData}
          setModalOpen={setModalOpen}
          setSelectedApp={setSelectedApp}
        />
      </Modal>
      {selectedApp && (
        <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
          <ModalDelete
            name="Application"
            endpoint={`${process.env.REACT_APP_AUTH_API_ENDPOINT}/configuration/app/${selectedApp.id}`}
            setModalOpen={setModalDeleteOpen}
            handleUpdateData={fetchData}
          >
            <ModalDeleteElem>
              <>
                <div className={styles.imageWrapper}>
                  {selectedApp?.app?.image}
                </div>
                <div>
                  <span className={styles.name}>{selectedApp?.app?.name}</span>
                  <span className={styles.version}>
                    {selectedApp?.app?.version}
                  </span>
                </div>
              </>
            </ModalDeleteElem>
          </ModalDelete>
        </Modal>
      )}
    </div>
  );
};

export default AppManagement;
