import React from "react";
import LayoutUserAuth from "../../../components/LayoutUserAuth/LayoutUserAuth";
import UserFormTemplate from "../../../components/UserFormTemplate/UserFormTemplate";
import NewPasswordForm from "../../../components/UserFormTemplate/NewPasswordForm";

const NewPasswordView = () => {

  return (
    <LayoutUserAuth title="Set New Password">
      <UserFormTemplate>
          <NewPasswordForm/>
      </UserFormTemplate>
    </LayoutUserAuth>
  );
};

export default NewPasswordView;
