import {
  GET_TABLE_EMPLOYEE_DEVICES,
  GET_TABLE_EMPLOYEE_DEVICES_DONE,
  GET_TABLE_EMPLOYEE_DEVICES_FAILED,
  TableStateActionT,
  EmployeeDevicesT
} from "../../types/widgets/EmployeeDevicesTable";

export const initialState: { loading: boolean; error: boolean; data: Array<EmployeeDevicesT> } = {
  data: [],
  loading: false,
  error: false
};

const employeeDevicesState = (state = initialState, action: TableStateActionT) => {
  switch (action.type) {
    case GET_TABLE_EMPLOYEE_DEVICES:
      return { ...state, data: [], loading: true, error: false };
    case GET_TABLE_EMPLOYEE_DEVICES_DONE:
      return { ...state, data: action.data, loading: false, error: false };
    case GET_TABLE_EMPLOYEE_DEVICES_FAILED:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default employeeDevicesState;
