import { GetHeatmapDoneT, GetHeatmapFailedT, GetHeatmapT, GET_HEATMAP, GET_HEATMAP_DONE, GET_HEATMAP_FAILED, HeatmapDataT, HeatmapParamsT } from "../../types/widgets/Heatmap.types";


export const GetHeatmap = (
  params: HeatmapParamsT
): GetHeatmapT => ( {
  type: GET_HEATMAP,
  params,
} );

export const GetHeatmapDone = (
  chartType: string,
  data: HeatmapDataT
): GetHeatmapDoneT => ( {
  type: GET_HEATMAP_DONE,
  chartType,
  data,
} );

export const GetHeatmapFailed = ( chartType: string ): GetHeatmapFailedT => ( {
  type: GET_HEATMAP_FAILED,
  chartType,
} );