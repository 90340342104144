import React from "react";
import styles from "../TableCellAgent/TableCellAgent.module.scss";

const TableCellId = (props: ITableCellId) => {
  const { name, id } = props;

  return (
    <div className={"d-flex flex-column"}>
      <span className={styles.name}>{name}</span>
      <span className={styles.id}>ID: {id}</span>
    </div>
  );
};

export default TableCellId;

export interface ITableCellId {
  name: string;
  id: number;
}
