import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import styles from "./UserFormTemplate.module.scss";
import TextInput from "../formElements/TextInput/TextInput";
import Button from "../Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/paper-plane.svg";
import { useDispatch, useSelector } from "react-redux";
import { recoveryPassword } from "../../store/reducers/authorization";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store/reducers";

const NewPasswordForm = () => {
  const { error, message } = useSelector(
    (state: AppState) => state.authorization
  );
  const [hasError, setError] = useState<string | null>(null);
  const [hasMessage, setMessage] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    setMessage(null);
    if (message) {
      setMessage(message);
      setTimeout(() => {
        history.push("/auth/sign-in");
      }, 3000);
    }
  }, [message, history]);

  useEffect(() => {
    setError(error);
  }, [error]);

  async function submitForm(values: any) {
    await dispatch(recoveryPassword(values));
  }

  if (hasMessage) {
    return <div className={styles.confirmMessage}>{hasMessage}</div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitForm(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className={styles.element}>
          <TextInput
            touched={touched}
            errors={errors}
            name="password"
            label="New password*"
            placeholder="Type new password"
            textInputType="password"
            required
          />
          <TextInput
            touched={touched}
            errors={errors}
            name="confirmPassword"
            label="Repead password*"
            placeholder="Confirm your new password"
            textInputType="password"
            confirmPassword
            required
          />

          <div className={styles.footer}>
            {hasError && <div className={styles.errorMessage}>{hasError}</div>}
            <Button
              typeAttr="submit"
              title="Set New Password"
              icon={<IconButton />}
              icon_position="right"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default NewPasswordForm;
