export const GET_PATH_ANALYTICS = "GET_PATH_ANALYTICS";
export const GET_PATH_ANALYTICS_DONE = "GET_PATH_ANALYTICS_DONE";
export const GET_PATH_ANALYTICS_FAILED = "GET_PATH_ANALYTICS_FAILED";

export type PathAnalyticsDataT = {
  devices: any[],
  nodes: any[],
  paths: any[]
} | null;

export type PathAnalyticsParamsT = {
  endpoint: string,
  customerId: string,
  startDate: number,
  endDate: number,
  startHour: number,
  endHour: number,
  chartType: string,
  isTop?: boolean,
  url?: string,
  location?: string,
  userId?: string,
  agentId?: string,
  isTraceroute?: boolean
}

export type GetPathAnalyticsT = {
  type: typeof GET_PATH_ANALYTICS;
  params: PathAnalyticsParamsT
}

export type GetPathAnalyticsDoneT = {
  type: typeof GET_PATH_ANALYTICS_DONE;
  chartType: string,
  data: PathAnalyticsDataT
}

export type GetPathAnalyticsFailedT = {
  type: typeof GET_PATH_ANALYTICS_FAILED;
  chartType: string,
}

export type PathAnalyticsStateActionT = GetPathAnalyticsT | GetPathAnalyticsDoneT | GetPathAnalyticsFailedT