import { IGlobalStyle } from "./global";

export const lightTheme: IGlobalStyle = {
  background: "#F5F6FA",
  text: "#19283C",
  sidebar: "#FFFFFF",
  border: "#F5F6FA",
  panelBg: "#FFFFFF",
  textLight: "#19283C",
  tooltip: "#F5F6FA",
  map: "#DCE0E6",
  inputBorder: "#D2D9DC",
  inputBorderPlain: "transparent",
  inputBorderChecked: "#000000",
  textFieldChecked: "#293238",
  inputBackground: "#ffffff",
  tabsBorder: "#F5F6FA",
  checkboxBg: "#F5F6FA",
  boxShadow: "0 8px 6px rgba(25, 40, 60, 0.04)",
};
