import colors from "../../../../assets/styles/variables/colors.module.scss";

export const GetConferenceCallDashboard = () => [
  {
    title: "Conference call details",
    id: "conference-call-important-metrics-old",
    type: "important-metrics",
    dashboardType: "conference-call",
    size: 1,
    endpoint: "/metric/ucc/conferenceDetailsByCallId",
    chartType: "ucc",
    // availableSizes: [1, 2],
  },
  {
    title: "Conference call quality score",
    id: "conference-call-gauge-results-old-quality",
    type: "gauge-results",
    dashboardType: "conference-call",
    size: 1,
    endpoint: "/metric/ucc/getConferenceQualityPercent",
    chartType: "ucc",
    // availableSizes: [1, 2],
  },
  {
    title: "Conference call by service type",
    id: "conference-call-gauge-half",
    type: "gauge-half",
    dashboardType: "conference-call",
    endpoint: "/metric/ucc/getActivityOfServices",
    size: 2,
    sections: [
      {
        title: "Voice",
        color: colors["widgets-blue"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
      {
        title: "Video",
        color: colors["widgets-green"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
      {
        title: "Appshare",
        color: colors["widgets-crusta"],
        accessor: (data: { value: number; title: string }) => data?.value,
      },
    ],
    // availableSizes: [2, 3, 4],
  },
  {
    title: "Conference calls details",
    id: "conference-call-simple-table",
    type: "simple-table",
    dashboardType: "conference-call",
    size: 4,
    endpoint: "/metric/ucc/getCallInfo",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
  //   {
  //     title: "Conference call performance details",
  //     id: "conference-call-column-line-chart-old",
  //     type: "column-line-chart",
  //     dashboardType: "conference-call",
  //     size: 4,
  //     endpoint: "/metric/ucc/getCallInfo",
  //     chartType: "ucc",
  //     // availableSizes: [2, 3, 4],
  //   },
  {
    title: "Participants list metrics",
    id: "conference-call-status-table-quality",
    type: "status-table",
    dashboardType: "conference-call",
    size: 4,
    endpoint: "/metric/ucc/getConferences",
    chartType: "ucc",
    // availableSizes: [3, 4],
  },
];
