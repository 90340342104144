import React from "react";
import SignInView from "./views/Auth/SignIn/SignIn.view";
import Dashboard from "./views/Dashboard/Dashboard.view";
import SignUpView from "./views/Auth/SignUp/SignUp.view";
import UsersManagementView from "./views/UsersManagement/UsersManagement.view";
import CustomersManagementView from "./views/CustomersManagement/CustomersManagement.view";
import ApiAndAppManagementView from "./views/ApiAndAppManagement/ApiAndAppManagement.view";
import AlertsManagementView from "./views/AlertsManagement/AlertsManagement.view";
import AgentsManagementView from "./views/AgentsManagement/AgentsManagement.view";
import DevicesManagementView from "./views/DevicesManagement/DevicesManagement.view";
import MessagesView from "./views/Messages/Messages.view";
import ReportsView from "./views/Reports/Reports.view";
import StandardReportsView from "./views/StandardReports/StandardReports.view";
import TemplateReportsView from "./views/TemplateReports/TemplateReports.view";
import PersonalReportsView from "./views/PersonalReports/PersonalReports.view";
import SearchView from "./views/Search/Search.view";
import PasswordRecoveryView from "./views/Auth/PasswordRecovery/PasswordRecovery.view";
import NewPasswordView from "./views/Auth/NewPassword/NewPassword.view";
import SingleReportView from "./views/SingleReport/SingleReport.view";
import ProfileSettingsView from "./views/ProfileSettings/ProfileSettings.view";
import NotificationsView from "./views/Notifications/Notifications.view";
import SingleAgentView from "./views/SingleAgent/SingleAgent.view";
import SingleDeviceView from "./views/SingleDevice/SingleDevice.view";
import NewAlertView from "./views/NewAlert/NewAlert.view";
import ReportTemplateView from "./views/ReportTemplate/ReportTemplate.view";
import SingleAccountView from "./views/SingleAccount/SingleAccount.view";
import SingleUserView from "./views/SingleUser/SingleUser.view";
import SingleCustomerView from "./views/SingleCustomer/SingleCustomer.view";
import AgentsUnknownManagementView from "./views/AgentsUnknownManagement/AgentsUnknownManagement.view";
import AgentsKnownManagementView from "./views/AgentsKnownManagement/AgentsKnownManagement.view";
import DevicesDiscoveredManagementView from "./views/DevicesDiscoveredManagement/DevicesDiscoveredMangement.view";
import DevicesAddedManagementView from "./views/DevicesAddedManagement/DevicesAddedManagement.view";
import EmployeesManagementView from "./views/EmployeesManagement/EmployeesManagement.view";
import DashboardDrillDown from "./views/DashboardDrillDown/DashboardDrillDown.view";
import APIKeyGenerator from "./views/APIKeyGenerator/APIKeyGenerator";

export const authRoutes = [
  {
    path: "/auth/sign-in",
    component: <SignInView />,
  },
  {
    path: "/auth/sign-up",
    component: <SignUpView />,
  },
];

export const layoutRoutes = [
  {
    path: "/users-management",
    component: <UsersManagementView />,
  },
  {
    path: "/customers-management",
    component: <CustomersManagementView />,
  },
  {
    path: "/user/:id/:user",
    component: <ProfileSettingsView />,
  },
  {
    path: "/account/:id",
    component: <SingleAccountView />,
  },
  {
    path: "/account/:id/:user",
    component: <SingleUserView />,
  },
  {
    path: "/customer/:id",
    component: <SingleCustomerView />,
  },
  {
    path: "/api-and-app-management",
    component: <ApiAndAppManagementView />,
  },
  {
    path: "/alerts-management",
    component: <AlertsManagementView />,
  },
  {
    path: "/alerts-management/new",
    // @ts-ignore
    component: <NewAlertView />,
  },
  {
    path: "/employees-management",
    component: <EmployeesManagementView />,
  },
  {
    path: "/agents-management",
    component: <AgentsManagementView />,
  },
  {
    path: "/agents-management/unknown",
    component: <AgentsUnknownManagementView />,
  },
  {
    path: "/agents-management/known",
    component: <AgentsKnownManagementView />,
  },
  {
    path: "/agents-management/agent/:id",
    component: <SingleAgentView />,
  },
  {
    path: "/devices-management",
    component: <DevicesManagementView />,
  },
  {
    path: "/devices-management/discovered",
    component: <DevicesDiscoveredManagementView />,
  },
  {
    path: "/devices-management/added",
    component: <DevicesAddedManagementView />,
  },
  {
    path: "/devices-management/device/:id",
    component: <SingleDeviceView />,
  },
  {
    path: "/search",
    component: <SearchView />,
  },
  {
    path: "/messages",
    component: <MessagesView />,
  },
  {
    path: "/reports",
    component: <ReportsView />,
  },
  {
    path: "/reports/standard",
    component: <StandardReportsView />,
  },
  {
    path: "/reports/personal",
    component: <PersonalReportsView />,
  },
  {
    path: "/reports/templates",
    component: <TemplateReportsView />,
  },
  {
    path: "/reports/templates/id/:id",
    component: <ReportTemplateView />,
  },
  {
    path: "/reports/id/:id",
    component: <SingleReportView />,
  },
  {
    path: "/create-template",
    component: <ReportTemplateView />,
  },
  {
    path: "/profile-edit",
    component: <ProfileSettingsView />,
  },
  {
    path: "/profile-edit/key",
    component: <APIKeyGenerator />,
  },
  {
    path: "/notifications",
    component: <NotificationsView />,
  },
  {
    path: "/new-password",
    component: <NewPasswordView />,
  },
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/dashboard/application/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/application/:name/details/:location",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/application/:traceroute/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/agent/:id",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/device/:name/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/location/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/employee-call/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/conference-call/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/call-queue/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/ucc-app/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/licenses",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/license/:companyName",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/resource/:name",
    component: <DashboardDrillDown />,
  },
  {
    path: "/dashboard/resources",
    component: <DashboardDrillDown />,
  },
];

export const authManagementRoutes = [
  {
    path: "/auth/password-recovery",
    component: <PasswordRecoveryView />,
  },
  {
    path: "/auth/reset",
    component: <NewPasswordView />,
  },
];
