import React, { useEffect, useState } from "react";
import styles from "./TopBarUser.module.scss";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconUser } from "../../../assets/icons/user-placeholder-sm.svg";
import { AppState } from "../../../store/reducers";
import { ToggleMenu } from "../../../store/actions/ToggleMenu.action";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";

const TopBarUser = () => {
  const auth = useSelector((state: AppState) => state.authorization);

  const [user, setUser] = useState(auth);

  useEffect(() => {
    setUser(auth);
  }, [auth]);

  const dispatch = useDispatch();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });

  return (
    <NavLink
      to="/profile-edit/"
      onClick={() => isResponsiveView && dispatch(ToggleMenu())}
      className={styles.wrapper}
    >
      <div className={styles.element}>
        <div className={styles.thumbnail}>
          {user?.photoLink ? (
            <img src={user.photoLink} alt={"test"} />
          ) : (
            <IconUser />
          )}
        </div>
        <div className="d-flex flex-column">
          <div className={styles.name}>
            {user?.name ? user.name : <Skeleton width={100} />}
          </div>
          <div className={styles.role}>
            {user?.email ? user?.email : <Skeleton width={75} />}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default TopBarUser;
