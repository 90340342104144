import {
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS,
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_DONE,
  GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_FAILED,
  TableStateActionT
} from "../../types/widgets/EmployeeCalleeOriginatorMetricsTable.types";

export const initialState = {
  data: [],
  loading: false,
  error: false
};

const employeeCalleeOriginatorMetricsState = (state = initialState, action: TableStateActionT) => {
  switch (action.type) {
    case GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS:
      return { ...state, data: [], loading: true, error: false };
    case GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_DONE:
      return { ...state, data: action.data, loading: false, error: false };
    case GET_TABLE_EMPLOYEE_CALLEE_ORIGINATOR_METRICS_FAILED:
      return { ...state, data: [], loading: false, error: true };
    default:
      return state;
  }
};

export default employeeCalleeOriginatorMetricsState;
