import React, { useState, useEffect, ChangeEvent } from "react";
import styles from "./ModalSelectCustomer.module.scss";
import ModalSelectCustomerElem from "./ModalSelectCustomerElem/ModalSelectCustomerElem";
import ModalSelectCustomerList from "./ModalSelectCustomerList/ModalSelectCustomerList";
import ModalSelectCustomerSearch from "./ModalSelectCustomerSearch/ModalSelectCustomerSearch";
import ModalSelectCustomerSearchResults, {
  ISearchResult,
} from "./ModalSelectCustomerSearchResults/ModalSelectCustomerSearchResults";
import { ReactComponent as IconChevron } from "../../../assets/icons/chevron.svg";
import cn from "classnames";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import Heading from "../../Heading/Heading";
import { AppState } from "../../../store/reducers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { GetCustomersTree } from "../../../store/actions/ManageCustomers.action";
import { ContextT } from "../../Sidebar/SidebarMenu/SidebarMenuContext/SidebarMenuContext";

const ModalSelectCustomer = (props: IModalSelectCustomer) => {
  const { setModalOpen, handleContext } = props;
  const { permissions, customer, photoLinkCustomer } = useSelector(
    (state: AppState) => state.authorization
  );

  const { customersTree, isLoading } = useSelector(
    (state: AppState) => state.customersState
  );
  const companyNoAccess =
    !permissions.organization.fullAccess && !permissions.organization.data;
  const [customersData, setCustomersData] = useState<CustomerT[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<ISearchResult[]>([]);
  const [isSearchResultsVisible, setIsSearchResultsVisible] =
    useState<boolean>(false);
  const [isCustomersVisible, setCustomersVisible] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const numbersOfLoadedItems = 5;
  const [itemsToShow, setItemsToShow] = useState<number>(
    expanded ? customersData.length : numbersOfLoadedItems
  );
  const [customersToShow, setCustomersToShow] = useState<CustomerT[]>(
    customersData.length > numbersOfLoadedItems
      ? customersData.slice(0, itemsToShow)
      : customersData
  );

  const dispatch = useDispatch();

  const handleFocus = () => {
    if (results.length > 0) {
      setIsSearchResultsVisible(true);
    } else {
      setIsSearchResultsVisible(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !customersTree) {
      dispatch(GetCustomersTree());
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch, customersTree]);

  useEffect(() => {
    if (isLoading) {
      setCustomersData([]);
      setNoData(false);
    } else {
      if (customersTree) {
        if (customersTree.length) {
          setCustomersData(customersTree);
          setNoData(false);
        } else {
          setNoData(true);
        }
      }
    }
  }, [customersTree, isLoading]);

  useEffect(() => {
    let resultfilerData: any[] = [];

    const searchData = (data: CustomerT[]) => {
      let filterData = data.filter((elem: CustomerT) => {
        return elem.customer.name.toLowerCase().includes(query.toLowerCase());
      });
      resultfilerData = [...resultfilerData, ...filterData];
      data.forEach((elem: CustomerT) => {
        if (elem.subCustomers.length > 0) {
          searchData(elem.subCustomers);
        }
      });
    };

    if (query.length > 0) {
      searchData(customersData);
      setCustomersVisible(false);
      setResults(resultfilerData);
      setIsSearchResultsVisible(true);
      return;
    }
    setCustomersVisible(true);
    setIsSearchResultsVisible(false);
    setResults([]);
  }, [customersData, query]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, clear?: boolean) => {
    if (clear) {
      setQuery("");
      return;
    }
    setQuery(e.target.value);
  };

  useEffect(() => {
    if (!customersToShow.length)
      setCustomersToShow(
        customersData.length > numbersOfLoadedItems
          ? customersData.slice(0, numbersOfLoadedItems)
          : customersData
      );
    if (customersToShow.length === customersData.length) setExpanded(true);
    else setExpanded(false);
  }, [customersToShow, customersData]);

  const handleClick = () => {
    if (itemsToShow < customersData.length) {
      setItemsToShow(customersToShow.length + numbersOfLoadedItems);
      setCustomersToShow(
        customersData.slice(0, itemsToShow + numbersOfLoadedItems)
      );
    } else {
      setItemsToShow(numbersOfLoadedItems);
      setCustomersToShow(customersData.slice(0, numbersOfLoadedItems));
    }
  };

  return (
    <>
      <div className={styles.header}>
        <Heading title="Select a Customer" weight="medium" />
        <button className={styles.button} onClick={() => setModalOpen(false)}>
          <IconClose width="1.8rem" height="1.8rem" />
        </button>
      </div>
      {!noData ? (
        customersData.length ? (
          <>
            <div className={styles.search}>
              <ModalSelectCustomerSearch
                placeholder="Find Customer"
                handleFocus={handleFocus}
                handleChange={handleChange}
                value={query}
              />
            </div>
            {isSearchResultsVisible && (
              <ModalSelectCustomerSearchResults
                query={query}
                handleContext={handleContext}
                results={results}
              />
            )}

            {isCustomersVisible ? (
              <div>
                <ModalSelectCustomerElem
                  customer={customer}
                  logo={photoLinkCustomer}
                  Tag={"div"}
                  handleContext={handleContext}
                  subCustomers={[]}
                  handleClick={handleClick}
                  setModalOpen={() => setModalOpen(false)}
                  isCompany
                  disabled={companyNoAccess}
                />
                <ModalSelectCustomerList>
                  {customersData.slice(0, itemsToShow).map((item, key) => {
                    return (
                      <ModalSelectCustomerElem
                        {...item}
                        key={key}
                        setModalOpen={() => setModalOpen}
                        handleContext={handleContext}
                        handleClick={handleClick}
                        isOpen
                      />
                    );
                  })}
                </ModalSelectCustomerList>
                {customersData.length > numbersOfLoadedItems && (
                  <button className={styles.showMore} onClick={handleClick}>
                    {!expanded ? "Show more" : "Show less"}
                    <IconChevron
                      className={cn(
                        styles.arrow,
                        expanded && styles["isExpanded"]
                      )}
                    />
                  </button>
                )}
              </div>
            ) : null}
          </>
        ) : (
          <Loader container className="pt-5 pb-4" />
        )
      ) : (
        <span className={styles.text}>You don't have any Customers</span>
      )}
    </>
  );
};

export default ModalSelectCustomer;

interface IModalSelectCustomer {
  setModalOpen: (e: boolean) => void;
  handleContext: (e: ContextT) => void;
}

export type CustomerT = {
  customer: {
    id: string;
    name: string;
    email: string;
    status?: string;
  };
  subCustomers: CustomerT[];
};
