import React, { useCallback, useEffect, useState } from "react";
import styles from "./NotificationsBar.module.scss";
import NotificationsBarList from "../NotificationsBarList/NotificationsBarList";
import Loader from "../../Loader/Loader";
import axios from "../../../api/axiosDashboard";
import moment from "moment";
import _ from "lodash";
import NotificationsBarFilter from "../NotificationsBarFilter/NotificationsBarFilter";

const NotificationsBar = () => {
  const [initialView, setInitialView] = useState<any[]>([]);
  const [view, setView] = useState<any[]>([]);

  const filter = [
    {
      title: "All",
      slug: "all",
    },
    {
      title: "Alerts",
      slug: "alert",
    },
    {
      title: "Notifications",
      slug: "invitation_accepted",
    },
  ];

  const fetchData = useCallback(async (endpoint: string) => {
    try {
      return await axios(endpoint, {
        params: {
          startDate: 1610107300,
          endDate: moment().unix(),
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData("/notifications").then((res) => {
      if (res && res.status === 200) {
        setInitialView(res.data);
        setView(res.data);
      }
    });
  }, [fetchData]);

  const handleFilter = (type: string) => {
    if (type !== "all") {
      setView(_.filter(initialView, { type: type }));
    } else {
      setView(initialView);
    }
  };

  return (
    <div className={styles.element}>
      <NotificationsBarFilter list={filter} handleClick={handleFilter} />
      {view.length > 0 ? (
        <NotificationsBarList list={view} />
      ) : (
        <Loader className="m-auto" />
      )}
    </div>
  );
};

export default NotificationsBar;
