import React, { useState, useCallback } from "react";
import axios from "../../../api/axiosDashboard";
import SelectInput from "../SelectInput/SelectInput";
import { useFormikContext } from "formik";

const DeviceLocationGroupSelect = (props: IDeviceLocationGroupSelect) => {
  const { id, touched, errors, label, name, required, className } = props;
  const [data, setData] = useState<Array<{ value: string; label: string }>>([]);

  const formValues = useFormikContext();
  const address =
    // @ts-ignore
    formValues.values.country &&
    // @ts-ignore
    formValues.values.city &&
    // @ts-ignore
    `${formValues.values.country}, ${formValues.values.city}`;

  const fetchData = useCallback(async () => {
    try {
      await axios(`/management/devices/locations/${id}`).then((res) => {
        if (res && res.status === 200) {
          const data = res.data.map((elem: string) => {
            return {
              value: elem,
              label: elem,
            };
          });
          if (address) {
            const includeAddress = res.data.find(
              (elem: string) => elem === address
            );
            if (includeAddress) {
              setData(data);
            } else {
              const sortedData = [
                ...data,
                { value: address, label: address },
              ].sort((a, b) => a.value.localeCompare(b.value));
              setData(sortedData);
            }
          } else {
            setData(data);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, address]);

  console.log(data);

  return (
    <>
      <SelectInput
        touched={touched}
        errors={errors}
        name={name}
        options={data}
        label={label}
        placeholder="Select Location"
        isSearchable={true}
        required={required}
        className={className}
        fetchData={fetchData}
        hasWatchedFields={true}
      />
    </>
  );
};

export default DeviceLocationGroupSelect;

interface IDeviceLocationGroupSelect {
  touched: any;
  errors: any;
  name: string;
  label?: string;
  required?: boolean;
  id: string;
  className?: string;
}
