export const formatUptime = (seconds: number) => {
  const numdays = Math.floor(seconds / 86400);

  const numhours = Math.floor((seconds % 86400) / 3600);

  const numminutes = Math.floor(((seconds % 86400) % 3600) / 60);

  const numseconds = ((seconds % 86400) % 3600) % 60;

  const addZero = (num: number) => {
    if (num < 10) {
      return `0${num}`;
    } else return num;
  };

  if (numdays === 0) {
    return `${addZero(numhours)}:${addZero(numminutes)}:${addZero(numseconds)}`;
  } else if (numdays === 1) {
    return `${numdays} day, ${addZero(numhours)}:${addZero(
      numminutes
    )}:${addZero(numseconds)}`;
  } else {
    return `${numdays} days, ${addZero(numhours)}:${addZero(
      numminutes
    )}:${addZero(numseconds)}`;
  }
};
