import React from "react";
import styles from "./SidebarMenuReports.module.scss";
import { NavLink } from "react-router-dom";
// import { ReactComponent as IconFile } from "../../../../assets/icons/file.svg";
import { ReactComponent as IconChevron } from "../../../../assets/icons/chevron.svg";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../../assets/styles/variables/breakpoints.module.scss";
import { useDispatch } from "react-redux";
import { ToggleMenu } from "../../../../store/actions/ToggleMenu.action";

const SidebarMenuReports = (props: ISidebarMenuReports) => {
  const { list } = props;

  return (
    <ul className={styles.list}>
      {list.map((elem, key) => {
        return <SidebarMenuReportsElem {...elem} key={key} />;
      })}
    </ul>
  );
};

export default SidebarMenuReports;

const SidebarMenuReportsElem = (props: ISidebarMenuReportsElem) => {
  const { title, path } = props;
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["md"]}px)`,
  });
  const dispatch = useDispatch();
  return (
    <li>
      <NavLink
        exact
        strict
        activeClassName={styles["isActive"]}
        to={path}
        onClick={() => isResponsiveView && dispatch(ToggleMenu())}
        className={styles.element}
      >
        <span className={styles.icon}>
          <IconChevron />
        </span>
        <span className={styles.title}>{title}</span>
      </NavLink>
    </li>
  );
};

interface ISidebarMenuReports {
  list: Array<ISidebarMenuReportsElem>;
}

export interface ISidebarMenuReportsElem {
  title: string;
  path: string;
}
