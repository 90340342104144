import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Label.module.scss";
import cn from "classnames";

const Label = (props: ILabel) => {
  const { title, link, className, type } = props;

  if (link) {
    return (
      <NavLink
        to={link}
        className={cn(styles.element, styles["isLink"], className)}
      >
        {title}
      </NavLink>
    );
  } else {
    return (
      <span
        className={cn(
          styles.element,
          type === "heading" && styles["isHeading"]
        )}
      >
        {title}
      </span>
    );
  }
};

export default Label;

interface ILabel {
  title: string;
  link?: string;
  className?: string;
  type?: "heading";
}
