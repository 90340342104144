import React, { useState, useCallback, useEffect } from "react";
import { Container } from "../../components/Bootstrap/Bootstrap";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/user.svg";
import Modal from "../../components/Modal/Modal";
import ModalAddCustomer from "../../components/Modal/ModalAddCustomer/ModalAddCustomer";
import ModalDelete from "../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import Customers from "../../widgets/Customers/Customers"; // } //   SingleCustomerCustomersT, //   SingleCustomerUsersT, //   SingleCustomerT, // , {
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/reducers";
import ModalEditCustomer from "../../components/Modal/ModalEditCustomer/ModalEditCustomer";
import NoDataPlaceholder from "../../components/NoDataPlaceholder/NoDataPlaceholder";
// import { toBase64 } from "../../functions/ToBase64/ToBase64";
import { checkEditAccess } from "../../functions/CheckEditAccess/CheckEditAccess";
import UserCard from "../../components/UserCard/UserCard";
import {
  GetCustomers,
  ClearState,
} from "../../store/actions/ManageCustomers.action";
import ModalEditSLATargets from "../../components/Modal/ModalEditSLATargets/ModalEditSLATargets";
import {
  ClearSLATargets,
  GetSLATargets,
} from "../../store/actions/SetSLATargets.action";

const CustomersManagementView = () => {
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [modalSLAOpen, setModalSLAOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<ICustomerValues>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const { permissions, customer } = useSelector(
    (state: AppState) => state.authorization
  );
  const { id, name } = useSelector(
    (state: AppState) => state.customerContextState
  );
  const { customers, isLoading, error, message } = useSelector(
    (state: AppState) => state.customersState
  );
  const SLATargetsState = useSelector(
    (state: AppState) => state.SLATargetsState
  );
  const dispatch = useDispatch();

  const openEdit = (user: ICustomerValues) => {
    setSelectedUser(user);
    setModalEditOpen(true);
  };

  const openDelete = (user: ICustomerValues) => {
    setSelectedUser(user);
    setModalDeleteOpen(true);
  };

  const openSLA = (user: ICustomerValues) => {
    dispatch(GetSLATargets(user.id));
    setSelectedUser(user);
    setModalSLAOpen(true);
  };

  useEffect(() => {
    !modalSLAOpen && SLATargetsState?.data && dispatch(ClearSLATargets());
  }, [SLATargetsState, modalSLAOpen, dispatch]);

  const customerContextId = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).id
    : id;
  const customerContextName = window.localStorage.getItem("customerContext")
    ? // @ts-ignore
      JSON.parse(window.localStorage.getItem("customerContext")).name
    : name;

  const fetchCustomers = useCallback(() => {
    dispatch(GetCustomers(customerContextId));
  }, [dispatch, customerContextId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchCustomers();
    return () => {
      isMounted = false;
    };
  }, [customerContextId, fetchCustomers]);

  useEffect(() => {
    !modalAddOpen && (error || message) && dispatch(ClearState());
  }, [dispatch, modalAddOpen, error, message]);

  useEffect(() => {
    if (isLoading) {
      setTableData([]);
      setNoData(false);
    } else {
      if (customers) {
        if (customers.length) {
          setTableData(customers);
          setNoData(false);
        } else {
          setNoData(true);
        }
      }
    }
  }, [isLoading, customers]);

  return (
    <>
      <Container fluid>
        <LayoutHeader title="Customers Management">
          {checkEditAccess(permissions, customer, id) && (
            <Button
              title="Add Customer"
              icon={<IconButton width="1.4rem" height="1.4rem" />}
              icon_position="right"
              handleClick={() => setModalAddOpen(true)}
            />
          )}
        </LayoutHeader>
        <Customers
          data={tableData}
          noData={noData}
          openEdit={openEdit}
          openDelete={openDelete}
          openSLA={openSLA}
        />
      </Container>
      {noData && (
        <NoDataPlaceholder
          image
          title="No customers added yet"
          subTitle="Add your first customer by clicking the invite button"
        />
      )}
      <Modal modalOpen={modalAddOpen} setModalOpen={setModalAddOpen}>
        <ModalAddCustomer
          setModalOpen={setModalAddOpen}
          handleUpdateData={fetchCustomers}
          customer={customerContextName}
        />
      </Modal>
      <Modal modalOpen={modalEditOpen} setModalOpen={setModalEditOpen}>
        <ModalEditCustomer
          setModalOpen={setModalEditOpen}
          handleUpdateData={fetchCustomers}
          selectedUser={selectedUser}
        />
      </Modal>
      <Modal modalOpen={modalSLAOpen} setModalOpen={setModalSLAOpen}>
        <ModalEditSLATargets
          setModalOpen={setModalSLAOpen}
          selectedUser={selectedUser}
        />
      </Modal>
      {selectedUser && (
        <>
          <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
            <ModalDelete
              name="Customer"
              endpoint={`/customer/${selectedUser && selectedUser.id}`}
              setModalOpen={setModalDeleteOpen}
              handleUpdateData={fetchCustomers}
            >
              <ModalDeleteElem>
                <UserCard {...selectedUser.customerDetails} />
              </ModalDeleteElem>
            </ModalDelete>
          </Modal>
        </>
      )}
    </>
  );
};

export default CustomersManagementView;

export interface ICustomerValues {
  id: string;
  phone: string;
  expireDate: Date;
  isAccount: boolean;
  customerDetails: {
    name: string;
    email: string;
    photoLink: any;
    isAccount: true;
  };
}
