import moment from "moment";
import TableCellQuality from "../../../../components/tableElements/TableCellQuality/TableCellQuality";
import TableCellText from "../../../../components/tableElements/TableCellText/TableCellText";

export const GetDeviceDashboard = () => [
  {
    title: "EUX score of the device",
    id: "device-gauge-results-old-eux",
    type: "gauge-results",
    dashboardType: "device",
    size: 1,
    // availableSizes: [1, 2],
    chartType: "EUX",
    endpoint: "/metric/devices/getOverall",
  },
  {
    title: "Availability",
    id: "device-gauge-results-availability",
    type: "gauge-results",
    dashboardType: "device",
    size: 1,
    // availableSizes: [1, 2],
    chartType: "availability",
    endpoint: "/metric/devices/getAvailibility",
  },
  {
    title: "Device details",
    id: "device-important-metrics-details",
    type: "important-metrics",
    dashboardType: "device",
    size: 2,
    // availableSizes: [1, 2],
    endpoint: "/metric/devices/important",
    chartType: "firmware",
  },
  // {
  //   title: "Conference calls details",
  //   id: "device-gauge-results-old-calls",
  //   type: "gauge-results",
  //   dashboardType: "device",
  //   size: 1,
  //   endpoint: "/metric/ucc/getPercentOfQuality",
  //   queryParams: { isConference: true },
  //   // availableSizes: [1, 2],
  // },
  // {
  //   title: "Number of sessions",
  //   id: "device-gauge-half-sessions",
  //   type: "gauge-half",
  //   dashboardType: "device",
  //   size: 2,
  //   // availableSizes: [2, 3, 4],
  // endpoint: 'metric/devices/callTypeVolume'
  // },
  {
    title: "Numbers of calls, sessions, failures",
    id: "device-column-line-chart-calls",
    type: "column-line-chart",
    dashboardType: "device",
    size: 2,
    // availableSizes: [2, 3, 4],
    endpoint: "metric/devices/getDeviceStatistic",
    chartType: "deviceStatistics",
    data: {
      column: [
        {
          title: "Device",
          id: "device",
        },
        {
          title: "Health",
          id: "health",
        },
        {
          title: "Status",
          id: "status",
        },
        {
          title: "Connection",
          id: "connection",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Device information over time",
    id: "device-column-line-chart-old-time",
    type: "column-line-chart",
    dashboardType: "device",
    size: 2,
    // availableSizes: [2, 3, 4],
    endpoint: "/metric/devices/getDeviceStatistic",
    chartType: "deviceInfo",
    data: {
      column: [
        {
          title: "Status",
          id: "status",
        },
        {
          title: "Connection",
          id: "connection",
        },
      ],
      line: [
        {
          title: "Availability",
          id: "availability",
          unit: "%",
        },
      ],
    },
  },
  {
    title: "Device status by employee and room using device",
    id: "device-status-table-device",
    type: "simple-table",
    dashboardType: "device",
    size: 4,
    // availableSizes: [3, 4],
    endpoint: "/metric/devices/getStatuses",
    display: window.location.href.includes('/discovered/') ? 'none' : '',
    columns: [
      {
        Header: "Device name",
        accessor: "deviceName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Device user",
        accessor: "userName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "current firmware",
        accessor: "firmware",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Last Connected Date",
        accessor: "lastConnected",
        Cell: ({ cell: { value } }: { cell: { value: number }, row: any }) => {
          return <TableCellText>{moment.unix(value).format("DD-MM-YYYY h:mm a")}</TableCellText>
        }
      },
      {
        Header: "Device health",
        accessor: "deviceHealth",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => (
          <TableCellQuality quality={value} />
        ),
      },
      {
        Header: "Device status",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "Device connected status",
        accessor: "deviceConnectedStatus",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "Quality",
        accessor: "quality",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
    ],
  },
  // {
  //   title: "Call and session quality",
  //   id: "device-gauge-results-old-quality",
  //   type: "gauge-results-old",
  //   dashboardType: "device",
  //   size: 1,
  //   // availableSizes: [1, 2],
  // },
  // {
  //   title: "Important Metrics",
  //   id: "device-important-metrics",
  //   type: "important-metrics",
  //   dashboardType: "device",
  //   size: 1,
  //   // availableSizes: [1, 2],
  //   endpoint: "/metric/devices/important",
  //   chartType: "healthDevices"
  // },
  {
    title: "Users affected by the device performance issues",
    id: "device-simple-table-users",
    type: "simple-table",
    dashboardType: "device",
    size: 4,
    // availableSizes: [2, 3, 4],
    endpoint: "metric/devices/users",
    columns: [
      {
        Header: "Device name",
        accessor: "deviceName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Peripheral name",
        accessor: "peripheralName",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <TableCellText>{value}</TableCellText>
        ),
      },
      {
        Header: "Last Connected Date",
        accessor: "lastConnected",
        Cell: ({ cell: { value } }: { cell: { value: number }, row: any }) => {
          return <TableCellText>{moment.unix(value).format("DD-MM-YYYY h:mm a")}</TableCellText>
        }
      },
      {
        Header: "Peripheral status",
        accessor: "peripheralStatus",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => (
          <TableCellQuality quality={value} />
        ),
      },
      {
        Header: "Connected status",
        accessor: "connectedStatus",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "Device health status",
        accessor: "healthStatus",
        disableSortBy: true,
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
      {
        Header: "Quality",
        accessor: "quality",
        Cell: ({ cell: { value } }: { cell: { value: boolean } }) => {
          return <TableCellQuality quality={value} />;
        },
      },
    ],
  },
];
