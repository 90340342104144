import React, { useState, useEffect } from "react";
import styles from "../ModalConnectNewAgent/ModalConnectNewAgent.module.scss";
import { Form, Formik } from "formik";
import TextInput from "../../../components/formElements/TextInput/TextInput";
import TextareaInput from "../../../components/formElements/TextareaInput/TextareaInput";
import RadioGroup from "../../../components/formElements/RadioGroup/RadioGroup";
import SelectInput from "../../../components/formElements/SelectInput/SelectInput";
import Button from "../../Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/check.svg";
import { Column, Row } from "../../Bootstrap/Bootstrap";
import Label from "../../Label/Label";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import { useDispatch, useSelector } from "react-redux";
import { AddDevice } from "../../../store/actions/Device";
import { AppState } from "../../../store/reducers";
import AgentsSelect from "../../formElements/AgentsSelect/AgentsSelect";
import Heading from "../../Heading/Heading";

export interface IModalConnectNewDevice {
  setModalOpen: (e: boolean) => void;
  activeContext?: boolean;
  handleUpdateData: () => void;
}

const ModalConnectNewDevice = (props: IModalConnectNewDevice) => {
  const { setModalOpen, handleUpdateData } = props;
  const [sent, setSent] = useState<boolean>(false);
  const [version, setVersion] = useState(1);
  const dispatch = useDispatch();
  const { id } = useSelector((state: AppState) => state.customerContextState);

  const initialValues: initialValuesT = {
    name: "",
    alias: "",
    description: "",
    deviceType: "",
    connectionType: "",
    vendor: "",
    model: "",
    country: "",
    province: "",
    zipCode: "",
    city: "",
    street: "",
    snmpVersion: "",
    agentId: "",
    ip: "",
    communityString: "",
    interface: "",
    userName: "",
    privProtocol: "",
    authProtocol: "",
    privKey: "",
    authKey: "",
  };

  const restDeviceData = {
    customerId: id,
    circuitId: "",
    mac: "",
    osVersion: "",
    latency: "",
    lastTestDate: "",
    locationGroup: "",
    bandwidthDownload: "",
    bandwidthUpload: "",
  };

  useEffect(() => {
    if (sent) {
      const timer = () =>
        setTimeout(() => {
          setModalOpen(false);
          handleUpdateData();
        }, 3000);
      const timerId = timer();

      return () => {
        clearTimeout(timerId);
      };
    }
    setSent(false);
  }, [sent, setModalOpen, handleUpdateData]);

  if (sent) {
    return <ModalSuccess text={`Device has been successfully added`} />;
  }

  const handleToggleVersionFields = (
    version: number,
    touched: any,
    errors: any
  ) => {
    switch (version) {
      case 1:
        return (
          <>
            <TextInput
              touched={touched}
              errors={errors}
              name="communityString"
              label="Community String"
              placeholder="Enter Community String"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="interface"
              label="Interface"
              placeholder="Enter Interface"
              required
            />
          </>
        );
      case 2:
        return (
          <>
            <TextInput
              touched={touched}
              errors={errors}
              name="userName"
              label="Username"
              placeholder="Enter Username"
              required
            />
            <SelectInput
              touched={touched}
              errors={errors}
              name="authProtocol"
              options={[
                { value: "md5", label: "MD5" },
                { value: "sha1", label: "SHA1" },
              ]}
              label="Authentication Protocol"
              placeholder="Select Authentication Protocol"
              isSearchable={false}
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="authKey"
              label="Authentication Key"
              placeholder="Enter Authentication Key"
              required
            />
            <SelectInput
              touched={touched}
              errors={errors}
              name="privProtocol"
              options={[
                { value: "des", label: "DES" },
                { value: "aes128", label: "AES128" },
              ]}
              label="Private Protocol"
              placeholder="Select Private Protocol"
              isSearchable={false}
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="privKey"
              label="Private Key"
              placeholder="Enter Private Key"
              required
            />
            <TextInput
              touched={touched}
              errors={errors}
              name="interface"
              label="Interface"
              placeholder="Enter Interface"
              required
            />
          </>
        );
    }
  };

  const handleToggle = (e: number) => {
    setVersion(e + 1);
  };

  return (
    <>
      <Heading title="Add Device" weight="medium" />
      <div className={styles.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              id && dispatch(AddDevice({ ...values, ...restDeviceData }));
              setSent(true);
            }, 500);
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles.element}>
              <TextInput
                touched={touched}
                errors={errors}
                name="name"
                label="Device Name"
                placeholder="Enter device name"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="alias"
                label="Alias"
                placeholder="Enter Alias"
              />
              <TextareaInput
                touched={touched}
                errors={errors}
                name="description"
                label="Description"
                placeholder="Enter Description"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="deviceType"
                label="Device type"
                placeholder="Enter Device Type"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="connectionType"
                label="Connection Type"
                placeholder="Enter Connection Type"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="vendor"
                label="Vendor"
                placeholder="Enter Vendor"
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="model"
                label="Model"
                placeholder="Enter Model"
              />
              <div className="hLine" />
              <Label title="Device Location" />
              <Row>
                <Column className="col-12 col-sm-6 pr-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="country"
                    label="Country"
                    placeholder="Enter Country"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pl-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="province"
                    label="State/Province/Region"
                    placeholder="Enter Region"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pr-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="zipCode"
                    label="Postal Code"
                    placeholder="Enter Postal Code"
                  />
                </Column>
                <Column className="col-12 col-sm-6 pl-sm-2">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="city"
                    label="City"
                    placeholder="Enter City"
                  />
                </Column>
                <Column className="col-12">
                  <TextInput
                    touched={touched}
                    errors={errors}
                    name="street"
                    label="Street"
                    placeholder="Enter Street, suite, apt."
                  />
                </Column>
              </Row>
              <div className="hLine" />
              <RadioGroup
                name="snmpVersion"
                label="snmp"
                options={[
                  {
                    value: "v2c",
                    label: "SNMP v2c",
                  },
                  {
                    value: "v3",
                    label: "SNMP v3",
                  },
                ]}
                className={styles.radioGroup}
                handleToggle={handleToggle}
              />
              <AgentsSelect
                touched={touched}
                errors={errors}
                name="agentId"
                label="Agent"
                required
              />
              <TextInput
                touched={touched}
                errors={errors}
                name="ip"
                label="Device IP Address"
                placeholder="Enter IP Address"
                required
              />
              {handleToggleVersionFields(version, touched, errors)}

              <div className={styles.footer}>
                <Button
                  title="Cancel"
                  type="plain"
                  typeAttr="button"
                  handleClick={() => setModalOpen(false)}
                />
                <Button
                  title="Save"
                  typeAttr="submit"
                  icon={<IconButton />}
                  icon_position="right"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ModalConnectNewDevice;

export type initialValuesT = {
  name: string;
  alias: string;
  description: string;
  deviceType: string;
  connectionType: string;
  vendor: string;
  model: string;
  country: string;
  province: string;
  zipCode: string;
  city: string;
  street: string;
  snmpVersion: string;
  agentId: string;
  ip: string;
  communityString: string;
  interface: string;
  userName: string;
  privProtocol: string;
  authProtocol: string;
  privKey: string;
  authKey: string;
};
