import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(
  initialIsVisible: any,
  modalIsOpen: boolean
) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event: { target: any }) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target) && !modalIsOpen) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
