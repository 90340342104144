export const NumSelectOptions = (el: number[]) => {
  const arr: { value: number; label: string }[] = [];
  el.forEach((item) => {
    arr.push({ value: item, label: item.toString() });
  });
  return arr;
};

export const NumSelectOptionsScope = (first: number, last: number) => {
  const arr: { value: number; label: string }[] = [];
  for (let i = first; i <= last; i++) {
    arr.push({ value: i, label: i.toString() });
  }
  return arr;
};
