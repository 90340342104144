import React, { useCallback, useEffect, useState } from "react";
import styles from "./ApiManagement.module.scss";
import { useSelector } from "react-redux";
import ConnectedAPI, {
  SingleApiT,
} from "../../../widgets/ConnectedAPI/ConnectedAPI";
import Modal from "../../../components/Modal/Modal";
import ModalDelete from "../../../components/Modal/ModalDelete/ModalDelete";
import ModalDeleteElem from "../../../components/Modal/ModalDelete/ModalDeleteElem/ModalDeleteElem";
import ModalAddNewApi, {
  IApiValues,
} from "../../../components/Modal/ModalAddNewApi/ModalAddNewApi";
import axiosConfig from "../../../api/axiosConfig";
import LayoutHeader from "../../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../../components/Button/Button";
import { ReactComponent as IconButton } from "../../../assets/icons/link.svg";
import { customerContextSelector } from "../../../selectors";

const ApiManagement = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newConnection, setNewConnection] = useState<boolean>(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [data, setTableData] = useState<SingleApiT[]>([]);
  const [selectedAPI, setSelectedAPI] = useState<IApiValues>();
  const customerContextState = useSelector(customerContextSelector);

  const fetchData = useCallback(async () => {
    try {
      await axiosConfig(
        `/configuration/api/list/${customerContextState.id}`
      ).then((res) => {
        if (res && res.status === 200) {
          setTableData(res.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [customerContextState.id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  const openEdit = (api: IApiValues) => {
    setSelectedAPI(api);
    setModalOpen(true);
  };

  const openNew = () => {
    setModalOpen(true);
    setNewConnection(true);
  };

  const openDelete = (api: IApiValues) => {
    setSelectedAPI(api);
    setModalDeleteOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <LayoutHeader title="Connected API’s">
        <Button
          title="Connect New"
          icon={<IconButton width="1.4rem" height="1.4rem" />}
          icon_position="right"
          handleClick={() => openNew()}
        />
      </LayoutHeader>
      <ConnectedAPI data={data} openEdit={openEdit} openDelete={openDelete} />
      <Modal
        title={selectedAPI ? "Edit API" : "Add New API"}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setNewConnection={setNewConnection}
      >
        <ModalAddNewApi
          api={selectedAPI}
          newConnection={newConnection}
          handleAdd={fetchData}
          setModalOpen={setModalOpen}
          setNewConnection={setNewConnection}
          setSelectedAPI={setSelectedAPI}
        />
      </Modal>
      {selectedAPI && (
        <Modal modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
          <ModalDelete
            name="API"
            endpoint={`${process.env.REACT_APP_AUTH_API_ENDPOINT}/configuration/api/${selectedAPI.id}`}
            setModalOpen={setModalDeleteOpen}
            handleUpdateData={fetchData}
          >
            <ModalDeleteElem>
              <>
                <div className={styles.imageWrapper}>
                  {selectedAPI?.app?.image}
                </div>
                <div>
                  <span className={styles.name}>{selectedAPI?.app?.name}</span>
                  <span className={styles.version}>
                    {selectedAPI?.app?.version}
                  </span>
                </div>
              </>
            </ModalDeleteElem>
          </ModalDelete>
        </Modal>
      )}
    </div>
  );
};

export default ApiManagement;
