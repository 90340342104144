import {
  ADD_TO_CUSTOM_PRESETS,
  CurrentPresetT,
  DELETE_PRESETS,
  DELETE_PRESETS_DONE,
  DELETE_PRESETS_FAILED,
  GET_PRESETS,
  GET_PRESETS_DONE,
  GET_PRESETS_FAILED,
  SetCurrentDashboardDoneT,
  SetCurrentDashboardFailedT,
  SetCurrentDashboardT,
  SET_CURRENT_DASHBOARD,
  SET_CURRENT_DASHBOARD_DONE,
  SET_CURRENT_DASHBOARD_FAILED,
  SET_CUSTOM_PRESETS,
  SET_CUSTOM_PRESETS_DONE,
  SET_CUSTOM_PRESETS_FAILED,
  SHARE_CUSTOM_PRESET,
  UNSHARE_DEFAULT_PRESET,
} from "../types/DashboardPresets.types";

export const SetCurrentDashboard = (
  preset: CurrentPresetT
): SetCurrentDashboardT => ({
  type: SET_CURRENT_DASHBOARD,
  preset,
});

export const SetCurrentDashboardDone = (
  payload: any
): SetCurrentDashboardDoneT => ({
  type: SET_CURRENT_DASHBOARD_DONE,
  payload,
});

export const SetCurrentDashboardFailed = (): SetCurrentDashboardFailedT => ({
  type: SET_CURRENT_DASHBOARD_FAILED,
});

export const GetPresets = (id: any) => ({
  type: GET_PRESETS,
  id,
});

export const GetPresetsDone = (data: any) => ({
  type: GET_PRESETS_DONE,
  payload: data,
});

export const GetPresetsFailed = (error: any) => ({
  type: GET_PRESETS_FAILED,
  error,
});

export const SetCustomPresets = (data: any) => ({
  type: SET_CUSTOM_PRESETS,
  data,
});

export const SetCustomPresetsDone = () => ({
  type: SET_CUSTOM_PRESETS_DONE,
});

export const SetCustomPresetsFailed = (error: any) => ({
  type: SET_CUSTOM_PRESETS_FAILED,
  error,
});

export const DeletePresets = (userId: string, data: any[]) => ({
  type: DELETE_PRESETS,
  userId,
  data,
});

export const DeletePresetsDone = () => ({
  type: DELETE_PRESETS_DONE,
});

export const DeletePresetsFailed = (error: any) => ({
  type: DELETE_PRESETS_FAILED,
  error,
});

export const ShareCustomPreset = (data: any) => ({
  type: SHARE_CUSTOM_PRESET,
  data,
});

export const AddToCustomPresets = (data: any) => ({
  type: ADD_TO_CUSTOM_PRESETS,
  data,
});

export const UnshareDefaultPreset = (data: any[]) => ({
  type: UNSHARE_DEFAULT_PRESET,
  data,
});
