import React, { useEffect } from "react";
import styles from "./Logo.module.scss";
import { ReactComponent as IconLogo } from "../../assets/icons/logo_hortium_eux.svg";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { AppState } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerLogo } from "../../store/actions/ManageCustomers.action";

const Logo = (props: ILogo) => {
  const { isLink = true, className } = props;
  const { name } = useSelector((state: AppState) => state.customerContextState);
  const dispatch = useDispatch();
  const { customerLogo } = useSelector((state: AppState) => state.customersState);
  // @ts-ignore
  const customer = JSON.parse(window.localStorage.getItem("customerContext"));

  useEffect(() => {
    if (customer?.id) {
      dispatch(getCustomerLogo(customer.id));
    }
  }, [customer?.id, dispatch]);

  const isDashboard = window.location.pathname === "/";
  const Logo = customerLogo ? (
    <img className={styles.logo} src={customerLogo} alt={`${name} logo`} />
  ) : (
    <IconLogo className={cn(!window.location.href.includes('/auth/sign-in') ? styles.logo : styles.SignInLogo)} />
  );

  if (isLink && !isDashboard) {
    return (
      <NavLink to="/" className={cn(styles.element, className)}>
        {Logo}
      </NavLink>
    );
  }
  return <div className={cn(styles.element, className)}>{Logo}</div>;
};

export default Logo;

interface ILogo {
  isLink?: boolean;
  className?: string;
}
