import React from "react";
import { useParams } from "react-router-dom";
import LayoutHeader from "../../components/Layout/LayoutHeader/LayoutHeader";
import Button from "../../components/Button/Button";
import { ReactComponent as IconButton } from "../../assets/icons/check.svg";
import styles from "../Reports/Reports.module.scss";
import { Column, Container, Row } from "../../components/Bootstrap/Bootstrap";
import { Form, Formik } from "formik";
import ReportTemplateValues from "../../components/ReportTemplateValues/ReportTemplateValues";
import ExpandGroup from "../../components/Expand/ExpandGroup/ExpandGroup";
import Expand from "../../components/Expand/Expand";
import ReportTemplateGeneral from "./ReportTemplateGeneral/ReportTemplateGeneral";
import ReportTemplateSummary from "./ReportTemplateSummary/ReportTemplateSummary";

const ReportTemplateView = () => {
  let { id } = useParams<{ id: string }>();
  const initialValues = {
    generalDescription: "",
    generalDescription_SummaryFormat: "",
    generalSharing: ["personal"],
    generalOrientation: ["portrait"],
    callsDescription: "",
    callsWidth: "",
    callsSummaryFormat: "",
    callsJustification: "",
    callsAggregateMethod: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => console.log(values)}
    >
      {({ errors, touched, values }) => (
        <Container fluid>
          <Form>
            <LayoutHeader title={id ? `Template ID: ${id}` : "Create template"}>
              <Button title="Cancel" type="plain" />
              <Button
                title="Save"
                icon={
                  <IconButton
                    className={styles.icon}
                    width="1.2rem"
                    height="1.5rem"
                  />
                }
                icon_position="right"
                typeAttr="submit"
              />
            </LayoutHeader>
            <Row>
              <Column className="col-12 col-lg-8">
                <ExpandGroup>
                  <Expand title="General" active>
                    <ReportTemplateGeneral touched={touched} errors={errors} />
                  </Expand>
                  <Expand title="Selected fields" level={0}>
                    <Expand title="Rates" level={1}>
                      Rates fields
                    </Expand>
                    <Expand title="Call Details" level={1}>
                      Call Details fields
                    </Expand>
                    <Expand title="Call Types" level={1}>
                      Call Types fields
                    </Expand>
                    <Expand title="Destination" level={1}>
                      Destination fields
                    </Expand>
                    <Expand title="Employee" level={1}>
                      Employee fields
                    </Expand>
                    <Expand title="Response Group" level={1}>
                      Response Group fields
                    </Expand>
                    <Expand title="Unigy Blue Wave" level={1}>
                      Unigy Blue Wave fields
                    </Expand>
                    <Expand title="Summary" level={1}>
                      <ReportTemplateSummary
                        touched={touched}
                        errors={errors}
                      />
                    </Expand>
                  </Expand>
                  <Expand title="Organization Structure" level={0}>
                    Empty
                  </Expand>
                  <Expand title="Response Group" level={0}>
                    Empty
                  </Expand>
                  <Expand title="Chart Options" level={0}>
                    Empty
                  </Expand>
                </ExpandGroup>
              </Column>
              <Column className="col-12 col-lg-4">
                <ReportTemplateValues values={values} />
              </Column>
            </Row>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default ReportTemplateView;
