import React from "react";
import styles from "./ActionTools.module.scss";
import useComponentVisible from "../UseComponentVisible/UseComponentVisible";
import cn from "classnames";

const PanelToolsOptions = (props: IPanelToolsOptions) => {
  const { children, type } = props;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, false);

  return (
    <div
      ref={ref}
      className={cn(styles.element, type === "inTable" && styles["inTable"])}
      onClick={() => setIsComponentVisible(!isComponentVisible)}
    >
      <PanelToolsOptionsTrigger />
      {isComponentVisible && <div className={styles.content}>{children}</div>}
    </div>
  );
};
export default PanelToolsOptions;

interface IPanelToolsOptions {
  children: any;
  type?: string;
}

const PanelToolsOptionsTrigger = () => {
  return (
    <button className={styles.trigger}>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </button>
  );
};
