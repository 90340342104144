import React from "react";
import styles from "./Button.module.scss";
import cn from "classnames";
import { NavLink } from "react-router-dom";

const Button = (props: IButton) => {
  const {
    path,
    title,
    icon,
    className,
    icon_position,
    disabled,
    type = "normal",
    typeAttr = "button",
    handleClick,
    scale = true,
    small,
    color,
  } = props;

  if (!path) {
    return (
      <button
        disabled={disabled ?? false}
        type={typeAttr}
        onClick={handleClick}
        className={cn(
          styles.element,
          type === "outline" && styles["isOutline"],
          type === "outline" && color === "orange" && styles["isOrange"],
          type === "tooltip" && styles["inTooltip"],
          type === "plain" && styles["isPlain"],
          icon_position === "left" && styles["iconLeft"],
          icon_position === "right" && styles["iconRight"],
          className && className,
          scale && styles["isScaled"],
          small && styles["isSmall"]
        )}
      >
        {icon && icon_position === "left" && icon}
        {title}
        {icon && icon_position !== "left" && icon}
      </button>
    );
  }

  return (
    <NavLink
      to={path}
      onClick={handleClick}
      className={cn(
        styles.element,
        type === "outline" && styles["isOutline"],
        type === "outline" && color === "orange" && styles["isOrange"],
        type === "tooltip" && styles["inTooltip"],
        type === "plain" && styles["isPlain"],
        icon_position === "left" && styles["iconLeft"],
        icon_position === "right" && styles["iconRight"],
        className && className
      )}
    >
      {icon && icon_position === "left" && icon}
      {title}
      {icon && icon_position !== "left" && icon}
    </NavLink>
  );
};

export default Button;

export interface IButton {
  type?: "outline" | "normal" | "tooltip" | "plain";
  path?: string | { pathname: string; state: any };
  title: string;
  className?: string;
  icon?: React.ReactElement;
  icon_position?: "left" | "right";
  typeAttr?: "button" | "submit";
  handleClick?: () => void;
  scale?: boolean;
  small?: boolean;
  color?: string;
  disabled?: boolean;
}
